import React, { useState, useEffect, useMemo } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Col, Row, Typography, Modal, Button } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import fileDownload from 'js-file-download';

import BackButton from 'src/components/common/BackButton';
import CustomButton from 'src/components/common/CustomButton';
import BoardModal from 'src/components/Modal/BoardModal';
import { PageWrapper, HeaderTitle } from 'src/components/common/Styles';
import { openNotification } from 'src/components/common/Notification';
import { CloseSquareOutlined, DownloadOutlined } from '@ant-design/icons';
import {isPturn } from 'src/utils';
import { useModal } from 'src/utils/hooks';
import { FileFromMimeType } from 'src/components/common/File';
import { userInfoVar } from 'src/apollo/cache';
import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';

const { Title, Text } = Typography;
const { confirm } = Modal;

const fileColors = {
  'image/gif': '#887053',
  'application/pdf': '#C64949',
  'application/zip': '#887053',
  'application/vnd.ms-powerpoint': '#C93810',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': '#C93810',
  'application/vnd.ms-excel': '#2D792F',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '#2D792F',
  'application/octet-stream': '#045E9B',
  default: '#887053',
};

function BoardDetail() {
  const history = useHistory();
  const { id, name } = useParams();
  const [boardInfo, setBoardInfo] = useState(null);
  const { visible, showModal, handleCancel, popupMode } = useModal();
  const company_idx = useMemo(() => userInfoVar().campus.company.idx, []);
  const company_name = userInfoVar()?.company_name;
  const { data: fetchBoards, refetch } = useQuery(queries.board.GET_BOARD_LIST, {
    fetchPolicy: 'no-cache',
    variables: { bo_table: name, company_idx },
  });

  const [updateHit] = useMutation(mutations.board.UPDATE_HIT, {
    variables: { boardWrite_idx: Number(id) },
    onCompleted(data) {
      console.log('on Completed data', data);
      refetch();
    },
  });

  useEffect(() => {
    if (fetchBoards) {
      const boardInfo = fetchBoards.getBoardList.filter((value) => value.idx === Number(id))[0];

      const customBoardInfo = {
        ...boardInfo,
      };

      setBoardInfo(customBoardInfo);

      if (!boardInfo) {
        infoConfirm('게시판이 존재하지 않습니다.', history.goBack);
      }
    }
  }, [fetchBoards, id]);

  useEffect(() => {
    if (id) {
      updateHit();
    }
  }, [id, updateHit]);

  const [deleteBoard] = useMutation(mutations.board.DELETE_BOARD, {
    variables: {
      boardWrite_idx_list: [Number(id)],
    },
    onCompleted(data) {
      if (data) {
        history.push(`/board/${name}`);
        openNotification('삭제 완료!');
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  const [deleteBoardFile] = useMutation(mutations.board.DELETE_BOARD_FILE, {
    onCompleted(data) {
      if (data) {
        refetch();
        openNotification('파일 삭제 완료!');
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  const infoConfirm = (msg, func) => {
    Modal.warning({
      title: msg,
      okText: '확인',
      onOk() {
        func();
      },
    });
  };

  const deleteConfirm = () => {
    confirm({
      title: '정말 삭제하시겠습니까?',
      okText: '예',
      okType: 'primary',
      cancelText: '아니오',
      onOk() {
        deleteBoard();
      },
      onCancel() {},
    });
  };

  const handleFileDelete = (file_idx) => {
    confirm({
      title: '파일을 삭제하시겠습니까?',
      okText: '예',
      okType: 'primary',
      cancelText: '아니오',
      onOk() {
        deleteBoardFile({
          variables: {
            boardFile_idx: Number(file_idx),
          },
          refetchQueries: [
            {
              query: queries.board.GET_BOARD_LIST,
              variables: { bo_table: name, company_idx },
            },
          ],
        });
      },
      onCancel() {},
    });
  };
  const handleDown = async (data, name) => {
    const blob = await fetch(data).then((r) => r.blob());
    fileDownload(blob, name);
  };

  const boardContentStyle = {
    padding: 12,
    //minHeight: '40vh',
    //display: 'flex',
  };
  return (
    <>
      <Row justify="space-around">
        <Col span={12}>
          <HeaderTitle level={4}>게시판 관리</HeaderTitle>
        </Col>
        <Col span={12} style={{ paddingTop: 5, textAlign: 'right' }}>
          <Col style={{ paddingTop: 5 }}>
            <BackButton />
          </Col>
        </Col>
      </Row>

      <Row gutter={[16, 16]} justify="center">
        <Col span={24}>
          <PageWrapper className="board-campusnotice-height">
            <Row justify="space-around">
              <Col
                span={24}
                style={{
                  background: '#edf3fb',
                  borderTop: '3px solid #a4d0e9',
                  padding: 10,
                }}
              >
                <Title level={4} style={{
                
                  margin: 0,
                }}>{boardInfo?.wr_subject}</Title>
              </Col>
            </Row>

            <Row justify="space-around" style={{ padding: 10 }}>
              <Col span={12} className="boarddetail-fullwidth">
                <Row>
                  <Col className="boarddetail-a board-b" span={4} style={{ width: '30%', fontSize: '0.9rem', color: '#777777' }}>
                    Name
                  </Col>
                  <Col className="boarddetail-a" span={8} style={{ color: '#202020' }}>
                    {boardInfo?.user?.name}
                  </Col>
                </Row>
              </Col>
              <Col span={12} className="boarddetail-fullwidth">
                <Row>
                  <Col className="boarddetail-a board-b" span={4} style={{ width: '30%', fontSize: '0.9rem', color: '#777777' }}>
                    Date
                  </Col>
                  <Col className="boarddetail-a" span={8} style={{ color: '#202020' }}>
                    {moment(boardInfo?.wr_datetime.replace('.000Z', '')).format('YYYY-MM-DD')}
                  </Col>

                 {
                  isPturn(company_name)&&<>
                  <Col className="boarddetail-a board-b topspmb" span={4} style={{ width: '30%', fontSize: '0.9rem', color: '#777777' }}>
                    Hit
                  </Col>
                  <Col className="boarddetail-a" span={8} style={{ color: '#202020' }}>
                    {boardInfo?.wr_hit}
                  </Col>
                  </>
                  }
                </Row>
              </Col>
            </Row>

            <hr />

            <Row>
              <Col span={24} style={boardContentStyle} className="campusnotice-section">
                {boardInfo &&
                  boardInfo?.wr_content?.split('\n').map((line, index) => {
                    return (
                      <span key={index}>
                        {line}
                        <br />
                      </span>
                    );
                  })}
              </Col>
            </Row>

            <hr />
            <Row>
              <Col span={24} style={{ padding: 10 }}>
                <Row>
                  <Col span={1} style={{ width: '30%', fontSize: '0.9rem', color: '#777777' }}>
                    File
                  </Col>
                  <Col span={23} style={{ color: '#202020' }}>
                    <Row>
                      {boardInfo?.boardFile?.length
                        ? boardInfo?.boardFile?.map((file) => {
                            const fileColor = fileColors[file.mimetype ? file.mimetype : 'default'];

                            return (
                              <>
                                <Col span={24} style={{ color: '#202020' }}>
                                  <FileFromMimeType
                                    file={{ ...file, mime_type: file.mimetype, path: file.bf_file, flatAudio: true }}
                                    thumbStyle={{ fontSize: 20, width: '100px', color: fileColor, padding: 10 }}
                                  />

                                  <Text style={{ marginRight: 10 }}>{file.bf_fileName}</Text>
                                  <DownloadOutlined
                                    style={{ fontSize: '1.2rem', marginRight: 10 }}
                                    onClick={() => handleDown(file.path, file.bf_fileName)}
                                  />
                                  {Number(boardInfo?.user?.idx) === Number(userInfoVar().idx) && (
                                    <CloseSquareOutlined style={{ fontSize: '1.2rem' }} onClick={() => handleFileDelete(file.idx)} />
                                  )}
                                </Col>
                              </>
                            );
                          })
                        : 'No file'}{' '}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            <hr />

            <Row justify="end">
              <Col span={24} style={{ textAlign: 'right' }}>
                {Number(boardInfo?.user?.idx) === Number(userInfoVar().idx) && (
                  <>
                    <CustomButton danger style={{ marginRight: 10 }} onClick={deleteConfirm}>
                      삭제
                    </CustomButton>
                    <CustomButton onClick={() => showModal('edit')} style={{ marginRight: 10 }} type="primary" ghost>
                      수정
                    </CustomButton>
                  </>
                )}

                <Link to={`/board/${name}`}>
                  <CustomButton style={{ marginRight: 10 }}>목록</CustomButton>
                </Link>
              </Col>
            </Row>
          </PageWrapper>
        </Col>
      </Row>

      {visible && (
        <BoardModal visible={visible} popupMode={popupMode} handleCancel={handleCancel} boardInfo={boardInfo} refetch={refetch} />
      )}
    </>
  );
}

export default BoardDetail;
