import { gql } from '@apollo/client';

export const GET_CONSULT_LIST = gql`
  query consultList($campus_idx: Int!, $ym: String, $page: Int, $take: Int, $filter_type: String) {
    consultList(campus_idx: $campus_idx, ym: $ym, page: $page, take: $take, filter_type: $filter_type) {
      idx
      school_info
      extra_data
      order_payment {
        idx
        pay_method
        idate
      }
      user {
        idx
        id
        birthday
        english_name
      }
      parent_phone
      name
      order_survey {
        idx
      }
      schedule {
        start_time
        end_time
      }
      order_leveltest {
        idx
        assigned_tests_idx
        assigned_tests {
          assigned_test_users {
            idx
            extra_data
            status
            score
            end_time
          }
          tests {
            idx
            batch_type
            test_type
            main_pdf {
              path
              name
            }
          }
        }
        choose_level
        reserve_date
      }
      order_consulting {
        memo
        manager {
          name
        }
      }
      test_result {
        idx
        score
        status
        start_time
        end_time
        idate
        assigned_tests {
          idx
        }
      }
      idate
      stat
    }
  }
`;

export const GET_CONSULT_LIST_QUESTIONS = gql`
  query consultList($campus_idx: Int!, $ym: String, $page: Int, $take: Int, $filter_type: String) {
    consultList(campus_idx: $campus_idx, ym: $ym, page: $page, take: $take, filter_type: $filter_type) {
      idx
      school_info
      extra_data
      order_payment {
        idx
        pay_method
        idate
      }
      user {
        idx
        id
        birthday
        english_name
      }
      parent_phone
      name
      order_survey {
        idx
      }
      schedule {
        start_time
        end_time
      }
      order_leveltest {
        idx
        assigned_tests_idx
        assigned_tests {
          assigned_test_users {
            idx
            extra_data
            status
            score
            end_time
            assigned_test_answers {
              code
              answer
              is_correct
            }
          }
          tests {
            idx
            batch_type
            test_type
            main_pdf {
              path
              name
            }
          }
        }
        choose_level
        reserve_date
      }
      order_consulting {
        memo
        manager {
          name
        }
      }
      test_result {
        idx
        score
        status
        start_time
        end_time
        idate
        extra_data
        assigned_tests {
          idx
        }
      }
      idate
      stat
    }
  }
`;

export const GET_ORDER = gql`
  query orderItemByIdx($idx: Int!) {
    orderItemByIdx(idx: $idx) {
      parent_phone
      name
    }
  }
`;
export const GET_ORDER_BY_ID = gql`
  query orderItemByIdx($idx: Int!) {
    orderItemByIdx(idx: $idx) {
      idx
      parent_phone
      name
      campus_idx
      idate
      school_info
      schedule {
        idx
        days
        start_time
        end_time
      }
      user_data {
        idx
        english_name
        birthday
      }
      order_leveltest_data {
        reserve_date
        idx
      }
      schedule_or_not
      schedule_detail
    }
  }
`;

export const GET_LEVEL_TEST_LIST = gql`
  query list {
    testsList(type: "publisher", testType: 2, take: 100) {
      tests {
        idx
        title
        batch_type
        test_type
      }
    }
  }
`;

export const GET_LEVEL_TEST_META_LIST = gql`
  query list($type: String!) {
    testsList(type: $type, testType: 2, take: 100) {
      tests {
        idx
        title
        batch_type
        test_type
      }
    }
  }
`;

export const GET_LEXILE_SCORE = gql`
  query getLexileScore($numberCorrect: Int!, $formId: String!) {
    getLexileScore(numberCorrect: $numberCorrect, formId: $formId) {
      json
    }
  }
`;

export const SMARTECLASS_PLACEMENT_TEST_LIST = gql`
  query smarteclassPlacementTestList($campus_idx: Int!, $ym: String!) {
    smarteclassPlacementTestList(campus_idx: $campus_idx, ym: $ym) {
      idx
      user {
        idx
        parent_phone
        school_grade
      }
      school_info
      parent_phone
      name
      order_leveltest {
        idx
        assigned_tests_idx
        assigned_tests {
          tests {
            idx
          }
        }
        choose_level
        reserve_date
      }
      test_result {
        idx
        score
        status
        assigned_tests {
          idx
        }
      }
      idate
      stat
    }
  }
`;

export const META_STATICS_DETAILS_LIST_FOR_RETESTER = gql`
  query getMetaStaticsDetails($campus_idx: Int!, $filter_type: String, $from_date: String, $to_date: String) {
    getMetaStaticsDetails(campus_idx: $campus_idx, filter_type: $filter_type, from_date: $from_date, to_date: $to_date) {
      user {
        id
        birthday
        english_name
      }
      parent_phone
      name
      schedule {
        end_time
      }
      order_leveltest {
        assigned_tests {
          tests {
            batch_type
          }
        }
      }
      test_result {
        end_time
      }
      stat
    }
  }
`;
export const META_STATICS_DETAILS_LIST = gql`
  query getMetaStaticsDetails($campus_idx: Int!, $fetchtype: String, $gettype: String, $filter_type: String) {
    getMetaStaticsDetails(campus_idx: $campus_idx, fetchtype: $fetchtype, gettype: $gettype, filter_type: $filter_type) {
      idx
      school_info
      user {
        idx
        id
        birthday
        english_name
      }
      parent_phone
      name
      order_survey {
        idx
      }
      schedule {
        start_time
        end_time
      }
      order_leveltest {
        idx
        assigned_tests_idx
        assigned_tests {
          assigned_test_users {
            idx
            extra_data
            status
            end_time
          }
          tests {
            idx
            batch_type
            test_type
          }
        }
        choose_level
        reserve_date
      }
      order_consulting {
        memo
        manager {
          name
        }
      }
      test_result {
        idx
        score
        status
        # extra_data
        end_time
        assigned_tests {
          idx
        }
      }
      idate
      stat
    }
  }
`;
export const META_COMPANY_STATICS_DETAILS = gql`
  query getMetaCompanyStaticsDetails($company_idx: Int!, $fetchtype: String, $gettype: String, $filter_type: String) {
    getMetaCompanyStaticsDetails(company_idx: $company_idx, fetchtype: $fetchtype, gettype: $gettype, filter_type: $filter_type) {
      idx
      campus_idx
      stat
      parent_phone
      name
      school_info
      campus {
        name
        stat
      }
      user {
        birthday
        english_name
      }
      test_result {
        idx
        status
        extra_data
        assigned_tests {
          idx
        }
      }
      order_leveltest {
        idx
        assigned_tests_idx
        assigned_tests {
          assigned_test_users {
            idx
            extra_data
            status
          }
          tests {
            idx
            batch_type
            test_type
          }
        }
        choose_level
        reserve_date
      }
    }
  }
`;
