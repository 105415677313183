import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Button, Form, Select, Input, Tabs, Modal, Upload, Spin, Divider, Button as AButton, Switch } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { UPLOAD_FILE, CREATE_SIGNED_S3_UPLOAD } from 'src/operations/mutations/uploadFileS3';
import {
  CREATE_AI_GROUP_TOPIC,
  CREATE_ASSISTANTS,
  UPLOAD_OPEN_AI_FILE,
  CREATE_THREAD,
  DELETE_UPLOADED_FILE,
  UPDATE_ASSISTANTS,
  CREATE_VECTOR,
  UPLOAD_FILE_IN_VECTOR,
  UPDATE_THREAD,
} from 'src/operations/mutations/aiGroupTopic';
import { OPEN_AI_MESSAGE, CREATE_RUNS, MESSAGE_LIST, GET_STEPS, GET_MESSAGE } from 'src/operations/mutations/aiGroupTopic';
import { AI_TALKING_DETAIL, GET_CONTENT_LIST } from 'src/operations/queries/aiGroupTopic';
import Loading from 'src/components/common/Loading';
import RecordRTC, { StereoAudioRecorder } from 'recordrtc';
import MessageBox from 'src/components/DashBoard/SubPage/MessageBox';
import InfiniteScroll from 'react-infinite-scroll-component';
import MessageItem from 'src/components/DashBoard/SubPage/MessageItem';
import ReactPlayer from 'react-player';
import SoundEffect from 'src/components/common/SoundEffect';
import { SendOutlined } from '@ant-design/icons';
import { captureUserMedia } from 'src/utils';
import { userInfoVar } from 'src/apollo/cache';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTextToSpeach } from 'src/utils/hooks';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';

import moment from 'moment';
import { message } from 'antd';
import * as axios from 'axios';
import XLSX from 'xlsx';
import { UploadOutlined } from '@ant-design/icons';
import fileDownload from 'js-file-download';
import styled from 'styled-components';
const clickSound = '/audio/LevelUp/button_click.mp3';
const recordingStartSound = '/audio/LevelUp/Game_Wrong.mp3';

const LoadingScreen = styled.div`
  height: 100%;
  background: transparent;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Container = styled.div`
  background-color: 'red';
  min-height: 100vh;
  /* like display:flex but will allow bleeding over the window width */
  min-width: 100vw;
  display: inline-flex;
`;

const PROMPTS = [
  ` I am a first grader kid who does not speak English well.
    So talk to me with easy words and do not use long sentences.
    I am going to give you a story. You probably know the story.
    It is called "The Three Little Piggy brothers".
    You can ask me five questions one at a time about the story and after one question is done ask next question.
    If I got it wrong, let me know that I got it wrong, and tell me the right answer. 
    
    Ask next question after you comment your feedback.
    "
    
    Once upon a time, there were three little pig brothers: Piggy 1, Piggy 2, and Piggy 3. 
    They decided to build houses to keep themselves safe from the big bad wolf.
    Piggy 1 was in a hurry and made a house of straw. It was quick but not very strong. 
    Piggy 2 also wanted to finish fast, so he built his house with sticks. It was a bit stronger than straw, but still not very tough.
    Now, Piggy 3 was wise. He took his time and built a sturdy house with strong bricks. It took more effort, but he knew it would be the safest.
    One day, the big bad wolf came by. He huffed and puffed and blew down the straw house of Piggy 1! Oh no! The little pig ran to Piggy 2's house.
    The wolf huffed and puffed again, and this time, the house made of sticks also fell. Now, both Piggy 1 and Piggy 2 were scared. They all rushed to Piggy 3's brick house.
    The wolf tried with all his might to blow down the brick house, but it was too strong. The three little pigs were safe inside. The wolf couldn't get them!
    In the end, the wolf gave up and went away. The three little pigs were happy and learned an important lesson – taking time to do things right and making strong choices pays off.
    And they all lived happily ever after in their sturdy brick house. The end! "
    
    You can now start by asking me the first question about the story.`,
];

const TO_LESSON = `Repeat last thing you said again.`;
const PAUSE = `Let's stop the conversation. I need to take a break and we will continue later.`;
const RESTART = `Understand the previous conversation and start from where we left off.`;
const STOP = `Stop the conversation.`;
const REPEAT = `Can we go back to beginning of the conversation?, and ask me if I am ready to start.
  If I say 'yes' then ask me the first question about our main topic.
  If I say 'no' then ask me a few questions about the story one at a time that you have not ask me before.`;
const EASY = `Can you say it again in easier words for 7 years old kid who does not speak English well? And you can also elaborate what you are saying if you could.`;
const TRANSLATE = `Can you say what you just said in Korean and afterwards go back to English mode?`;
const SHORT = `Can say what you said in short, concise, easy to understand way?`;
const ASK = `Translate this into English and go back and ask the last question again.`;
const LESSON = `please go back to original topic which was asked in the beginning of the conversation.
  Ask if the student has any questions about the original topic.
  If answer is yes then answer the question,  if no then ask the student if you want AI to ask a question about the topic.`;
const REVIEWSAVE = `Give me a summary of our conversation and an opinion about how well our conversation in English was. 
  Check our conversation dialogue to see if I made any grammatical errors or wrong expression in English or wrong answers; and correct it, give me explanation for me one by one in a format of
  + Original :  ,
  - Corrections : ,
  * Explanation :
  ---------------
  At the end give me an over all comment to encourage me learning English.
  After that translate in Korean in same format.`;

const { TextArea } = Input;
const { Option } = Select;
const AddGroupList = () => {
  const companyName = useSelector(classStoreData);
  let isUploadInProgress = false;
  const [formControl] = Form.useForm();
  const [modalForm] = Form.useForm();
  const [contentList, setContentList] = useState([]);
  const [contentIdx, setContentIdx] = useState(userInfoVar()?.type == 5 ? 1 : null);
  const [saveData, setSaveData] = useState(false);
  const [groupIdx, setGroupIdx] = useState('');
  // const [folderType, setFolderType] = useState('campus');
  const [folderType, setFolderType] = useState(
    userInfoVar()?.type == 5 ? 'gen content' : userInfoVar()?.type == 2 ? 'campus' : userInfoVar()?.type == 4 ? 'publisher' : 'me',
  );
  const [loading, setLoading] = useState(false);
  const [topicType, setTopicType] = useState('ai-talking-tutor');
  const [level, setLevel] = useState('1');
  const [tabIndex, setTabIndex] = useState('1');
  const [modalTabIndex, setModalTabIndex] = useState('1');
  const [tabVal, setTabVal] = useState('');
  const [promptValidate, setPromptValidate] = useState(false);
  const [formSubmitClick, setFormSubmitClick] = useState(false);
  const [btnSaveTest, setBtnSaveTest] = useState(false);
  const [recordText, setRecordText] = useState('');
  const [pauseStop, setPauseStop] = useState({ pause: false, stop: false });
  const [imageAudio, setImageAudio] = useState({ image: false, audio: false });
  // const [changeIcon, setChangeIcon] = useState(false);
  const [modalTabVal, setModalTabVal] = useState({
    modal_question: `I am a student ask me this question`,
    modal_chat: ``,
    modal_debate: ``,
    modal_main_instruction: `I am a Korean student who is trying to learn English.
        I am 7 years old who do not speak English well.
        Therefore, you need to use very easy words all the time.
        
        Also, you must assume that because I am young, I don’t have much background knowledge of
        many things, so ask me some words used in the conversation that I might not know and explain to me what it means. If I said I know then don’t explain.`,
    modal_main_description: '',
  });
  const [dupModalTabVal, setDupModalTabVal] = useState(modalTabVal);
  const [validate, setValidate] = useState(true);
  const [indx, setIndx] = useState(0);
  const [groupDisable, setGroupDisable] = useState(false);
  const [uploadDisable, setUploadDisable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [filedata, setFiledata] = useState([]);
  const [uploadedFile, setUploadedFile] = useState([]);
  const [instructions, setInstructions] = useState('I am a student ask me this question');
  const [testIdx, setTestIdx] = useState({ test_assistant_idx: '', test_thread_idx: '', test_run_idx: '', test_vector_idx: '' });
  // const [disableControl, setDisableControl] = useState(false);
  const [micValue, setMicValue] = useState('mice_1.png');
  const [soundPlay, setSoundPlay] = useState(false);
  const [cancelBtnClick, setCancelBtnClick] = useState(false);
  const [sound, setSound] = useState(clickSound);
  const [currentRecLength, setCurrentRecLenght] = useState(0);
  const [micBtnClick, setMicBtnClick] = useState(0);
  const [pg, setPg] = useState({ current: 0, pageSize: 10 });
  const [disableBtnVal, setDisableBtnVal] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [currentText, setCurrentText] = useState('');
  const [micBtnChange, setMicBtnChange] = useState(false);
  const [promptActive, setPromptActive] = useState(false);
  const [msgIdx, setMsgIdx] = useState('');
  const [active, setActive] = useState(false);
  const [currentUrl, setCurrentUrl] = useState('');
  const [speed, setSpeed] = useState(1.0);
  const [voice, setVoice] = useState('shimmer');
  const [lastBlob, setLastBlob] = useState(null);
  const [chatList, setChatList] = useState([]);
  const [lastUserMsg, setLastUserMsg] = useState(tabVal?.question);
  const [startRecording, setStartRecording] = useState(false);
  const [stopInterval, setStopInterval] = useState(0);
  const [activeAnswer, setActiveAnswer] = useState(false);
  const [clearBtn, setClearBtn] = useState(false);
  // const [uploadfile, setUploadFile] = useState("");
  const [audioPaths, setAudioPaths] = useState([]);
  const [imagePaths, setImagePaths] = useState([]);
  const [uploadFileList, setUploadFileList] = useState([]);
  const [gptModel, setGptModel] = useState('gpt-4o-mini');
  const messageBoxRef = useRef(null);
  const recorderStreams = useRef(null);
  const history = useHistory();
  const recordAudio = useRef(null);
  const autoSendRef = useRef([]);

  const [upload] = useMutation(UPLOAD_FILE);
  const [uploadFileS3] = useMutation(CREATE_SIGNED_S3_UPLOAD);
  const [createAIGroupTopic, { loading: createLoading }] = useMutation(CREATE_AI_GROUP_TOPIC);
  const [createAssistants, { loading: assistantsLoading }] = useMutation(CREATE_ASSISTANTS);
  const [updateAssistants, { loading: updateLoading }] = useMutation(UPDATE_ASSISTANTS);
  const [uploadOpenAiFile, { loading: fileLoading }] = useMutation(UPLOAD_OPEN_AI_FILE);
  const [createVector, { loading: vectorLoading }] = useMutation(CREATE_VECTOR);
  const [uploadFileInVectorStore, { loading: uploadingFile }] = useMutation(UPLOAD_FILE_IN_VECTOR);
  const [createThread, { loading: threadLoading }] = useMutation(CREATE_THREAD);
  const [updateThread, { loading: updateThreadloading }] = useMutation(UPDATE_THREAD);
  const [openAiMessage, { data: openAiMsg, loading: openAiMsgLoading }] = useMutation(OPEN_AI_MESSAGE);
  const [createRun, { data: runData, loading: runLoading }] = useMutation(CREATE_RUNS);
  const [getSteps, { data: stepsData, loading: stepLoading }] = useMutation(GET_STEPS);
  const [getMsg, { data: msgData, loading: msgLoading }] = useMutation(GET_MESSAGE);
  const [deleteFile, { loading: deleteLoding }] = useMutation(DELETE_UPLOADED_FILE, {
    onError(error) {
      console.log('error', error);
    },
    onCompleted(data) {
      if (data?.deleteOpenAIUploadedFile) {
        setUploadedFile([]);
      }
    },
  });
  const { data: getContentList } = useQuery(GET_CONTENT_LIST, { variables: { type: 'ai-talking-tutor' } });
  const { data: talkingDetail, refetch: refetchTalking } = useQuery(AI_TALKING_DETAIL, {
    fetchPolicy: 'no-cache',
    variables: {
      groupIdx: groupIdx !== ' ' && groupIdx !== '' ? groupIdx : 0,
    },
  });
  const { isSpeaking, texts, speak, stop } = useTextToSpeach(speed, voice);

  const onDataAvailableCallback = useCallback(async (blob) => {
    setCurrentRecLenght((prev) => prev + 100);
  }, []);

  const processRecordCallback = useCallback(async (blob, val, cb) => {
    let headers;
    if (val === 'ask') {
      headers = { 'x-spoken-language': 'ko' };
    } else {
      headers = { 'Content-Type': 'audio/webm' };
    }
    try {
      if (blob !== undefined) {
        const res = await axios('https://a2vdkjqiwop2mrkfprrzmp7yqq0xdicc.lambda-url.ap-northeast-2.on.aws/', {
          method: 'POST',
          data: blob,
          headers,
        });
        // if (val === "ask") {
        //     askForm.setFieldValue('korean_text', res.data.text);
        //     askForm.submit();
        // } else {
        if (val === STOP) {
          setDisableBtnVal(true);
        } else {
          setDisableBtnVal(false);
        }
        if (
          val === PAUSE ||
          val === STOP ||
          val === TO_LESSON ||
          val === REPEAT ||
          val === EASY ||
          val === TRANSLATE ||
          val === SHORT ||
          val === ASK ||
          val === LESSON ||
          val === REVIEWSAVE ||
          val === 'ask-stop-recording' ||
          val === 'tab-change' ||
          val === 'message' ||
          val === 'clear' ||
          val === 'send-text'
        ) {
        } else {
          setCurrentText(res.data.text);
          setRecordText(res?.data?.text);
          if (cb) {
            cb(res.data.text);
          }
          // setTabVal({
          //     question: res.data.text,
          // });
        }
        // }
      }
    } catch (error) {
      console.error('Error occurred:', error);
    }
  }, []);

  useEffect(() => {
    if (startRecording) {
      const startTime = Date.now();
      const interval = setInterval(() => {
        const elapsedTime = Date.now() - startTime;
        const percentElapsed = (elapsedTime / 30000) * 100;
        if (percentElapsed <= 100) {
          setStopInterval(percentElapsed);
        } else {
          clearInterval(interval);
          setStopInterval(100);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [startRecording]);
  useEffect(() => {
    if (startRecording && stopInterval == 100) {
      setPauseStop((prevState) => ({
        ...prevState,
        stop: true,
      }));
      stopRecording(STOP);
      setMicBtnClick(0);
      setActive(false);
      setMicBtnChange(true);
      setMicValue('mice_1.png');
    }
  }, [stopInterval]);

  // useEffect(() => {
  //     if (testIdx?.test_assistant_idx !== "" && testIdx?.test_thread_idx !== "") {
  //         setDisableControl(true)
  //     }
  // }, [testIdx])

  const levelList = useMemo(() => {
    return companyName === 'aone' && folderType !== 'gen content'
      ? ['CS', 'AS', 'TS', 'GS', 'SS', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
      : [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  }, [companyName, folderType]);

  useEffect(() => {
    if (talkingDetail !== undefined) {
      let tmp = [];
      talkingDetail?.aiTalkingDetail?.ai_topic_detail?.map((ele) => {
        let obj = JSON.parse(ele.data);
        obj.book['id'] = ele?.idx;
        obj.book['group_name'] = talkingDetail?.aiTalkingDetail.group_name;
        obj.book['ai_talking_group_idx'] = talkingDetail?.aiTalkingDetail.idx;
        obj.book['level'] = talkingDetail?.aiTalkingDetail.level;
        tmp.push(obj.book);
      });
      if (tmp?.length > 0) {
        setContentList(tmp);
        formControl.setFieldsValue({
          group_name: tmp[0]?.group_name,
          title: tmp[0]?.title,
          topic: tmp[0]?.topic,
          level: talkingDetail?.aiTalkingDetail?.level ? talkingDetail?.aiTalkingDetail?.level : 'mx 01',
          id: tmp[0]?.id,
        });

        if (tmp[0]?.prompt?.question) {
          setTabVal({ question: tmp[0]?.prompt?.question });
          setTabIndex('1');
        }
        if (tmp[0]?.prompt?.chat) {
          setTabVal({ chat: tmp[0]?.prompt?.chat });
          setTabIndex('2');
        }
        if (tmp[0]?.prompt?.debate) {
          setTabVal({ debate: tmp[0]?.prompt?.debate });
          setTabIndex('3');
        }
        setGroupDisable(true);
        setUploadDisable(true);
      }
    }
  }, [talkingDetail]);

  useEffect(() => {
    if (level !== -1) {
      setPg({ current: 0, pageSize: 10 });
    }
    setPg({ ...pg, current: 0 });
  }, [folderType, topicType, level]);

  useEffect(() => {
    const uploadFiles = async () => {
      if (uploadedFile?.length > 0) {
        try {
          await updateAssistants({
            variables: {
              files: JSON.stringify(uploadedFile),
              instructions: modalTabVal?.modal_main_instruction,
              description: modalTabVal?.modal_main_description,
              name: contentList?.length ? contentList[0].group_name : '',
              assistant_id: testIdx?.test_assistant_idx,
              vector_id: testIdx?.test_vector_idx,
              image_file_list: JSON.stringify(imagePaths.map((item) => item.path)),
              audio_file_list: JSON.stringify(audioPaths.map((item) => item.path)),
              model: gptModel,
            },
          }).then(async (result) => {
            console.log('result', result);
            const { data } = await updateThread({
              variables: {
                idx: undefined,
                thread_idx: testIdx?.test_thread_idx,
                vector_store_idx: testIdx?.test_vector_idx,
              },
            });
          });
          // if (data?.updateAssistants) {
          //     const assistantData = JSON.parse(data?.updateAssistants);
          //     setTestIdx((prevState) => ({
          //         ...prevState,
          //         test_assistant_idx: assistantData?.id,
          //     }));
          //     await createThread({
          //         variables: {
          //             idx: undefined,
          //         },
          //     }).then((res) => {
          //         setTestIdx((prevState) => ({
          //             ...prevState,
          //             test_thread_idx: res?.data?.createThread?.id,
          //         }));
          //     });
          // }
        } catch (error) {
          console.error('Error creating assistants:', error);
        }
      }
    };

    uploadFiles();
  }, [uploadedFile]);

  useEffect(() => {
    if (currentText || recordText) {
      setDisableBtn(false);
    }
  }, [currentText]);

  useEffect(() => {
    // if (contentList?.length > 0) {
    const assistant = async () => {
      try {
        const { data } = await createAssistants({
          variables: {
            files: uploadedFile?.length > 0 ? JSON.stringify(uploadedFile) : '',
            instructions: modalTabVal?.modal_main_instruction,
            description: modalTabVal?.modal_main_description,
            name: contentList?.length ? contentList[0].group_name : '',
            model: gptModel,
          },
        });
        if (data?.createAssistants) {
          const assistantData = JSON.parse(data?.createAssistants);
          setTestIdx((prevState) => ({
            ...prevState,
            test_assistant_idx: assistantData?.id,
          }));
          // await createThread({
          //   variables: {
          //     idx: undefined,
          //   },
          // }).then((res) => {
          //   setTestIdx((prevState) => ({
          //     ...prevState,
          //     test_thread_idx: res?.data?.createThread?.id,
          //   }));
          // });
        }
      } catch (error) {
        console.error('Error creating assistants:', error);
      }
      // }
    };
    assistant();
    // }
  }, []);

  const handleSoundEnd = () => {
    setSound(undefined);
    setSoundPlay(false);
    if (cancelBtnClick) {
      history.goBack();
    }
  };

  const handlePlayEnd = () => {
    if (micBtnChange) {
      setMicValue('mice_1.png');
    } else {
      setMicValue('stop2x.png');
    }
    // if (disableBtnVal) {
    //     setDisableBtn(false)
    // } else {
    //     setDisableBtn(true)
    // }
    if ((pauseStop?.stop || pauseStop?.pause) && disableBtnVal) {
      // if (pauseStop?.stop) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
    setCurrentUrl('');
    if (!micBtnChange) {
      startRecord();
      setStartRecording(true);
    }
  };
  useEffect(() => {
    if (!isSpeaking && texts.length > 0) {
      handlePlayEnd();
    }
  }, [isSpeaking, texts]);
  const startRecord = () => {
    if (recordAudio.current) return;
    setSoundPlay(true);
    setSound(recordingStartSound);
    captureUserMedia({ audio: { echoCancellation: true } }, async (stream) => {
      recorderStreams.current = stream;
      recordAudio.current = RecordRTC(stream, {
        type: 'audio',
        mimeType: 'audio/webm',
        sampleRate: 48000,
        desiredSampRate: 16000,

        recorderType: StereoAudioRecorder,
        numberOfAudioChannels: 1,

        //1)
        // get intervals based blobs
        // value in milliseconds
        // as you might not want to make detect calls every seconds
        timeSlice: 100,

        // 2)
        // as soon as the stream is available
        ondataavailable: onDataAvailableCallback,
      });
      setLastBlob(null);
      recordAudio.current.startRecording();
    });
  };

  const handleReRecord = () => {
    if (autoSendRef.current.length) {
      clearTimeout(autoSendRef.current[0]);
      autoSendRef.current = [];
    }
    if (recordAudio.current) {
      stopRecording();
    }
    setMicValue('stop2x.png');
    setDisableBtn(false);
    setSoundPlay(true);
    setSound(clickSound);
    setMicBtnClick(0);
    setActive(true);
    setMicBtnChange(false);
    setRecordText('');
    setCurrentText('');
    setLastBlob(null);
    setStartRecording(true);
    startRecord();
  };

  const stopRecording = (val) => {
    if (recordAudio?.current != null) {
      recordAudio?.current?.stopRecording(async () => {
        let reader = new FileReader();

        reader.onload = async function (e) {
          processRecordCallback(recordAudio?.current?.getBlob(), val, (text) => {
            autoSendRef.current = [
              setTimeout(() => {
                onSubmit(text);
              }, 5000),
              new Date(),
            ];
          });
          setLastBlob(recordAudio.current.toURL());
          recordAudio?.current.destroy();
          recordAudio.current = null;
          if (recorderStreams?.current) {
            recorderStreams?.current?.getTracks().forEach((track) => track.stop());
            recorderStreams.current = null;
          }
        };

        try {
          reader?.readAsArrayBuffer(recordAudio?.current?.getBlob());
        } catch (error) {
          console.log('error', error);
        }
      });
    }
  };

  const handleFormSubmit = async () => {
    try {
      const fieldValue = formControl?.getFieldValue();
      setSoundPlay(true);
      setSound(clickSound);
      if (recordAudio.current) {
        stopRecording();
      }
      if (
        (fieldValue?.topic !== undefined && fieldValue?.title !== undefined && tabVal == '') ||
        tabVal?.question == '' ||
        tabVal?.chat == '' ||
        tabVal?.debate == ''
      ) {
        setPromptValidate(true);
        setFormSubmitClick(false);
      } else {
        setPromptValidate(false);
        setFormSubmitClick(false);
        if (!saveData) {
          setValidate(false);
          formControl
            .validateFields()
            .then((formData) => {
              let updatedObject;
              if (fieldValue?.id) {
                const indexToUpdate = contentList.findIndex((item) => item.id === fieldValue?.id);
                if (indexToUpdate !== -1) {
                  const updatedObj = { ...contentList[indexToUpdate] };
                  updatedObj.group_name = fieldValue.group_name;
                  updatedObj.title = fieldValue.title;
                  updatedObj.topic = fieldValue.topic;
                  updatedObj.level = fieldValue.level;
                  updatedObj.image_file_list = imagePaths?.length > 0 ? JSON.stringify(imagePaths.map((item) => item.path)) : undefined;
                  updatedObj.audio_file_list = audioPaths?.length > 0 ? JSON.stringify(audioPaths.map((item) => item.path)) : undefined;
                  const updatedList = [...contentList];
                  setTabIndex('1');
                  updatedList[indexToUpdate] = updatedObj;
                  setContentList(updatedList);
                }
              } else {
                // if ((fieldValue?.title !== '' || fieldValue?.title !== undefined) || (fieldValue?.topic !== '' || fieldValue.topic !== undefined)) {
                if (fieldValue?.title !== undefined || fieldValue.topic !== undefined) {
                  updatedObject = {
                    ...fieldValue,
                    prompt: tabVal,
                    level: level,
                    image_file_list: imagePaths?.length > 0 ? JSON.stringify(imagePaths.map((item) => item.path)) : undefined,
                    audio_file_list: audioPaths?.length > 0 ? JSON.stringify(audioPaths.map((item) => item.path)) : undefined,
                  };
                  setContentList([...contentList, updatedObject]);
                } else {
                  console.log('contentList', contentList);
                }
              }
              setSaveData(true);
            })
            .catch((errorInfo) => {
              console.error('errorInfo', errorInfo);
            });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (
      stepsData?.getSteps?.data[0]?.status !== 'completed' &&
      testIdx?.test_run_idx !== undefined &&
      testIdx?.test_run_idx !== null &&
      testIdx?.test_run_idx !== ''
    ) {
      const interval = setInterval(() => {
        getSteps({
          variables: {
            thread_id: testIdx?.test_thread_idx,
            run_id: testIdx?.test_run_idx,
          },
        });
      }, 2000);
      return () => clearInterval(interval);
    }
    if (stepsData?.getSteps?.data[0]?.status == 'completed') {
      if (msgIdx) {
        getMsg({
          variables: {
            thread_id: testIdx?.test_thread_idx,
            message_id: msgIdx,
          },
        }).then((res) => {
          const result = [
            {
              user: userInfoVar()?.id,
              chat_user_id: res?.data?.openaiMessage?.role == 'user' ? userInfoVar()?.id : 'AI봇',
              chat_user_name: res.data?.getMessage?.data[0] === 'user' ? userInfoVar()?.name : 'AI봇',
              content: res.data?.getMessage?.data[0]?.content[0]?.text?.value,
              created: moment().format('YYYY-MM-DD HH:mm:ss.sss'),
            },
          ];
          setChatList([...chatList, ...result]);
          speak(
            res?.data.getMessage?.data[0]?.content[0]?.text.value,
            () => {
              setMicValue('robot.png');
            },
            audioPaths.map((item) => item.path),
          );
          if (pauseStop?.stop || pauseStop?.pause) {
            setDisableBtn(true);
          } else {
            setDisableBtn(false);
          }
        });
      }
    }
  }, [stepsData]);

  useEffect(() => {
    const saveDataToAIGroup = async () => {
      if (saveData) {
        try {
          const { data } = await updateAssistants({
            variables: {
              files: JSON.stringify(uploadedFile),
              instructions: modalTabVal?.modal_main_instruction,
              description: modalTabVal?.modal_main_description,
              name: contentList?.length ? contentList[0].group_name : '',
              assistant_id: testIdx?.test_assistant_idx,
              vector_id: testIdx?.test_vector_idx,
              image_file_list: JSON.stringify(imagePaths),
              audio_file_list: JSON.stringify(audioPaths),
              model: gptModel,
            },
          });
          if (data) {
            // const res = await updateThread({
            //     variables: {
            //         idx: undefined,
            //         thread_idx: testIdx?.test_thread_idx,
            //         vector_store_idx: testIdx?.test_vector_idx
            //     },
            // });
            // .then(async (res) => {
            //     console.log("res---->>>>>>1212", res);
            //     if (res?.data?.updateThread?.id) {
            //         const assistant = JSON.parse(data?.updateAssistants);
            //         await createAIGroupTopic({
            //             variables: {
            //                 folder_type: folderType,
            //                 type: 'ai-talking-tutor',
            //                 content_idx: contentIdx,
            //                 data: JSON.stringify(contentList),
            //                 assistants_id: assistant.id,
            //                 assistants_data: JSON.stringify(assistant),
            //                 files_list: JSON.stringify(uploadedFile),
            //                 vector_idx: res?.data?.updateThread?.tool_resources?.file_search?.vector_store_ids[0],
            //             },
            //         }).then((result) => {
            //             formControl.resetFields();
            //             history.push('/ai-talking-tutor/topiclist');
            //         });
            //     }
            // })
            // console.log("res--->>>>>>>>>>>>123", res);
            const assistant = JSON.parse(data?.updateAssistants);
            const updatedArray = contentList?.map((item) => ({
              ...item,
              pre_instruction_prompt: modalTabVal,
            }));
            await createAIGroupTopic({
              variables: {
                folder_type: folderType,
                type: 'ai-talking-tutor',
                content_idx: contentIdx,
                data: JSON.stringify(updatedArray),
                assistants_id: assistant.id,
                assistants_data: JSON.stringify(assistant),
                files_list: JSON.stringify(uploadedFile),
                vector_idx: testIdx?.test_vector_idx,
                // image_file_list: JSON.stringify(imagePaths),
                // audio_file_list: JSON.stringify(audioPaths)
                //vector_idx: res?.data?.updateThread?.tool_resources?.file_search?.vector_store_ids[0],
              },
            }).then((result) => {
              formControl.resetFields();
              if (btnSaveTest) {
                history.push(`/ai-talking-tutor/edit/${result.data.createAIGroupTopic}`);
              } else {
                history.push('/ai-talking-tutor/topiclist');
              }
            });
          }
        } catch (error) {
          console.error('Error uploading file:', error);
          message.error('Failed to upload file');
        }
      }
    };

    saveDataToAIGroup();
  }, [saveData]);

  const handleUplaod = (data) => {
    console.log('data', data);
    return new Promise(async (resolve, reject) => {
      try {
        const { name: filename, type: mimetype } = data;
        let res = await uploadFileS3({
          variables: {
            name: `ai-talking-tutor/${testIdx?.test_assistant_idx}/${indx}/${filename}`,
            contentType: mimetype,
            replace: true,
          },
        });
        await fetch(res.data.createSignedS3Upload, {
          method: 'PUT',
          body: data,
          headers: {
            'Content-Type': mimetype,
            'x-amz-acl': 'public-read',
          },
        });

        const s3Url = res.data.createSignedS3Upload.split('?')[0].replace('s3.ap-northeast-2.amazonaws.com/', '');
        console.log('mimetype', mimetype);
        if (mimetype.startsWith('audio')) {
          setAudioPaths((prevaudioPaths) => {
            const updatedaudioPaths = [...prevaudioPaths, { name: filename, path: s3Url }];
            return updatedaudioPaths;
          });
          setImageAudio((prevState) => ({
            ...prevState,
            image: true,
          }));
        } else {
          setImagePaths((prevaudioPaths) => {
            const updatedImagePaths = [...prevaudioPaths, { name: filename, path: s3Url }];
            return updatedImagePaths;
          });
          setImageAudio((prevState) => ({
            ...prevState,
            image: true,
          }));
        }
        setTimeout(() => {
          resolve(s3Url);
        }, 1000);

        // setUploadFile(data.name)
      } catch (e) {
        reject(e);
      }
    });
  };

  const handleFileSelect = async (val) => {
    try {
      const { data } = await createAssistants({
        variables: {
          files: JSON.stringify(uploadedFile),
          instructions: modalTabVal?.modal_main_instruction,
          description: modalTabVal?.modal_main_description,
          name: val?.length ? val[0].group_name : '',
          model: gptModel,
        },
      });

      if (data) {
        const assistant = JSON.parse(data?.createAssistants);
        setTestIdx((prevState) => ({
          ...prevState,
          test_assistant_idx: assistant?.id,
        }));
        // await createThread({
        //   variables: {
        //     idx: undefined,
        //   },
        // }).then((res) => {
        //   setTestIdx((prevState) => ({
        //     ...prevState,
        //     test_thread_idx: res?.data?.createThread?.id,
        //   }));
        // });
        // if (saveData) {
        await createAIGroupTopic({
          variables: {
            folder_type: folderType,
            type: 'ai-talking-tutor',
            content_idx: contentIdx,
            data: JSON.stringify(val),
            assistants_id: assistant.id,
            assistants_data: JSON.stringify(assistant),
            files_list: JSON.stringify(uploadedFile),
            vector_idx: testIdx?.test_vector_idx,
          },
        }).then((result) => {
          setGroupIdx(result.data.createAIGroupTopic);
          setValidate(false);
        });
      }
    } catch (e) {
      console.log(e);
    }

    // refetch();
  };
  const handleContentList = () => {
    setSoundPlay(true);
    setSound(clickSound);
    setValidate(false);
    const randomValue = Math.floor(Math.random() * 100) + 1;
    const formVal = formControl?.getFieldValue();
    if (tabVal == '' || tabVal?.question == '' || tabVal?.chat == '' || tabVal?.debate == '') {
      setPromptValidate(true);
    } else {
      if (formVal?.id) {
        const indexToUpdate = contentList.findIndex((item) => item.id === formVal?.id);
        if (indexToUpdate !== -1) {
          const updatedObj = { ...contentList[indexToUpdate] };
          updatedObj.group_name = formVal.group_name;
          updatedObj.title = formVal.title;
          updatedObj.topic = formVal.topic;
          updatedObj.level = formVal.level;
          updatedObj.image_file_list = imagePaths?.length > 0 ? JSON.stringify(imagePaths.map((item) => item.path)) : undefined;
          updatedObj.audio_file_list = audioPaths?.length > 0 ? JSON.stringify(audioPaths.map((item) => item.path)) : undefined;
          updatedObj.prompt = tabVal;
          const updatedList = [...contentList];
          updatedList[indexToUpdate] = updatedObj;
          setContentList(updatedList);
          formControl.resetFields(['title', 'topic', 'prompt', 'id', 'level', 'image_upload', 'audio_upload']);
        }
      } else {
        const fieldVal = formControl?.getFieldValue();
        if (
          (fieldVal &&
            typeof fieldVal === 'object' &&
            Object.keys(fieldVal).length > 0 &&
            fieldVal?.title !== '' &&
            fieldVal?.title !== undefined) ||
          (fieldVal?.topic !== '' && fieldVal?.topic !== undefined)
        ) {
          let updatedObj;
          if (fieldVal) {
            updatedObj = {
              ...fieldVal,
              prompt: tabVal,
              id: randomValue,
              level: level,
              image_file_list: imagePaths?.length > 0 ? JSON.stringify(imagePaths.map((item) => item.path)) : undefined,
              audio_file_list: audioPaths?.length > 0 ? JSON.stringify(audioPaths.map((item) => item.path)) : undefined,
            };
          }
          setContentList([...contentList, updatedObj]);
        }

        formControl.resetFields(['title', 'topic', 'prompt', 'level', 'image_upload', 'audio_upload']);
      }
      if (Object.keys(formVal).length !== 0) {
        setGroupDisable(true);
      }
      setTabVal('');
      setTabIndex('1');
      setImagePaths([]);
      setAudioPaths([]);
      setUploadFileList([]);
    }
  };

  const handlePromptChange = (data) => {
    if (data?.target?.value === '' || data?.target?.value === undefined || data?.target?.value === null) {
      setPromptValidate(true);
      setFormSubmitClick(false);
    } else {
      setPromptValidate(false);
      setFormSubmitClick(false);
    }

    if (micBtnClick > 0) {
      setCurrentText(data?.target.value);
    }
    setTabVal({
      [data.target.name]: data?.target.value,
    });
    if (contentList.length > 0) {
      setContentList((prevState) => {
        const updatedList = [...prevState];
        updatedList[indx].prompt = {
          ...updatedList[indx].prompt,
          [data.target.name]: data?.target.value,
        };
        return updatedList;
      });
    }
  };
  const handleModalPromptChange = (data) => {
    if (data.target.name === 'modal_main_instruction') {
      setModalTabVal((prevState) => ({
        ...prevState,
        modal_main_instruction: data?.target.value,
      }));
    }

    if (data.target.name === 'modal_main_description') {
      setModalTabVal((prevState) => ({
        ...prevState,
        modal_main_description: data?.target.value,
      }));
    }

    if (data.target.name === 'modal_question') {
      setModalTabVal((prevState) => ({
        ...prevState,
        modal_question: data?.target.value,
      }));
    }
    if (data.target.name === 'modal_chat') {
      setModalTabVal((prevState) => ({
        ...prevState,
        modal_chat: data?.target.value,
      }));
    }
    if (data.target.name === 'modal_debate') {
      setModalTabVal((prevState) => ({
        ...prevState,
        modal_debate: data?.target.value,
      }));
    }
  };

  const ExcelUpload = ({ onFileSelect, setLoading }) => {
    const [topics, setTopics] = useState([]);
    const [groupLevel, setGroupLevel] = useState('');

    useEffect(() => {
      if (topics.length > 0) {
        onFileSelect(
          topics.map((item) => {
            setLevel(1);
            item['group_name'] = groupLevel.group_name;
            item['level'] = groupLevel.level;
            return { ...item };
          }),
        );
      }
    }, [topics]);

    const props = {
      multiple: false,
      accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      onChange: (info) => {
        const file = info.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            /* Parse data */
            //https://stackoverflow.com/questions/53163552/format-date-with-sheetjs
            const ab = e.target.result;
            const wb = XLSX.read(ab, { type: 'binary', cellText: false, cellDates: true });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            console.log('ws', wsname);
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, {
              header: ['group_name', 'level', 'title', 'topic', 'prompt', 'content'],
              raw: false,
              dateNF: 'yyyy-mm-dd',
            });
            if (data && data instanceof Array) {
              // setGroupName(data[1].group_name)
              setGroupLevel({
                group_name: data[1].group_name,
                level: data[1].level,
              });
              const filtered_data = data.slice(1);
              const updatedArray = filtered_data.map((item) => {
                let obj;

                if (item?.prompt?.startsWith('question:')) {
                  obj = { question: item.prompt.replace('question:', '') };
                } else if (item?.prompt?.startsWith('chat:')) {
                  obj = { chat: item.prompt.replace('chat:', '') };
                } else if (item?.prompt?.startsWith('debate:')) {
                  obj = { debate: item.prompt.replace('debate:', '') };
                }

                return {
                  ...item,
                  prompt: obj,
                };
              });
              setTopics(updatedArray);
            } else {
              alert('오류가 발생했습니다.');
            }
          };
          reader.readAsArrayBuffer(file);
        } else {
          setTopics([]);
        }
      },
    };
    return (
      <Input
        key={`finle-input-0`}
        type="file"
        {...props}
        name="files"
        style={
          uploadDisable
            ? {
                width: '200px',
                margin: '0 10px',
                //opacity: 0.5,
                cursor: 'not-allowed',
              }
            : {
                width: '200px',
                margin: '0 10px',
                cursor: 'pointer',
              }
        }
        disabled={uploadDisable}
      />
    );
  };
  const handleTabChange = (val) => {
    setTabIndex(val);
    if (tabVal == '' || tabVal?.question == '' || tabVal?.chat == '' || tabVal?.debate == '') {
      if (formSubmitClick) {
        setPromptValidate(true);
      }
    } else {
      setPromptValidate(false);
      setFormSubmitClick(false);
    }
  };
  const handleModalTabChange = (val) => {
    setModalTabIndex(val);
  };
  const handleListClick = (data, index) => {
    setIndx(index);
    formControl.setFieldsValue({
      group_name: data?.group_name,
      title: data?.title,
      topic: data?.topic,
      id: data?.id ? data?.id : data?.idx,
      level: data?.level,
      image_upload: data?.image_upload,
      audio_upload: data?.audio_upload,
    });
    if (data?.prompt?.question) {
      setTabVal({ question: data?.prompt?.question });
      setTabIndex('1');
    }
    if (data?.prompt?.chat) {
      setTabVal({ chat: data?.prompt?.chat });
      setTabIndex('2');
    }
    if (data?.prompt?.debate) {
      setTabVal({ debate: data?.prompt?.debate });
      setTabIndex('3');
    }
    setGroupDisable(false);

    let tempImageAudio = {
      image: false,
      audio: false,
    };
    let tempContentList = contentList;
    if (imagePaths?.length > 0) {
      tempContentList[indx].imageFiles = imagePaths.map((item) => item.path);
    }
    if (audioPaths?.length > 0) {
      tempContentList[indx].audioFiles = audioPaths.map((item) => item.path);
    }
    setContentList(tempContentList);
    if (data?.imageFiles?.length > 0) {
      setImagePaths(data?.imageFiles.reduce((acc, cur) => [...acc, { name: cur.split('/')[cur.split('/').length - 1], path: cur }], []));
      tempImageAudio = {
        ...tempImageAudio,
        image: true,
      };
    } else {
      setImagePaths([]);
    }
    if (data?.audioFiles?.length > 0) {
      setAudioPaths(data?.audioFiles.reduce((acc, cur) => [...acc, { name: cur.split('/')[cur.split('/').length - 1], path: cur }], []));
      tempImageAudio = {
        ...tempImageAudio,
        audio: true,
      };
    } else {
      setAudioPaths([]);
    }
    setImageAudio(tempImageAudio);
  };

  const handleClear = () => {
    setClearBtn(true);
    setSoundPlay(true);
    setSound(clickSound);
    // uploadedFile?.map(async (ele) => {
    //     await deleteFile({
    //         variables: {
    //             file_id: ele?.id,
    //         },
    //     });
    // });
  };
  const handleClosClear = () => {
    setClearBtn(false);
  };
  const handleYes = async () => {
    setSoundPlay(true);
    setSound(clickSound);
    setChatList([]);
    setMicBtnClick(0);
    setClearBtn(false);
    setActive(false);
    stop();
    await createThread({
      variables: {
        idx: undefined,
      },
    }).then((res) => {
      setTestIdx((prevState) => ({
        ...prevState,
        test_thread_idx: res?.data?.createThread?.id,
      }));
      stopRecording('clear');
      setMicValue('mice_1.png');
      setStartRecording(false);
    });
    setPauseStop({ pause: false, stop: false });
  };

  const handleNo = () => {
    setSoundPlay(true);
    setSound(clickSound);
    setClearBtn(false);
  };

  const handleSetting = () => {
    setSoundPlay(true);
    setSound(clickSound);
    setShowModal(true);
  };

  const handleRemove = async (val) => {
    const tmp = filedata;
    const filteredArr = tmp.filter((item) => item.uid !== val.uid);
    setFiledata(filteredArr);
  };

  const handleUploadComplete = async (e) => {
    if (!isUploadInProgress && e?.fileList && e.fileList.length > 0) {
      isUploadInProgress = true;
      try {
        const { data } = await uploadOpenAiFile({
          variables: {
            files: e.fileList,
          },
        });
        if (data) {
          const fileIds = data?.uploadOpenAIFile?.map((ele) => {
            return ele.id;
          });
          if (fileIds?.length > 0) {
            if (testIdx?.test_vector_idx) {
              await uploadFileInVectorStore({
                variables: {
                  vector_store_idx: testIdx?.test_vector_idx,
                  file_ids: fileIds,
                },
              }).then((result) => {
                if (result?.data?.uploadFileInVectorStore) {
                  setUploadedFile((prevUploadedFile) => {
                    const newData = data?.uploadOpenAIFile.map((file) => ({
                      name: file.filename,
                      id: file.id,
                    }));
                    return [...(prevUploadedFile || []), ...(newData || [])];
                  });
                }
              });
            } else {
              await createVector({
                variables: {
                  uploadedFileIdx: fileIds,
                },
              }).then(async (res) => {
                setTestIdx((prevState) => ({
                  ...prevState,
                  test_vector_idx: res?.data?.createVector?.id,
                }));
                if (res?.data?.createVector?.id) {
                  await uploadFileInVectorStore({
                    variables: {
                      vector_store_idx: res?.data?.createVector?.id,
                      file_ids: fileIds,
                    },
                  }).then((result) => {
                    if (result?.data?.uploadFileInVectorStore) {
                      setUploadedFile((prevUploadedFile) => {
                        const newData = data?.uploadOpenAIFile.map((file) => ({
                          name: file.filename,
                          id: file.id,
                        }));
                        return [...(prevUploadedFile || []), ...(newData || [])];
                      });
                    }
                  });
                }
              });
            }
          }
        }
        // setUploadedFile(
        //     data?.uploadOpenAIFile.map((file) => ({
        //         name: file.filename,
        //         id: file.id,
        //     })),
        // );
      } catch (error) {
        console.error('Error uploading files:', error);
      } finally {
        isUploadInProgress = false;
      }
    }
  };
  const handleModalFormSubmit = () => {
    setShowModal(false);
    setDupModalTabVal(modalTabVal);
  };
  const handleModalClose = () => {
    setModalTabVal(dupModalTabVal);
    setShowModal(false);
  };

  const handleMicBtnClick = () => {
    setMicValue('mice_3.png');
    setDisableBtn(true);
    setSoundPlay(true);
    setSound(clickSound);
    setMicBtnClick(micBtnClick + 1);
    if (!active) {
      setActive(true);
      handlePromptClick('main');
      setDisableBtnVal(false);
      setMicBtnChange(false);
    } else if (recordAudio.current) {
      stopRecording();
      setStartRecording(false);
    } else {
      startRecord();
      setStartRecording(true);
    }
  };

  const handlePromptClick = async (val) => {
    let prmpt;
    let recording;

    switch (val) {
      case 'easy':
        prmpt = EASY;
        recording = EASY;
        break;
      case 'translate':
        prmpt = TRANSLATE;
        recording = TRANSLATE;
        break;
      case 'short':
        prmpt = SHORT;
        recording = SHORT;
        break;
      case 'lesson':
        prmpt = LESSON;
        recording = LESSON;
        break;
      // case "review-save":
      //     prmpt = REVIEWSAVE;
      //     recording = REVIEWSAVE;
      //     setActive(false);
      //     setReviewSave(true);
      //     setPercent(0)
      //     break;
      default:
        prmpt = val;
    }
    if (val == 'main') {
      prmpt = PROMPTS[0];
    }
    if (recording) {
      stopRecording(recording);
    }

    let threadIdx = testIdx?.test_thread_idx;

    if (!threadIdx) {
      let res = await createThread({
        variables: {
          idx: undefined,
        },
      });
      threadIdx = res?.data?.createThread?.id;
      setTestIdx((prevState) => ({
        ...prevState,
        test_thread_idx: threadIdx,
      }));
    }

    if (micBtnClick === 0) {
      if (prmpt !== STOP || prmpt !== PAUSE) {
        setPauseStop({ pause: false, stop: false });
      }
      let finalPrmpt;
      if (pauseStop?.pause === true || pauseStop?.stop === true) {
        finalPrmpt = RESTART;
      } else if (tabIndex == '1') {
        finalPrmpt = tabVal?.question !== undefined ? modalTabVal?.modal_question + ' ' + tabVal?.question : modalTabVal?.modal_question;
      } else if (tabIndex == '2') {
        finalPrmpt = tabVal?.chat !== undefined ? modalTabVal?.modal_chat + ' ' + tabVal?.chat : modalTabVal?.modal_chat;
      } else {
        finalPrmpt = tabVal?.debate !== undefined ? modalTabVal?.modal_debate + ' ' + tabVal?.debate : modalTabVal?.modal_debate;
      }
      openAiMessage({
        variables: {
          thread_id: threadIdx,
          role: 'user',
          content: finalPrmpt,
        },
      }).then((res) => {
        if (res?.data?.openaiMessage?.id) {
          setMsgIdx(res?.data?.openaiMessage?.id);
          createRun({
            variables: {
              assistant_id: testIdx?.test_assistant_idx,
              thread_id: threadIdx,
              image_audio: JSON.stringify(imageAudio),
              model: gptModel,
            },
          }).then((result) => {
            if (result?.data?.createRuns?.id) {
              // setRunIdx(result?.data?.createRuns?.id)
              setTestIdx((prevState) => ({
                ...prevState,
                test_run_idx: result?.data?.createRuns?.id,
              }));
              getSteps({
                variables: {
                  thread_id: threadIdx,
                  run_id: result?.data?.createRuns?.id,
                },
              });
            }
          });
        }
      });
    } else {
      if (prmpt !== STOP || prmpt !== PAUSE) {
        setPauseStop({ pause: false, stop: false });
      }
      openAiMessage({
        variables: {
          thread_id: threadIdx,
          role: 'user',
          content: prmpt,
        },
      }).then((res) => {
        const tmpChat = [];
        const aiResponse = res?.data?.openaiMessage?.content[0]?.text?.value;
        if (
          TO_LESSON == aiResponse ||
          PAUSE == aiResponse ||
          STOP == aiResponse ||
          REPEAT == aiResponse ||
          EASY == aiResponse ||
          TRANSLATE == aiResponse ||
          SHORT == aiResponse ||
          ASK == aiResponse
        ) {
        } else {
          const array = {
            user: userInfoVar()?.id,
            chat_user_id: res?.data?.openaiMessage?.role == 'user' ? userInfoVar()?.id : 'AI봇',
            chat_user_name: res?.data?.openaiMessage?.role == 'user' ? userInfoVar()?.name : 'AI봇',
            content: res?.data?.openaiMessage?.content[0]?.text?.value,
            created: moment().format('YYYY-MM-DD HH:mm:ss.sss'),
          };
          tmpChat.push(array);
          setChatList([...chatList, ...tmpChat]);

          speak(
            res?.data.openaiMessage?.content[0]?.text.value,
            () => {
              setDisableBtn(false);
              setMicValue('robot.png');
            },
            audioPaths.map((item) => item.path),
          );
        }

        if (res?.data?.openaiMessage?.id) {
          setMsgIdx(res?.data?.openaiMessage?.id);
          createRun({
            variables: {
              assistant_id: testIdx?.test_assistant_idx,
              thread_id: threadIdx,
              image_audio: JSON.stringify(imageAudio),
              model: gptModel,
            },
          }).then((result) => {
            if (result?.data?.createRuns?.id) {
              setTestIdx((prevState) => ({
                ...prevState,
                test_run_idx: result?.data?.createRuns?.id,
              }));
              getSteps({
                variables: {
                  thread_id: threadIdx,
                  run_id: result?.data?.createRuns?.id,
                },
              });
            }
          });
        }
      });
      setCurrentText('');
      setRecordText('');
    }
    formControl.resetFields(['user_content', 'message']);
    setPromptActive(true);
  };
  const onSubmit = async (text) => {
    if (autoSendRef.current.length) {
      clearTimeout(autoSendRef.current[0]);
      autoSendRef.current = [];
    }
    if (recordAudio?.current) {
      stopRecording('send-text');
    }
    setSoundPlay(true);
    setSound(clickSound);
    setMicValue('mice_3.png');
    setDisableBtn(true);
    setLastUserMsg(tabVal?.question);
    openAiMessage({
      variables: {
        thread_id: testIdx?.test_thread_idx,
        role: 'user',
        content: text !== '' ? text : currentText !== '' ? currentText : tabVal?.question || tabVal?.chat || tabVal?.debate,
      },
    }).then((res) => {
      const tmpChat = [];
      const array = {
        user: userInfoVar()?.id,
        chat_user_id: res?.data?.openaiMessage?.role == 'user' ? userInfoVar()?.id : 'AI봇',
        chat_user_name: res?.data?.openaiMessage?.role == 'user' ? userInfoVar()?.name : 'AI봇',
        content: res?.data?.openaiMessage?.content[0]?.text?.value,
        created: moment().format('YYYY-MM-DD HH:mm:ss.sss'),
      };
      tmpChat.push(array);
      setChatList([...chatList, ...tmpChat]);
      if (res?.data?.openaiMessage?.id) {
        setMsgIdx(res?.data?.openaiMessage?.id);
        createRun({
          variables: {
            assistant_id: testIdx?.test_assistant_idx,
            thread_id: testIdx?.test_thread_idx,
            image_audio: JSON.stringify(imageAudio),
            model: gptModel,
          },
        }).then((result) => {
          if (result?.data?.createRuns?.id) {
            setTestIdx((prevState) => ({
              ...prevState,
              test_run_idx: result?.data?.createRuns?.id,
            }));
            getSteps({
              variables: {
                thread_id: testIdx?.test_thread_idx,
                run_id: result?.data?.createRuns?.id,
              },
            });
          }
        });
      }
    });
    setCurrentText('');
    setRecordText('');
  };

  const handleRecording = (val) => {
    setStartRecording(false);
    setCurrentUrl('');
    setMicValue('mice_3.png');
    setDisableBtn(true);
    handlePromptClick(val);
    setCurrentText('');
    stopRecording(val);
    setCurrentUrl('');
    stop();
    if (val == PAUSE || val == STOP) {
      setMicBtnClick(0);
      setActive(false);
      setMicBtnChange(true);
    }

    if (val == PAUSE) {
      setPauseStop((prevState) => ({
        ...prevState,
        pause: true,
      }));
      let lastAiMsg = null;
      if (chatList !== undefined && chatList) {
        for (let i = chatList.length - 1; i >= 0; i--) {
          const msg = chatList[i];
          if (msg['chat_user_name'] === 'AI봇') {
            lastAiMsg = msg;
            break;
          }
        }
      }
      setLastUserMsg(lastAiMsg);
    }

    if (val == STOP) {
      setPauseStop((prevState) => ({
        ...prevState,
        stop: true,
      }));
    }

    setSoundPlay(true);
    setSound(clickSound);
  };

  const handleCancel = () => {
    setSoundPlay(true);
    setSound(clickSound);
    setCancelBtnClick(true);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedList = Array.from(contentList);
    const [removed] = reorderedList.splice(result.source.index, 1);
    reorderedList.splice(result.destination.index, 0, removed);
    setContentList(reorderedList);
  };
  const handleRemoveTopic = (index) => {
    setSoundPlay(true);
    setSound(clickSound);
    if (index >= 0 && index < contentList.length) {
      const newContentList = [...contentList];
      newContentList.splice(index, 1);
      setContentList(newContentList);
    }
  };

  const handleExcelFileDownload = () => {
    (async () => {
      const blob = await fetch('https://culp.cloubot.com/sample.xlsx').then((r) => r.blob());
      //const blob = await fetch('http://localhost:3030/sample.xlsx').then((r) => r.blob());
      fileDownload(blob, 'topics.xlsx');
    })();
  };

  const handleGptModel = async () => {
    let tempgptModel = gptModel === 'gpt-4o' ? 'gpt-4o-mini' : 'gpt-4o';
    await updateAssistants({
      variables: {
        assistant_id: testIdx?.test_assistant_idx,
        model: tempgptModel,
      },
    });

    setGptModel(tempgptModel);
  };

  return (
    <>
      <div className="add-header">
        <h2>Add/Edit Content</h2>
        <Switch checkedChildren="gpt-4o" unCheckedChildren="gpt-4o-mini" checked={gptModel === 'gpt-4o'} onChange={handleGptModel} />
        <div className="right-section">
          <Button type="primary" onClick={handleExcelFileDownload} style={{ background: '#1890ff', padding: '0 10px' }}>
            Title / Prompt 등록 양식 다운로드
          </Button>
          <div className="upload-btn">
            <img
              src="/images/talking-tutor/upload.png"
              alt="upload-btn"
              style={uploadDisable ? { opacity: 0.5, cursor: 'not-allowed' } : { cursor: 'pointer' }}
            />
            <ExcelUpload onFileSelect={handleFileSelect} setLoading={setLoading} />
          </div>

          <Button type="primary">
            <img src="/images/talking-tutor/close-icon.png" alt="close-arrow" onClick={() => history.goBack()} />
          </Button>
        </div>
      </div>

      {createLoading ? (
        <Loading />
      ) : (
        <>
          <Form
            labelCol={{
              span: 0,
            }}
            wrapperCol={{
              span: 24,
            }}
            layout="vertical"
            form={formControl}
            onFinish={handleFormSubmit}
          >
            <div className="add-header-field dynamic-content">
              <div className="main-row cust-row cust-one">
                <Form.Item
                  name="group_name"
                  label="Assignment Group Name"
                  style={{ marginBottom: 5 }}
                  rules={[{ required: validate, message: 'Please Enter Group Name' }]}
                >
                  <Input
                    name="group_name"
                    onChange={(e) => (e.target.value !== '' ? setUploadDisable(true) : setUploadDisable(false))}
                    disabled={groupDisable}
                  />
                </Form.Item>
                <Form.Item name="level" label="Level" style={{ marginBottom: 5 }} className="select-form-control">
                  <Select
                    className="select-form-control"
                    onChange={(e) => setLevel(e)}
                    placeholder="Select Level"
                    defaultValue={level}
                    disabled={groupDisable}
                  >
                    {levelList.map((e) => {
                      return <Option value={`${e}`}>{e === 0 ? 'PH' : `${isNaN(e) ? '' : 'mx'} ${e}`}</Option>;
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div className="main-row cust-row cust-two">
                {userInfoVar()?.type === 5 ? (
                  <Form.Item name="content_list" label="Content List" style={{ marginBottom: 5 }} className="select-form-control">
                    <Select
                      style={{ width: 164, color: '#065aa9' }}
                      defaultValue={contentIdx}
                      placeholder="폴더 유형 선택"
                      optionFilterProp="children"
                      onChange={(val) => setContentIdx(val)}
                    >
                      {getContentList?.getContentList?.map((item) => {
                        return (
                          <>
                            <Select.Option value={item.idx}>{item?.content_name}</Select.Option>
                          </>
                        );
                      })}
                    </Select>
                  </Form.Item>
                ) : (
                  <Form.Item name="acess_level" label="Content Folder" style={{ marginBottom: 5 }} className="select-form-control">
                    <Select
                      style={{ color: '#065aa9' }}
                      defaultValue={folderType}
                      placeholder="폴더 유형 선택"
                      optionFilterProp="children"
                      onChange={(val) => setFolderType(val)}
                    >
                      {userInfoVar()?.type === 4 ? <Select.Option value="publisher">Company</Select.Option> : <></>}
                      {userInfoVar()?.type === 2 ? (
                        <>
                          <Select.Option value="campus">Campus</Select.Option>
                          <Select.Option value="me">Personal</Select.Option>
                        </>
                      ) : userInfoVar()?.type === 1 ? (
                        <>
                          <Select.Option value="me">Personal</Select.Option>
                        </>
                      ) : (
                        <></>
                      )}
                    </Select>
                  </Form.Item>
                )}
              </div>
            </div>
            <div className="middle-row blue-middle-row coman-row">
              <div className="left-part">
                <div className="content-list">
                  <h2>content list</h2>
                  <div className="list-items">
                    {/* {contentList?.map((ele, index) => {
                                            return (
                                                <span className={index == indx ? 'active-span' : ''} onClick={() => handleListClick(ele, index)}>
                                                    {ele?.title}
                                                    <Button className='remove-btn' >-</Button>
                                                </span>
                                            )
                                        })} */}

                    {/* dragable Droppable functionality code */}
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided) => (
                          <div {...provided.droppableProps} ref={provided.innerRef}>
                            {contentList.map((item, index) => (
                              <Draggable key={item?.id} draggableId={item?.id?.toString()} index={index}>
                                {(provided) => (
                                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                    {/* Render your row content here */}
                                    <div>
                                      <div>
                                        <span className={index === indx ? 'active-span' : ''} onClick={() => handleListClick(item, index)}>
                                          <p style={{ marginBottom: 0, lineHeight: 2 }}>{item?.title}</p>
                                          <Button
                                            className="remove-btn"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              handleRemoveTopic(index);
                                            }}
                                          >
                                            X
                                          </Button>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    {/* end dragable Droppable */}

                    <button className="add-more-btn" type="button" onClick={handleContentList}>
                      add more +
                    </button>
                  </div>
                </div>
                <div className="middle-input">
                  <Form.Item
                    name="title"
                    label="Title"
                    style={{ marginBottom: 5 }}
                    rules={[{ required: validate, message: 'Please Enter Title' }]}
                  >
                    <Input name="title" />
                  </Form.Item>
                  <Form.Item
                    name="topic"
                    label="Topic"
                    style={{ marginBottom: 5 }}
                    rules={[{ required: validate, message: 'Please Enter Topic' }]}
                  >
                    <Input name="topic" />
                  </Form.Item>
                  <Form.Item
                    name="prompt"
                    label="Prompt"
                    style={{ marginBottom: 5 }}
                    rules={[formSubmitClick ? '' : { required: promptValidate, message: 'Please Enter Prompt Value' }]}
                  >
                    <Tabs activeKey={tabIndex} type="card" style={{ height: '100%' }} className="promot-tabs" onChange={handleTabChange}>
                      <Tabs.TabPane tab="Question" key="1" style={{ height: '100%' }} tabBarStyle={{ backgroundColor: '#ffda00' }}>
                        <TextArea name="question" value={tabVal?.question} rows={7} onChange={(e) => handlePromptChange(e)} />
                      </Tabs.TabPane>
                      <Tabs.TabPane tab="Chat" key="2" style={{ height: '100%' }}>
                        <TextArea name="chat" value={tabVal?.chat} rows={7} onChange={(e) => handlePromptChange(e)} />
                      </Tabs.TabPane>
                      <Tabs.TabPane tab="Debate" key="3" style={{ height: '100%' }}>
                        <TextArea name="debate" value={tabVal?.debate} rows={7} onChange={(e) => handlePromptChange(e)} />
                      </Tabs.TabPane>
                    </Tabs>
                  </Form.Item>
                  {formSubmitClick || promptValidate ? (
                    <p style={{ margin: '-15px 0 10px 10px', color: '#ff4d4f', fontSize: '15px' }}> Please Enter Prompt Value</p>
                  ) : (
                    ''
                  )}
                  <div className="action-img-btn-wrap">
                    <div>
                      <img
                        src="/images/talking-tutor/y_1.png"
                        alt="y_1-icon"
                        // className="enabled-btn"
                        // onClick={() => handleRecording(TO_LESSON)}
                        className={disableBtn ? 'disabled-btn' : 'enabled-btn'}
                        onClick={!disableBtn ? () => handleRecording(TO_LESSON) : undefined}
                      />
                      {/* <p>Repeat</p> */}
                    </div>
                    <div>
                      <img
                        src="/images/talking-tutor/y_2.png"
                        alt="y_2-icon"
                        // className="enabled-btn"
                        // onClick={() => handleRecording(PAUSE)}
                        className={disableBtn ? 'disabled-btn' : 'enabled-btn'}
                        onClick={!disableBtn ? () => handleRecording(PAUSE) : undefined}
                      />
                      {/* <p>Pause</p> */}
                    </div>
                    {/* <img src="/images/talking-tutor/y_1.png" alt="y_1-icon" className={!disableBtn ? "disabled-btn" : "enabled-btn"} />
                                        <img src="/images/talking-tutor/y_2.png" alt="y_2-icon" className={!disableBtn ? "disabled-btn" : "enabled-btn"} /> */}

                    {currentText !== '' || recordText !== '' ? (
                      <AButton
                        type="primary"
                        shape="circle"
                        icon={<SendOutlined />}
                        onClick={() => onSubmit(recordText)}
                        style={{ height: 60, width: 60, transform: 'rotate(270deg)' }}
                        className="blink_me"
                      />
                    ) : (
                      <>
                        <img
                          src={`/images/talking-tutor/${micValue}`}
                          className={recordAudio.current !== null && !micBtnChange && micValue == 'stop2x.png' ? 'blink_me' : ''}
                          alt="mice_1-icon"
                          onClick={
                            !disableBtn || pauseStop?.pause || pauseStop?.stop
                              ? micValue == 'mice_3.png' || micValue == 'robot.png'
                                ? undefined
                                : handleMicBtnClick
                              : undefined
                          }
                          style={
                            !disableBtn || pauseStop?.pause || pauseStop?.stop
                              ? { cursor: 'pointer', width: '60px', height: '64px', marginTop: '-4px' }
                              : { cursor: 'not-allowed', width: '60px', height: '64px', marginTop: '-4px' }
                          }
                        />
                        {/* <p style={{ marginTop: '4px' }}>Start</p> */}
                      </>
                    )}
                    <div>
                      <img
                        src="/images/talking-tutor/y_5.png"
                        alt="y_5-icon"
                        // className="enabled-btn"
                        // onClick={() => handleRecording(STOP)}
                        className={disableBtn || micValue === 'stop2x.png' ? 'disabled-btn' : 'enabled-btn'}
                        onClick={disableBtn || micValue === 'stop2x.png' ? undefined : () => handleReRecord()}
                      />
                      {/* <p>Stop</p> */}
                    </div>
                    <div>
                      <img
                        src="/images/talking-tutor/y_4.png"
                        alt="y_4-icon"
                        // className="enabled-btn"
                        // onClick={() => handleRecording(REPEAT)}
                        className={disableBtn ? 'disabled-btn' : 'enabled-btn'}
                        onClick={!disableBtn ? () => handleRecording(REPEAT) : undefined}
                      />
                      {/* <p>Restart</p> */}
                    </div>
                  </div>

                  <div className="setting-btns">
                    <div className="left-btn">
                      <img
                        src="/images/talking-tutor/setting_b.png"
                        style={{ cursor: 'pointer' }}
                        alt="setting-icon"
                        onClick={handleSetting}
                      />
                      <img
                        src="/images/talking-tutor/guide_o.png"
                        alt="guide-icon"
                        style={{ cursor: 'pointer' }}
                        onClick={() => window.open('https://platform.openai.com/docs/assistants/overview', '_blank')}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="right-part upload-right-part">
                <div className="upload-wrap">
                  <UploadViewModal
                    buttonText={`Upload Book: ${uploadedFile?.length}`}
                    title="Upload Book"
                    content={
                      <>
                        {fileLoading ? (
                          <LoadingScreen>
                            <div style={{ flex: 1, resize: 'none', padding: '0' }}>
                              <Spin fontSize="24px" height="44px" />
                            </div>
                          </LoadingScreen>
                        ) : (
                          <Form.Item>
                            <Upload
                              accept=".pdf"
                              name="file"
                              multiple={true}
                              showUploadList={true}
                              fileList={filedata}
                              onRemove={(e) => handleRemove(e)}
                              onChange={handleUploadComplete}
                            >
                              <Button icon={<UploadOutlined />}>Upload Book</Button>
                            </Upload>
                            <label className="upload-lable">Uploaded File List</label>
                            {uploadedFile?.map((ele) => {
                              return (
                                <>
                                  <p>{ele?.name}</p>
                                </>
                              );
                            })}
                          </Form.Item>
                        )}
                      </>
                    }
                  />
                  <hr />
                  <UploadViewModal
                    buttonText={`Upload Image: ${imagePaths?.length}`}
                    title="Upload Image"
                    content={
                      <>
                        {fileLoading ? (
                          <LoadingScreen>
                            <div style={{ flex: 1, resize: 'none', padding: '0' }}>
                              <Spin fontSize="24px" height="44px" />
                            </div>
                          </LoadingScreen>
                        ) : (
                          <Form.Item name="image_upload">
                            <Upload
                              accept="image/*"
                              name="file"
                              multiple={true}
                              action={handleUplaod}
                              fileList={imagePaths}
                              listType="text"
                              showUploadList={{ showRemoveIcon: false }}
                              // onChange={({ fileList }) => setUploadFileList(fileList)}
                            >
                              <Button icon={<UploadOutlined />}>Upload Image</Button>
                            </Upload>
                          </Form.Item>
                        )}
                      </>
                    }
                  />
                  &nbsp;
                  <UploadViewModal
                    buttonText={`Upload Audio: ${audioPaths?.length}`}
                    title="Upload Audio"
                    content={
                      <>
                        {fileLoading ? (
                          <LoadingScreen>
                            <div style={{ flex: 1, resize: 'none', padding: '0' }}>
                              <Spin fontSize="24px" height="44px" />
                            </div>
                          </LoadingScreen>
                        ) : (
                          <Form.Item name="audio_upload">
                            <Upload
                              accept="audio/*"
                              name="file"
                              style={{ display: 'inline-block', flex: 1 }}
                              multiple={true}
                              fileList={audioPaths}
                              action={handleUplaod}
                              listType="text"
                              showUploadList={{ showRemoveIcon: false }}
                            >
                              <Button icon={<UploadOutlined />}>Upload Audio</Button>
                            </Upload>
                          </Form.Item>
                        )}
                      </>
                    }
                  />
                </div>
                <Form.Item
                  labelCol={{
                    span: 0,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  name="dialog"
                  label="Dialogue"
                  style={{ marginBottom: 5 }}
                >
                  {!activeAnswer ? (
                    <MessageBox
                      id="MessageBox"
                      style={{
                        height: '25vh',
                        overflow: 'auto',
                        margin: '10px 0',
                        backdround: '#fff',
                      }}
                      className="message-box"
                      ref={messageBoxRef}
                    >
                      <InfiniteScroll
                        scrollableTarget="MessageBox"
                        style={{ display: 'flex', flexDirection: 'column' }}
                        //next={aiUserRequestResponseList}
                        inverse={false}
                        hasMore={true}
                        dataLength={chatList?.length}
                        loader={
                          openAiMsgLoading ? (
                            <p style={{ textAlign: 'center' }}>
                              <b>Loading...</b>
                            </p>
                          ) : null
                        }
                        endMessage={
                          msgLoading || runLoading || stepLoading ? (
                            <p style={{ textAlign: 'center' }}>
                              <b>생각중...</b>
                            </p>
                          ) : null
                        }
                      >
                        {chatList?.map((thread, key) => {
                          const isMe = String(userInfoVar()?.id) === thread.chat_user_id;
                          return (
                            <React.Fragment key={`message-item-key${key}`}>
                              <DateDivider
                                isShow={
                                  key < chatList?.length &&
                                  moment(new Date(thread?.created)).format('YYYY-MM-DD') !==
                                    moment(new Date(chatList[key - 1]?.created)).format('YYYY-MM-DD')
                                }
                                date={`${moment(new Date(thread?.created)).format('YYYY-MM-DD')}`}
                              />
                              <MessageItem
                                type={'ai-talking-tutor'}
                                isMe={isMe}
                                name={thread.chat_user_name}
                                message={thread.content}
                                urls={thread.urls}
                                currentFileList={[...imagePaths.map((ele) => ele.path), ...audioPaths.map((ele) => ele.path)]}
                                date={thread.created}
                                readYN={true}
                                unreadCount={0}
                              />
                            </React.Fragment>
                          );
                        })}
                        {msgLoading && (
                          <p style={{ textAlign: 'center' }}>
                            <b>loading...</b>
                          </p>
                        )}
                      </InfiniteScroll>
                    </MessageBox>
                  ) : (
                    <Input.TextArea
                      style={{
                        height: '30vh',
                        overflow: 'auto',
                        border: '1px solid #00b598',
                        backdround: '#fff',
                        margin: '10px 0',
                        color: '#00b598',
                      }}
                      placeholder="답변하기"
                      name="answer"
                    />
                  )}
                </Form.Item>
                <Form.Item label="Message" style={{ marginBottom: 5 }}>
                  <TextArea
                    className="msg-input"
                    rows={2}
                    aria-expanded="false"
                    style={{ cursor: 'pointer', minHeight: '70px' }}
                    onFocus={(e) => {
                      if (autoSendRef.current.length) {
                        clearTimeout(autoSendRef.current[0]);
                        autoSendRef.current = [];
                      }
                    }}
                    onChange={(e) => {
                      if (autoSendRef.current.length) {
                        clearTimeout(autoSendRef.current[0]);
                        autoSendRef.current = [];
                      }
                      setRecordText(e.target.value);
                    }}
                    value={recordText}
                    onBlur={(e) => {
                      setRecordText(e.target.value);
                      stopRecording('message');
                      setStartRecording(false);
                    }}
                  />
                </Form.Item>

                <p className="clear-wrap">
                  clear
                  <img src="/images/talking-tutor/redo.png" alt="close-arrow" onClick={handleClear} />
                </p>
                <div className="form-btn">
                  <div className="right-btn">
                    <Button htmlType="button" style={fileLoading ? { cursor: 'not-allowed', opacity: 0.5 } : {}}>
                      <img src="/images/talking-tutor/cancel.png" alt="cancel-btn" onClick={fileLoading ? undefined : handleCancel} />
                    </Button>
                    <Button
                      htmlType="button"
                      style={fileLoading ? { cursor: 'not-allowed', opacity: 0.5 } : {}}
                      onClick={fileLoading ? undefined : () => (setFormSubmitClick(true), formControl.submit())}
                    >
                      <img src="/images/talking-tutor/save.png" alt="save-btn" />
                    </Button>
                    <Button
                      htmlType="button"
                      style={fileLoading ? { cursor: 'not-allowed', opacity: 0.5 } : {}}
                      onClick={fileLoading ? undefined : () => (setFormSubmitClick(true), setBtnSaveTest(true), formControl.submit())}
                    >
                      Save & Test
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
          <ReactPlayer
            url={currentUrl}
            playing={currentUrl !== ''}
            width={0}
            height={0}
            loop={false}
            onEnded={handlePlayEnd}
            onError={handlePlayEnd}
          />
          {soundPlay ? <SoundEffect src={sound} onEnd={handleSoundEnd} /> : ''}
        </>
      )}
      {showModal ? (
        <Modal
          className="instruction-modal"
          visible={showModal}
          style={{ top: '40%' }}
          closable={true}
          footer={null}
          onCancel={() => setShowModal(false)}
          title="Pre-Instruction Setting"
        >
          <Form form={modalForm} onFinish={handleModalFormSubmit}>
            <Form.Item name="model_prompt" style={{ marginBottom: 5 }}>
              <Tabs
                activeKey={modalTabIndex}
                type="card"
                style={{ height: '100%' }}
                className="promot-tabs"
                onChange={handleModalTabChange}
              >
                <Tabs.TabPane tab="Instruction" key="1" style={{ height: '100%' }} tabBarStyle={{ backgroundColor: '#ffda00' }}>
                  <TextArea
                    name="modal_main_instruction"
                    value={modalTabVal?.modal_main_instruction}
                    rows={10}
                    onChange={(e) => handleModalPromptChange(e)}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Description" key="2" style={{ height: '100%' }} tabBarStyle={{ backgroundColor: '#ffda00' }}>
                  <TextArea
                    name="modal_main_description"
                    value={modalTabVal?.modal_main_description}
                    rows={10}
                    onChange={(e) => handleModalPromptChange(e)}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Question" key="3" style={{ height: '100%' }} tabBarStyle={{ backgroundColor: '#ffda00' }}>
                  <TextArea
                    name="modal_question"
                    value={modalTabVal?.modal_question}
                    rows={10}
                    onChange={(e) => handleModalPromptChange(e)}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Chat" key="4" style={{ height: '100%' }}>
                  <TextArea name="modal_chat" value={modalTabVal?.modal_chat} rows={10} onChange={(e) => handleModalPromptChange(e)} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Debate" key="5" style={{ height: '100%' }}>
                  <TextArea name="modal_debate" value={modalTabVal?.modal_debate} rows={10} onChange={(e) => handleModalPromptChange(e)} />
                </Tabs.TabPane>
              </Tabs>
            </Form.Item>
            <div className="instruction-btn-wrap">
              <Button onClick={handleModalClose}>
                {' '}
                {/* Modify onClick here */}
                <img src="/images/talking-tutor/cancel.png" alt="cancel-btn" />
              </Button>
              <Button htmlType="submit">
                <img src="/images/talking-tutor/save.png" alt="save-btn" />
              </Button>
            </div>
          </Form>
        </Modal>
      ) : (
        ''
      )}

      <Modal visible={clearBtn} width={400} onCancel={handleClosClear} className="restart-task-modal">
        <h3>Are you sure you want to clear?</h3>

        <div className="modal-footer">
          <Button onClick={handleYes} className="ant-btn ant-btn-default">
            <span>Yes</span>
          </Button>
          <Button onClick={handleNo} className="ant-btn btn-primary">
            <span>No</span>
          </Button>
        </div>
      </Modal>
    </>
  );
};
export default withRouter(AddGroupList);

export const DateDivider = ({ date, isShow }) => {
  if (!isShow) return false;
  return (
    <Divider plain>
      <span
        style={{
          padding: '3px',
          backgroundColor: '#666',
          color: '#fff',
          borderRadius: '5px',
        }}
      >
        {date}
      </span>
    </Divider>
  );
};

export const UploadViewModal = ({ content, buttonText, title }) => {
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Button onClick={showModal}>{buttonText}</Button>
      <Modal title={title} visible={visible} onOk={handleOk} onCancel={handleCancel}>
        {content}
      </Modal>
    </>
  );
};
