import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { storage } from 'src/utils/hooks';
import { Avatar, Layout, Typography, Menu, Dropdown, Space, Form, Input, Modal, Button, AutoComplete } from 'antd';
import { LogoutOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import { DownOutlined, SwapOutlined, SearchOutlined, UnorderedListOutlined, SettingOutlined } from '@ant-design/icons';

import { isLogedIn, userInfoVar, userVar } from 'src/apollo/cache';
import { USER_ME } from 'src/operations/queries/getUser';
import { useQueryProxy } from 'src/operations/proxy/user';
import { getCurrentToken } from '../../utils';
//
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import * as dummy from 'src/dummy';
import { isPturn } from 'src/utils';
import VimeoVideo from 'src/components/common/VimeoVideo';
//
const { Header: HeaderWrapper } = Layout;

const { SubMenu } = Menu;
const { Text } = Typography;

const BookrHeader = ({
  collapsed,
  onCollapse,
  level,
  setLevel,
  bookType,
  setBookType,
  sort,
  setSort,
  search,
  setSearch,
  topic,
  setTopic,
  isStatic = false,
  searchOption,
  setSearchOption,
  searchValue,
  setSearchValue,
  searchHistory,
  setSearchHistory,
}) => {
  const history = useHistory();
  const token = getCurrentToken();
  const { data } = useQueryProxy(USER_ME, { variables: { token } });
  const client = useApolloClient();
  //
  const companyName = useSelector(classStoreData);
  // const userType = userInfoVar()?.type;
  //
  const [visible, setVisible] = useState(false);
  const onLogout = () => {
    isLogedIn(false);
    userInfoVar(null);
    client.clearStore();
    client.cache.gc();
    storage.removeItem('culp_token');
    window.location.reload();
  };
  const levels = ['1', '2', '3', '4', '5', '6'];
  const lv = (
    <Menu className="ebook-header-dropdown">
      <Menu.Item>
        <a rel="noopener noreferrer" onClick={() => setLevel('All')}>
          All
        </a>
      </Menu.Item>
      {levels.map((e) => (
        <Menu.Item>
          <a rel="noopener noreferrer" onClick={() => setLevel(e)}>
            level {e}
          </a>
        </Menu.Item>
      ))}
    </Menu>
  );

  // 2nd
  const sorts = ['Newest', 'Oldest', 'A-Z', 'Z-A'];
  const orderBookr = (
    <Menu className="ebook-header-dropdown">
      {sorts.map((e) => (
        <Menu.Item>
          <a rel="noopener noreferrer" onClick={() => setSort(e)}>
            {e}
          </a>
        </Menu.Item>
      ))}
    </Menu>
  );

  // 3rd
  const studyToolsBookr = (
    <Menu className="ebook-header-dropdown">
      <Menu.Item>
        <a rel="noopener noreferrer" onClick={() => setBookType('book')}>
          Book
        </a>
      </Menu.Item>
      <Menu.Item>
        <a rel="noopener noreferrer" onClick={() => setBookType('flashcard')}>
          Flashcard
        </a>
      </Menu.Item>
      <Menu.Item>
        <a rel="noopener noreferrer" onClick={() => setBookType('karaoke')}>
          Song & karaoke
        </a>
      </Menu.Item>
    </Menu>
  );
  const onClickTopic = (e) => {
    if (e !== topic) {
      setTopic(e);
    }
    setVisible(false);
  };
  const topics = [
    'All',
    'Colors and shapes',
    'Alphabet',
    'Numbers',
    'People',
    'Nature',
    'Place of living',
    'Travelling',
    'Shopping',
    'Food and drinks',
    'Lifestyle',
    'Free time',
    'Time and weather',
    'Science',
    'School',
    'Work',
    'Personality',
    'Relationships',
    'Sport',
    'Classics',
    'Smart',
    'Animals',
    'Technology',
    'Aliens and monsters',
    'Music',
    'Vehicles',
    'Culture',
    'Cute',
    'Friends',
    'Funny',
    'Magic',
    'Body and health',
    'Everyday life',
  ];
  const topicSelect = (
    <div>
      {topics.map((e) => (
        <Button style={{ margin: '2px' }} type={e === topic ? 'primary' : ''} onClick={() => onClickTopic(e)}>
          {e}
        </Button>
      ))}
    </div>
  );
  const closeModal = () => {
    setVisible(false);
  };

  function handleSearch(value) {
    setSearchValue(value);
  }

  function handleSelect(value) {
    setSearch(value);
    searchHistory.indexOf(value) < 0 &&
      value.length > 0 &&
      setSearchHistory((prevHistory) => {
        const history = [...prevHistory.filter((word) => word.toUpperCase() !== searchValue.toUpperCase()), searchValue].slice(0, 10);
        localStorage.setItem('searchHistory', JSON.stringify(history));
        return history;
      });
  }
  function handleClear() {
    setSearch('');
    setSearchValue('');
  }
  const handleChange = (data) => {
    if (data == undefined || data == null || data == '') {
      localStorage.setItem('searchData', 'false');
    } else {
      localStorage.setItem('searchData', 'true');
    }
  };
  function recentClear() {
    setSearchHistory([]);
    handleClear();
    localStorage.setItem('searchHistory', JSON.stringify([]));
  }
  return (
    <>
      <Modal visible={visible} title="Topic Select" onCancel={closeModal} width={'40%'} footer={null}>
        {topicSelect}
      </Modal>
      <HeaderWrapper
        style={{
          background: '#fff',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          padding: '0 10px ',
          position: isStatic ? 'fixed' : 'fixed',
          boxShadow: '1px 1px 4px 0px rgb(135 102 59 / 40%)',
          zIndex: '999',
        }}
      >
        <div style={{ visibility: `${userInfoVar()?.level_test === 1 ? 'hidden' : 'visible'}`, flex: 1 }}>
          <div style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
            <div key="close" onClick={onCollapse}>
              <span style={{ color: '#999', cursor: 'pointer' }}>
                <CloseCircleOutlined
                  style={{
                    verticalAlign: 'middle',
                    fontSize: 25,
                    color: '#424242',
                  }}
                  key="close"
                />
              </span>
            </div>
          </div>
        </div>

        {!isStatic ? (
          <>
            <li
              className="eBook-header"
              key="user"
              mode="horizontal"
              style={{
                height: '100%',
                flex: 6,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <div
                className="eBook-header-con"
                style={{
                  display: 'flex',
                  width: 'auto',
                  marginRight: '0',
                  justifyContent: 'space-between',
                }}
              >
                <Menu>
                  <Menu.Item>
                    <Dropdown overlay={orderBookr} trigger={['click']}>
                      <Link onClick={(e) => e.preventDefault()}>
                        <Space>
                          <SwapOutlined /> {sort}
                        </Space>
                      </Link>
                    </Dropdown>
                  </Menu.Item>
                </Menu>
                <Menu>
                  <Menu.Item>
                    <div>
                      <Dropdown overlay={lv} trigger={['click']}>
                        <Link onClick={(e) => e.preventDefault()}>
                          <Space>
                            {level}
                            <DownOutlined />
                          </Space>
                        </Link>
                      </Dropdown>
                    </div>
                  </Menu.Item>
                </Menu>
                <Menu>
                  <Menu.Item>
                    <div
                      onClick={() => {
                        setVisible(true);
                      }}
                    >
                      {topic === 'All' ? 'Topic' : topic}
                    </div>
                  </Menu.Item>
                </Menu>
                {/* <Menu>
                  <Menu.Item className="lib-searchitem">
                    <SearchOutlined htmlType="submit" />
                    <Form.Item name="search">
                      <Input value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search" />
                    </Form.Item>
                  </Menu.Item>
                </Menu> */}
                <Menu>
                  <Menu.Item className="lib-searchitem">
                    <SearchOutlined htmlType="submit" />
                    <Form.Item name="search">
                      <AutoComplete
                        value={searchValue}
                        onChange={handleChange}
                        options={searchOption}
                        onSearch={handleSearch}
                        placeholder="Search"
                        // filterOption={filterOption}
                        onSelect={handleSelect}
                        allowClear={true}
                        onClear={handleClear}
                        style={{
                          width: 200,
                        }}
                        dropdownRender={(list) => (
                          <>
                            {list}{' '}
                            {searchValue.length === 0 && searchHistory.length > 0 && (
                              <div style={{ cursor: 'pointer', textAlign: 'right' }} onClick={recentClear}>
                                전체삭제
                              </div>
                            )}
                          </>
                        )}
                      />
                    </Form.Item>
                  </Menu.Item>
                </Menu>
                <Menu>
                  <Menu.Item>
                    <Dropdown overlay={studyToolsBookr} trigger={['click']}>
                      <Link onClick={(e) => e.preventDefault()}>
                        <Space>
                          <UnorderedListOutlined /> {bookType !== 'All' ? bookType : ''}
                        </Space>
                      </Link>
                    </Dropdown>
                  </Menu.Item>
                </Menu>
              </div>
            </li>
          </>
        ) : null}

        <Menu>
          <SubMenu
            key="SubMenu"
            title={
              <div style={{ display: 'flex' }}>
                {isPturn(companyName) && (
                  <Menu.Item key="info">
                    <VimeoVideo
                      width={35}
                      thumpImage="/images/guide.png"
                      item={
                        companyName === 'englishtap'
                          ? dummy.test.guideVideosForAfterSchool[companyName]?.[0]?.videos
                          : dummy.test.guideVideosForAfterSchool[companyName]?.[15]?.videos
                      }
                    />
                  </Menu.Item>
                )}
                <>
                  <Avatar

                  //src="//image.zuiidea.com/photo-1487412720507-e7ab37603c6f.jpeg?imageView2/1/w/200/h/200/format/webp/q/75|imageslim"
                  />
                  <span style={{ color: '#999', marginLeft: 10 }}>
                    <span className="username">
                      {data?.me?.name} <Text strong>({data?.me?.view_id})</Text>
                    </span>
                    <LogoutOutlined
                      style={{
                        marginLeft: 20,
                        verticalAlign: 'middle',
                        fontSize: 25,
                        color: '#ccc',
                      }}
                      key="logout"
                    />
                  </span>
                </>
              </div>
            }
          >
            <Menu.Item key="SignOut" onClick={onLogout}>
              <span>Sign out</span>
            </Menu.Item>
          </SubMenu>
        </Menu>
      </HeaderWrapper>
    </>
  );
};

export default BookrHeader;
