import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const MenuList = styled.div`
  padding: 18px 12px;
  width: 180px;
  min-width: 180px;
  background-color: #eaaa3e;
  border-radius: 15px;
  /* min-height: 580px; */
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  overflow-y: auto;
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }
  ::-webkit-scrollbar {
    width: 3px !important;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  @media (max-width: 1280px) {
    width: 160px;
    min-width: 160px;
  }
`;
const MXMenu = styled.div`
  color: #fff;
  height: 43px;
  width: 100%;
  border-radius: 10px;
  padding: 0 13px;
  font-size: 21px;
  line-height: 42px;
  text-align: center;
  @media (max-width: 1280px) {
    font-size: 17px;
    line-height: 36px;
  }
`;
const MyMenuBox = styled(MXMenu)`
  background-color: ${(props) => (props.on ? '#f4db4b' : '#ed8c3d')};
  cursor: pointer;
  text-align: start;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 16px;
  @media (max-width: 1280px) {
    font-size: 13px;
    /* line-height: 36px; */
  }
`;
const PhonicsMenuBox = styled(MXMenu)`
  background-color: ${(props) => (props.on ? '#f4db4b' : props.blocked ? '#808080' : '#ed8c3d')};
  cursor: pointer;
  text-align: start;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  font-size: 16px;
  @media (max-width: 1280px) {
    font-size: 13px;
    /* line-height: 36px; */
  }
`;
const MenuBox = styled(MXMenu)`
  background-color: ${(props) => (props.on ? '#f4db4b' : props.blocked ? '#808080' : '#ed8c3d')};
  cursor: pointer;
  text-align: start;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-left: 40px;
`;
const MXListBranch = styled.div`
  width: 9px;
  height: 8px;
  margin-right: 9px;
  background-image: url('/images/book/levelup-branch.png');
  background-size: 9px 8px;
  background-repeat: no-repeat;
`;
const MXListMY = styled.div`
  font-size: 13px;
  padding: 3px 9px;
  line-height: 18px;
  background-color: #fff6e6;
  border-radius: 12px;
  color: ${(props) => (props.on ? '#1cbdb0' : '#7f5032')};
  @media (max-width: 1280px) {
    padding: 3px 7px;
    font-size: 11px;
  }
`;
const Icon = styled.div`
  background-image: url(${(props) => props.url});
  width: 23px;
  height: 23px;
  background-repeat: no-repeat;
  background-size: 23px 23px;
  margin: 0 0 0 10px;
`;
export default function LeftMenu({ myLevel = -2, level, setLevel, isLibrary, isMain = false, isSearch = false, isStudent = true }) {
  const companyName = useSelector(classStoreData);
  const history = useHistory();
  const tmplv = companyName === 'educloud' ? parseInt(myLevel) : -2;
  // const tmplv = levelForHS ??myLevel;
  const handleClickLevel = (e) => {
    if (parseInt(level) === e && !isLibrary && isSearch) {
      setLevel(null);
    } else {
      setLevel(e);
    }
  };
  const LevelList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const myLevelList = useMemo(() => {
    // if (tmplv&&tmplv !== null) {
    if (tmplv !== -2 && companyName === 'educloud') {
      let tmp = [-1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      // const tmp = LevelList.filter((e) => e >= parseInt(tmplv)  && e <= parseInt(tmplv) + 3);
      if (parseInt(tmplv) === 0) {
        tmp = tmp.slice(0, 5);
      } else if (parseInt(tmplv) === 2) {
        tmp = tmp.slice(0, 7);
      } else if (parseInt(tmplv) === 4) {
        tmp = tmp.slice(4, 9);
      } else if (parseInt(tmplv) === 6) {
        tmp = tmp.slice(6, 11);
      } else if (parseInt(tmplv) === 9) {
        tmp = tmp.slice(8);
      }
      return tmp;
    } else return LevelList;
  }, [tmplv, level, isStudent]);
  // useEffect(() => {
  //   if (tmplv && !isMain && tmplv !== -2) {
  //     if (!myLevelList.includes(parseInt(level))) {
  //       history.push('/level-up');
  //     }
  //   }
  // }, [tmplv, level, isMain]);
  const handleCheck = (lv) => {
    if (companyName === 'educloud') {
      if (isStudent) {
        if (myLevel === -2 || (!myLevel && myLevel !== 0)) {
          return false;
        } else if (myLevelList.includes(parseInt(lv))) {
          return true;
        } else return false;
      } else return true;
    } else {
      return true;
    }
  };
  return (
    <MenuList>
      <MyMenuBox
        onClick={() => {
          history.push('/level-up');
        }}
        on={isMain}
      >
        My Page
        <Icon url={'/images/levelUp/books.png'} />
      </MyMenuBox>
      <MyMenuBox
        onClick={() => {
          history.push('/level-up/portfolio');
        }}
      >
        My Portfolio
        <Icon url={'/images/portfolio_icon.svg'} />
        {/* Bookshelf
        <Icon url={'https://elibrary.ai-levelup.com/assets/images/list/levelup/my_bok.png'} /> */}
      </MyMenuBox>
      <MXMenu>mx Level</MXMenu>
      {
        /* {(tmplv <= 0 || companyName !== 'educloud') && */
        isSearch ? (
          <>
            <MenuBox
              onClick={() => {
                // handleCheck(-1) &&
                handleClickLevel(-1);
              }}
              on={parseInt(level) === -1}
              blocked={!handleCheck(-1)}
            >
              <RowFlex style={{ lineHeight: (parseInt(myLevel) === -1 || parseInt(myLevel) === 0) && 1 }}>
                {/* <MXListBranch /> */}
                Phonics
              </RowFlex>
              {parseInt(myLevel) === -1 || parseInt(myLevel) === 0 ? <MXListMY on={false}>MY</MXListMY> : null}
            </MenuBox>
          </>
        ) : (
          <>
            <PhonicsMenuBox
              onClick={() => {
                // handleCheck(-1) &&
                history.push(`/level-up/library?lv=-1`);
              }}
              on={parseInt(level) === -1}
              blocked={!handleCheck(-1)}
            >
              <RowFlex style={{ lineHeight: parseInt(myLevel) === -1 && 1 }}>
                {/* <MXListBranch /> */}
                Phonics Class
              </RowFlex>
              {parseInt(myLevel) === -1 ? <MXListMY on={false}>MY</MXListMY> : null}
            </PhonicsMenuBox>
            <PhonicsMenuBox
              onClick={() => {
                // handleCheck(0) &&
                history.push(`/level-up/library?lv=0`);
              }}
              blocked={!handleCheck(0)}
              on={parseInt(level) === 0}
            >
              <RowFlex style={{ lineHeight: parseInt(myLevel) === 0 && 1, marginLeft: parseInt(myLevel) === 0 && '18px' }}>
                {/* <MXListBranch /> */}
                Phonics Readers
              </RowFlex>
              {parseInt(myLevel) === 0 ? <MXListMY on={false}>MY</MXListMY> : null}
            </PhonicsMenuBox>
          </>
        )
      }
      {LevelList?.map((e, i) =>
        isSearch ? (
          <MenuBox
            blocked={!handleCheck(e)}
            key={i}
            on={parseInt(level) === e}
            onClick={() =>
              // handleCheck(e) &&
              handleClickLevel(e)
            }
          >
            <RowFlex>
              {/* <MXListBranch /> */}
              {`mx ${String(e).padStart(2, '0')}`}
            </RowFlex>
            {parseInt(myLevel) === parseInt(e) ? <MXListMY on={false}>MY</MXListMY> : null}
          </MenuBox>
        ) : (
          <MenuBox
            key={i}
            onClick={() => {
              // handleCheck(e) && parseInt(level) !== e &&
              history.push(`/level-up/library?lv=${e}`);
            }}
            blocked={!handleCheck(e)}
            on={parseInt(level) === e}
          >
            <RowFlex>
              {/* <MXListBranch /> */}
              {`mx ${String(e).padStart(2, '0')}`}
            </RowFlex>
            {parseInt(myLevel) === parseInt(e) ? <MXListMY on={false}>MY</MXListMY> : null}
          </MenuBox>
        ),
      )}
    </MenuList>
  );
}
