import React, { useMemo, useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Avatar, Layout, Menu, Typography, Spin } from 'antd';
import FullScreenButton from 'src/components/common/FullScreenButton';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
  CloseCircleOutlined,
  SettingOutlined,
  MobileOutlined,
} from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import queryString from 'query-string';
import { useQuery, useApolloClient, useMutation } from '@apollo/client';
import VimeoVideo from 'src/components/common/VimeoVideo';
import ReturnToManagerBar from 'src/components/common/ReturnToManagerBar';
import OpenAppModal from '../Modal/OpenAppModal';
import styled from 'styled-components';

import { isLogedIn, userInfoVar, userVar } from 'src/apollo/cache';
import { USER_ME } from 'src/operations/queries/getUser';
import { useQueryProxy } from 'src/operations/proxy/user';
import { getCurrentToken } from '../../utils';
import { storage, useModal } from 'src/utils/hooks';
import * as dummy from 'src/dummy';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { isAfterSchool } from 'src/utils';
import { settings } from 'src/dummy/settings';

const { Header: HeaderWrapper } = Layout;
const { SubMenu } = Menu;
const { Text } = Typography;

const HeaderAICoach = styled(HeaderWrapper)`
  display: flex;
  flex-direction: row;
  justify-content: 'space-between';
  height: 75px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: transparent;
  /* background-image: ${(props) => !props.isStatic && `url('/images/cloud.png')`};
  background-position: ${(props) => !props.isStatic && `center bottom`};
  background-repeat: ${(props) => !props.isStatic && 'no-repeat'}; */
`;
const HeadBox = styled.div`
  height: 43px;
  line-height: 43px;
  font-size: 1.5em;
  font-weight: bolder;
  border-radius: 5px;
  background-color: ${(props) => (props.background ? props.background : '#fff')};
  color: ${(props) => props.color};
  padding: 0 20px;
  margin-right: 30px;
`;

const ImgButton = styled.img`
  cursor: pointer;
  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

const Header = ({ collapsed, onCollapse, isStatic = false }) => {
  const params = queryString.parse(window.location.search);
  const companyName = useSelector(classStoreData);
  const [appData, setAppData] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const token = getCurrentToken();
  const client = useApolloClient();
  const userType = useMemo(() => {
    if (userInfoVar()) {
      return userVar()[userInfoVar().type];
    }
    return '';
  }, []);
  const { data } = useQueryProxy(USER_ME, { variables: { token } });
  const { visible, showModal, handleCancel, popupMode } = useModal();

  const onLogout = () => {
    if (window.confirm('로그아웃 하시겠습니까?')) {
      isLogedIn(false);
      userInfoVar(null);
      client.clearStore();
      client.cache.gc();
      storage.removeItem('culp_token');
      window.location.reload();
    } else {
      return;
    }
  };

  const returnId = useMemo(() => {
    return window.localStorage.getItem('culp_return_id');
  }, []);

  const inputRef = useRef(null);

  const sharedProps = {
    style: {
      width: '100%',
    },
    defaultValue: '. ',
    ref: inputRef,
  };

  useEffect(() => {
    if (params.toApp && isMobile) {
      const curAppUrl = params.toApp;
      let culptoken = localStorage.getItem('culp_token')
        ? JSON.parse(localStorage.getItem('culp_token'))
        : {
            accessToken: '',
            refreshToken: '',
          };
      params.toApp = undefined;
      history.replace({
        search: queryString.stringify(params),
      });
      const url = `${curAppUrl}://oauth${location.pathname}?accessToken=${culptoken.accessToken}&refreshToken=${culptoken.refreshToken}`;
      setTimeout(() => {
        setAppData({
          url,
        });
      }, 1000);
    }
  }, [params.toApp]);

  const renderMobileButton = () => {
    let culptoken = localStorage.getItem('culp_token')
      ? JSON.parse(localStorage.getItem('culp_token'))
      : {
          accessToken: '',
          refreshToken: '',
        };

    return (
      <>
        {isMobile && !window.ReactNativeWebView ? (
          <Menu.Item
            key="mobile"
            onClick={() => {
              setAppData({
                url: `culpmobile://oauth/login?accessToken=${culptoken.accessToken}&refreshToken=${culptoken.refreshToken}`,
              });
            }}
          >
            <MobileOutlined style={{ fontSize: '1.6em', color: '#08c', verticalAlign: 'middle' }} />
          </Menu.Item>
        ) : null}
      </>
    );
  };

  const rightContent = (
    <>
      <Menu
        key="user"
        mode="horizontal"
        style={{ height: '100%', background: 'transparent', border: 0, flex: 8, display: 'flex', justifyContent: 'flex-end' }}
      >
        {(location.pathname === '/' || location.pathname === '/dashboard') &&
          (userType === 'campus' || userType === 'manager' || userType === 'student' || userType === 'fc') && (
            <>
              <Menu.Item key="settings">
                <SettingOutlined style={{ fontSize: '1.6em', color: '#08c', verticalAlign: 'middle' }} onClick={() => showModal('edit')} />
              </Menu.Item>
            </>
          )}
        {/* another guide video btn  */}
        {isAfterSchool(companyName) && ['campus', 'manager'].includes(userType) && ['/dashboard', '/'].includes(location.pathname) && (
          <Menu.Item key="info" style={{ paddingTop: '10px' }}>
            <VimeoVideo
              width={35}
              marginTop={14}
              thumpImage="/images/guide.png"
              item={dummy.test.guideVideosForAfterSchool[companyName]?.[14]?.videos}
            />{' '}
          </Menu.Item>
        )}
        <Menu.Item key="info" style={{ paddingTop: '10px' }}>
          <FullScreenButton
            element={<ImgButton className="header-btn" src="/images/fullscreen.png" alt="back arrow in header" />}
            activeElement={<ImgButton className="header-btn" src="/images/fullscreenExit.png" alt="back arrow in header" />}
          />
        </Menu.Item>
        {/*  */}
        <SubMenu
          key="SubMenu"
          title={
            <>
              <Avatar

              //src="//image.zuiidea.com/photo-1487412720507-e7ab37603c6f.jpeg?imageView2/1/w/200/h/200/format/webp/q/75|imageslim"
              />
              <span style={{ color: '#000', marginLeft: 10 }}>
                <span className="username">
                  {data?.me?.name} <Text strong>({data?.me?.view_id})</Text>
                </span>
                <span onClick={onLogout} style={{ color: '#999', marginLeft: 20 }}>
                  로그아웃
                  <LogoutOutlined
                    style={{
                      marginLeft: 5,
                      verticalAlign: 'middle',
                      fontSize: 25,
                      color: '#ccc',
                    }}
                    key="logout"
                  />
                </span>
              </span>
            </>
          }
        >
          {/* <Menu.Item key="SignOut" onClick={onLogout}>
            <span>Sign out</span>
          </Menu.Item> */}
        </SubMenu>
        {window.Android ? (
          <Menu.Item key="close" onClick={() => window.Android.closeApp()}>
            <span style={{ color: '#999' }}>
              <CloseCircleOutlined
                style={{
                  marginLeft: 20,
                  verticalAlign: 'middle',
                  fontSize: 25,
                  color: '#ccc',
                }}
                key="close"
              />
            </span>
          </Menu.Item>
        ) : null}
      </Menu>
    </>
  );

  return (
    <>
      {returnId && <ReturnToManagerBar returnId={returnId} />}
      <HeaderAICoach
        isStatic={isStatic}
        style={{
          position: isStatic ? 'fixed' : 'relative',
          // justifyContent: 'start',
          // alignItems: 'center',
          // height: '75px',
          // padding: '25px 65px',
          backgroundColor: '#1F317D',
          color: '#DEEBF7',
          fontSize: '40px',
        }}
      >
        <div style={{ visibility: `${userInfoVar()?.level_test === 1 ? 'hidden' : 'visible'}`, flex: 1 }}>
          <div style={{ display: 'flex', height: '100%', alignItems: 'center' }}>
            {collapsed ? (
              <MenuFoldOutlined
                style={{
                  fontSize: 35,
                }}
                className="trigger"
                onClick={onCollapse}
              />
            ) : (
              <MenuUnfoldOutlined
                style={{
                  fontSize: 35,
                }}
                className="trigger"
                onClick={onCollapse}
              />
            )}
            {!isStatic && (
              <div
                onClick={() => {
                  history.push('/');
                }}
                style={{ cursor: 'pointer', padding: '0 20px', width: '400px' }}
              >
                {' '}
                <div>AI WRITING COACH</div>
              </div>
            )}
            {/* {isExistPPT && (
              <h3
                style={{
                  height: '80%',
                  margin: '0 0 0 20px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  textWrap: 'nowrap',
                }}
                onClick={() => {
                  window.open(pptLink, '_blank');
                }}
              >
                <img style={{ height: '70%', margin: '0 5px 0 0' }} src="/images/v-tube-guide.png"></img>
                {` ${guideText}`}
              </h3>
            )} */}
          </div>
        </div>
        {!isStatic ? <>{rightContent}</> : null}
      </HeaderAICoach>

      {/* {userType === 'fc' && (
        <TeacherModal userInfo={userInfo?.getUserData} popupMode="edit" handleCancel={handleCancel} visible={visible} myInfo={true} />
      )}
      {userType === 'campus' && (
        <CampusModal
          campusInfo={userInfo?.getUserData?.campus}
          popupMode="edit"
          handleCancel={handleCancel}
          visible={visible}
          campus_idx={userInfo?.getUserData?.campus?.idx}
        />
      )}
      {userType === 'manager' && (
        <TeacherModal userInfo={userInfo?.getUserData} popupMode="edit" handleCancel={handleCancel} visible={visible} myInfo={true} />
      )}

      {userType === 'student' && (
        <StudentModal userInfo={userInfo?.getUserData} popupMode="edit" handleCancel={handleCancel} visible={visible} myInfo={true} />
      )}
      <OpenAiModal
        visible={openAiModal}
        handleCancel={() => {
          setOpenAiModal(false);
        }}
      /> */}
      {appData !== null && <OpenAppModal visible={true} handleClose={() => setAppData(null)} url={appData?.url} />}
    </>
  );
};

export default Header;
