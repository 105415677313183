import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import * as queries from 'src/operations/queries';
import { classStoreData } from 'src/operations/store';
import { rootStateVar, userInfoVar } from 'src/apollo/cache';
import * as dummy from 'src/dummy';
import moment from 'moment';
import { useStudentStudyAccessCheck } from 'src/utils/hooks';

const CalendarBlock = styled.div`
  padding: 12px;
  background: #fff;
  //box-shadow: 2px 1px 5px 5px #dddddd;
  border-radius: 10px;
  min-height: calc(100vh - 18vh);
  h2.fc-toolbar-title {
    display: inline-block;
  }
  .fc-prev-button,
  .fc-next-button {
    background-color: #fff;
    color: #666;
    border: 0;
    font-weight: bold;
    font-size: 1.5em;
  }
  .fc-prev-button:hover,
  .fc-next-button:hover {
    background-color: #fff;
    color: #666;
    border: 0;
  }
`;
// const eventColor = ['#ffce54', '#ed5565', '#A0d468', '#5d9cec'];
const StudentCalendar = () => {
  const { isOnlineContent, isAIspeaking, isAiCoach } = useStudentStudyAccessCheck();
  const calendarRef = useRef();
  const [events, setEvents] = useState([]);
  const [testEvents, setTestEvents] = useState([]);
  const [zoomEvents, setZoomEvents] = useState([]);
  const [bookEvents, setBookEvents] = useState([]);
  const [PhonicsEvents, setPhonicsEvents] = useState([]);
  const [OfflineEvents, setOfflineEvents] = useState([]);
  const [externalStudyEvents, setExternalStudyEvents] = useState([]);
  const [externalStudyStudentEvents, setExternalStudyStudentEvents] = useState([]);
  const [bookrEvents, setBookrEvents] = useState([]);
  const [levelupEvents, setLevelupEvents] = useState([]);
  const [starwordsEvents, setStarwordsEvents] = useState([]);
  const [bookrEventsStudent, setBookrEventsStudent] = useState([]);
  const [aiTopics, setAiTopics] = useState([]);
  const [bookReportTopics, setBookReportTopics] = useState([]);
  const [aiTalkingTopics, setAiTalkingTopics] = useState([]);
  const [dailyTest, setDailyTest] = useState([]);
  const defaultClass = useSelector(classStoreData);
  const {
    calendar: { selectDay },
  } = useReactiveVar(rootStateVar);

  const classType = useMemo(
    () =>
      dummy.settings.settings[defaultClass]
        ? dummy.settings.settings[defaultClass].classType
        : dummy.settings.settings['default'].classType,
    [],
  );
  const settings = useMemo(
    () => (dummy.settings.settings[defaultClass] ? dummy.settings.settings[defaultClass] : dummy.settings.settings['default']),
    [],
  );

  const classColor = useMemo(() => (dummy.settings.settings[defaultClass] ? dummy.settings.settings[defaultClass].classColor : {}), []);

  const [getStudySchedule, { data }] = useLazyQuery(queries.getClass.DASHBOARD_STUDENT_LESSON_LIST, { fetchPolicy: 'no-cache' });
  const [getTestSchedule, { data: fetchTestData }] = useLazyQuery(queries.getClass.DASHBOARD_STUDENT_TEST_LIST, {
    fetchPolicy: 'no-cache',
  });
  const [getAiTopicList, { data: aiTopicsData }] = useLazyQuery(queries.getClass.DASHBOARD_STUDENT_AI_TOPIC_LIST, {
    fetchPolicy: 'no-cache',
  });

  const [getBookReportTopicList, { data: bookReportTopicsData }] = useLazyQuery(queries.getClass.DASHBOARD_STUDENT_BOOK_REPORT_TOPIC_LIST, {
    fetchPolicy: 'no-cache',
  });
  const [getAiTalkingTopicList, { data: aiTalkingTopicsData }] = useLazyQuery(queries.getClass.DASHBOARD_STUDENT_TALKING_TUTOR_TOPIC_LIST, {
    fetchPolicy: 'no-cache',
  });

  const [getBookSchedule, { data: fetchBookData }] = useLazyQuery(queries.getClass.DASHBOARD_STUDENT_BOOK_LIST, {
    fetchPolicy: 'no-cache',
  });
  const [getExternalStudySchedule, { data: fetchExternalStudyData }] = useLazyQuery(
    queries.getClass.DASHBOARD_STUDENT_EXTERNAL_STUDY_LIST,
    {
      fetchPolicy: 'no-cache',
    },
  );
  const [getZoomSchedule, { data: fetchZoomData }] = useLazyQuery(queries.getClass.DASHBOARD_STUDENT_ZOOM_LIST, {
    fetchPolicy: 'no-cache',
  });

  const [getPhonicsSchedule, { data: fetchPhonicsData }] = useLazyQuery(queries.getClass.GET_ALL_PHONICS_SCHEDULE, {
    fetchPolicy: 'no-cache',
  });

  const [getOfflineStudentSchedule, { data: fetchOfflineStudentData }] = useLazyQuery(queries.getClass.GET_ALL_OFFLINE_SCHEDULE_STUDENT, {
    fetchPolicy: 'no-cache',
  });

  const [getExternalStudyScheduleForStudent, { data: fetchExternalStudyDataForStudent }] = useLazyQuery(
    queries.getClass.GET_DAILY_CLASS_EXTERNAL_STUDY_SCHEDULE_FOR_STUDENT,
    {
      fetchPolicy: 'no-cache',
    },
  );
  const [getDailyTestSchedule, { data: fetchDailyTestData }] = useLazyQuery(queries.getClass.DAILY_DASHBOARD_STUDENT_TEST_LIST, {
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    if (data) {
      if (defaultClass === 'rise' && !isOnlineContent) {
        setEvents([]);
      } else {
        setEvents(
          data.dashBoardStudentClassLessonList.map((item, i) => {
            return {
              key: i,
              date: item.study_date,
              title: `${item?.lesson_cnt}: Homework`,
              isDone: item?.lesson_cnt === item?.lesson_done,
              color: classColor[`${item.__typename}`],
              type: item?.__typename,
              textColor: item?.__typename,
              backgroundColor: classColor['UserLesson'],
            };
          }),
        );
      }
    }
    if (fetchPhonicsData !== undefined) {
      setPhonicsEvents(
        fetchPhonicsData.AssignedPhonicsAllSchedule.map((item, i) => {
          if (item.phonics_start_date !== undefined) {
            return {
              key: i,
              date: moment(new Date(item.phonics_start_date.split('T')[0])).format(),
              // title: item?.type === 'SW' ? `${item?.SWCount} : StarWords` : `${item?.PHCount} : Phonics`,
              // color: item?.type === 'SW' ? classColor['StarwordsLesson'] : classColor['PhonicsLesson'],
              // type: item?.type === 'SW' ? 'StarwordsLesson' : 'PhonicsLesson',
              // textColor: item?.type === 'SW' ? 'StarwordsLesson' : 'PhonicsLesson',
              backgroundColor: classColor['PhonicsLesson'],
              title: `${item?.count} : Phonics`,
              isDone: item?.count === item?.done,
              color: classColor['PhonicsLesson'],
              type: 'PhonicsLesson',
              textColor: 'PhonicsLesson',
            };
          }
        }),
      );
    }

    if (fetchOfflineStudentData !== undefined || fetchOfflineStudentData !== null) {
      if (fetchOfflineStudentData?.AssignedOfflineStudentSchedule) {
        setOfflineEvents(
          fetchOfflineStudentData?.AssignedOfflineStudentSchedule.map((item, i) => {
            if (item.offline_start_date !== undefined) {
              return {
                key: i,
                date: moment(new Date(item.offline_start_date.split('T')[0])).format(),
                title: `${item?.count} : Offline`,
                color: classColor['OfflineLesson'],
                type: 'OfflineLesson',
                textColor: 'OfflineLesson',
                backgroundColor: classColor['OfflineLesson'],
              };
            }
          }),
        );
      } else {
        setOfflineEvents([{}]);
      }
    }
    if (fetchTestData) {
      setTestEvents(
        fetchTestData.dashBoardStudentTestList
          .filter((item) => item.test_cnt)
          .map((item, i) => {
            return {
              key: i,
              date: moment(new Date(item.DateOnly)).format(),
              title: `${item?.test_cnt} : Test`,
              color: classColor[`${item.__typename}`],
              isDone: item?.test_cnt === item?.test_done,
              type: item?.__typename,
              textColor: item?.__typename,
              backgroundColor: classColor[item?.__typename],
            };
          }),
      );
    }
    if (fetchBookData) {
      setBookEvents(
        fetchBookData.dashBoardStudentBookList
          .filter((item) => item.book_idx)
          .map((item, i) => {
            return {
              key: i,
              date: moment(new Date(item.start_date)).format(),
              title: `${item.elibrary_cnt} : Book`,
              color: classColor['AssignedBooks'],
              type: 'AssignedBooks',
              textColor: 'AssignedBooks',
              backgroundColor: classColor['AssignedBooks'],
            };
          }),
      );
    }
    if (fetchExternalStudyData) {
      const newso = [];
      const bookr = [];
      const levelup = [];
      const starWords = [];

      for (let item of fetchExternalStudyData?.dashBoardStudentExternalStudyList) {
        if (item.type === 'newsomatic') {
          newso.push(item);
        } else if (item.type === 'bookr') {
          bookr.push(item);
        } else if (item.type === 'levelup') {
          levelup.push(item);
        } else if (item.type === 'starwords') {
          starWords.push(item);
        }
      }

      const newsoAccordingToDate = newso.reduce((acc, item) => {
        const dateOnly = moment(new Date(item.start_date)).format('YYYY-MM-DD');
        if (acc[dateOnly]) {
          acc[dateOnly]['count'] += 1;
          acc[dateOnly]['isDone'] += item.is_done ? 1 : 0;
        } else {
          acc[dateOnly] = { count: 1, date: dateOnly, isDone: item.is_done ? 1 : 0 };
        }
        return acc;
      }, {});
      setExternalStudyEvents(
        Object.values(newsoAccordingToDate).map((item, i) => {
          return {
            key: `external-${i}`,
            date: moment(new Date(item.date)).format(),
            title: `${item?.count} : Newsomatic`,
            color: classColor['ExternalStudy'],
            isDone: item?.count === item?.isDone,
            type: 'ExternalStudy',
            textColor: 'ExternalStudy',
            backgroundColor: classColor['ExternalStudy'],
          };
        }),
      );
      const bookrAccordingToDate = bookr.reduce((acc, item) => {
        const dateOnly = moment(new Date(item.start_date)).format('YYYY-MM-DD');
        if (acc[dateOnly]) {
          acc[dateOnly]['count'] += 1;
          acc[dateOnly]['isDone'] += item.is_done ? 1 : 0;
        } else {
          acc[dateOnly] = { count: 1, date: dateOnly, isDone: item.is_done ? 1 : 0 };
        }
        return acc;
      }, {});
      setBookrEvents(
        Object.values(bookrAccordingToDate).map((item, i) => {
          return {
            key: `bookr-${i}`,
            date: moment(new Date(item.date)).format(),
            title: `${item?.count} : Bookr`,
            color: classColor['Bookr'],
            isDone: item?.count === item?.isDone,
            type: 'Bookr',
            textColor: 'Bookr',
            backgroundColor: classColor['Bookr'],
          };
        }),
      );

      const levelupAccordingToDate = levelup.reduce((acc, item) => {
        const dateOnly = moment(new Date(item.start_date)).format('YYYY-MM-DD');
        if (acc[dateOnly]) {
          acc[dateOnly]['count'] += 1;
          acc[dateOnly]['isDone'] += item.is_done ? 1 : 0;
        } else {
          acc[dateOnly] = { count: 1, date: dateOnly, isDone: item.is_done ? 1 : 0 };
        }
        return acc;
      }, {});

      setLevelupEvents(
        Object.values(levelupAccordingToDate).map((item, i) => {
          return {
            key: `LevelUp-${i}`,
            date: moment(new Date(item.date)).format(),
            title: `${item?.count} : LevelUp`,
            color: classColor['LevelUp'],
            isDone: item?.count === item?.isDone,
            type: 'LevelUp',
            textColor: 'LevelUp',
            backgroundColor: classColor['LevelUp'],
          };
        }),
      );

      const starWordsAccordingToDate = starWords.reduce((acc, item) => {
        const dateOnly = moment(new Date(item.start_date)).format('YYYY-MM-DD');
        if (acc[dateOnly]) {
          acc[dateOnly]['count'] += 1;
          acc[dateOnly]['isDone'] += item.is_done ? 1 : 0;
        } else {
          acc[dateOnly] = { count: 1, date: dateOnly, isDone: item.is_done ? 1 : 0 };
        }
        return acc;
      }, {});

      setStarwordsEvents(
        Object.values(starWordsAccordingToDate).map((item, i) => {
          return {
            key: `StarWords-${i}`,
            date: moment(new Date(item.date)).format(),
            title: `${item?.count} : StarWords`,
            color: classColor['StarWords'],
            isDone: item?.count === item?.isDone,
            type: 'StarWords',
            textColor: 'StarWords',
            backgroundColor: classColor['StarWords'],
          };
        }),
      );
    }
    if (fetchZoomData) {
      setZoomEvents(
        fetchZoomData.dashBoardStudentZoomList
          .filter((item) => item.zoom_cnt)
          .map((item, i) => {
            return {
              key: i,
              date: moment(new Date(item.DateOnly)).format(),
              title: `${item?.zoom_cnt} : Zoom`,
              color: classColor[`${item.__typename}`],
              type: item?.__typename,
              textColor: item?.__typename,
            };
          }),
      );
    }
    if (fetchExternalStudyDataForStudent) {
      const newsoAccordingToDateStudent = fetchExternalStudyDataForStudent?.dailyClassExternalStudyScheduleForStudent?.reduce(
        (acc, item) => {
          const dateOnly = moment(new Date(item.study_date)).format('YYYY-MM-DD');
          if (acc[dateOnly]) {
            acc[dateOnly]['count'] += 1;
          } else {
            acc[dateOnly] = { count: 1, date: dateOnly };
          }
          return acc;
        },
        {},
      );
      setExternalStudyStudentEvents(
        Object.values(newsoAccordingToDateStudent).map((item, i) => {
          // fetchExternalStudyDataForStudent?.dailyClassExternalStudyScheduleForStudent?.map((item, i) => {
          return {
            key: `external-${i}`,
            date: moment(new Date(item.date)).format(),
            title: `${item?.count} : Newsomatic`,
            color: classColor['ExternalStudyForStudent'],
            type: 'ExternalStudyForStudent',
            textColor: 'ExternalStudyForStudent',
            backgroundColor: classColor['News_O_Matic'],
          };
        }),
      );
    }
    if (fetchDailyTestData) {
      setDailyTest(
        fetchDailyTestData.dailyDashBoardStudentTestList
          // .filter((item) => item.test_cnt)
          .map((item, i) => {
            return {
              key: i,
              date: moment(new Date(item.DateOnly)).format(),
              title: `${item?.test_cnt} : DailyTest`,
              color: classColor[`${item.__typename}`],
              // isDone: item?.test_cnt === item?.test_done,
              type: item?.__typename,
              textColor: item?.__typename,
              backgroundColor: classColor[item?.__typename],
            };
          }),
      );
    }
  }, [
    classColor,
    data,
    fetchTestData,
    fetchZoomData,
    fetchBookData,
    fetchPhonicsData,
    fetchOfflineStudentData,
    fetchExternalStudyData,
    fetchExternalStudyDataForStudent,
    fetchDailyTestData,
    isOnlineContent,
    defaultClass,
  ]);

  useEffect(() => {
    if (aiTopicsData) {
      setAiTopics(
        aiTopicsData.dashBoardStudentAiTopicList
          .filter((item) => item.ai_topic_cnt)
          .map((item, i) => {
            return {
              key: i,
              date: moment(new Date(item.DateOnly)).format(),
              title: `${item?.ai_topic_cnt} : AI Topic`,
              isDone: item?.ai_topic_cnt === item?.done_cnt,
              color: classColor[`${item.__typename}`],
              type: item?.__typename,
              textColor: item?.__typename,
              backgroundColor: classColor['AI_Topics'],
            };
          }),
      );
    }
  }, [aiTopicsData, classColor]);

  useEffect(() => {
    if (bookReportTopicsData) {
      if (defaultClass === 'rise' && !isAiCoach) {
        setBookReportTopics([]);
      } else {
        setBookReportTopics(
          bookReportTopicsData.dashBoardStudentBookReportTopicList
            .filter((item) => item.ai_topic_cnt)
            .map((item, i) => {
              return {
                key: i,
                date: moment(new Date(item.DateOnly)).format(),
                title: `${item?.ai_topic_cnt} : Writing Coach`,
                isDone: item?.ai_topic_cnt === item?.done_cnt,
                color: classColor[`${item.__typename}`],
                type: item?.__typename,
                textColor: item?.__typename,
                backgroundColor: classColor['Book_Report'],
              };
            }),
        );
      }
    }
  }, [bookReportTopicsData, classColor, defaultClass, isAiCoach]);

  useEffect(() => {
    if (aiTalkingTopicsData) {
      if (defaultClass === 'rise' && !isAIspeaking) {
        setAiTalkingTopics([]);
      } else {
        setAiTalkingTopics(
          aiTalkingTopicsData.dashBoardStudentAiTalkingTopicList
            .filter((item) => item.ai_topic_cnt)
            .map((item, i) => {
              return {
                key: i,
                date: moment(new Date(item.DateOnly)).format(),
                title: `${item?.ai_topic_cnt} : Speaking tutor`,
                isDone: item?.ai_topic_cnt === item?.done_cnt,
                color: classColor[`${item.__typename}`],
                type: item?.__typename,
                textColor: item?.__typename,
                backgroundColor: classColor['AI_Talking'],
              };
            }),
        );
      }
    }
  }, [aiTalkingTopicsData, classColor, defaultClass, isAIspeaking]);

  function handleCalendarClick({ startStr }) {
    rootStateVar({
      ...rootStateVar(),
      calendar: {
        ...rootStateVar().calendar,
        selectDay: startStr,
      },
    });
  }

  function handleEventClick({ event }) {
    const selectDay = moment(event.start).format('yyyy-MM-DD');
    const calendarApi = calendarRef.current.getApi();
    calendarApi.select(selectDay);
  }

  function handleDateClick(e) {
    const selectDay = moment(e.date).format('yyyy-MM-DD');
    const calendarApi = calendarRef.current.getApi();
    calendarApi.select(selectDay);
  }

  return (
    <CalendarBlock>
      <FullCalendar
        ref={calendarRef}
        //selectable={true}
        datesSet={({ start, end }) => {
          const startDate = `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`;
          const endDate = `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`;
          rootStateVar({
            ...rootStateVar(),
            calendar: {
              start: startDate,
              end: endDate,
            },
          });
          getStudySchedule({
            variables: {
              start,
              end,
            },
          });

          getTestSchedule({
            variables: {
              start,
              end,
            },
          });
          getBookSchedule({
            variables: {
              start,
              end,
            },
          });

          getExternalStudySchedule({
            variables: {
              start,
              end,
            },
          });

          getZoomSchedule({
            variables: {
              start,
              end,
            },
          });
          getPhonicsSchedule({
            variables: {
              start: startDate,
              end: endDate,
            },
          });

          getOfflineStudentSchedule({
            variables: {
              start: startDate,
              end: endDate,
            },
          });
          getExternalStudyScheduleForStudent({
            variables: {
              start: startDate,
              end: endDate,
            },
          });
          getAiTopicList({
            variables: {
              start: startDate,
              end: endDate,
            },
          });

          getBookReportTopicList({
            variables: {
              start: startDate,
              end: endDate,
            },
          });
          getAiTalkingTopicList({
            variables: {
              start: startDate,
              end: endDate,
            },
          });
          getDailyTestSchedule({
            variables: {
              start: startDate,
              end: endDate,
            },
          });
        }}
        plugins={[dayGridPlugin, interactionPlugin]}
        height={'calc(100vh - 25vh)'}
        initialView="dayGridMonth"
        events={[
          ...events,
          ...testEvents,
          ...zoomEvents,
          ...bookEvents,
          ...PhonicsEvents,
          ...OfflineEvents,
          ...externalStudyEvents,
          ...bookrEvents,
          ...externalStudyStudentEvents,
          ...bookrEventsStudent,
          ...aiTopics,
          ...bookReportTopics,
          ...dailyTest,
          ...levelupEvents,
          ...starwordsEvents,
          ...aiTalkingTopics,
        ]}
        eventContent={(eventInfo, i) => {
          return (
            <>
              <div
                style={{
                  backgroundColor: eventInfo.backgroundColor,
                  color: 'white',
                  borderRadius: 10,
                  width: 25,
                  height: 18,
                  textAlign: 'center',
                  marginRight: 5,
                  textDecoration: eventInfo.event?.extendedProps?.isDone ? 'line-through' : 'none',
                }}
                className={`iconcalander ${settings.eventsIcon[eventInfo.textColor]}`}
              >
                {/* className="icon_userlesson" */}
                {/* className="icon_assignedtests" */}
                {/* className="icon_zoomschedule" */}
                {settings.calendarEventsIn ? classType[eventInfo.textColor] : eventInfo.event.title}
              </div>
              <div
                style={{
                  textDecoration: eventInfo.event?.extendedProps?.isDone ? 'line-through' : 'none',
                }}
                className={`iconcommontext ${settings.eventsIcon[eventInfo.textColor]}`}
              >
                {' '}
                {!settings.calendarEventsIn ? classType[eventInfo.textColor] : eventInfo.event.title}
              </div>
            </>
          );
        }}
        select={handleCalendarClick}
        eventClick={handleEventClick}
        dateClick={handleDateClick}
        //locale={'ko-KR'}
        dayMaxEvents={2}
        longPressDelay={0}
        eventLongPressDelay={0}
        selectLongPressDelay={0}
        headerToolbar={{
          start: '',
          center: 'prev,title,next',
          end: 'todayButton',
        }}
        customButtons={{
          todayButton: {
            text: 'today',
            click: () => {
              const calendarApi = calendarRef.current.getApi();
              calendarApi.today();
              calendarApi.select(moment(new Date()).format('yyyy-MM-DD'));
            },
          },
        }}
      />
      {defaultClass === 'lucid' ? (
        <div>
          <div className="dasboard-list">
            <span style={{ backgroundColor: '#FF0000', borderRadius: '10px' }}>H</span>
            <span style={{ backgroundColor: '#FF9900', borderRadius: '10px' }}>L</span>
            <span style={{ backgroundColor: '#000066', borderRadius: '10px' }}>T</span>
            <span style={{ backgroundColor: '#7030A0', borderRadius: '10px' }}>O</span>
          </div>
        </div>
      ) : (
        ''
      )}
    </CalendarBlock>
  );
};

export default StudentCalendar;
