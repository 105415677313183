import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { GET_ORDER_ITEM_LIST_EDUCO_REPORT } from 'src/operations/queries/getLevelTest';
import { getEducoLevelTestScore, getMetaExamId } from 'src/utils';
import { useLocation } from 'react-router-dom';

import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { META_EXAM_SAVE_REPORT } from 'src/operations/mutations/createTests';
import axios from 'axios';

const LEVEL_CHART_DATA = [
  {
    min: 0,
    max: 9,
    level: 'Prep 3',
    level_no: 0,
    lexile: '400L ~ 500L',
    grade: '내신 ( 중1 )',
    comment: '중1 교과서 수준 읽고 이해 / 과거 시제까지',
  },
  {
    min: 10,
    max: 16,
    level: 'Level 1',
    level_no: 1,
    lexile: '450L ~ 550L',
    grade: '내신 ( 중2 )',
    comment: '중2 교과서 수준 읽고 이해 / 관계 절 까지',
  },
  {
    min: 17,
    max: 22,
    level: 'Level 2',
    level_no: 2,
    lexile: '550L ~ 650L',
    grade: '내신 ( 중3 )',
    comment: '중3 교과서 수준 읽고 이해 / 중등 문법 전체',
  },
  {
    min: 23,
    max: 26,
    level: 'Level 3',
    level_no: 3,
    lexile: '650L ~ 750L',
    grade: '내신 ( 중3 [상] )',
    comment: '고1 수능 모의고사  4등급',
  },
  {
    min: 0,
    max: 9,
    level: 'Level 4',
    level_no: 4,
    lexile: '700L ~ 800L',
    grade: '고1 ( 3등급 )',
    comment: '고1 수능 모의고사  3등급',
  },
  {
    min: 10,
    max: 16,
    level: 'Level 5',
    level_no: 5,
    lexile: '800L ~ 900L',
    grade: '고1 ( 2등급 )',
    comment: '고1 수능 모의고사  2등급',
  },
  {
    min: 17,
    max: 20,
    level: 'Level 6',
    level_no: 6,
    lexile: '850L ~ 950L',
    grade: '고1 ( 1등급 )',
    comment: '고1 수능 모의고사  1등급',
  },
  {
    min: 21,
    max: 24,
    level: 'Level 7',
    level_no: 7,
    lexile: '950L ~ 1,050L',
    grade: '고2 ( 3등급 )',
    comment: '고2 수능 모의고사  3~4등급',
  },
  {
    min: 25,
    max: 27,
    level: 'Level 8',
    level_no: 8,
    lexile: '1,000L ~ 1,100L',
    grade: '고2 ( 2등급 )',
    comment: '고2 수능 모의고사  2등급',
  },
  {
    min: 28,
    max: 30,
    level: 'Level 9',
    level_no: 9,
    lexile: '1,100L ~ 1,200L',
    grade: '고2 ( 1등급 )',
    comment: '고2 수능 모의고사  1등급 이상 수준 / 실전 수능 레벨 판정 불가',
  },
];

const CHART_COLORS = ['#ffff00', '#f2ceef', '#daf2d0', '#ffd43b', '#caedfb', '#fbe2d5', '#bdcdff'];

const TYPE_CHART_GROUP = {
  A: [
    ['주제', '제목', '요지', '요약'],
    ['목적', '주장'],
    ['어휘', '어법'],
    ['빈칸', '삽입'],
    ['순서', '흐름'],
    ['일치', '세부 내용', '지침'],
  ],
  B: [['주제', '제목', '요지', '요약'], ['함축'], ['어휘', '어법'], ['빈칸', '삽입'], ['순서', '흐름']],
};
const InnerWrap = styled.div`
  width: 100%;
  max-width: 2000px;
  height: calc(100% - 7rem);
  padding: 1rem;
  margin: 0rem auto;
  background-color: #c0e5fc;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 2000px) and (max-width: 2560px) and (aspect-ratio: 16/9) {
    max-width: 2600px;
  }

  @media (max-width: 1200px) and (max-height: 800px) and (orientation: landscape) {
    width: 100%;
    max-width: 1200px;
    padding: 0;
  }

  @media (max-width: 800px) and (max-height: 1200px) and (orientation: portrait) {
    width: 100%;
    max-width: 800px;
    padding: 0;
  }

  @media print {
    page-break-before: always;
  }
`;

const ReportWrap = styled.div`
  width: calc(100% - 17rem);
  height: calc(100% - 7rem);
  padding: 1rem 0 0;
  margin: 0rem auto 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media print {
    overflow: hidden;
  }

  @media (max-width: 800px) and (max-height: 1200px) {
    width: 100%;
    max-width: 700px;
  }

  & .result-table {
    margin-top: 1rem;
    width: 100%;
    background-color: #fff;
    td {
      padding: 0.4rem 0.5rem;
      border: 1px solid #4dabe8;
      text-align: center;
      font-size: 12px;
    }
  }
  & .result-table-header {
    background-color: #d9f0ff;
    font-weight: 500;
  }
`;

const ReportCard = styled.div`
  width: calc(100% - 17rem);
  height: calc(100% - 7rem);
  margin: 0rem auto 0;
  padding: 0 1rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: #fff;

  @media (max-width: 800px) and (max-height: 1200px) {
    width: 100%;
    max-width: 700px;
  }

  @media print {
    overflow: hidden;
  }

  & .result-table {
    width: 100%;
    background-color: #fff;

    td {
      padding: 0.4rem 0.5rem;
      border: 1px solid #4dabe8;
      text-align: center;
      font-size: 12px;
    }

    th {
      padding: 0.4rem 0.5rem;
      border: 1px solid #4dabe8;
      background-color: #ebebeb;
    }
  }
  & .result-table-header {
    background-color: #d9f0ff;
    font-weight: 500;
  }
`;

const PrintWrapper = styled.div`
  background-color: #c0e5fc;

  .print-section {
    display: none;
  }

  @media print {
    padding: 10px;
    .web-section {
      display: none;
    }
    .print-section {
      display: block;
    }
  }
`;

const StudentReportLevelTestEduco = () => {
  const { id } = useParams();
  const location = useLocation();
  const [saveReport, { loading: updateLoading }] = useMutation(META_EXAM_SAVE_REPORT);
  const [uploadTos3, setuploadTos3] = useState(false);

  const { data } = useQuery(GET_ORDER_ITEM_LIST_EDUCO_REPORT, {
    variables: { idx_array: [parseInt(id)] },
  });

  const userObj = useMemo(() => {
    if (data) {
      let newUserObj = data.orderItemListByIdx[0].order_leveltest[0]?.assigned_tests?.assigned_test_users[0].user;
      return {
        korean_name: newUserObj.english_name,
        name: newUserObj.name,
        user_idx: newUserObj.idx,
        id: getMetaExamId(newUserObj.birthday, newUserObj?.parent_phone),
        grade: data.orderItemListByIdx[0]?.order_leveltest[0]?.order?.school_info || '',
      };
    }
  }, [data]);

  const testObj = useMemo(() => {
    if (data) {
      return data.orderItemListByIdx[0].order_leveltest[0]?.assigned_tests.tests;
    }
  }, [data]);

  const assignedTestObj = useMemo(() => {
    if (data) {
      return data.orderItemListByIdx[0].order_leveltest[0]?.assigned_tests?.assigned_test_users[0];
    }
  }, [data]);

  const testAnswers = useMemo(() => {
    if (testObj && assignedTestObj && assignedTestObj.assigned_test_answers) {
      return testObj.test_answers.map((test) => {
        let userAnswer = assignedTestObj.assigned_test_answers.find((answer) => answer.code === test.code);
        return {
          ...test,
          ...userAnswer,
        };
      });
    }
  }, [testObj, assignedTestObj]);

  useEffect(() => {
    if (assignedTestObj && testAnswers) {
      if (!JSON.parse(assignedTestObj?.extra_data)?.report_pdf_meta) {
        setTimeout(() => {
          setuploadTos3(true);
        }, 2000);
      } else if (JSON.parse(assignedTestObj?.extra_data)?.report_pdf_meta) {
        window.location = JSON.parse(assignedTestObj?.extra_data)?.report_pdf_meta;
      }
    }
  }, [assignedTestObj, testAnswers]);

  const testType = testObj?.test_type;

  const scoreData = useMemo(() => {
    if (testAnswers) {
      let scoreData = testAnswers.reduce((acc, item) => {
        let cat = item.questions.question_direction;
        let score = item.is_correct ? 1 : 0;

        if (acc[cat]) {
          acc[cat].score += score;
          acc[cat].total += 1;
        } else {
          acc[cat] = { section: cat, score, total: 1 };
        }
        return acc;
      }, {});

      return Object.values(scoreData);
    }
  }, [testAnswers]);

  const chartData = useMemo(() => {
    if (scoreData) {
      let charts = [];
      TYPE_CHART_GROUP[testType].forEach((group, index) => {
        let groupScore = scoreData.filter((item) => group.includes(item.section));
        let score = groupScore.reduce((acc, item) => {
          return acc + item.score;
        }, 0);
        let total = groupScore.reduce((acc, item) => {
          return acc + item.total;
        }, 0);
        charts.push({
          name: group.join(' / '),
          score: score,
          total: total,
        });
      });

      return charts;
    }
  }, [scoreData, testType]);

  console.log(chartData);

  const maxChartTotal = useMemo(() => {
    if (chartData) {
      return chartData.reduce((acc, item) => {
        return item.total > acc ? item.total : acc;
      }, 0);
    }
  }, [chartData]);

  useEffect(() => {
    if (userObj?.id && uploadTos3) {
      exportToS3(userObj.id);
    }
  }, [userObj, uploadTos3]);

  const exportToS3 = (filename) => {
    let url = `${window.location.protocol}//${window.location.host}/educo/level-test/report/${id}`;

    return new Promise(async (resolve, reject) => {
      try {
        const fileData = await axios.post('https://llf63tb67l3wj7nqerumdipm440stovy.lambda-url.ap-northeast-2.on.aws', {
          url: url,
          fileName: '-' + filename + '.pdf',
        });
        const fileUrl = fileData.data;
        // const fullUploader = await upload({ variables: { url: url, filename: '-' + filename + '.pdf' } });
        const variables = {
          order_id: Number(id),
          pdf_url: fileUrl,
          mx: levelData?.rcLevel,
          assigned_test_user_idx: assignedTestObj?.idx,
        };
        try {
          await saveReport({ variables: variables });
          window.location = fileUrl;
          // window.location = fullUploader?.data?.generatePdfS3Meta?.id;
          return;
        } catch (e) {
          console.log(e);
        }
      } catch (e) {
        console.log(e);
      }
    });
  };
  const levelData = useMemo(() => {
    if (testAnswers && testObj) {
      let rcScore = 0;

      for (let i = 0; i < 30; i++) {
        rcScore += testAnswers[i]?.is_correct ? 1 : 0;
      }

      let rcLevel = getEducoLevelTestScore(rcScore, 'RC' + testObj?.test_type);

      let grScore = 0;

      for (let i = 30; i < 60; i++) {
        grScore += testAnswers[i]?.is_correct ? 1 : 0;
      }

      let grLevel = getEducoLevelTestScore(grScore, 'GR' + testObj?.test_type);

      return {
        rcLevel: rcLevel.level,
        grLevel: grLevel.level,
        rcScore,
        grScore,
        comment: rcLevel.comment,
        grade: rcLevel.grade,
      };
    }
  }, [testObj, testAnswers]);

  const getScoreCards = (start, end) => {
    const scoreCard = [];

    if (!testAnswers) return;

    for (let i = start; i < end; i++) {
      if (testAnswers.length <= i) {
        scoreCard.push(
          <tr key={i}>
            <td className="result-table-header">{i + 1}</td>
            <td className="result-table-content"></td>
            <td className="result-table-content"></td>
          </tr>,
        );
      } else {
        scoreCard.push(
          <tr key={i}>
            <td className="result-table-header">{testAnswers[i]?.code}</td>
            <td className="result-table-content">{testAnswers[i]?.questions?.question_direction || '목적'}</td>
            <td
              className="result-table-content"
              style={{
                color: testAnswers[i]?.is_correct ? 'green' : 'red',
              }}
            >
              {testAnswers[i]?.is_correct ? 'O' : 'X'}
            </td>
          </tr>,
        );
      }
    }
    return scoreCard;
  };

  const getLevelChart = () => {
    const levelChart = [];
    for (let i = 0; i < LEVEL_CHART_DATA.length; i++) {
      levelChart.push(
        <tr key={i}>
          <td className="result-table-content">{LEVEL_CHART_DATA[i].level}</td>
          <td className="result-table-content">{LEVEL_CHART_DATA[i].lexile}</td>
          <td className="result-table-content">{LEVEL_CHART_DATA[i].grade}</td>
          <td className="result-table-content">{LEVEL_CHART_DATA[i].comment}</td>
        </tr>,
      );
    }

    return levelChart.reverse();
  };

  return (
    <PrintWrapper className={`meta-report-con allPage `}>
      <InnerWrap>
        <ReportWrap className="result-table-con">
          <div className="report-header" style={{ width: '100%', height: '75px', position: 'relative', marginTop: '10px' }}>
            <img src="/images/educo_logo1.png" alt="logo" height="75px" style={{ position: 'absolute', top: '0', left: '0' }} />
            <div
              style={{
                fontSize: '30px',
                fontWeight: 'bold',
                width: '100%',
                textAlign: 'center',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <span>
                Level Test Report <span style={{ textTransform: 'capitalize' }}>{testType}</span>
              </span>
            </div>
          </div>
          <table className="result-table">
            <tr>
              <td className="result-table-header">Name</td>
              <td className="result-table-content">{userObj?.name}</td>
              <td className="result-table-header">Grade</td>
              <td className="result-table-header">Date</td>
              <td className="result-table-header">RC Level</td>
              <td className="result-table-header">RC Score</td>
              <td className="result-table-header">GR Level</td>
              <td className="result-table-header">GR Score</td>
            </tr>
            <tr>
              <td className="result-table-header">Test ID</td>
              <td className="result-table-content">{userObj?.id}</td>
              <td className="result-table-content">{userObj?.grade}</td>
              <td className="result-table-content">{moment(assignedTestObj?.end_time).format('YYYY-MM-DD')}</td>
              <td className="result-table-content">{levelData?.rcLevel}</td>
              <td className="result-table-content">{levelData?.rcScore}</td>
              <td className="result-table-content">{levelData?.grLevel}</td>
              <td className="result-table-content">{levelData?.grScore}</td>
            </tr>
          </table>
          <p
            style={{
              fontSize: '24px',
              fontWeight: 'bold',
              width: '100%',
              marginTop: '1rem',
              marginBottom: '1rem',
            }}
          >
            Reading
          </p>
        </ReportWrap>
        <ReportWrap className="report-top" style={{ marginBottom: 0, padding: '1rem', borderBottom: 0 }}>
          <div style={{ display: 'flex', paddingBottom: '15px', width: '60%', justifyContent: 'center' }}>
            <ResponsiveContainer className="web-section" width="100%" height={300}>
              <BarChart
                width={100}
                height={300}
                data={chartData}
                barSize={20}
                margin={{
                  top: 5,
                  right: 0,
                  left: 0,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <YAxis domain={[0, maxChartTotal]} />
                <Bar dataKey="total" fill="gray" barSize={10} />
                <Bar dataKey="score" fill="#8884d8">
                  {chartData &&
                    chartData.map((item, index) => <Cell key={`cell-${index}`} fill={CHART_COLORS[index % CHART_COLORS.length]} />)}
                </Bar>
                <XAxis dataKey="name" tick={{ fontSize: 8 }} interval={0} />
              </BarChart>
            </ResponsiveContainer>
            <ResponsiveContainer className="print-section" width={400} height={300}>
              <BarChart
                width={100}
                height={300}
                data={chartData}
                barSize={20}
                margin={{
                  top: 5,
                  right: 0,
                  left: 0,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <YAxis domain={[0, maxChartTotal]} />
                <Bar dataKey="total" fill="gray" barSize={10} />
                <Bar dataKey="score" fill="#8884d8">
                  {chartData &&
                    chartData.map((item, index) => <Cell key={`cell-${index}`} fill={CHART_COLORS[index % CHART_COLORS.length]} />)}
                </Bar>
                <XAxis dataKey="name" tick={{ fontSize: 8 }} interval={0} />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div className="part-con">
            {/* chart donut in here*/}
            <div className="donut-wrap" style={{ left: '0', top: '0' }}>
              <img src="/images/Meta-rightDonut.png" alt="right_donut" style={{ maxWidth: '300px' }} />
              <div style={{ textAlign: 'center' }}>
                <h2
                  className="measure-score"
                  style={{
                    fontSize: '2rem',
                  }}
                >
                  {levelData?.rcLevel}
                </h2>
              </div>
            </div>
          </div>
        </ReportWrap>
        <ReportCard style={{ paddingBottom: '1rem' }}>
          <p
            style={{
              fontSize: '24px',
              fontWeight: 'bold',
              width: '100%',
              color: '#837e7e',
              marginBottom: '1rem',
            }}
          >
            Score Card
          </p>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              backgroundColor: '#ebebeb',
            }}
          >
            <table className="result-table" style={{ flex: 3 }}>
              <thead>
                <tr>
                  <th className="result-table-header"></th>
                  <th className="result-table-header">Q-Type</th>
                  <th className="result-table-header">O/X</th>
                </tr>
              </thead>
              <tbody>{getScoreCards(0, 15)}</tbody>
            </table>
            <div
              style={{
                flex: 1,
                backgroundColor: '#4dabe8',
                width: '100px',
                height: '100%',
                borderTop: '1px solid #4dabe8',
              }}
            ></div>
            <table className="result-table" style={{ flex: 3 }}>
              <thead>
                <tr>
                  <th className="result-table-header"></th>
                  <th className="result-table-header">Q-Type</th>
                  <th className="result-table-header">O/X</th>
                </tr>
              </thead>
              <tbody>{getScoreCards(15, 30)}</tbody>
            </table>
          </div>
        </ReportCard>
      </InnerWrap>
      <InnerWrap>
        {testAnswers && testAnswers.length > 30 && (
          <ReportCard style={{ padding: '0' }}>
            <p
              style={{
                fontSize: '28px',
                fontWeight: 'bold',
                width: '100%',
                backgroundColor: '#d9f0ff',
                padding: '1rem',
                marginBottom: '1rem',
              }}
            >
              Grammar Type {testType}
            </p>
          </ReportCard>
        )}

        {testAnswers && testAnswers.length > 30 && (
          <ReportCard style={{ paddingBottom: '1rem' }}>
            <p
              style={{
                fontSize: '24px',
                fontWeight: 'bold',
                width: '100%',
                color: '#837e7e',
                marginBottom: '1rem',
              }}
            >
              Score Card
            </p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                backgroundColor: '#ebebeb',
              }}
            >
              <table className="result-table" style={{ flex: 3 }}>
                <thead>
                  <tr>
                    <th className="result-table-header"></th>
                    <th className="result-table-header">Q-Type</th>
                    <th className="result-table-header">O/X</th>
                  </tr>
                </thead>
                <tbody>{getScoreCards(30, 45)}</tbody>
              </table>
              <div
                style={{
                  flex: 1,
                  backgroundColor: '#4dabe8',
                  width: '100px',
                  height: '100%',
                  borderTop: '1px solid #4dabe8',
                }}
              ></div>
              <table className="result-table" style={{ flex: 3 }}>
                <thead>
                  <tr>
                    <th className="result-table-header"></th>
                    <th className="result-table-header">Q-Type</th>
                    <th className="result-table-header">O/X</th>
                  </tr>
                </thead>
                <tbody>{getScoreCards(45, 60)}</tbody>
              </table>
            </div>
          </ReportCard>
        )}
        <ReportCard style={{ paddingBottom: '1rem', paddingTop: testAnswers && testAnswers.length > 30 ? '0' : '1rem' }}>
          <p
            style={{
              fontSize: '24px',
              fontWeight: 'bold',
              width: '100%',
              color: '#837e7e',
              marginBottom: '1rem',
            }}
          >
            Level Chart
          </p>
          <table className="result-table">
            <tr>
              <th className="result-table-header">RC Level</th>
              <th className="result-table-header">Passage Level</th>
              <th className="result-table-header">K-Grade Level</th>
              <th className="result-table-header">Description</th>
            </tr>
            {getLevelChart()}
          </table>
        </ReportCard>
        <ReportWrap className="report-footer-con">
          <h5>
            Powered by
            <span style={{ color: '#2e76b3', fontWeight: 'bold' }}>
              &nbsp;Clou<span style={{ color: '#13ADED', fontWeight: 'bold' }}>Bot</span> AI
            </span>
          </h5>

          <h5 style={{ maxWidth: '55%', fontSize: '10px' }}>Copyright &nbsp; © 2025 Cloubot, Inc. All rights reserved.</h5>
        </ReportWrap>
      </InnerWrap>
    </PrintWrapper>
  );
};

export default StudentReportLevelTestEduco;
