import React, { useEffect, useState, useMemo } from 'react';
import { Button, Col, Modal, Row, Form, DatePicker, Typography, Badge, Select, Checkbox } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import { useSelector } from 'react-redux';

import CustomTable from 'src/components/common/CustomTable';

import { GET_COMPANY_CONTENTS } from 'src/operations/queries/company';
import { CLASS_LESSON_LIST } from 'src/operations/queries/getClass';
import {
  UPDATE_CLASS_LESSON,
  UPDATE_CLASS_LESSON_FOR_HOMEWORK_STYLE,
  UPDATE_CLASS_LESSON_TABLE,
  UPDATE_CLASS_LESSON_FOR_CALENDAR,
} from 'src/operations/mutations/updateClassLesson';

import { ADD_BOOK_TO_CLASS, REMOVE_SINGLE_LESSON } from 'src/operations/mutations/classRoom';
import { userInfoVar } from 'src/apollo/cache';
import { openNotification } from '../common/Notification';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

import * as mutations from 'src/operations/mutations';
import * as queries from 'src/operations/queries';
import LoadingLayer from 'src/components/common/LoadingLayer';
import { classStoreData } from 'src/operations/store';
import { isAfterSchool, isPturn } from 'src/utils/index';
import { CloseCircleFilled } from '@ant-design/icons';

const { Option } = Select;
const { Text } = Typography;
const { confirm } = Modal;

const iconColor = {
  //이건 evine인데...
  v: '#ffce54',
  s: '#5d9cec',
  e: '#a0d468',
  voca: '#ffce54',
  sentence: '#5d9cec',
  essay: '#a0d468',
  //culp
  reading: '#f52516',
  listening: '#f59f00',
  grammar: '#04a7f5',

  //short
  r: '#f52516',
  l: '#f59f00',
  g: '#04a7f5',
};

const classColor = {
  ClassLesson: '#11783D',
  UserLesson: '#11783D',
  AssignedTests: '#ed5565',
  ZoomSchedule: '#3C79F6',
};

const classType = {
  ClassLesson: 'H',
  UserLesson: 'H',
  AssignedTests: 'T',
  ZoomSchedule: 'Z',
};

const yoilOptions = [
  { label: '일', value: '일' },
  { label: '월', value: '월' },
  { label: '화', value: '화' },
  { label: '수', value: '수' },
  { label: '목', value: '목' },
  { label: '금', value: '금' },
  { label: '토', value: '토' },
];

function ClassScheduleModal({ visible, handleCancel, class_idx, classItems, refetchClass, setCreditVisible = () => {} }) {
  const classDayOfWeek = useMemo(() => {
    return [...classItems?.day_of_week].reduce((acc, cur, index) => {
      // 체크한 요일
      if (cur === '1') {
        acc = [...acc, yoilOptions[index].value];
      }
      return acc;
    }, []);
  }, [classItems]);
  const [form] = Form.useForm();
  const [studyList, setStudyList] = useState([]);
  const [events, setEvents] = useState([]);
  const [selectDate, setSelectDate] = useState(null);
  const [bookCodes, setBookCodes] = useState([]);
  const [classBooks, setClassBooks] = useState(classItems?.class_book || []);
  const [deleteActive, setDeleteActive] = useState(false);
  const [dayOfWeek, setDayOfWeek] = useState(classDayOfWeek);
  const [booktitle, setBooktitle] = useState(null);
  const company_name = useSelector(classStoreData);
  const [selectedBook, setSelectedBook] = useState(null);

  const classBookCodes = useMemo(() => {
    let bookCodes = classItems?.class_book?.map((item) => {
      return item.book_code;
    });
    return [classItems?.book_code, ...bookCodes];
  }, [classItems?.class_book]);

  const { data: dataBookContents } = useQuery(GET_COMPANY_CONTENTS, {
    skip: !userInfoVar(),
    variables: { company_idx: userInfoVar()?.campus.company.idx },
    fetchPolicy: 'no-cache',
  });

  const titleList = useMemo(() => {
    let tmp = [];
    if (dataBookContents?.getCompanyBookContentList) {
      tmp = dataBookContents?.getCompanyBookContentList.map((e) => ({ key: e?.book?.code, title: e?.book?.book_sub[0]?.short_title }));
      const keyForCoco = company_name === 'smart-eclass' ? 'SM' : 'SK';
      if (isAfterSchool(company_name)) {
        tmp = [
          { key: keyForCoco, title: company_name === 'smart-eclass' ? 'SMART E Class' : 'Sneakers' },
          { key: `${keyForCoco}*`, title: 'Coco Phonics', isCoco: true },
          ...tmp.filter((e) => e.key !== keyForCoco),
        ];
      }
    }
    return tmp;
  }, [dataBookContents]);

  const bookList = useMemo(() => {
    if (dataBookContents?.getCompanyBookContentList) {
      let bookList = [];
      dataBookContents?.getCompanyBookContentList.forEach((bookContent) => {
        if (bookContent?.book_list) {
          bookContent.book_list.forEach((book) => {
            const short_title = book.book_sub[0]?.short_title;
            bookList.push({
              key: book.code,
              value: short_title || book.volume_name || book.code,
            });
          });
        }
      });

      return bookList;
    }
    return [];
  }, [dataBookContents, classBookCodes, classItems]);

  const isAutoSchedule = useMemo(() => {
    const company = userInfoVar()?.campus?.company;
    if (company?.is_auto_schedule === '0') {
      return false;
    }
    return true;
  }, []);

  const {
    data,
    loading: studentLoading,
    refetch,
  } = useQuery(queries.getClass.CLASS_LESSON_LIST, {
    fetchPolicy: 'no-cache',
    skip: !class_idx,
    variables: { class_idx },
  });

  // const [updateClassLession, { loading }] = useMutation(UPDATE_CLASS_LESSON, {
  //   onCompleted: (data) => {
  //     if (data) {
  //       refetch();

  //       openNotification('일정 수정 완료!');
  //     }
  //   },
  // });

  const [addBookToCLass, { loading: addBookLoading }] = useMutation(ADD_BOOK_TO_CLASS, {
    refetchQueries: [
      {
        query: CLASS_LESSON_LIST,
        skip: !class_idx,
        variables: { class_idx },
      },
    ],
    onCompleted: (data) => {
      if (data) {
        refetch();
        setClassBooks(data.addBookToClass.class_book);

        openNotification('일정 수정 완료!');
      }
    },
  });

  const [removeSingleLesson] = useMutation(REMOVE_SINGLE_LESSON, {
    refetchQueries: [
      {
        query: CLASS_LESSON_LIST,
        skip: !class_idx,
        variables: { class_idx },
      },
    ],
    onCompleted: (data) => {
      if (data) {
        refetch();
        openNotification('Removed');
      }
    },
  });

  const [updateClassLessonForCanlendar, { loading: loadingUpdateClassLessonForCanlendar }] = useMutation(UPDATE_CLASS_LESSON_FOR_CALENDAR, {
    refetchQueries: [
      {
        query: CLASS_LESSON_LIST,
        skip: !class_idx,
        variables: { class_idx },
      },
    ],
    onCompleted: (data) => {
      if (data) {
        refetch();

        openNotification('일정 수정 완료!');
      }
    },
  });

  const [update, { loading: loadingUpdateClassLesson }] = useMutation(
    isAutoSchedule ? UPDATE_CLASS_LESSON_TABLE : UPDATE_CLASS_LESSON_FOR_HOMEWORK_STYLE,
    {
      fetchPolicy: 'no-cache',
      variables: { dateList: studyList, class_idx },
      refetchQueries: [
        {
          query: CLASS_LESSON_LIST,
          skip: !class_idx,
          variables: { class_idx },
        },
      ],
      onCompleted(data) {
        if (data) {
          refetch();
          openNotification('일정 수정 완료!');
        }
      },
      onError(error) {
        console.log('error', error);
      },
    },
  );

  useEffect(() => {
    if (data?.classLessonList) {
      setEvents(
        data.classLessonList.map((item, i) => {
          let group = item.book_lesson?.stage_group_code?.split('')[0];
          if (item.lesson_code.startsWith('CR-L2') && item.lesson_code.endsWith('D4')) {
            group = 'g';
          }
          const color = group ? iconColor[group] : classColor[`${item.__typename}`];
          // console.log('setEvents', item?.book_lesson);
          return {
            key: item.idx,
            date: item.study_date,
            //title: `${i + 1}회차`,
            title: item.book_lesson.title || item.book_lesson.code,
            color,
            classLessonIdx: item.idx,
          };
        }),
      );

      setStudyList(
        data.classLessonList.reduce((acc, cur, index) => {
          let group = cur.book_lesson?.stage_group_code?.split('')[0];
          if (cur.lesson_code.startsWith('CR-L2') && cur.lesson_code.endsWith('D4')) {
            group = 'g';
          }
          const obj = {
            key: cur.idx,
            number: `${index + 1}회차`,
            lesson_code: cur.lesson_code,
            title: cur.book_lesson.title,
            unit: cur.book_lesson.unit_no,
            lesson: cur.book_lesson.day_no,
            studyDate: cur.study_date,
            group,
            stage_group_code: cur.book_lesson?.stage_group_code,
          };

          const names = `studyDate-${cur.idx}`;
          form.setFieldsValue({
            [names]: cur.study_date && moment(cur.study_date, 'YYYY-MM-DD'),
          });

          return [...acc, obj];
        }, []),
      );
    }
  }, [data]);

  const handleTitle = (title) => {
    const selected = titleList.find((e) => e.title === title);
    setBooktitle(selected);
  };

  const filteredList = useMemo(() => {
    if (booktitle) {
      const keyForCoco = company_name === 'smart-eclass' ? 'SM' : 'SK';
      if (booktitle.isCoco) {
        return bookList.filter((e) => e.key.includes(`${keyForCoco}-L0`));
      } else if (booktitle.key === keyForCoco) {
        return bookList.filter((e) => e.key.includes(booktitle.key) && !e.key.includes('L0'));
      } else {
        return bookList.filter((e) => e.key.includes(booktitle.key));
      }
    } else {
      return bookList;
    }
  }, [booktitle, company_name, bookList]);

  const filteredStudyList = useMemo(() => {
    if (selectedBook) {
      return studyList.filter((item) => item.lesson_code.startsWith(selectedBook));
    }
    return studyList;
  }, [selectedBook, studyList]);

  const columns = [
    {
      title: '회차',
      dataIndex: 'number',
      key: 'number',
      align: 'center',
      width: '15%',
    },
    {
      title: 'Lesson Code',
      dataIndex: 'lesson_code',
      key: 'lesson_code',
      align: 'center',
      width: '25%',
    },
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      width: '30%',
      render: (text, record) => {
        // console.log('record', record);
        return (
          <>
            <Badge
              className="site-badge-count-109"
              count={record?.group?.toUpperCase()}
              style={{ backgroundColor: iconColor[record?.group] ? iconColor[record?.group] : '#ff0000' }}
            />
            <span>&nbsp;{text}</span>
          </>
        );
      },
    },
    {
      title: '학습날짜',
      dataIndex: 'studyDate',
      key: 'studyDate',
      align: 'center',
      render: (text, record) => {
        const names = `studyDate-${record.key}`;
        // form.setFieldsValue({
        //   [names]: text && moment(text, 'YYYY-MM-DD'),
        // });
        return (
          <>
            <Form.Item name={`studyDate-${record.key}`}>
              <DatePicker
                name={`studyDate-${record.key}`}
                initialValues={text && moment(text, 'YYYY-MM-DD')}
                allowClear={false}
                inputReadOnly={true}
              />
            </Form.Item>
          </>
        );
      },
    },
  ];

  const columnBooks = [
    {
      title: 'Code',
      dataIndex: 'book_code',
      key: 'book_code',
      align: 'center',
      render: (text, record) => {
        return <span>{record?.book?.book_sub[0]?.title || text}</span>;
      },
    },
    {
      title: '시작일',
      dataIndex: 'begin_date',
      key: 'begin_date',
      align: 'center',
      render: (text, record) => {
        return (
          <>
            <span>{new Date(text).toLocaleDateString()}</span>
          </>
        );
      },
    },
  ];
  function handleSelect() {
    console.log('handleSelect');
  }

  // type -> all 시에 모든 스케쥴 재조정
  function handleEventChange(changeInfo, type = '') {
    const {
      start,
      extendedProps: { classLessonIdx },
    } = changeInfo.event.toPlainObject();
    updateClassLessonForCanlendar({
      variables: {
        class_idx,
        class_lesson_idx: classLessonIdx,
        study_date: start,
      },
    });
    // updateClassLession({
    //   variables: {
    //     class_idx,
    //     class_lesson_idx: classLessonIdx,
    //     study_date: start,
    //     type,
    //   },
    // });
  }

  function showUpdateConfirm(changeInfo) {
    handleEventChange(changeInfo);
    // confirm({
    //   // title: '수정 되었습니다.',
    //   content: '모든 뒤의 일정을 재조정 하시겠습니까?',
    //   okText: '예',
    //   okType: 'primary',
    //   cancelText: '아니오',
    //   onOk() {
    //     handleEventChange(changeInfo, 'all');
    //   },
    //   onCancel() {
    //     handleEventChange(changeInfo);
    //   },
    // });
  }

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((formData) => {
        setStudyList((prev) => {
          return prev.map((study) => {
            const formValue = formData[`studyDate-${study.key}`] && moment(formData[`studyDate-${study.key}`]).format('YYYY-MM-DD');

            return {
              ...study,
              studyDate: formValue ? formValue : null,
            };
          });
        });

        update();
      })
      .catch((errorInfo) => {
        console.error('errorInfo', errorInfo);
      });
  };

  const handelSaveAddBooks = () => {
    Modal.confirm({
      icon: null,
      title: <p style={{ textAlign: 'center' }}>입력하신 정보로 수정하시겠습니까?</p>,
      cancelText: '아니오',
      okText: '네',
      content: (
        <p style={{ color: 'red', textAlign: 'center' }}>
          <span>※주의 사항 </span>
          <br /> "네"를 누른 후에 일정을 변경하려면 수동으로 해야하니 잘 확인하고 선택하시길 바랍니다.
        </p>
      ),
      onOk: async () => {
        const dayOfWeekData = yoilOptions
          .map(({ value }) => {
            if (dayOfWeek) {
              return dayOfWeek.includes(value) ? '1' : '0';
            } else {
              return '0';
            }
          })
          .join('');
        await addBookToCLass({
          variables: {
            class_idx,
            book_codes: bookCodes,
            begin_date: !selectDate ? moment().format('YYYY-MM-DD') : moment(selectDate).format('YYYY-MM-DD'),
            day_of_week: dayOfWeekData,
            is_auto_schedule: isAutoSchedule,
          },
        });
        setDayOfWeek(classDayOfWeek);
        setBookCodes([]);
        setSelectDate(null);
        refetchClass();
        company_name === 'englishtap' && setCreditVisible(true);
      },
    });
  };

  const handelDeleteBooks = () => {
    if (deleteActive) {
      setDeleteActive(false);
      return;
    }

    Modal.confirm({
      icon: null,
      title: <p style={{ textAlign: 'center' }}>등록한 교재를 삭제하시겠습니까?</p>,
      cancelText: '아니오',
      okText: '네',
      content: (
        <>
          <p style={{ textAlign: 'center' }}>
            맞으면 "네"를 누른 후에 <span style={{ color: 'black', fontWeight: '700' }}>삭제할 레슨을 모두 삭제하길 바랍니다.</span>
          </p>
          <p style={{ color: 'red', textAlign: 'center' }}>
            <span>※주의 사항 </span>
            <br /> 삭제한 레슨을 학생이 이미 학습한 경우에도 학습기록은 사라집니다. 잘 확인하고 삭제하시길 바랍니다. 만약, 삭제를 원하지
            않으면 "아니오"를 누르세요.
          </p>
        </>
      ),
      onOk: () => {
        setDeleteActive(!deleteActive);
      },
    });
  };

  useEffect(() => {
    window.setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 1000);
  });

  const hasLevelCheck = useMemo(() => {
    if (isPturn(company_name) || company_name === 'rise') {
      return true;
    } else {
      return false;
    }
  }, [company_name]);

  return (
    <>
      <Modal
        className={`${company_name} modify-schedule-modal`}
        centered
        visible={visible}
        title="일정 수정"
        onCancel={handleCancel}
        width={'80%'}
        footer={[
          <Button key="submit" type="primary" onClick={handleFormSubmit} loading={loadingUpdateClassLesson}>
            수정
          </Button>,
        ]}
        //footer={false}
      >
        <Row gutter={[16, 16]}>
          <Col span={12} className="modify-schedule-modal-a">
            <Row style={{ marginBottom: 10 }}>
              {company_name === 'rise' && (
                <Col span={12}>
                  <Select style={{ width: 200 }} placeholder="Book Code" onChange={(value) => setSelectedBook(value)}>
                    <Option value={null}>All</Option>
                    {[...new Set(classBookCodes)].map((book) => (
                      <Option key={book} value={book}>
                        {book}
                      </Option>
                    ))}
                  </Select>
                </Col>
              )}
              <Col span={9} offset={company_name === 'rise' ? 3 : 15}>
                {classItems?.begin_date && classItems?.end_date
                  ? `${moment(classItems?.begin_date).format('YYYY-MM-DD')} ~ ${
                      classItems?.end_date && moment(classItems?.end_date).format('YYYY-MM-DD')
                    }`
                  : ''}
              </Col>
            </Row>
            <Form form={form} component={false} onFinish={handleFormSubmit}>
              <CustomTable
                className="detaildatapopup-table"
                loading={studentLoading}
                dataSource={filteredStudyList}
                columns={columns}
                color="#edf3fb"
                scroll={{ y: 'calc(100vh - 254px)' }}
                pagination={{
                  total: studyList.length,
                  pageSize: studyList.length,
                  hideOnSinglePage: true,
                }}
                bordered
              />

              {/* <DivBlock justifyContent={'flex-end'}>
                <Button
                  htmlType="submit"
                  style={{
                    background: '#646A6D',
                    borderRadius: 6,
                    marginTop: 5,
                    color: 'white',
                  }}
                  size={'large'}
                  loading={loadingUpdateClassLesson}
                  // onClick={handleFormSubmit}
                >
                  수정
                </Button>
              </DivBlock> */}
            </Form>
          </Col>
          <Col span={12} className="modify-schedule-modal-b">
            {(loadingUpdateClassLessonForCanlendar || loadingUpdateClassLesson) && <LoadingLayer style={{ zIndex: 2000 }} />}
            <FullCalendar
              plugins={[dayGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              selectable={true}
              events={events}
              droppable={true}
              editable={true}
              eventContent={(eventInfo, i) => {
                return (
                  <>
                    <div
                      style={{
                        backgroundColor: eventInfo.backgroundColor,
                        color: 'white',
                        borderRadius: 10,
                        width: 20,
                        textAlign: 'center',
                        marginRight: 5,
                      }}
                    >
                      {eventInfo.event.title}
                    </div>
                    {deleteActive && (
                      <CloseCircleFilled
                        style={{
                          position: 'absolute',
                          right: 0,
                          top: 0,
                          cursor: 'pointer',
                        }}
                        onClick={async () => {
                          const {
                            extendedProps: { classLessonIdx },
                          } = eventInfo.event.toPlainObject();
                          console.log(classLessonIdx);
                          await removeSingleLesson({
                            variables: {
                              idx: classLessonIdx,
                            },
                          });
                        }}
                      />
                    )}
                    <div>{classType[eventInfo.textColor]}</div>
                  </>
                );
              }}
              select={handleSelect}
              eventChange={showUpdateConfirm}
              locale={'ko-KR'}
            />

            <div justifyContent={'flex-end'}>
              {hasLevelCheck && (
                <Select placeholder={'교재 선택'} style={{ width: '30%' }} onChange={handleTitle}>
                  {titleList.map((book) => (
                    <Option key={book.key} value={book.title}>
                      {book.title}
                    </Option>
                  ))}
                </Select>
              )}
              <Select
                // mode="multiple"
                showSearch
                name="book_code"
                style={{ width: hasLevelCheck ? '40%' : '60%' }}
                placeholder="레벨을 선택하거나 입력하세요"
                value={bookCodes}
                onChange={setBookCodes}
                filterOption={(input, option) => {
                  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              >
                {filteredList.map((book) => (
                  <Option key={`title_${book.key}`} value={book.key}>
                    {book.value}
                  </Option>
                ))}
              </Select>
              <DatePicker
                style={{ width: hasLevelCheck ? '30%' : '40%' }}
                placeholder="시작일"
                value={selectDate ? moment(selectDate) : null}
                name="begin_date"
                onChange={(_, d) => setSelectDate(d)}
              />
              <Checkbox.Group options={yoilOptions} value={dayOfWeek} onChange={setDayOfWeek} name="day_of_week" />
              {/* <br/> */}
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Button
                  htmlType="submit"
                  style={{
                    background: '#646A6D',
                    borderRadius: 6,
                    margin: 5,
                    color: 'white',
                  }}
                  size={'large'}
                  loading={addBookLoading}
                  onClick={handelSaveAddBooks}
                >
                  Add Book
                </Button>
                <Button
                  style={{
                    background: deleteActive ? '#f52516' : '#646A6D',
                    borderRadius: 6,
                    marginTop: 5,
                    color: 'white',
                  }}
                  size={'large'}
                  onClick={handelDeleteBooks}
                >
                  Delete Book
                </Button>
              </div>
            </div>
            <CustomTable
              rowKey={(record) => record.idx}
              className="detaildatapopup-table"
              dataSource={classBooks}
              columns={columnBooks}
              color="#edf3fb"
              scroll={{ y: 'calc(100vh - 254px)' }}
              bordered
            />
          </Col>
        </Row>
      </Modal>
      {/* <ScheduleEditModal
        visible={selectDate}
        onOk={handleEditOk}
        onCancel={handleEditCancel}
      /> */}
    </>
  );
}

export default ClassScheduleModal;
