import { gql } from '@apollo/client';

export const GET_ORDER_LEVEL_TEST_IDX = gql`
  query Test($phone: String!) {
    getOrderLevelTestIdx(phone: $phone)
  }
`;

export const GET_ORDER_ITEM = gql`
  query Test($phone: String!, $student_name: String!) {
    orderItem(phone: $phone, student_name: $student_name) {
      idx
    }
  }
`;

export const GET_ORDER_ITEM2 = gql`
  query Test($idx: Int!) {
    orderItemByIdx(idx: $idx) {
      idx
      name
      parent_phone
      school_info
      idate
      user {
        school_grade
        school_name
      }
      campus {
        name
      }
      order_survey {
        idx
      }
      order_survey_answer {
        idx
        no
        answer
        memo
      }
      order_leveltest {
        ticket
        choose_level
        assigned_tests {
          idx
          tests {
            title
          }
          assigned_test_users {
            status
          }
        }
      }
    }
  }
`;

export const GET_ORDER_ITEM_LIST = gql`
  query Test($idx_array: [Int!]!) {
    orderItemListByIdx(idx_array: $idx_array) {
      order_leveltest {
        ticket
        choose_level
        assigned_tests {
          idx
          tests {
            title
            batch_type
            test_type
          }
          assigned_test_users {
            status
          }
        }
      }
    }
  }
`;
export const GET_ORDER_ITEM_LIST_META = gql`
  query Test($idx_array: [Int!]!) {
    orderItemListByIdx(idx_array: $idx_array) {
      idate
      idx
      stat
      order_leveltest {
        ticket
        choose_level
        assigned_tests {
          idx
          start_time
          tests {
            title
            batch_type
            test_type
          }
          assigned_test_users {
            status
            user {
              idx
              parent_phone
              english_name
              birthday
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_ORDER_ITEM_LIST_EDUCO_REPORT = gql`
  query Test($idx_array: [Int!]!) {
    orderItemListByIdx(idx_array: $idx_array) {
      order_leveltest {
        order {
          idx
          school_info
        }
        ticket
        choose_level
        assigned_tests {
          idx
          tests {
            idx
            title
            batch_type
            test_type
            test_answers {
              idx
              code
              questions {
                question_direction
              }
            }
          }
          assigned_test_users {
            idx
            status
            end_time
            extra_data
            score
            user {
              idx
              parent_phone
              english_name
              birthday
              name
            }
            assigned_test_answers {
              code
              answer
              is_correct
            }
          }
        }
      }
    }
  }
`;

export const GET_ORDER_ITEM_LIST_META_REPORT = gql`
  query Test($idx_array: [Int!]!) {
    orderItemListByIdx(idx_array: $idx_array) {
      order_leveltest {
        order {
          idx
          school_info
        }
        ticket
        choose_level
        assigned_tests {
          idx
          tests {
            idx
            title
            batch_type
            test_type
            test_answers {
              idx
            }
          }
          assigned_test_users {
            idx
            status
            end_time
            extra_data
            score
            user {
              idx
              parent_phone
              english_name
              birthday
              name
            }
          }
        }
      }
    }
  }
`;
export const GET_META_EXAM_AVG_SCORE = gql`
  query Test($idx_array: [Int!]!, $grade: String!, $user_idx: Int!, $batch_type: String!, $order_idx: Int!) {
    metaExamAvgScore(idx_array: $idx_array, grade: $grade, user_idx: $user_idx, order_idx: $order_idx, batch_type: $batch_type) {
      json
    }
  }
`;
export const GET_TOKEN_FOR_LEVEL_TEST = gql`
  query Test($order_idx: Int!) {
    getTokenForLevelTest(order_idx: $order_idx) {
      accessToken
      refreshToken
    }
  }
`;

export const LOGIN_FOR_LEVEL_TEST = gql`
  query Test($phone: String!, $student_name: String!) {
    loginForLevelTest(phone: $phone, student_name: $student_name) {
      accessToken
      refreshToken
    }
    orderItemList(phone: $phone, student_name: $student_name) {
      idx
    }
  }
`;

export const LOGIN_FOR_META_EXAM = gql`
  query Test($phone: String!, $student_name: String!, $type: String, $dob: String) {
    loginForLevelTest(phone: $phone, student_name: $student_name, type: $type, dob: $dob) {
      accessToken
      refreshToken
    }
    orderItemList(phone: $phone, student_name: $student_name) {
      idx
    }
  }
`;
