import React, { useState } from 'react';
import { Col, Input, Form, Row, DatePicker, Select } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { useSelector } from 'react-redux';
// import queryString from 'query-string';
import { classStoreData } from 'src/operations/store';
// import { DAY_WISE_SCHEDULE_DATA, GET_SCHEDULE_OR_NOT } from 'src/operations/queries/getScheduleData';
// const { TextArea } = Input;

const classOptions = {
  creo: ['초등 1학년', '초등 2학년', '초등 3학년', '초등 4학년', '초등 5학년', '초등 6학년'],
  educo: ['초1', '초2', '초3', '초4', '초5', '초6', '중1', '중2', '중3', '고1', '고2', '고3'],
  default: ['K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
};

const { Option } = Select;

const CreateConsultForm = ({
  form,
  onFinish,
  onDateChange,
  status,
  handleStartTime,
  handleEndTime,
  scheduleData,
  handleSchedule,
  testDetail,
  defaultTime,
  isEdit = false,
}) => {
  const defaultClass = useSelector(classStoreData);
  const [schoolinfo, setschoolinfo] = useState();
  const [scheduleTime, setScheduleTime] = useState(-1);
  // const [dayname, setDayname] = useState('');
  // const [scheduleDetail, setScheduleDetail] = useState('');
  // const [startTime, setStartTime] = useState('');
  const classOptionList = classOptions[defaultClass] || classOptions.default;

  // function onTimeChange(name, timeString) {
  //   if (name == 'start_time') {
  //     handleStartTime(timeString);
  //   }
  //   if (name == 'end_time') {
  //     handleEndTime(timeString);
  //   }
  // }
  return (
    <Form name="control-ref" layout="inline" form={form} onFinish={onFinish} labelCol={{ span: 24 }} wrapperCol={{ span: 14 }}>
      <Row gutter={[16, 16]}>
        {defaultClass !== 'educo' && (
          <Col span={24}>
            <FormItem name="korean_name" rules={[{ required: true }]} label={`학생 이름`} hasFeedback labelAlign="left">
              <Input name="korean_name" maxLength={30} />
            </FormItem>
          </Col>
        )}
        <Col span={24}>
          <FormItem name="student_name" rules={[{ required: true }]} label={`영문이름`} hasFeedback>
            <Input name="student_name" maxLength={30} />
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem
            name="phone"
            rules={[
              {
                required: true,
                whitespace: false,
                pattern: /[0-9]+/,
                message: '숫자만 입력하세요.',
                len: 12,
                max: 12,
              },
            ]}
            label={`학부모 전화`}
            hasFeedback
            labelAlign="left"
          >
            <Input name="phone" maxLength={12} />
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem
            name="birthday"
            label={`생년월일`}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker
              size="large"
              name="birthday"
              style={{ width: '100%' }}
              onChange={(_, d) => onDateChange('birthday', d)}
              inputReadOnly
            />
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem name="school_info" label="학교/학년" rules={[{ required: true }]}>
            <Select className="plainInput gradeInput" placeholder="Select " value={schoolinfo} onChange={(v) => setschoolinfo(v)}>
              {classOptionList.map((item, indx) => {
                return (
                  <Option key={indx} value={item}>
                    {item}
                  </Option>
                );
              })}
            </Select>
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem
            name="reserve_date"
            label={`예약 날짜`}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker
              size="large"
              name="reserve_date"
              style={{ width: '100%' }}
              onChange={(_, d) => onDateChange('reserve_date', d)}
              inputReadOnly
            />
          </FormItem>
        </Col>

        {status == true ? (
          <>
            <Col span={24}>
              <FormItem label={isEdit ? '예약 시간' : '테스트 신청 예약 날짜'} name="school_time" rules={[{ required: true }]}>
                <Select
                  className="plainInput gradeInput"
                  placeholder="사용 가능한 일정 선택"
                  value={
                    scheduleTime !== -1
                      ? scheduleTime
                      : defaultTime == undefined || defaultTime == ' '
                      ? '사용 가능한 일정 선택'
                      : defaultTime
                  }
                  // defaultValue={defaultTime == undefined || defaultTime == ' ' ? '사용 가능한 일정 선택' : defaultTime}
                  onChange={(v) => (setScheduleTime(v), handleSchedule(v))}
                >
                  {scheduleData &&
                    scheduleData?.map((item, key) => {
                      return (
                        <Option key={key} value={key}>
                          {item.start_time} to {item.end_time}
                        </Option>
                      );
                    })}
                </Select>
              </FormItem>
            </Col>
          </>
        ) : (
          ''
        )}
      </Row>
    </Form>
  );
};

export default CreateConsultForm;
