import React, { useMemo, useState, useEffect } from 'react';
import moment from 'moment';
import { useQuery, useReactiveVar } from '@apollo/client';
import { Button, Table, Typography, Tooltip } from 'antd';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { rootStateVar, userInfoVar } from 'src/apollo/cache';
import { getCurrentToken } from 'src/utils';
import { useQueryProxy } from 'src/operations/proxy/user';
import { USER_ME } from 'src/operations/queries/getUser';
import {
  DASHBOARD_CAMPUS_OFFLINE_SCHEDULE_GRID,
  DASHBOARD_CAMPUS_PHONICS_SCHEDULE_GRID,
  CLASS_LESSON_DETAIL_LIST,
  GET_DAILY_CLASS_TEST_SCHEDULE,
  GET_DAILY_CLASS_ZOOM_SCHEDULE,
  GET_DAILY_CLASS_BOOK_SCHEDULE,
  GET_DAILY_CLASS_EXTERNAL_STUDY_SCHEDULE,
  GET_DAILY_EXTERNAL_STUDY_SCHEDULE_FOR_STUDENT,
  GET_DAILY_AI_TOPIC_SCHEDULE,
  GET_DAILY_CLASS_TEST_GROUP_SCHEDULE,
} from 'src/operations/queries/getClass';
import { classStoreData } from 'src/operations/store';
import { useSelector } from 'react-redux';
import { isShowLessonDetail } from 'src/utils';
import { settings } from 'src/dummy/settings.js';
import ViewAnswerModal from 'src/components/Modal/ViewAnswerModal';

const { Title } = Typography;

const TodoCategory = styled.div`
  text-align: center;
  border-radius: 50px;
  color: white;
  font-weight: 600;
  padding: 0;
`;

const StudyComplete = styled(TodoCategory)`
  background-color: red;
`;

const StudyNoComplete = styled(TodoCategory)`
  /* background-color: #ea4444; */
  background-color: #ef811a;
`;

const StudIng = styled(TodoCategory)`
  background-color: blue;
`;

const StudyBefore = styled(TodoCategory)`
  background-color: red;
`;

const StudyOnline = styled(TodoCategory)`
  background-color: #a0d468;
`;

const TodayTable = styled(Table)`
  cursor: pointer;
`;

const StudyCompleteGray = styled(TodoCategory)`
  background-color: #dee2e6;
`;
const StudyVoca = styled(TodoCategory)`
  background-color: #ffce54;
`;
const StudySentence = styled(TodoCategory)`
  background-color: #5d9cec;
`;
const StudyEssay = styled(TodoCategory)`
  background-color: #a0d468;
`;

const StudyRecording = styled(TodoCategory)`
  background-color: #ea4444;
`;

const AssignedTest = styled(TodoCategory)`
  /* background-color: #ed5565; */
  background-color: #ff7f00;
`;

const AssignedPhonicsTest = styled(TodoCategory)`
  /* background-color: #ed5565; */
  background-color: '#ffbf32';
`;

const ZoomSchedule = styled(TodoCategory)`
  /* background-color: #3c79f6; */
  background-color: #f2ac52;
`;

const PhonicsSchedule = styled(TodoCategory)`
  /* background-color: #3c79f6; */
  background-color: #ffce54;
`;

const OfflineSchedule = styled(TodoCategory)`
  /* background-color: #3c79f6; */
  background-color: #4e9b4e;
`;

const AssignedEvineBook = styled(TodoCategory)`
  /* background-color: #ed5565; */
  background-color: #6eb2c3;
`;

// const STAGE_GROUP_NAMES_EVINE = {
//   voca: (done) => (done ? <StudyCompleteGray>VOCA</StudyCompleteGray> : <StudyVoca>VOCA</StudyVoca>),
//   sentence: (done) => (done ? <StudyCompleteGray>Key Sentence</StudyCompleteGray> : <StudySentence>Key Sentence</StudySentence>),
//   essay: (done) => (done ? <StudyCompleteGray>Essay</StudyCompleteGray> : <StudyEssay>Essay</StudyEssay>),
//   listening: (done) => (done ? <StudyCompleteGray>Listening</StudyCompleteGray> : <StudyEssay>Listening</StudyEssay>),
//   grammar: (done) => (done ? <StudyCompleteGray>Grammar</StudyCompleteGray> : <StudyEssay>Grammar</StudyEssay>),
//   reading: (done) => (done ? <StudyCompleteGray>Reading</StudyCompleteGray> : <StudyEssay>Reading</StudyEssay>),
//   AssignedTests: (done) => (done ? <StudyCompleteGray>Test</StudyCompleteGray> : <AssignedTest>Test</AssignedTest>),
//   ZoomSchedule: (done) => (done ? <StudyCompleteGray>Zoom Meeting</StudyCompleteGray> : <ZoomSchedule>Zoom Meeting</ZoomSchedule>),
//   recording: (done) => (done ? <StudyCompleteGray>Recording</StudyCompleteGray> : <StudyRecording>Recording</StudyRecording>),
// };

const STAGE_GROUP_NAMES = {
  voca: (done) => (done ? <StudyCompleteGray>Homework</StudyCompleteGray> : <StudyVoca>Homework</StudyVoca>),
  sentence: (done) => (done ? <StudyCompleteGray>Homework</StudyCompleteGray> : <StudySentence>Homework</StudySentence>),
  essay: (done) => (done ? <StudyCompleteGray>Essay</StudyCompleteGray> : <StudyEssay>Essay</StudyEssay>),
  listening: (done) => (done ? <StudyCompleteGray>Homework</StudyCompleteGray> : <StudyEssay>Homework</StudyEssay>),
  reading: (done) => (done ? <StudyCompleteGray>Homework</StudyCompleteGray> : <StudyEssay>Homework</StudyEssay>),
  AssignedTests: (done) =>
    done ? <StudyCompleteGray>Test</StudyCompleteGray> : <AssignedTest className="test-schedule-list">Test</AssignedTest>,
  ZoomSchedule: (done) => (done ? <StudyCompleteGray>Zoom</StudyCompleteGray> : <ZoomSchedule>Zoom</ZoomSchedule>),
  PhonicsSchedule: (done) =>
    done ? <StudyCompleteGray>Zoom</StudyCompleteGray> : <PhonicsSchedule className="phonics-schedule-list">Phonics</PhonicsSchedule>,
  OfflineSchedule: (done) =>
    done ? <StudyCompleteGray>Zoom</StudyCompleteGray> : <OfflineSchedule className="offline-schedule-list">Offline</OfflineSchedule>,
  recording: (done) =>
    done ? <StudyCompleteGray>Homework</StudyCompleteGray> : <StudyRecording className="elibrary-schedule-list">Homework</StudyRecording>,
  grammar: (done) => (done ? <StudyCompleteGray>Homework</StudyCompleteGray> : <StudyEssay className="studing-list">Homework</StudyEssay>),
  lc_low: (done) => (done ? <StudyCompleteGray>Homework</StudyCompleteGray> : <StudyEssay className="studing-list">Homework</StudyEssay>),
  lc_high: (done) => (done ? <StudyCompleteGray>Homework</StudyCompleteGray> : <StudyEssay className="studing-list">Homework</StudyEssay>),
  rc_low: (done) => (done ? <StudyCompleteGray>Homework</StudyCompleteGray> : <StudyEssay className="studing-list">Homework</StudyEssay>),
  rc_high: (done) => (done ? <StudyCompleteGray>Homework</StudyCompleteGray> : <StudyEssay className="studing-list">Homework</StudyEssay>),
  phonics: (done) => (done ? <StudyCompleteGray>Homework</StudyCompleteGray> : <StudyEssay className="studing-list">Homework</StudyEssay>),
  //Phonics: (done) => (done ? <StudyCompleteGray>Homework</StudyCompleteGray> : <AssignedPhonicsTest>Homework</AssignedPhonicsTest>),
  AssignedEvineBook: (done) =>
    done ? <StudyCompleteGray>Evine</StudyCompleteGray> : <AssignedEvineBook className="studing-list">Evine</AssignedEvineBook>,
  lesson1: (done) => (done ? <StudyCompleteGray>Homework</StudyCompleteGray> : <StudyVoca className="studing-list">Homework</StudyVoca>),
  lesson2: (done) => (done ? <StudyCompleteGray>Homework</StudyCompleteGray> : <StudyVoca className="studing-list">Homework</StudyVoca>),
  lesson3: (done) => (done ? <StudyCompleteGray>Homework</StudyCompleteGray> : <StudyVoca className="studing-list">Homework</StudyVoca>),
  lesson4: (done) => (done ? <StudyCompleteGray>Homework</StudyCompleteGray> : <StudyVoca className="studing-list">Homework</StudyVoca>),
  lesson5: (done) => (done ? <StudyCompleteGray>Homework</StudyCompleteGray> : <StudyVoca className="studing-list">Homework</StudyVoca>),
  lesson6: (done) => (done ? <StudyCompleteGray>Homework</StudyCompleteGray> : <StudyVoca className="studing-list">Homework</StudyVoca>),
  lesson7: (done) => (done ? <StudyCompleteGray>Homework</StudyCompleteGray> : <StudyVoca className="studing-list">Homework</StudyVoca>),
  lesson8: (done) => (done ? <StudyCompleteGray>Homework</StudyCompleteGray> : <StudyVoca className="studing-list">Homework</StudyVoca>),
  review1: (done) => (done ? <StudyCompleteGray>Homework</StudyCompleteGray> : <StudyVoca className="studing-list">Homework</StudyVoca>),
  review2: (done) => (done ? <StudyCompleteGray>Homework</StudyCompleteGray> : <StudyVoca className="studing-list">Homework</StudyVoca>),
  review3: (done) => (done ? <StudyCompleteGray>Homework</StudyCompleteGray> : <StudyVoca className="studing-list">Homework</StudyVoca>),
  review4: (done) => (done ? <StudyCompleteGray>Homework</StudyCompleteGray> : <StudyVoca className="studing-list">Homework</StudyVoca>),
  review5: (done) => (done ? <StudyCompleteGray>Homework</StudyCompleteGray> : <StudyVoca className="studing-list">Homework</StudyVoca>),
};

const studyState = {
  1: (text) => {
    return <StudyComplete>{text}</StudyComplete>;
  },
  2: (text) => {
    return <StudyBefore>{text}</StudyBefore>;
  },
  3: (text) => {
    return <StudyBefore>{text}</StudyBefore>;
  },
  4: (text) => {
    return <StudIng className="studing-list">{text}</StudIng>;
  },
  5: (text) => {
    return <StudyCompleteGray>{text}</StudyCompleteGray>;
  },
  6: (text) => {
    return <ZoomSchedule>{text}</ZoomSchedule>;
  },
  7: (text) => {
    return <PhonicsSchedule className="phonics-schedule-list">{text}</PhonicsSchedule>;
  },
  8: (text) => {
    return <OfflineSchedule className="offline-schedule-list">{text}</OfflineSchedule>;
  },
  9: (text) => {
    return <AssignedEvineBook>{text}</AssignedEvineBook>;
  },
  10: (text, backgroundColor) => {
    return <TodoCategory style={{ backgroundColor }}>{text}</TodoCategory>;
  },
  default: (text) => <StudyOnline>{text}</StudyOnline>,
};

const getStatusText = (company, status) => {
  switch (company) {
    case 'creo':
      return status === 1 ? 'Completed' : 'Not Completed';
    default:
      return status === 1 ? '학습완료' : '미완료';
  }
};

const TodaySchedule = () => {
  const defaultClass = useSelector(classStoreData);
  const history = useHistory();
  const [user, setUser] = useState({});
  const [externalUserStudyResult, setExternalUserStudyResult] = useState([]);
  const [externalUserMainStudyResult, setExternalUserMainStudyResult] = useState([]);
  const [bookrResult, setBookrResult] = useState([]);
  const [levelupResult, setLevelupResult] = useState([]);
  const [starwordsResult, setStarwordsResult] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment(new Date()).format('yyyy-MM-DD'));
  const companySettings = settings[defaultClass];
  const [viewAnswerModal, setViewAnswerModal] = useState(false);
  const [topic, setTopic] = useState();
  const [userTopicId, setUserTopicId] = useState();
  const [topicAnswer, setTopicAnswer] = useState('');

  const token = getCurrentToken();
  const { data: fetchUser } = useQueryProxy(USER_ME, { variables: { token } });
  const {
    calendar: { selectDay },
  } = useReactiveVar(rootStateVar);

  // const selectedDate = useMemo(() => {
  //   return selectDay || moment(new Date()).format('YYYY-MM-DD');
  // }, [selectDay]);
  useEffect(() => {
    if (selectDay) {
      setSelectedDate(moment(selectDay).format('yyyy-MM-DD'));
    }
  }, [selectDay]);

  useEffect(() => {
    if (fetchUser?.me) {
      setUser(fetchUser.me);
    }
  }, [fetchUser]);

  const { data } = useQuery(CLASS_LESSON_DETAIL_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      campus_idx: userInfoVar()?.campus_idx,
      day: selectedDate,
    },
  });

  const { data: fetchTestData } = useQuery(GET_DAILY_CLASS_TEST_SCHEDULE, {
    fetchPolicy: 'no-cache',
    variables: {
      campus_idx: userInfoVar()?.campus_idx,
      date_string: selectedDate,
    },
    skip: !userInfoVar()?.campus_idx,
  });

  const { data: fetchTestGroupData } = useQuery(GET_DAILY_CLASS_TEST_GROUP_SCHEDULE, {
    fetchPolicy: 'no-cache',
    variables: {
      campus_idx: userInfoVar()?.campus_idx,
      date_string: selectedDate,
    },
    skip: !userInfoVar()?.campus_idx,
  });

  const { data: fetchZoomData } = useQuery(GET_DAILY_CLASS_ZOOM_SCHEDULE, {
    fetchPolicy: 'no-cache',
    variables: {
      campus_idx: userInfoVar()?.campus_idx,
      date_string: selectedDate,
    },
    skip: !userInfoVar()?.campus_idx,
  });

  const { data: fetchPhonicsData } = useQuery(DASHBOARD_CAMPUS_PHONICS_SCHEDULE_GRID, {
    fetchPolicy: 'no-cache',
    variables: {
      inputuser: userInfoVar()?.campus_idx,
      inputdate: selectedDate,
    },
    skip: !userInfoVar()?.campus_idx,
  });

  const { data: fetchOfflineData } = useQuery(DASHBOARD_CAMPUS_OFFLINE_SCHEDULE_GRID, {
    fetchPolicy: 'no-cache',
    variables: {
      inputuser: userInfoVar()?.campus_idx,
      inputdate: selectedDate,
    },
    skip: !userInfoVar()?.campus_idx,
  });

  const { data: fetchBookData } = useQuery(GET_DAILY_CLASS_BOOK_SCHEDULE, {
    fetchPolicy: 'no-cache',
    variables: {
      campus_idx: userInfoVar()?.campus_idx,
      date_string: selectDay || moment(new Date()).format('YYYY-MM-DD'),
    },
    skip: !userInfoVar()?.campus_idx,
  });

  const { data: fetchExternalStudyData } = useQuery(GET_DAILY_CLASS_EXTERNAL_STUDY_SCHEDULE, {
    fetchPolicy: 'no-cache',
    variables: {
      campus_idx: userInfoVar()?.campus_idx,
      date_string: selectDay || moment(new Date()).format('YYYY-MM-DD'),
    },
    skip: !userInfoVar()?.campus_idx,
  });

  const { data: dailyAiTopicScheduleList, refetch: refetchAiTopics } = useQuery(GET_DAILY_AI_TOPIC_SCHEDULE, {
    fetchPolicy: 'no-cache',
    variables: {
      user_idx: parseInt(data?.me?.idx),
      date_string: selectedDate,
    },
  });

  useEffect(() => {
    if (fetchExternalStudyData?.dailyClassExternalStudySchedule?.length > 0 || fetchExternalStudyData !== undefined) {
      let tempExternalResult = [];
      let tempExternalMainStudyResult = [];
      let tempBookrResult = [];
      let tempLevelUpResult = [];
      let tempStarWordsResult = [];
      for (let item of fetchExternalStudyData?.dailyClassExternalStudySchedule) {
        const studyData = JSON.parse(item.external_study_data || '{}');
        const external_student = item?.external_user_study;
        let stage_completed_student = 0;
        if (external_student) {
          stage_completed_student = external_student.filter((item) => item.status == 1).length;
        }
        let className = item?.assigned_external_study_group ? item?.assigned_external_study_group?.class?.name : '';

        if (item?.assigned_external_study_user?.length > 0) {
          if (item?.type == 'newsomatic-assign') {
            tempExternalResult.push({
              key: `newsomatic-assign-${item.idx}`,
              name: `newsomatic-assign-${item.idx}`,
              keyIdx: item.idx,
              student_detail: external_student,
              student_completed: stage_completed_student,
              date: studyData?.editionId || 'All day',
              study_date: item?.start_date ? moment(item?.start_date).format('YYYY-MM-DD') : '',
              // date:externalStudyData?.editionId || '',
              // date: 'News_O_Matic',
              class_idx: item?.assigned_external_study_group?.class?.idx,
              type: item.type,
              title_type: studyData?.type || 'ExternalStudy',
              title_type_str: 'News-o-Matic',
              title: studyData?.editionId ? `${className} / ${studyData?.editionId}` : 'ExternalStudy',
              studyItem: item,
              group_code: item?.__typename,
              assigned_external_study_group: item?.assigned_external_study_group?.idx,
            });
          }
        } else {
          const title = (
            <>
              <span style={{ fontWeight: 'bold' }}>{className}</span> / {studyData.title || ''}
            </>
          );
          if (item?.type === 'newsomatic') {
            tempExternalMainStudyResult.push({
              key: `newsomatic-${item.idx}`,
              name: `newsomatic-${item.idx}`,
              idx: item.idx,
              student_detail: external_student,
              student_completed: stage_completed_student,
              date: item.start_date || 'All day',
              class_idx: item?.assigned_external_study_group?.class?.idx,
              type: item.type,
              title_type: studyData?.type || 'ExternalStudy',
              title_type_str: studyData?.type_text || 'ExternalStudy',
              title,
              studyItem: item,
              group_code: item?.__typename,
              assigned_external_study_group: item?.assigned_external_study_group?.idx,
            });
          }
          if (item?.type === 'bookr') {
            tempBookrResult.push({
              key: `bookr-${item.idx}`,
              name: `bookr-${item.idx}`,
              student_detail: external_student,
              student_completed: stage_completed_student,
              date: item.start_date || 'All day',
              class_idx: item?.assigned_external_study_group?.class?.idx,
              type: item.type,
              title_type: studyData?.type || 'ExternalStudy',
              title_type_str: studyData?.type_text || 'ExternalStudy',
              title,
              studyItem: item,
              group_code: item?.__typename,
              assigned_external_study_group: item?.assigned_external_study_group?.idx,
            });
          }
          if (item?.type === 'levelup') {
            tempLevelUpResult.push({
              key: `LevelUp-${item.idx}`,
              name: `LevelUp-${item.idx}`,
              student_detail: external_student,
              student_completed: stage_completed_student,
              date: studyData.bookreport ? 'LevelUp - Book Report' : 'LevelUp' || 'All day',
              class_idx: item?.assigned_external_study_group?.class?.idx,
              type: item.type,
              title_type: studyData?.type || 'Level-Up',
              title_type_str: studyData?.type_text || 'Level-Up',
              title,
              studyItem: item,
              group_code: item?.__typename,
              assigned_external_study_group: item?.assigned_external_study_group?.idx,
            });
          }
          if (item?.type === 'starwords') {
            tempStarWordsResult.push({
              key: `starwords-${item.idx}`,
              name: `starwords-${item.idx}`,
              keyIdx: item.idx,
              student_detail: external_student,
              student_completed: stage_completed_student,
              date: 'StarWords',
              study_date: item?.start_date ? moment(item?.start_date).format('YYYY-MM-DD') : '',
              class_idx: item?.assigned_external_study_group?.class?.idx,
              type: item.type,
              title_type: studyData?.type || 'ExternalStudy',
              title_type_str: 'StarWords',
              title,
              studyItem: item,
              group_code: item?.__typename,
              assigned_external_study_group: item?.assigned_external_study_group?.idx,
            });
          }
        }
      }

      if (tempExternalResult.length > 0) {
        setExternalUserStudyResult(tempExternalResult);
      }

      if (tempExternalMainStudyResult.length > 0) {
        setExternalUserMainStudyResult(tempExternalMainStudyResult);
      }

      if (tempBookrResult.length > 0) {
        setBookrResult(tempBookrResult);
      }
      if (tempLevelUpResult.length > 0) {
        setLevelupResult(tempLevelUpResult);
      }
      if (tempStarWordsResult.length > 0) {
        setStarwordsResult(tempStarWordsResult);
      }
    } else {
      setExternalUserStudyResult([]);
      setExternalUserMainStudyResult([]);
      setBookrResult([]);
      setLevelupResult([]);
      setStarwordsResult([]);
    }
  }, [fetchExternalStudyData]);
  const dataBookSchedule = useMemo(() => {
    if (fetchBookData) {
      return fetchBookData.dailyClassBookSchedule.map((item, key) => {
        let user_completed_stages = {};
        let stage_completed_student = 0;
        if (item?.user_study.length) {
          let stage = [...new Set(item?.user_study.map((itm) => itm?.stage_no))];
          item?.user_study.map((ele, key) => {
            if (user_completed_stages[ele.user.idx]) {
              user_completed_stages[ele.user.idx].push(ele.stage_no);
            } else {
              user_completed_stages[ele.user.idx] = [ele.stage_no];
            }
          });
          Object.keys(user_completed_stages).map((key) => {
            if (user_completed_stages[key].length === stage.length) {
              stage_completed_student += 1;
            }
          });
        }
        const library_student = item?.assigned_elibrary_users;
        const title = `${item?.assigned_elibrary_group?.class?.name} / ${item?.book_lesson?.title}`;
        return {
          key: item.idx,
          name: item.idx,
          student_detail: library_student,
          student_completed: stage_completed_student,
          date: item.start_date || 'All day',
          class_idx: item?.assigned_elibrary_group?.class?.idx,
          type: 'Library',
          title,
          studyItem: item,
          group_code: item?.__typename,
          assigned_elibrary_group: item?.assigned_elibrary_group?.idx,
        };
      });
    } else {
      return [];
    }
  }, [fetchBookData]);

  const dataPhonicsSchedule = useMemo(() => {
    if (fetchPhonicsData) {
      return fetchPhonicsData.CamppusPhonicsDailyScheduleGrid.map((item, key) => {
        const title = (
          <>
            <span style={{ fontWeight: 'bold' }}>
              {item.name} / {item.book_idx}
            </span>
          </>
        );
        return {
          key: item.book_idx + item.name + key,
          name: item.book_idx + item.name + key,
          date: item.begin_time || 'All day',
          type: 'Phonics',
          title,
          studyItem: item,
          group_code: 'PhonicsLesson',
          phonics_test_group_assigned: item.book_idx,
          class_idx: item.class_idx,
        };
      });
    } else {
      return [];
    }
  }, [fetchPhonicsData?.CamppusPhonicsDailyScheduleGrid]);

  const dataOfflineSchedule = useMemo(() => {
    if (fetchOfflineData) {
      return fetchOfflineData.CamppusOfflineDailyScheduleGrid.map((item, loopkey) => {
        const title = (
          <>
            <span style={{ fontWeight: 'bold' }}>
              {item.name} {item.days_lesson} {item.plantopic}
            </span>
          </>
        );
        return {
          key: loopkey,
          name: loopkey,
          date: item.begin_time || 'All day',
          type: 'Offline',
          title,
          studyItem: item,
          group_code: 'OfflineLesson',
          offline_test_group_assigned: 'OfflineLesson',
        };
      });
    } else {
      return [];
    }
  }, [fetchOfflineData]);

  const dataSchedule = useMemo(() => {
    if (data) {
      const isShowDetail = isShowLessonDetail(defaultClass);
      return data.classLessonDetailList.map((item) => {
        //const title = `${item?.class?.name} / ${item.lesson_code}`;
        const lessonTitle =
          isShowDetail || item.lesson_code.includes('ELT-')
            ? item?.book_lesson?.title?.replace(',', '') ||
              `${item?.book_lesson?.book?.book_sub[0]?.title} Unit${item?.book_lesson?.unit_no} Lesson${item?.book_lesson?.day_no}`
            : item?.lesson_code;
        const title = (
          <>
            <span style={{ fontWeight: 'bold' }}>{item.class?.name}</span> / {lessonTitle}
          </>
        );
        return {
          key: item.idx,
          name: item.idx,
          homework_assigned_student: item.homework_assigned_student,
          homework_completed_student: item.homework_completed_student,
          date: item.begin_time || 'All day',
          type: 'Homework',
          title,
          studyItem: item,
        };
      });
    } else {
      return [];
    }
  }, [data, defaultClass]);

  const dataTestSchedule = useMemo(() => {
    if (fetchTestData) {
      return fetchTestData.dailyClassTestSchedule.map((item) => {
        const title = (
          <>
            <span style={{ fontWeight: 'bold' }}>{item.class?.name}</span> / {item?.title}
          </>
        );

        return {
          key: item.idx,
          name: item.idx,
          date: item.start_time || 'All day',
          type: 'Test',
          title,
          studyItem: item,
          total: item?.class?.class_student?.length,
          group_code: item?.__typename,
        };
      });
    } else {
      return [];
    }
  }, [fetchTestData]);

  const dataTestGroupSchedule = useMemo(() => {
    if (fetchTestGroupData) {
      return fetchTestGroupData.dailyClassTestGroupSchedule.map((item) => {
        const title = (
          <>
            <span style={{ fontWeight: 'bold' }}>{item?.test_group_name}</span>
          </>
        );

        return {
          key: item.idx,
          name: item.idx,
          date: item.start_time || 'All day',
          type: 'Test Group',
          title,
          studyItem: item,
          group_code: item?.__typename,
          test_group_assigned: item?.test_group_assigned?.idx,
          daily_test_group_assigned_idx: item?.daily_test_group_assigned?.idx,
          class_idx: item?.class?.idx,
        };
      });
    } else {
      return [];
    }
  }, [fetchTestGroupData]);

  const dataZoomSchedule = useMemo(() => {
    if (fetchZoomData) {
      return fetchZoomData.dailyClassZoomSchedule.map((item, key) => {
        //const title = `${item?.title}`;
        const title = (
          <>
            <span style={{ fontWeight: 'bold' }}>{item.class?.name}</span> / {item.start_time}
          </>
        );

        return {
          key: item.idx,
          name: item.idx,
          date: item.start_time || 'All day',
          type: 'Zoom',
          title,
          studyItem: item,
          group_code: item?.__typename,
        };
      });
    } else {
      return [];
    }
  }, [fetchZoomData]);

  const ai_topic_data = useMemo(() => {
    if (dailyAiTopicScheduleList) {
      return dailyAiTopicScheduleList.dailyAiTopicSchedule.map((item, key) => {
        let data = JSON.parse(item?.data || '{}');
        let topicData = JSON.parse(item?.ai_topic_user_assignments[0]?.ai_topic?.data || '{}');
        const titleTxt = data?.revisedPrompt || `${topicData?.book?.title || item?.ai_topic_user_assignments[0]?.ai_topic?.topic}`;
        const title = (
          <>
            <span style={{ fontWeight: 'bold' }}>{item?.ai_topic_user_assignments[0]?.class?.name}</span> /{' '}
            <Tooltip title={titleTxt} placement="topLeft" color="#fff">
              {titleTxt}
            </Tooltip>
          </>
        );
        return {
          key: `ai_topic_${key}`,
          name: `ai_topic_${key}`,
          idx: item.idx,
          day: item.start_date,
          date: item.type === 'ai-topic' ? 'AI Topic' : item.type === 'ai-talking-tutor' ? 'Speaking Coach' : 'Writing Coach',
          title,
          // title: data?.revisedPrompt || `${item?.ai_topic_user_assignments[0]?.ai_topic?.topic}`,
          percent: false,
          class_idx: item?.ai_topic_user_assignments[0]?.class?.idx,
          status: item?.ai_topic_user_assignments?.reduce((accu, curr) => (curr.status < accu ? curr.status : accu), 2),
          studyItem: item,
          link_url: null,
          type: item.type,
          group_code: item?.__typename,
        };
      });
    }

    return [];
  }, [dailyAiTopicScheduleList, data?.me?.idx]);

  const columns = [
    {
      key: 'date',
      title: 'Date',
      dataIndex: 'date',
      width: '20%',
      render: (text, it) => {
        const groupCode = it?.studyItem?.book_lesson?.stage_group_code || it?.group_code;
        //console.log('it.groupCode', groupCode);
        if (groupCode) {
          // if (Object.keys(STAGE_GROUP_NAMES_EVINE).includes(groupCode)) {
          //   return STAGE_GROUP_NAMES_EVINE[groupCode](it.studyItem?.stat === '1');
          // }
          if (it?.type === 'Homework') {
            const done = it?.studyItem?.stat === '1';

            if (done) {
              return studyState[10]('Homework', companySettings.appColors['done']);
            }

            if (Object.keys(companySettings.appColors).includes(`homework_${groupCode}`)) {
              return studyState[10]('Homework', companySettings.appColors[`homework_${groupCode}`]);
            }

            if (Object.keys(STAGE_GROUP_NAMES).includes(groupCode)) {
              return STAGE_GROUP_NAMES[groupCode](done);
            }
          }
        }
        if (it.status === 5) {
          return studyState[6](text);
        } else {
          if (it?.type === 'Phonics') {
            return studyState[7]('Phonics');
          } else if (it?.type === 'Offline') {
            return studyState[8]('Offline');
          } else if (it?.type === 'Library') {
            return studyState[10]('Library', companySettings.appColors.book);
          } else if (it?.type === 'newsomatic') {
            return studyState[10](it?.title_type_str, companySettings.appColors.externalStudy);
          } else if (it?.type === 'bookr') {
            return studyState[10]('Bookr', companySettings.appColors.bookr);
          } else if (it?.type === 'levelup') {
            return studyState[10]('levelup', companySettings.appColors.LevelUp);
          } else if (it?.type === 'starwords') {
            return studyState[10]('Starwords', companySettings.appColors.StarWords);
          } else if (it?.type === 'newsomatic-assign') {
            return studyState[10](it?.title_type_str, companySettings.appColors.News_O_Matic);
          } else if (it?.type === 'Test') {
            return studyState[10]('Test', companySettings.appColors.test);
          } else if (it?.type === 'Test Group') {
            return studyState[10]('Test Group', companySettings.appColors.test);
          } else if (it?.type === 'ai-topic') {
            return studyState[10]('Ai Topic', companySettings.appColors.AI_Topics);
          } else if (it?.type === 'ai-talking-tutor') {
            return studyState[10]('Speaking Coach', companySettings.appColors.AI_Talking);
          } else if (it?.type === 'book-report') {
            return studyState[10]('Writing Coach', companySettings.appColors.Book_Report);
          } else {
            return studyState[4](text);
          }
        }
      },
    },
    {
      key: 'title',
      title: 'title',
      dataIndex: 'title',
      style: 'text-align: left',
    },
    {
      key: 'result',
      title: 'result',
      dataIndex: 'result',
      align: 'right',
      render: (_, record) => {
        if (record.type === 'ai-topic') {
          return <Button size="small">학습관리</Button>;
        } else if (record.type === 'ai-talking-tutor' || record.type === 'book-report') {
          const tmp = record.studyItem.ai_topic_user_assignments;
          const countChecker = record.type === 'ai-talking-tutor' ? 1 : 2;
          const count = tmp.reduce((acc, cur) => {
            const doneCheck = cur.status === countChecker ? 1 : 0;
            return acc + doneCheck;
          }, 0);
          return companySettings.showCountButtons ? (
            <>
              <Button className="lucid-btn" size="small">
                {count}/{tmp.length}
              </Button>
              <Button size="small">학습관리</Button>
            </>
          ) : (
            <>
              <Button size="small">학습관리</Button>
            </>
          );
        } else if (record?.studyItem?.lesson_code && record?.studyItem?.class?.idx) {
          //return <Button size="small">학습관리</Button>;
          return companySettings.showCountButtons ? (
            <>
              <Button className="lucid-btn" size="small">
                {record?.homework_completed_student}/{record?.homework_assigned_student}
              </Button>
              <Button size="small">학습관리</Button>
            </>
          ) : (
            <>
              <Button size="small">학습관리</Button>
            </>
          );
        } else if (record?.assigned_elibrary_group) {
          return companySettings.showCountButtons ? (
            <>
              <Button className="lucid-btn" size="small">
                {record?.student_completed}/{record?.student_detail.length}
              </Button>
              <Button size="small">학습결과</Button>
            </>
          ) : (
            <>
              <Button size="small">학습결과</Button>
            </>
          );
        } else if (record?.test_group_assigned) {
          return <Button size="small">학습관리</Button>;
        } else if (record?.offline_test_group_assigned) {
          return '';
        } else if (record?.studyItem?.idx && record?.type === 'levelup') {
          const tmp = record.studyItem.external_user_study;
          const count = tmp.reduce((acc, cur) => {
            const doneCheck = cur.status === 2 ? 1 : 0;
            return acc + doneCheck;
          }, 0);
          return companySettings.showCountButtons ? (
            <>
              <Button className="lucid-btn" size="small">
                {count}/{tmp.length}
              </Button>
              <Link to={`/level-up/assignedlist/results/${record?.studyItem?.idx}`}>
                <Button size="small">학습결과</Button>
              </Link>
            </>
          ) : (
            <>
              <Link to={`/level-up/assignedlist/results/${record?.studyItem?.idx}`}>
                <Button size="small">학습결과</Button>
              </Link>
            </>
          );
        } else if (record?.studyItem?.idx && record?.type === 'starwords') {
          const tmp = record.studyItem.external_user_study;
          const count = tmp.reduce((acc, cur) => {
            const doneCheck = cur.status === 2 ? 1 : 0;
            return acc + doneCheck;
          }, 0);
          return companySettings.showCountButtons ? (
            <>
              <Button className="lucid-btn" size="small">
                {count}/{tmp.length}
              </Button>
              <Link to={`/star-words/assignedlist/results/${record?.studyItem?.idx}`}>
                <Button size="small">학습결과</Button>
              </Link>
            </>
          ) : (
            <>
              <Link to={`/star-words/assignedlist/results/${record?.studyItem?.idx}`}>
                <Button size="small">학습결과</Button>
              </Link>
            </>
          );
        } else if (record?.studyItem?.idx) {
          return (
            <>
              <Button className="lucid-btn" size="small">
                {record?.studyItem?.done}/{record.total}
              </Button>
              <Button size="small">학습관리</Button>
            </>
          );
        } else if (record?.phonics_test_group_assigned) {
          return <Button size="small">Phonics</Button>;
        } else {
          return '';
        }
      },
      //   title: 'view',
      //   dataIndex: 'view',
      //   width: '40%',
      //   style: 'text-align: right',
      //   render: (_, it) => it?.link_url && <Link to={it.link_url}>학습보기</Link>,
    },
  ];

  const onHandleRowClick = (row, index, user) => {
    if (row?.type === 'book-report') {
      history.push(`/aicoach/report/details-by-date/${row?.idx}`);
      return;
    }
    if (row?.type === 'ai-talking-tutor') {
      history.push(`/ai-talking-tutor/report/details-by-date/${row?.idx}`);
      return;
    }
    if (row?.type === 'ai-topic') {
      history.push(`/ai-topic/details-by-date/${row?.idx}`);
      return;
    }
    if (row?.type == 'Offline') {
      history.push(`/offline/lesson/dashboardview/${row?.studyItem?.idx}/${row?.studyItem?.level}`);
      return;
    }

    if (row?.type == 'newsomatic') {
      history.push(`/external-study/homework/results/${row?.class_idx}/${row?.idx}`);
      return;
    }
    if (row?.type == 'newsomatic-assign') {
      history.push(`/external-study/homework/multiple/results/${row?.class_idx}/${row?.study_date}/${row?.keyIdx}`);
      return;
    }
    if (row?.type == 'bookr') {
      history.push(`/bookr/result/${row?.class_idx}/${row?.key.split('-')[1]}`);
      return;
    }
    if (row?.type == 'levelup') {
      history.push(`/level-up/assignedlist/results/${row?.studyItem?.idx}`);
      return;
    }
    if (row?.type == 'starwords') {
      history.push(`/star-words/assignedlist/results/${row?.studyItem?.idx}`);
      return;
    }

    if (row?.type == 'Library') {
      history.push(`/elibrary/homework/results/${row?.class_idx}/${row?.key}`);
      return;
    }
    if (row?.type == 'Test Group') {
      history.push(`/daily/test/result/${row?.daily_test_group_assigned_idx}/${row?.class_idx}`);
      return;
    }

    if (row?.type === 'ai-topic') {
      if (row.status > 0) {
        setTopic(row.title);
        setUserTopicId(row.studyItem.idx);
        setTopicAnswer(row.studyItem.answer);
        setViewAnswerModal(true);
      }
      return;
    }
    if (row?.studyItem?.lesson_code && row?.studyItem?.class?.idx) {
      history.push(`/learning/${row?.studyItem?.class?.idx}/${row?.studyItem?.lesson_code}`, {
        className: row?.studyItem?.class?.name,
        lessonTitle: row?.studyItem?.book_lesson?.title || row?.studyItem?.lesson_code,
        org_date: row?.studyItem?.study_date,
      });
      return;
    }
    if (row?.test_group_assigned) {
      history.push(`/test/syllabus/assigned/${row?.test_group_assigned}`);
      return;
    }

    if (row?.studyItem?.idx) {
      history.push(`/test/${row?.studyItem?.idx}`);
      return;
    }

    if (row?.phonics_test_group_assigned) {
      history.push(`/learningschedule/results/${row?.phonics_test_group_assigned}/${selectedDate}/${row?.class_idx}`);
      return;
    }
    // if (row.status === 4) {
    //   console.log(row.link_url);
    //   window.open(row.link_url, '_blank');
    // }

    if (window.Android && row.isMeeting) {
      if (user.type === 0) {
        window.Android.joinZoomClass(JSON.stringify(user));
      } else {
        window.Android.startZoomClass(JSON.stringify(user));
      }
    } else if (row.isMeeting) {
      window.open('https://zoom.us/j/96909142653?pwd=MUFwTmM0bWxhRzUzcmVGaTRYZmtyZz09', '_blank');
    }
  };

  return (
    <>
      <Title level={5} style={{ paddingLeft: '20px', position: 'relative' }}>
        <span>{moment(selectedDate).format('YYYY-MM-DD')}</span>
        {/* <span>{moment(selectedDate).format('YYYY-MM-DD')}</span><span>완료/총학생 정보</span> */}
      </Title>
      <div className="today-table">
        <TodayTable
          pagination={false}
          showHeader={false}
          columns={columns}
          rowKey="name"
          dataSource={[
            ...dataSchedule,
            ...dataTestSchedule,
            ...dataZoomSchedule,
            ...dataPhonicsSchedule,
            ...dataOfflineSchedule,
            ...dataBookSchedule,
            ...externalUserMainStudyResult,
            ...externalUserStudyResult,
            ...bookrResult,
            ...levelupResult,
            ...starwordsResult,
            ...ai_topic_data,
            ...dataTestGroupSchedule,
          ]}
          scroll={{ x: 476, y: 265 }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => onHandleRowClick(record, rowIndex, user),
            };
          }}
          size="small"
        />
        {viewAnswerModal && (
          <ViewAnswerModal
            visible={viewAnswerModal}
            topic={topic}
            userTopicId={userTopicId}
            topicAnswer={topicAnswer}
            handleCancel={() => {
              setViewAnswerModal(false);
              refetchAiTopics();
            }}
          />
        )}
      </div>
    </>
  );
};

export default TodaySchedule;
