import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Switch, Select, Input, Tabs, Upload, Modal, Spin, Button as AButton, Divider, message } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import {
  CREATE_AI_GROUP_TOPIC,
  DELETE_UPLOADED_FILE,
  UPLOAD_OPEN_AI_FILE,
  UPDATE_ASSISTANTS,
  CREATE_ASSISTANTS,
  CREATE_THREAD,
  UPLOAD_FILE_IN_VECTOR,
} from 'src/operations/mutations/aiGroupTopic';
import {
  OPEN_AI_MESSAGE,
  CREATE_RUNS,
  MESSAGE_LIST,
  GET_STEPS,
  GET_MESSAGE,
  GET_FILE_FROM_VECTOR,
  DELETE_FILE_FROM_VECTOR,
  CREATE_VECTOR,
  RETRIEVE_RUN,
  SUBMIT_TOOL_OUTPUT,
} from 'src/operations/mutations/aiGroupTopic';
import { useTextToSpeach } from 'src/utils/hooks';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';

import { AI_TALKING_TUTOR_COMPLETION } from 'src/operations/queries/message';
import { CREATE_SIGNED_S3_UPLOAD } from 'src/operations/mutations/uploadFileS3';
import { AI_TOPICS_LIST } from 'src/operations/queries/aiTopic';
import { AI_TALKING_DETAIL, GET_CONTENT_LIST } from 'src/operations/queries/aiGroupTopic';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import XLSX from 'xlsx';
import { UploadOutlined } from '@ant-design/icons';
import Loading from 'src/components/common/Loading';
import InfiniteScroll from 'react-infinite-scroll-component';
import MessageItem from 'src/components/DashBoard/SubPage/MessageItem';
import RecordRTC, { StereoAudioRecorder } from 'recordrtc';
import MessageBox from 'src/components/DashBoard/SubPage/MessageBox';
import { parse } from 'query-string';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import * as axios from 'axios';
import { userInfoVar } from 'src/apollo/cache';
import { SendOutlined } from '@ant-design/icons';
import moment from 'moment';
import { captureUserMedia } from 'src/utils';
import ReactPlayer from 'react-player';
import SoundEffect from 'src/components/common/SoundEffect';

const clickSound = '/audio/LevelUp/button_click.mp3';
const recordingStartSound = '/audio/LevelUp/Game_Wrong.mp3';

const LoadingScreen = styled.div`
  height: 100%;
  background: transparent;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const PROMPTS = [
  ` I am a first grader kid who does not speak English well.
    So talk to me with easy words and do not use long sentences.
    I am going to give you a story. You probably know the story.
    It is called "The Three Little Piggy brothers".
    You can ask me five questions one at a time about the story and after one question is done ask next question.
    If I got it wrong, let me know that I got it wrong, and tell me the right answer. 
    
    Ask next question after you comment your feedback.
    "
    
    Once upon a time, there were three little pig brothers: Piggy 1, Piggy 2, and Piggy 3. 
    They decided to build houses to keep themselves safe from the big bad wolf.
    Piggy 1 was in a hurry and made a house of straw. It was quick but not very strong. 
    Piggy 2 also wanted to finish fast, so he built his house with sticks. It was a bit stronger than straw, but still not very tough.
    Now, Piggy 3 was wise. He took his time and built a sturdy house with strong bricks. It took more effort, but he knew it would be the safest.
    One day, the big bad wolf came by. He huffed and puffed and blew down the straw house of Piggy 1! Oh no! The little pig ran to Piggy 2's house.
    The wolf huffed and puffed again, and this time, the house made of sticks also fell. Now, both Piggy 1 and Piggy 2 were scared. They all rushed to Piggy 3's brick house.
    The wolf tried with all his might to blow down the brick house, but it was too strong. The three little pigs were safe inside. The wolf couldn't get them!
    In the end, the wolf gave up and went away. The three little pigs were happy and learned an important lesson – taking time to do things right and making strong choices pays off.
    And they all lived happily ever after in their sturdy brick house. The end! "
    
    You can now start by asking me the first question about the story.`,
];
const TO_LESSON = `Repeat last thing you said again.`;
const PAUSE = `Let's stop the conversation. I need to take a break and we will continue later.`;
const RESTART = `Understand the previous conversation and start from where we left off.`;
const STOP = `Stop the conversation.`;
const REPEAT = `Can we go back to beginning of the conversation?, and ask me if I am ready to start.
  If I say 'yes' then ask me the first question about our main topic.
  If I say 'no' then ask me a few questions about the story one at a time that you have not ask me before.`;

const { TextArea } = Input;
const { Option } = Select;
const EditGroupList = () => {
  let isUploadInProgress = false;
  const companyName = useSelector(classStoreData);
  const { idx } = useParams();
  const [formControl] = Form.useForm();
  const [modalForm] = Form.useForm();
  const [contentList, setContentList] = useState([]);
  const [saveData, setSaveData] = useState(false);
  const [contentIdx, setContentIdx] = useState(userInfoVar()?.type == 5 ? 1 : null);
  const [groupIdx, setGroupIdx] = useState('');
  const [folderType, setFolderType] = useState('me');
  const [loading, setLoading] = useState(false);
  const [topicType, setTopicType] = useState('ai-talking-tutor');
  const [level, setLevel] = useState('1');
  const [tabIndex, setTabIndex] = useState('1');
  const [recordText, setRecordText] = useState('');
  const [prompt, setPrompt] = useState('');
  const [tabVal, setTabVal] = useState('');
  const [addMore, setAddMore] = useState(false);
  const [indx, setIndx] = useState(0);
  const [filedata, setFiledata] = useState([]);
  const [groupDisable, setGroupDisable] = useState(false);
  const [uploadedFile, setUploadedFile] = useState([]);
  const [instructions, setInstructions] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalTabIndex, setModalTabIndex] = useState('1');
  // const [modalTabVal, setModalTabVal] = useState({ modal_question: 'I am a student ask me this question', modal_chat: '', modal_debate: '' })
  const [modalTabVal, setModalTabVal] = useState('');
  const [testIdx, setTestIdx] = useState({ test_assistant_idx: '', test_thread_idx: '', test_run_idx: '', test_vector_idx: '' });
  const [dupModalTabVal, setDupModalTabVal] = useState(modalTabVal);
  const [micValue, setMicValue] = useState('mice_1.png');
  const [soundPlay, setSoundPlay] = useState(false);
  const [sound, setSound] = useState(clickSound);
  const [disableBtn, setDisableBtn] = useState(false);
  const [disableBtnVal, setDisableBtnVal] = useState(false);
  const [currentText, setCurrentText] = useState('');
  const [micBtnChange, setMicBtnChange] = useState(false);
  const [cancelBtnClick, setCancelBtnClick] = useState(false);
  const [speed, setSpeed] = useState(1.0);
  const [voice, setVoice] = useState('shimmer');
  const [currentUrl, setCurrentUrl] = useState('');
  const [msgIdx, setMsgIdx] = useState('');
  const [active, setActive] = useState(false);
  const [disableControl, setDisableControl] = useState(false);
  const [chatList, setChatList] = useState([]);
  const [lastBlob, setLastBlob] = useState(null);
  const [activeAnswer, setActiveAnswer] = useState(false);
  const [currentRecLength, setCurrentRecLenght] = useState(0);
  const [micBtnClick, setMicBtnClick] = useState(0);
  const [formSubmitClick, setFormSubmitClick] = useState(false);
  const [promptValidate, setPromptValidate] = useState(false);
  const [pg, setPg] = useState({ current: 0, pageSize: 10 });
  const [startRecording, setStartRecording] = useState(false);
  const [stopInterval, setStopInterval] = useState(0);
  const [pauseStop, setPauseStop] = useState({ pause: false, stop: false });
  const [lastUserMsg, setLastUserMsg] = useState(tabVal?.question);
  const [gptModel, setGptModel] = useState('gpt-4o');
  const [clearBtn, setClearBtn] = useState(false);
  const [audioPaths, setAudioPaths] = useState([]);
  const [imagePaths, setImagePaths] = useState([]);
  const [audioUrl, setAudioUrl] = useState(null);
  const [imageAudio, setImageAudio] = useState({ image: false, audio: false });
  const [formattedAudioPaths, setFormattedAudioPaths] = useState([]);
  const [formattedImagePaths, setFormattedImagePaths] = useState([]);
  const [btnSaveTest, setBtnSaveTest] = useState(false);
  const [topicIdx, setTopicIdx] = useState('');
  const messageBoxRef = useRef(null);
  const recordAudio = useRef(null);
  const recorderStreams = useRef(null);
  const autoSendRef = useRef([]);
  const inputRef = useRef([]);

  const { isSpeaking, texts, speak, stop } = useTextToSpeach(speed, voice);

  const history = useHistory();

  const parseMessage = (content) => {
    let value = '';
    let valueObj = {};
    if (content.length > 0) {
      for (let i = 0; i < content.length; i++) {
        if (content[i]?.text?.value.replace(/(\r\n|\n|\r)/gm, '').startsWith('{')) {
          let temp = JSON.parse(content[i]?.text?.value);
          for (let key in temp) {
            if (
              key.endsWith('image_url') ||
              key.endsWith('audio_url') ||
              key.startsWith('image_url') ||
              key.startsWith('audio_url') ||
              key.startsWith('image_') ||
              key.startsWith('audio_')
            ) {
              valueObj[key] = temp[key];
            } else if (key in valueObj) {
              valueObj[key] = valueObj[key] === temp[key] ? valueObj[key] : valueObj[key] + '\n' + temp[key];
            } else {
              valueObj[key] = temp[key];
            }
          }
        } else {
          value += content[i]?.text?.value;
        }
      }
    }

    return value === '' ? valueObj : value;
  };

  const levelList = useMemo(() => {
    return companyName === 'aone' && folderType !== 'gen content'
      ? ['CS', 'AS', 'TS', 'GS', 'SS', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
      : [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  }, [companyName, folderType]);

  const mapContentData = (contentText, data) => {
    if (typeof contentText === 'string') {
      return [{ ...data, content: contentText }];
    }
    if (typeof contentText === 'object') {
      let result = [];
      let keys = Object.keys(contentText);
      let text = '';
      for (let i = 0; i < keys.length; i++) {
        if (typeof contentText[keys[i]] === 'object') {
          continue;
        }
        if (
          keys[i].endsWith('image_url') ||
          keys[i].endsWith('audio_url') ||
          keys[i].startsWith('image_url') ||
          keys[i].startsWith('audio_url') ||
          keys[i].startsWith('image_') ||
          keys[i].startsWith('audio_')
        ) {
          result.push({
            ...data,
            content: text,
            urls:
              keys[i].endsWith('image_url') || keys[i].startsWith('image_url') || keys[i].startsWith('image_')
                ? [{ type: 'image', url: contentText[keys[i]] }]
                : [{ type: 'audio', url: contentText[keys[i]] }],
          });
          text = '';
          continue;
        }
        text = text + '\n' + contentText[keys[i]];
      }
      if (text !== '') {
        result.push({ ...data, content: text });
      }
      return result;
    }
  };

  const [uploadFileS3] = useMutation(CREATE_SIGNED_S3_UPLOAD);
  const [createAIGroupTopic, { loading: createLoading }] = useMutation(CREATE_AI_GROUP_TOPIC);
  const [createAssistants, { loading: assistantsLoading }] = useMutation(CREATE_ASSISTANTS);
  const [createThread, { loading: threadLoading }] = useMutation(CREATE_THREAD);
  const [openAiMessage, { data: openAiMsg, loading: openAiMsgLoading }] = useMutation(OPEN_AI_MESSAGE);
  const [uploadOpenAiFile, { loading: fileLoading }] = useMutation(UPLOAD_OPEN_AI_FILE);
  const [updateAssistants, { loading: updateLoading }] = useMutation(UPDATE_ASSISTANTS);
  const [createRun, { data: runData, loading: runLoading }] = useMutation(CREATE_RUNS);
  const [getSteps, { data: stepsData, loading: stepLoading }] = useMutation(GET_STEPS);
  const [getMsg, { data: msgData, loading: msgLoading }] = useMutation(GET_MESSAGE);
  const [getVectorFiles, { data: vectorfile }] = useMutation(GET_FILE_FROM_VECTOR);
  const [deleteFileFromVector, { data: deletevectorfile }] = useMutation(DELETE_FILE_FROM_VECTOR);
  const [uploadFileInVectorStore, { loading: uploadingFile }] = useMutation(UPLOAD_FILE_IN_VECTOR);
  const [createVector, { loading: vectorLoading }] = useMutation(CREATE_VECTOR);
  const [retrieveRun, { data: retrieveRunData, loading: retrieveRunLoading }] = useMutation(RETRIEVE_RUN);
  const [submitToolOutput, { data: submitToolData, loading: submitToolLoading }] = useMutation(SUBMIT_TOOL_OUTPUT);
  const [updateAiRequestResponse, { data: completeAi, loading: aiComplete }] = useLazyQuery(AI_TALKING_TUTOR_COMPLETION);
  const [deleteFile, { loading: deleteLoding }] = useMutation(DELETE_UPLOADED_FILE, {
    onError(error) {
      console.log('error', error);
    },
    onCompleted(data) {
      if (data?.deleteOpenAIUploadedFile) {
        setFiledata([]);
      }
    },
  });
  const { data: getContentList } = useQuery(GET_CONTENT_LIST, { variables: { type: 'ai-talking-tutor' } });

  const { data, refetch } = useQuery(AI_TOPICS_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      folderType: topicType === 'levelup' ? 'NA' : folderType,
      type: topicType,
      take: pg.pageSize,
      page: pg.current,
      level: level !== -1 ? `"level": ${level},` : null,
    },
  });

  const { data: talkingDetail, refetch: refetchTalking } = useQuery(AI_TALKING_DETAIL, {
    fetchPolicy: 'no-cache',
    variables: {
      groupIdx: parseInt(idx),
    },
  });
  const onDataAvailableCallback = useCallback(async (blob) => {
    setCurrentRecLenght((prev) => prev + 100);
  }, []);

  const processRecordCallback = useCallback(async (blob, val, cb) => {
    let headers;
    if (val === 'ask') {
      headers = { 'x-spoken-language': 'ko' };
    } else {
      headers = { 'Content-Type': 'audio/webm' };
    }
    try {
      if (blob !== undefined) {
        const res = await axios('https://a2vdkjqiwop2mrkfprrzmp7yqq0xdicc.lambda-url.ap-northeast-2.on.aws/', {
          method: 'POST',
          data: blob,
          headers,
        });
        // if (val === "ask") {
        //     askForm.setFieldValue('korean_text', res.data.text);
        //     askForm.submit();
        // } else {
        if (val === STOP) {
          setDisableBtnVal(true);
        } else {
          setDisableBtnVal(false);
        }
        if (
          val === PAUSE ||
          val === STOP ||
          val === TO_LESSON ||
          val === REPEAT ||
          val === 'ask-stop-recording' ||
          val === 'message' ||
          val === 'clear' ||
          val === 'send-text'
        ) {
        } else {
          if (
            res?.data?.text === '' ||
            res?.data?.text.toLowerCase().startsWith('thank you for watching') ||
            res?.data?.text.toLowerCase().startsWith('thanks for watching')
          ) {
            speak('I am sorry, I did not understand what you said. Can you repeat that?', () => {
              setMicValue('robot.png');
            });
          } else {
            setCurrentText(res.data.text);
            setRecordText(res?.data?.text);
            if (cb) cb(res.data.text);
          }
          // setTabVal({
          //     question: res.data.text
          // })
        }
        // }
      }
    } catch (error) {
      console.error('Error occurred:', error);
    }
  }, []);

  useEffect(() => {
    if (startRecording) {
      const startTime = Date.now();
      const interval = setInterval(() => {
        const elapsedTime = Date.now() - startTime;
        const percentElapsed = (elapsedTime / 30000) * 100;
        if (percentElapsed <= 100) {
          setStopInterval(percentElapsed);
        } else {
          clearInterval(interval);
          setStopInterval(100);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [startRecording]);
  useEffect(() => {
    if (startRecording && stopInterval == 100) {
      setPauseStop((prevState) => ({
        ...prevState,
        stop: true,
      }));
      stopRecording(STOP);
      setMicBtnClick(0);
      setActive(false);
      setMicBtnChange(true);
      setMicValue('mice_1.png');
    }
  }, [stopInterval]);

  useEffect(() => {
    let interval;
    if (
      retrieveRunData?.retrieveRun?.status !== 'completed' &&
      retrieveRunData?.retrieveRun?.status !== 'requires_action' &&
      testIdx?.test_run_idx !== undefined &&
      testIdx?.test_run_idx !== null &&
      testIdx?.test_run_idx !== ''
    ) {
      interval = setInterval(() => {
        retrieveRun({
          variables: {
            thread_id: testIdx?.test_thread_idx,
            run_id: testIdx?.test_run_idx,
            group_id: parseInt(idx),
            topic_id: topicIdx,
          },
        });
      }, 2000);
    }

    if (retrieveRunData?.retrieveRun?.status === 'requires_action') {
      if (
        retrieveRunData?.retrieveRun?.required_action?.type === 'submit_tool_outputs' &&
        retrieveRunData?.retrieveRun?.output?.length > 0
      ) {
        submitToolOutput({
          variables: {
            thread_id: testIdx?.test_thread_idx,
            run_id: testIdx?.test_run_idx,
            tool_outputs: retrieveRunData?.retrieveRun?.output ? JSON.stringify(retrieveRunData?.retrieveRun?.output) : '',
          },
        }).then((result) => {
          if (result?.data?.submitToolOutput?.id) {
            retrieveRun({
              variables: {
                thread_id: testIdx?.test_thread_idx,
                run_id: testIdx?.test_run_idx,
                group_id: parseInt(idx),
                topic_id: topicIdx,
              },
            }).then((res) => {
              if (res?.data?.retrieveRun?.status === 'completed') {
                if (msgIdx) {
                  const formVal = formControl.getFieldValue();
                  updateAiRequestResponse({
                    variables: {
                      topic: formVal?.topic,
                      prompt: currentText,
                      thread_id: testIdx?.test_thread_idx,
                      tokens: JSON.stringify(res?.data?.retrieveRun?.retrieveRun?.usage),
                      top_p: 1,
                      userTopicId: parseInt(idx),
                      company_idx: userInfoVar()?.campus?.fc_company_idx,
                      userType: userInfoVar()?.type,
                      open_ai_message: gptModel,
                    },
                  });
                  getMsg({
                    variables: {
                      thread_id: testIdx?.test_thread_idx,
                      message_id: msgIdx,
                    },
                  }).then((res) => {
                    const filtered_data = parseMessage(
                      res.data?.getMessage?.data.reduce((acc, ele) => {
                        let temp = ele.content.concat(acc);
                        return temp;
                      }, []),
                    );
                    const result = mapContentData(filtered_data, {
                      user: userInfoVar()?.id,
                      chat_user_id: res.data?.getMessage?.data[0] === 'user' ? userInfoVar()?.id : 'AI봇',
                      chat_user_name: res.data?.getMessage?.data[0] === 'user' ? userInfoVar()?.name : 'AI봇',
                      created: moment().format('YYYY-MM-DD HH:mm:ss.sss'),
                    });
                    if (res?.data?.getMessage?.data[0]?.metadata.type !== 'prompt') {
                      if (filtered_data?.audio_url) {
                        setAudioUrl(filtered_data?.audio_url);
                      }
                      setChatList([...chatList, ...result]);
                    }
                    let getTTSText = result.reduce((acc, ele) => {
                      return acc + ele.content;
                    }, '');
                    speak(
                      getTTSText,
                      () => {
                        setMicValue('robot.png');
                      },
                      audioPaths.map((item) => item.path),
                    );
                    if (pauseStop?.stop || pauseStop?.pause) {
                      setDisableBtn(true);
                    } else {
                      setDisableBtn(false);
                    }
                  });
                }
              } else {
                console.log('error');
              }
            });
          } else {
            console.log('error');
          }
        });
      }
    }

    if (retrieveRunData?.retrieveRun?.status === 'completed') {
      if (msgIdx) {
        const formVal = formControl.getFieldValue();
        updateAiRequestResponse({
          variables: {
            topic: formVal?.topic,
            prompt: currentText,
            thread_id: testIdx?.test_thread_idx,
            tokens: JSON.stringify(retrieveRunData?.retrieveRun?.usage),
            top_p: 1,
            userTopicId: parseInt(idx),
            company_idx: userInfoVar()?.campus?.fc_company_idx,
            userType: userInfoVar()?.type,
            open_ai_message: gptModel,
          },
        });
        getMsg({
          variables: {
            thread_id: testIdx?.test_thread_idx,
            message_id: msgIdx,
          },
        }).then((res) => {
          const parseRes = parseMessage(
            res.data?.getMessage?.data.reduce((acc, ele) => {
              let temp = ele.content.concat(acc);
              return temp;
            }, []),
          );
          const result = mapContentData(parseRes, {
            user: userInfoVar()?.id,
            chat_user_id: res.data?.getMessage?.data[0] === 'user' ? userInfoVar()?.id : 'AI봇',
            chat_user_name: res.data?.getMessage?.data[0] === 'user' ? userInfoVar()?.name : 'AI봇',
            created: moment().format('YYYY-MM-DD HH:mm:ss.sss'),
          });
          if (res?.data?.getMessage?.data[0]?.metadata.type !== 'prompt') {
            if (parseRes?.audio_url) {
              setAudioUrl(parseRes?.audio_url);
            }
            setChatList([...chatList, ...result]);
          }
          let getTTSText = result.reduce((acc, ele) => {
            return acc + ele.content;
          }, '');
          speak(
            getTTSText,
            () => {
              setMicValue('robot.png');
            },
            audioPaths.map((item) => item.path),
          );
          if (pauseStop?.stop || pauseStop?.pause) {
            setDisableBtn(true);
          } else {
            setDisableBtn(false);
          }
        });
      }
    }

    return () => clearInterval(interval);
  }, [retrieveRunData]);

  const startRecord = () => {
    if (recordAudio.current) return;
    setSoundPlay(true);
    setSound(recordingStartSound);
    captureUserMedia({ audio: { echoCancellation: true } }, async (stream) => {
      recorderStreams.current = stream;
      recordAudio.current = RecordRTC(stream, {
        type: 'audio',
        mimeType: 'audio/webm',
        sampleRate: 48000,
        desiredSampRate: 16000,

        recorderType: StereoAudioRecorder,
        numberOfAudioChannels: 1,

        //1)
        // get intervals based blobs
        // value in milliseconds
        // as you might not want to make detect calls every seconds
        timeSlice: 100,

        // 2)
        // as soon as the stream is available
        ondataavailable: onDataAvailableCallback,
      });
      setLastBlob(null);
      recordAudio.current.startRecording();
    });
  };

  const handleReRecord = () => {
    if (autoSendRef.current.length) {
      clearTimeout(autoSendRef.current[0]);
      autoSendRef.current = [];
    }
    if (recordAudio.current) {
      stopRecording();
    }
    setMicValue('stop2x.png');
    setDisableBtn(false);
    setSoundPlay(true);
    setSound(clickSound);
    setMicBtnClick(0);
    setActive(true);
    setMicBtnChange(false);
    setRecordText('');
    setCurrentText('');
    setLastBlob(null);
    setStartRecording(true);
    startRecord();
  };

  const stopRecording = (val) => {
    if (recordAudio?.current != null) {
      recordAudio?.current?.stopRecording(async () => {
        let reader = new FileReader();

        reader.onload = async function (e) {
          processRecordCallback(recordAudio?.current?.getBlob(), val, (text) => {
            autoSendRef.current = [
              setTimeout(() => {
                onSubmit(text);
              }, 5000),
              new Date(),
            ];
          });
          setLastBlob(recordAudio.current.toURL());
          recordAudio?.current.destroy();
          recordAudio.current = null;
          if (recorderStreams?.current) {
            recorderStreams?.current?.getTracks().forEach((track) => track.stop());
            recorderStreams.current = null;
          }
        };

        try {
          reader?.readAsArrayBuffer(recordAudio?.current?.getBlob());
        } catch (error) {
          console.log('error', error);
        }
      });
    }
  };

  const onSubmit = async (text) => {
    if (autoSendRef.current.length) {
      clearTimeout(autoSendRef.current[0]);
      autoSendRef.current = [];
    }
    if (recordAudio?.current) {
      stopRecording('send-text');
    }
    // setLastUserMsg(tabVal?.question)
    setSoundPlay(true);
    setSound(clickSound);
    setMicValue('mice_3.png');
    setDisableBtn(true);
    openAiMessage({
      variables: {
        thread_id: testIdx?.test_thread_idx,
        role: 'user',
        content: text !== '' ? text : currentText !== '' ? currentText : tabVal?.question || tabVal?.chat || tabVal?.debate,
      },
    }).then((res) => {
      const tmpChat = [];
      const array = {
        user: userInfoVar()?.id,
        chat_user_id: res?.data?.openaiMessage?.role == 'user' ? userInfoVar()?.id : 'AI봇',
        chat_user_name: res?.data?.openaiMessage?.role == 'user' ? userInfoVar()?.name : 'AI봇',
        content: res?.data?.openaiMessage?.content[0]?.text?.value,
        created: moment().format('YYYY-MM-DD HH:mm:ss.sss'),
      };
      tmpChat.push(array);
      setChatList([...chatList, ...tmpChat]);
      if (res?.data?.openaiMessage?.id) {
        setMsgIdx(res?.data?.openaiMessage?.id);
        createRun({
          variables: {
            assistant_id: testIdx?.test_assistant_idx,
            thread_id: testIdx?.test_thread_idx,
            image_audio: JSON.stringify(imageAudio),
            model: gptModel,
          },
        }).then((result) => {
          if (result?.data?.createRuns?.id) {
            setTestIdx((prevState) => ({
              ...prevState,
              test_run_idx: result?.data?.createRuns?.id,
            }));
            // getSteps({
            //   variables: {
            //     thread_id: testIdx?.test_thread_idx,
            //     run_id: result?.data?.createRuns?.id,
            //   },
            // });
            retrieveRun({
              variables: {
                thread_id: testIdx?.test_thread_idx,
                run_id: result?.data?.createRuns?.id,
                group_id: parseInt(idx),
                topic_id: topicIdx,
              },
            });
          }
        });
      }
    });
    setCurrentText('');
    // setTabVal("")
    setRecordText('');
  };

  const handleMicBtnClick = () => {
    setMicValue('mice_3.png');
    setDisableBtn(true);
    setSoundPlay(true);
    setSound(clickSound);
    setMicBtnClick(micBtnClick + 1);
    if (!active) {
      setActive(true);
      handlePromptClick('main');
      setDisableBtnVal(false);
      setMicBtnChange(false);
    } else if (recordAudio.current) {
      stopRecording();
      setStartRecording(false);
    } else {
      startRecord();
      setStartRecording(true);
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedList = Array.from(contentList);
    const [removed] = reorderedList.splice(result.source.index, 1);
    reorderedList.splice(result.destination.index, 0, removed);
    setContentList(reorderedList);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (testIdx?.test_assistant_idx !== '') {
        setDisableControl(true);
      }
      if (testIdx?.test_vector_idx !== '') {
        await getVectorFiles({
          variables: {
            vector_idx: testIdx?.test_vector_idx,
          },
        });
      }
    };

    fetchData();
  }, [testIdx]);

  useEffect(() => {
    if (audioPaths?.length) {
      const formattedAudio = audioPaths.map((file) => ({
        ...file,
        name: formatFileName(file.name),
      }));
      setFormattedAudioPaths(formattedAudio);
    } else {
      setFormattedAudioPaths([]);
    }

    if (imagePaths?.length) {
      const formattedImage = imagePaths.map((file) => ({
        ...file,
        name: formatFileName(file.name),
      }));
      setFormattedImagePaths(formattedImage);
    } else {
      setFormattedImagePaths([]);
    }
  }, [audioPaths, imagePaths]);

  const formatFileName = (name) => {
    const extension = name.split('.').pop();
    const baseName = name.substring(0, name.length - extension.length - 1);
    if (baseName.length > 8) {
      const firstPart = baseName.slice(0, 4);
      const lastPart = baseName.slice(-4);
      return `${firstPart}....${lastPart}.${extension}`;
    }
    return name;
  };

  // useEffect(() => {
  //     if (testIdx?.test_assistant_idx !== "" && testIdx?.test_thread_idx !== "") {
  //         setDisableControl(true)
  //     }
  //     // if (testIdx?.test_vector_idx !== "") {
  //     //     await getVectorFiles({
  //     //         variables: {
  //     //             vector_idx: testIdx?.test_vector_idx
  //     //         }
  //     //     })
  //     // }
  // }, [testIdx])

  useEffect(() => {
    if (talkingDetail !== undefined) {
      let tmp = [];
      if (
        talkingDetail?.aiTalkingDetail?.ai_topic_detail?.length !== 0 &&
        talkingDetail?.aiTalkingDetail?.ai_topic_detail[indx]?.data !== null
      ) {
        setTopicIdx(talkingDetail?.aiTalkingDetail?.ai_topic_detail[indx]?.idx);
      }
      talkingDetail?.aiTalkingDetail?.ai_topic_detail?.map((ele) => {
        let obj = JSON.parse(ele.data);
        setModalTabVal(obj?.book?.pre_instruction_prompt);
        setDupModalTabVal(obj?.book?.pre_instruction_prompt);

        obj.book['id'] = ele?.idx;
        obj.book['group_name'] = talkingDetail?.aiTalkingDetail.group_name;
        obj.book['ai_talking_group_idx'] = talkingDetail?.aiTalkingDetail.idx;
        obj.book['level'] = talkingDetail?.aiTalkingDetail?.level;
        obj.book['audio_file_list'] = ele?.audio_file_list !== null && ele?.image_file_list !== '' ? ele?.audio_file_list : undefined;
        obj.book['image_file_list'] = ele?.image_file_list !== null && ele?.image_file_list !== '' ? ele?.image_file_list : undefined;
        obj.book['audioFiles'] = ele?.audio_file_list !== null && ele?.audio_file_list !== '' ? JSON.parse(ele?.audio_file_list) : [];
        obj.book['imageFiles'] = ele?.image_file_list !== null && ele?.image_file_list !== '' ? JSON.parse(ele?.image_file_list) : [];
        tmp.push(obj.book);
      });
      if (tmp?.length > 0) {
        setContentList(tmp);
        formControl.setFieldsValue({
          group_name: tmp[0]?.group_name,
          //content_item: tmp[0]?.content ? tmp[0]?.content : tmp[0]?.content_item,
          title: tmp[0]?.title,
          topic: tmp[0]?.topic,
          level: talkingDetail?.aiTalkingDetail?.level ? talkingDetail?.aiTalkingDetail?.level : 'mx 01',
          ai_talking_group_idx: tmp[0]?.ai_talking_group_idx,
          id: tmp[0]?.id,
        });
        if (tmp[0]?.prompt?.question) {
          setTabVal({ question: tmp[0]?.prompt?.question });
          setTabIndex('1');
        }
        if (tmp[0]?.prompt?.chat) {
          setTabVal({ chat: tmp[0]?.prompt?.chat });
          setTabIndex('2');
        }
        if (tmp[0]?.prompt?.debate) {
          setTabVal({ debate: tmp[0]?.prompt?.debate });
          setTabIndex('3');
        }
        if (tmp[0]?.imageFiles.length > 0) {
          setImagePaths(
            tmp[0]?.imageFiles.reduce((acc, cur) => [...acc, { name: cur.split('/')[cur.split('/').length - 1], path: cur }], []),
          );
          setImageAudio((prevState) => ({
            ...prevState,
            image: true,
          }));
        }
        if (tmp[0]?.audioFiles.length > 0) {
          setAudioPaths(
            tmp[0]?.audioFiles.reduce((acc, cur) => [...acc, { name: cur.split('/')[cur.split('/').length - 1], path: cur }], []),
          );
          setImageAudio((prevState) => ({
            ...prevState,
            audio: true,
          }));
        }
      }
      if (talkingDetail?.aiTalkingDetail?.files_list !== null && talkingDetail?.aiTalkingDetail?.files_list !== '') {
        setFiledata(JSON.parse(talkingDetail?.aiTalkingDetail?.files_list));
      }
      const assistants = async () => {
        try {
          try {
            const fileList = JSON.parse(
              talkingDetail?.aiTalkingDetail?.files_list !== null && talkingDetail?.aiTalkingDetail?.files_list !== ''
                ? talkingDetail?.aiTalkingDetail?.files_list
                : '[]',
            );
            if (talkingDetail?.aiTalkingDetail?.assistants_id) {
              setTestIdx((prevState) => ({
                ...prevState,
                test_assistant_idx: talkingDetail?.aiTalkingDetail?.assistants_id,
                test_vector_idx: talkingDetail?.aiTalkingDetail?.vector_idx,
              }));
              // await createThread({
              //   variables: {
              //     idx: undefined,
              //   },
              // }).then((res) => {
              //   setTestIdx((prevState) => ({
              //     ...prevState,
              //     test_thread_idx: res?.data?.createThread?.id,
              //   }));
              // });
              let assistants_data = JSON.parse(talkingDetail?.aiTalkingDetail?.assistants_data);
              setGptModel(assistants_data?.model);
            } else {
              const { data } = await createAssistants({
                variables: {
                  files: JSON.stringify(fileList),
                  instructions: instructions,
                  name: contentList?.length ? contentList[0].group_name : '',
                  model: gptModel,
                },
              });
              if (data?.createAssistants) {
                const assistantData = JSON.parse(data?.createAssistants);
                setTestIdx((prevState) => ({
                  ...prevState,
                  test_assistant_idx: assistantData?.id,
                }));
                // await createThread({
                //   variables: {
                //     idx: undefined,
                //   },
                // }).then((res) => {
                //   setTestIdx((prevState) => ({
                //     ...prevState,
                //     test_thread_idx: res?.data?.createThread?.id,
                //   }));
                // });
              }
            }
          } catch (error) {
            console.error('Error creating assistants:', error);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      assistants();
    }
  }, [talkingDetail]);

  useEffect(() => {
    if (level !== -1) {
      setPg({ current: 0, pageSize: 10 });
    }
    setPg({ ...pg, current: 0 });
  }, [folderType, topicType, level]);

  const handleFormSubmit = () => {
    try {
      setSoundPlay(true);
      setSound(clickSound);
      if (recordAudio.current) {
        stopRecording();
      }
      const fieldValue = formControl?.getFieldValue();
      if (
        fieldValue?.title !== undefined &&
        fieldValue?.topic !== undefined &&
        (tabVal == '' || tabVal?.question == '' || tabVal?.chat == '' || tabVal?.debate == '')
      ) {
        setPromptValidate(true);
        setFormSubmitClick(false);
      } else {
        setPromptValidate(false);
        setFormSubmitClick(false);
        formControl
          .validateFields()
          .then((formData) => {
            let updatedObject;
            const fieldValue = formControl?.getFieldValue();
            if (fieldValue?.id) {
              const indexToUpdate = contentList.findIndex((item) => item.id === fieldValue?.id);
              if (indexToUpdate !== -1) {
                const updatedObj = { ...contentList[indexToUpdate] };
                updatedObj.group_name = fieldValue.group_name;
                updatedObj.title = fieldValue.title;
                updatedObj.topic = fieldValue.topic;
                updatedObj.level = fieldValue.level;
                updatedObj.image_file_list = imagePaths?.length > 0 ? JSON.stringify(imagePaths.map((item) => item.path)) : undefined;
                updatedObj.audio_file_list = audioPaths?.length > 0 ? JSON.stringify(audioPaths.map((item) => item.path)) : undefined;
                updatedObj.prompt = tabVal;
                const updatedList = [...contentList];
                updatedList[indexToUpdate] = updatedObj;
                setContentList(updatedList);
              }
            } else {
              if (fieldValue?.title !== undefined && fieldValue.topic !== undefined) {
                const { ai_talking_group_idx, ...rest } = fieldValue;
                updatedObject = {
                  ...rest,
                  prompt: prompt == '' || prompt == undefined ? tabVal : prompt,
                  level: level,
                  image_file_list: imagePaths?.length > 0 ? JSON.stringify(imagePaths.map((item) => item.path)) : undefined,
                  audio_file_list: audioPaths?.length > 0 ? JSON.stringify(audioPaths.map((item) => item.path)) : undefined,
                };
                const updatedContentList = contentList.map((item) => ({
                  ...item,
                  group_name: fieldValue.group_name,
                }));

                updatedContentList[indx] = updatedObject;

                if (updatedObject !== undefined) {
                  setContentList([...updatedContentList]);
                }
              } else {
                setPromptValidate(true);
              }
            }

            //formControl.resetFields()
            setSaveData(true);
          })
          .catch((errorInfo) => {
            console.error('errorInfo', errorInfo);
          });
      }
    } catch (e) {
      console.log(e);
    }
    // refetch()
  };
  useEffect(() => {
    if (currentText || recordText) {
      setDisableBtn(false);
    }
  }, [currentText]);

  useEffect(() => {
    const saveDataToAIGroup = async () => {
      // const mergedArray = [...filedata.filter(item => !item.uid), ...uploadedFile];
      const filteredRecords = filedata.filter((item) => item.id && !item.path);
      const mergedArray = [...filteredRecords, ...uploadedFile];
      if (saveData) {
        const { data } = await updateAssistants({
          variables: {
            files: JSON.stringify(mergedArray),
            instructions: modalTabVal?.modal_main_instruction,
            description: modalTabVal?.modal_main_description,
            name: contentList?.length ? contentList[0].group_name : '',
            assistant_id: testIdx?.test_assistant_idx,
            vector_id: testIdx?.test_vector_idx,
            image_file_list: JSON.stringify(imagePaths),
            audio_file_list: JSON.stringify(audioPaths),
            model: gptModel,
          },
        });
        if (data) {
          const assistant = JSON.parse(data?.updateAssistants);
          const updatedArray = contentList?.map((item) => ({
            ...item,
            pre_instruction_prompt: modalTabVal,
          }));
          await createAIGroupTopic({
            variables: {
              folder_type: folderType,
              type: 'ai-talking-tutor',
              data: JSON.stringify(updatedArray),
              files_list: JSON.stringify(uploadedFile),
              assistants_data: JSON.stringify(assistant),
              assistants_id: testIdx?.test_assistant_idx,
            },
          }).then((result) => {
            formControl.resetFields();
            if (btnSaveTest) {
              window.location.reload();
              // history.push(`/ai-talking-tutor/edit/${result.data.createAIGroupTopic}`);
              // refetchTalking();
              // setBtnSaveTest(false)
            } else {
              history.push('/ai-talking-tutor/topiclist');
            }
          });
          refetch();
        }
      }
    };
    saveDataToAIGroup();
  }, [saveData]);

  const handlePromptClick = async (val) => {
    let prmpt;
    let recording;
    if (val == 'main') {
      prmpt = PROMPTS[0];
    } else {
      prmpt = val;
    }
    if (recording) {
      stopRecording(recording);
      setStartRecording(false);
      setCurrentUrl('');
      stop();
    }

    let threadIdx = testIdx?.test_thread_idx;

    if (!threadIdx) {
      let res = await createThread({
        variables: {
          idx: undefined,
        },
      });
      threadIdx = res?.data?.createThread?.id;
      setTestIdx((prevState) => ({
        ...prevState,
        test_thread_idx: threadIdx,
      }));
    }

    if (micBtnClick === 0) {
      if (prmpt !== STOP || prmpt !== PAUSE) {
        setPauseStop({ pause: false, stop: false });
      }
      let finalPrmpt;
      if (pauseStop?.pause === true || pauseStop?.stop === true) {
        finalPrmpt = RESTART;
      } else if (tabIndex == '1') {
        finalPrmpt = tabVal?.question !== undefined ? modalTabVal?.modal_question + ' ' + tabVal?.question : modalTabVal?.modal_question;
      } else if (tabIndex == '2') {
        finalPrmpt = tabVal?.chat !== undefined ? modalTabVal?.modal_chat + ' ' + tabVal?.chat : modalTabVal?.modal_chat;
      } else {
        finalPrmpt = tabVal?.debate !== undefined ? modalTabVal?.modal_debate + ' ' + tabVal?.debate : modalTabVal?.modal_debate;
      }
      openAiMessage({
        variables: {
          thread_id: threadIdx,
          role: 'user',
          content: finalPrmpt,
          metadata: { type: 'prompt' },
        },
      }).then((res) => {
        if (res?.data?.openaiMessage?.id) {
          setMsgIdx(res?.data?.openaiMessage?.id);
          createRun({
            variables: {
              assistant_id: testIdx?.test_assistant_idx,
              thread_id: threadIdx,
              image_audio: JSON.stringify(imageAudio),
              model: gptModel,
            },
          }).then((result) => {
            if (result?.data?.createRuns?.id) {
              // setRunIdx(result?.data?.createRuns?.id)
              setTestIdx((prevState) => ({
                ...prevState,
                test_run_idx: result?.data?.createRuns?.id,
              }));
              // getSteps({
              //   variables: {
              //     thread_id: testIdx?.test_thread_idx,
              //     run_id: result?.data?.createRuns?.id,
              //   },
              // });
              retrieveRun({
                variables: {
                  thread_id: threadIdx,
                  run_id: result?.data?.createRuns?.id,
                  group_id: parseInt(idx),
                  topic_id: topicIdx,
                },
              });
            }
          });
        }
      });
      // setTabVal("")
    } else {
      if (prmpt !== STOP || prmpt !== PAUSE) {
        setPauseStop({ pause: false, stop: false });
      }
      openAiMessage({
        variables: {
          thread_id: threadIdx,
          role: 'user',
          content: prmpt,
          metadata:
            prmpt == STOP || prmpt == PAUSE || prmpt == REPEAT || prmpt == TO_LESSON
              ? {
                  type: 'prompt',
                }
              : {},
        },
      }).then((res) => {
        const tmpChat = [];
        const aiResponse = res?.data?.openaiMessage?.content[0]?.text?.value;
        if (TO_LESSON == aiResponse || PAUSE == aiResponse || STOP == aiResponse || REPEAT == aiResponse) {
        } else {
          const array = {
            user: userInfoVar()?.id,
            chat_user_id: res?.data?.openaiMessage?.role == 'user' ? userInfoVar()?.id : 'AI봇',
            chat_user_name: res?.data?.openaiMessage?.role == 'user' ? userInfoVar()?.name : 'AI봇',
            content: res?.data?.openaiMessage?.content[0]?.text?.value,
            created: moment().format('YYYY-MM-DD HH:mm:ss.sss'),
          };
          tmpChat.push(array);
          setChatList([...chatList, ...tmpChat]);

          speak(
            res?.data.openaiMessage?.content[0]?.text.value,
            () => {
              setMicValue('robot.png');
            },
            audioPaths.map((item) => item.path),
          );
        }

        if (res?.data?.openaiMessage?.id) {
          setMsgIdx(res?.data?.openaiMessage?.id);
          createRun({
            variables: {
              assistant_id: testIdx?.test_assistant_idx,
              thread_id: threadIdx,
              image_audio: JSON.stringify(imageAudio),
              model: gptModel,
            },
          }).then((result) => {
            if (result?.data?.createRuns?.id) {
              setTestIdx((prevState) => ({
                ...prevState,
                test_run_idx: result?.data?.createRuns?.id,
              }));
              // getSteps({
              //   variables: {
              //     thread_id: testIdx?.test_thread_idx,
              //     run_id: result?.data?.createRuns?.id,
              //   },
              // });
              retrieveRun({
                variables: {
                  thread_id: threadIdx,
                  run_id: result?.data?.createRuns?.id,
                  group_id: parseInt(idx),
                  topic_id: topicIdx,
                },
              });
            }
          });
        }
      });
      // setTabVal("")
      setCurrentText('');
      setRecordText('');
    }
    formControl.resetFields(['user_content', 'message']);
  };

  const handlePlayEnd = () => {
    if (audioUrl) {
      setCurrentUrl(audioUrl);
      if (micBtnChange) {
        setMicValue('mice_1.png');
      } else {
        if (!audioUrl) {
          setMicValue('stop2x.png');
        }
      }
      if (pauseStop?.stop || pauseStop?.pause) {
        setDisableBtn(true);
      } else {
        setDisableBtn(false);
      }
      setAudioUrl('');
      if (!micBtnChange && !audioUrl) {
        startRecord();
        setStartRecording(true);
      }
    } else {
      console.log('micBtnChange', micBtnChange);
      if (micBtnChange) {
        setMicValue('mice_1.png');
      } else {
        setMicValue('stop2x.png');
      }
      if ((pauseStop?.stop || pauseStop?.pause) && disableBtnVal) {
        setDisableBtn(true);
      } else {
        setDisableBtn(false);
      }
      setCurrentUrl('');
      if (!micBtnChange) {
        console.log('go insie');
        startRecord();
        setStartRecording(true);
      }
    }
  };

  useEffect(() => {
    if (!isSpeaking && texts.length > 0) {
      handlePlayEnd();
    }
  }, [isSpeaking, texts]);

  const handleSoundEnd = () => {
    setSound(undefined);
    setSoundPlay(false);
    if (cancelBtnClick) {
      history.goBack();
    }
  };

  const handleUploadBook = async (file) => {};

  const handleUplaod = (data) => {
    console.log('data', data);
    return new Promise(async (resolve, reject) => {
      try {
        const { name: filename, type: mimetype } = data;
        let res = await uploadFileS3({
          variables: {
            name: `ai-talking-tutor/${testIdx?.test_assistant_idx}/${indx}/${filename}`,
            contentType: mimetype,
            replace: true,
          },
        });
        await fetch(res.data.createSignedS3Upload, {
          method: 'PUT',
          body: data,
          headers: {
            'Content-Type': mimetype,
            'x-amz-acl': 'public-read',
          },
        });

        const s3Url = res.data.createSignedS3Upload.split('?')[0].replace('s3.ap-northeast-2.amazonaws.com/', '');
        console.log('mimetype', mimetype);
        if (mimetype.startsWith('audio')) {
          setAudioPaths((prevaudioPaths) => {
            const updatedaudioPaths = [...prevaudioPaths, { name: filename, path: s3Url }];
            return updatedaudioPaths;
          });
        } else {
          setImagePaths((prevaudioPaths) => {
            const updatedImagePaths = [...prevaudioPaths, { name: filename, path: s3Url }];
            return updatedImagePaths;
          });
        }
        setTimeout(() => {
          resolve(s3Url);
        }, 1000);

        // setUploadFile(data.name)
      } catch (e) {
        reject(e);
      }
    });
  };

  const handleFileSelect = async (data) => {
    if (data.length !== contentList.length) {
      return message.error('list length is not equal to file length');
    }

    let tempdata = contentList.map((item, index) => {
      return {
        ...item,
        title: data[index].title,
        topic: data[index].topic,
        prompt: data[index].prompt,
        level: data[index].level,
        audio_file_list: item.audio_file_list ? item.audio_file_list : undefined,
        image_file_list: item.image_file_list ? item.image_file_list : undefined,
        audioFiles: item.audioFiles ? item.audioFiles : [],
        imageFiles: item.imageFiles ? item.imageFiles : [],
      };
    });

    try {
      await createAIGroupTopic({
        variables: {
          folder_type: folderType,
          type: 'ai-talking-tutor',
          data: JSON.stringify(tempdata),
          files_list: JSON.stringify(uploadedFile),
          assistants_data: talkingDetail?.aiTalkingDetail?.assistants_data,
          assistants_id: talkingDetail?.aiTalkingDetail?.assistants_id,
        },
      }).then((result) => {
        window.location.reload();
      });
    } catch (e) {
      console.log(e);
    }

    refetch();
  };
  const handleContentList = () => {
    const randomValue = Math.floor(Math.random() * 100) + 1;
    const formVal = formControl?.getFieldValue();
    if (tabVal == '' || tabVal?.question == '' || tabVal?.chat == '' || tabVal?.debate == '') {
      setPromptValidate(true);
    } else {
      const newData = {
        group_name: formVal.group_name,
        title: '',
        topic: '',
        prompt: {
          question: '',
          chat: '',
          debate: '',
        },
        level: formVal.level,
        imageFiles: [],
        audioFiles: [],
      };
      if (formVal?.id) {
        const indexToUpdate = contentList.findIndex((item) => item.id === formVal?.id);
        if (indexToUpdate !== -1) {
          const updatedObj = { ...contentList[indexToUpdate] };
          updatedObj.group_name = formVal.group_name;
          updatedObj.title = formVal.title;
          updatedObj.topic = formVal.topic;
          updatedObj.prompt = tabVal;
          updatedObj.level = formVal.level;
          updatedObj.image_file_list = imagePaths?.length > 0 ? JSON.stringify(imagePaths.map((item) => item.path)) : undefined;
          updatedObj.audio_file_list = audioPaths?.length > 0 ? JSON.stringify(audioPaths.map((item) => item.path)) : undefined;
          const updatedList = [...contentList];
          updatedList[indexToUpdate] = updatedObj;
          setContentList([...updatedList, newData]);
          formControl.resetFields(['title', 'topic', 'prompt', 'id', 'level']);
        }
      } else {
        if (formVal?.title !== undefined || formVal?.topic !== undefined) {
          const { ai_talking_group_idx, ...rest } = formVal;
          let updatedObj;
          updatedObj = {
            ...rest,
            prompt: tabVal,
            id: randomValue,
            level: level,
            image_file_list: imagePaths?.length > 0 ? JSON.stringify(imagePaths.map((item) => item.path)) : undefined,
            audio_file_list: audioPaths?.length > 0 ? JSON.stringify(audioPaths.map((item) => item.path)) : undefined,
          };
          setContentList([...contentList, updatedObj]);
        }
        formControl.resetFields(['title', 'topic', 'prompt', 'level']);
      }
      setIndx(contentList.length);
      setLevel('1');
      setTabVal('');
      setGroupDisable(true);
      setTabIndex('1');
      setAddMore(false);
      setImagePaths([]);
      setAudioPaths([]);
    }
  };

  const handlePromptChange = (data) => {
    if (data?.target?.value === '' || data?.target?.value === undefined || data?.target?.value === null) {
      setPromptValidate(true);
      setFormSubmitClick(false);
    } else {
      setPromptValidate(false);
      setFormSubmitClick(false);
      // stopRecording('tab-change')
    }

    if (micBtnClick > 0) {
      setCurrentText(data?.target.value);
    }
    setTabVal({
      [data.target.name]: data?.target.value,
    });

    if (indx <= contentList.length - 1) {
      setContentList((prevState) => {
        const updatedList = [...prevState];
        updatedList[indx].prompt = {
          ...updatedList[indx].prompt,
          [data.target.name]: data?.target.value,
        };
        return updatedList;
      });
    }
  };
  const handleSetting = () => {
    setSoundPlay(true);
    setSound(clickSound);
    setShowModal(true);
  };

  const ExcelUpload = ({ onFileSelect, setLoading }) => {
    const [topics, setTopics] = useState([]);
    const [groupLevel, setGroupLevel] = useState('');

    useEffect(() => {
      if (topics.length > 0) {
        onFileSelect(
          topics.map((item) => {
            item['group_name'] = groupLevel.group_name;
            item['level'] = groupLevel.level;
            return { ...item };
          }),
        );
      }
      // eslint-disable-next-line
    }, [topics]);

    const props = {
      multiple: false,
      accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      onChange: (info) => {
        const file = info.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            /* Parse data */
            //https://stackoverflow.com/questions/53163552/format-date-with-sheetjs
            const ab = e.target.result;
            const wb = XLSX.read(ab, { type: 'binary', cellText: false, cellDates: true });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            console.log('ws', wsname);
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, {
              header: ['group_name', 'level', 'title', 'topic', 'prompt', 'content'],
              raw: false,
              dateNF: 'yyyy-mm-dd',
            });
            if (data && data instanceof Array) {
              // setGroupName(data[1].group_name)
              setGroupLevel({
                group_name: data[1].group_name,
                level: data[1].level,
              });
              const filtered_data = data.slice(1);
              const updatedArray = filtered_data.map((item) => {
                let obj;

                if (item?.prompt?.startsWith('question:')) {
                  obj = { question: item.prompt.replace('question:', '') };
                } else if (item?.prompt?.startsWith('chat:')) {
                  obj = { chat: item.prompt.replace('chat:', '') };
                } else if (item?.prompt?.startsWith('debate:')) {
                  obj = { debate: item.prompt.replace('debate:', '') };
                }

                return {
                  ...item,
                  prompt: obj,
                };
              });
              setTopics(updatedArray);
            } else {
              alert('오류가 발생했습니다.');
            }
          };
          reader.readAsArrayBuffer(file);
        } else {
          setTopics([]);
        }
      },
    };
    return (
      <Input
        key={`finle-input-0`}
        type="file"
        {...props}
        name="files"
        style={{
          width: '200px',
          margin: '0 10px',
        }}
      />
    );
  };
  const handleTabChange = (val) => {
    setTabIndex(val);
    if (tabVal == '' || tabVal?.question == '' || tabVal?.chat == '' || tabVal?.debate == '') {
      if (formSubmitClick) {
        setPromptValidate(true);
      }
    } else {
      setPromptValidate(false);
      setFormSubmitClick(false);
    }
  };
  const handleListClick = (data, index) => {
    if (data?.id) {
      setTopicIdx(data?.id);
    }
    setIndx(index);
    inputRef.current.focus();
    formControl.setFieldsValue({
      group_name: data?.group_name,
      title: data?.title,
      topic: data?.topic,
      //content_item: data?.content_item ? data?.content_item : data?.content,
      id: data?.id ? data?.id : data?.idx,
      level: data?.level,
    });
    if (data?.prompt == undefined && data?.prompt == '') {
      setPromptValidate(true);
    } else {
      setPromptValidate(false);
    }

    if (data?.prompt?.question) {
      setTabVal({ question: data?.prompt?.question });
      setTabIndex('1');
    }
    if (data?.prompt?.chat) {
      setTabVal({ chat: data?.prompt?.chat });
      setTabIndex('2');
    }
    if (data?.prompt?.debate) {
      setTabVal({ debate: data?.prompt?.debate });
      setTabIndex('3');
    }
    setPrompt(data?.prompt);
    setTabVal(data?.prompt);
    let tempImageAudio = {
      image: false,
      audio: false,
    };
    let tempContentList = contentList;
    if (imagePaths?.length > 0) {
      tempContentList[indx].imageFiles = imagePaths.map((item) => item.path);
    }
    if (audioPaths?.length > 0) {
      tempContentList[indx].audioFiles = audioPaths.map((item) => item.path);
    }
    setContentList(tempContentList);
    if (data?.imageFiles?.length > 0) {
      setImagePaths(data?.imageFiles?.reduce((acc, cur) => [...acc, { name: cur.split('/')[cur.split('/').length - 1], path: cur }], []));
      tempImageAudio = {
        ...tempImageAudio,
        image: true,
      };
    } else {
      setImagePaths([]);
    }
    if (data?.audioFiles?.length > 0) {
      setAudioPaths(data?.audioFiles?.reduce((acc, cur) => [...acc, { name: cur.split('/')[cur.split('/').length - 1], path: cur }], []));
      tempImageAudio = {
        ...tempImageAudio,
        audio: true,
      };
    } else {
      setAudioPaths([]);
    }
    setImageAudio(tempImageAudio);

    setGroupDisable(false);
    setAddMore(true);
  };

  const handleClear = () => {
    setSoundPlay(true);
    setSound(clickSound);
    // filedata?.map(async (ele) => {
    //     await deleteFile({
    //         variables: {
    //             file_id: ele?.id
    //         }
    //     })
    // })
    // formControl.setFieldsValue({
    //     content_item: '',
    // });
    setClearBtn(true);
  };
  const handleClosClear = () => {
    setClearBtn(false);
  };
  const handleYes = async () => {
    setSoundPlay(true);
    setSound(clickSound);
    setChatList([]);
    setMicBtnClick(0);
    setClearBtn(false);
    setActive(false);
    stop();
    await createThread({
      variables: {
        idx: undefined,
      },
    }).then((res) => {
      setTestIdx((prevState) => ({
        ...prevState,
        test_thread_idx: res?.data?.createThread?.id,
      }));
      stopRecording('clear');
      setMicValue('mice_1.png');
      setStartRecording(false);
      setPauseStop({ pause: false, stop: false });
    });
  };

  const handleNo = () => {
    setSoundPlay(true);
    setSound(clickSound);
    setClearBtn(false);
  };

  const uploadBooks = async () => {
    const fileList = filedata?.filter((ele) => !ele?.id);
    if (fileList?.length > 0) {
      const { data } = await uploadOpenAiFile({
        variables: {
          files: fileList,
        },
      });
      setUploadedFile(
        data?.uploadOpenAIFile.map((file) => {
          return {
            name: file.filename,
            id: file.id,
          };
        }),
      );
    }
  };
  const handleRemove = async (val) => {
    await deleteFile({
      variables: {
        idx: parseInt(idx),
        file_id: val?.id,
      },
    }).then((ele) => {
      deleteFileFromVector({
        variables: {
          vector_idx: testIdx?.test_vector_idx,
          file_id: val?.id,
        },
      });
      if (ele?.data?.deleteOpenAIUploadedFile) {
        const uploadTmp = uploadedFile;
        const filtered_data = uploadTmp?.filter((e) => e.id !== val.id);
        setUploadedFile(filtered_data);
        const tmp = filedata;
        const fileData = tmp?.filter((e) => e.id !== val.id);
        setFiledata(fileData);
      }
    });
  };

  const handleRemoveImage = (val) => {
    const filtered_data = imagePaths?.filter((e) => e.path !== val.path);
    setImagePaths(filtered_data);
  };
  const handleRemoveAudio = (val) => {
    const filtered_data = audioPaths?.filter((e) => e.path !== val.path);
    setAudioPaths(filtered_data);
  };

  const handleRemoveTopic = (index) => {
    setSoundPlay(true);
    setSound(clickSound);
    if (index >= 0 && index < contentList.length) {
      const newContentList = [...contentList];
      newContentList.splice(index, 1);
      setContentList(newContentList);
    }
  };
  const handleModalFormSubmit = () => {
    setShowModal(false);
    setDupModalTabVal(modalTabVal);
  };
  const handleModalTabChange = (val) => {
    setModalTabIndex(val);
  };
  const handleModalPromptChange = (data) => {
    if (data.target.name === 'modal_main_instruction') {
      setModalTabVal((prevState) => ({
        ...prevState,
        modal_main_instruction: data?.target.value,
      }));
    }

    if (data.target.name === 'modal_main_description') {
      setModalTabVal((prevState) => ({
        ...prevState,
        modal_main_description: data?.target.value,
      }));
    }

    if (data.target.name === 'modal_question') {
      setModalTabVal((prevState) => ({
        ...prevState,
        modal_question: data?.target.value,
      }));
    }
    if (data.target.name === 'modal_chat') {
      setModalTabVal((prevState) => ({
        ...prevState,
        modal_chat: data?.target.value,
      }));
    }
    if (data.target.name === 'modal_debate') {
      setModalTabVal((prevState) => ({
        ...prevState,
        modal_debate: data?.target.value,
      }));
    }
  };
  const handleModalClose = () => {
    setModalTabVal(dupModalTabVal);
    setShowModal(false);
  };
  const handleUploadComplete = async (e) => {
    console.log('testIdx', testIdx);

    if (!isUploadInProgress && e?.fileList && e.fileList.length > 0) {
      isUploadInProgress = true;
      try {
        const fileList = e?.fileList?.filter((ele) => !ele?.id);
        const existingFile = e?.fileList?.filter((ele) => ele?.id);
        if (fileList?.length > 0) {
          const { data } = await uploadOpenAiFile({
            variables: {
              files: fileList,
            },
          });
          const recentUpload = data?.uploadOpenAIFile.map((file) => {
            return {
              name: file.filename,
              id: file.id,
            };
          });

          const fileIds = data?.uploadOpenAIFile?.map((ele) => {
            return ele.id;
          });
          if (testIdx?.test_vector_idx == '' || testIdx?.test_vector_idx == null) {
            await createVector({
              variables: {
                uploadedFileIdx: fileIds,
              },
            }).then(async (res) => {
              setTestIdx((prevState) => ({
                ...prevState,
                test_vector_idx: res?.data?.createVector?.id,
              }));
              if (res?.data?.createVector?.id) {
                await uploadFileInVectorStore({
                  variables: {
                    vector_store_idx: res?.data?.createVector?.id,
                    file_ids: fileIds,
                  },
                }).then((result) => {
                  if (result?.data?.uploadFileInVectorStore) {
                    setUploadedFile((prevUploadedFile) => {
                      const newData = data?.uploadOpenAIFile.map((file) => ({
                        name: file.filename,
                        id: file.id,
                      }));
                      return [...(prevUploadedFile || []), ...(newData || [])];
                    });
                  }
                });
              }
            });
          } else {
            await uploadFileInVectorStore({
              variables: {
                vector_store_idx: testIdx?.test_vector_idx,
                file_ids: fileIds,
              },
            }).then((result) => {
              if (result?.data?.uploadFileInVectorStore) {
                setUploadedFile((prevUploadedFile) => {
                  const newData = data?.uploadOpenAIFile.map((file) => ({
                    name: file.filename,
                    id: file.id,
                  }));
                  return [...(prevUploadedFile || []), ...(newData || [])];
                });
              }
            });
          }
          const combinedFile = existingFile ? [...existingFile, ...recentUpload] : recentUpload;

          setFiledata(combinedFile);
          // setUploadedFile(data?.uploadOpenAIFile.map(file => {
          //     return {
          //         name: file.filename,
          //         id: file.id
          //     };
          // }))
        }
        // const { data } = await uploadOpenAiFile({
        //     variables: {
        //         files: e.fileList
        //     }
        // });
        // setUploadedFile(data?.uploadOpenAIFile.map(file => ({
        //     name: file.filename,
        //     id: file.id
        // })));
      } catch (error) {
        console.error('Error uploading files:', error);
      } finally {
        isUploadInProgress = false;
      }
    }
  };
  const handleRecording = (val) => {
    setStartRecording(false);
    setCurrentUrl('');
    stop();
    setMicValue('mice_3.png');
    setDisableBtn(true);
    handlePromptClick(val);
    setCurrentText('');
    stopRecording(val);
    if (val == PAUSE || val == STOP) {
      setMicBtnClick(0);
      setActive(false);
      setMicBtnChange(true);
    }
    if (val == PAUSE) {
      setPauseStop((prevState) => ({
        ...prevState,
        pause: true,
      }));
      let lastAiMsg = null;
      if (chatList !== undefined && chatList) {
        for (let i = chatList.length - 1; i >= 0; i--) {
          const msg = chatList[i];
          if (msg['chat_user_name'] === 'AI봇') {
            lastAiMsg = msg;
            break;
          }
        }
      }
      setLastUserMsg(lastAiMsg);
    }

    if (val == STOP) {
      setPauseStop((prevState) => ({
        ...prevState,
        stop: true,
      }));
    }

    setSoundPlay(true);
    setSound(clickSound);
  };

  const handleCancel = () => {
    setSoundPlay(true);
    setSound(clickSound);
    setCancelBtnClick(true);
  };
  const handleKeyDown = (e) => {
    e.preventDefault();
  };

  const handleGptModel = async () => {
    let tempgptModel = gptModel === 'gpt-4o' ? 'gpt-4o-mini' : 'gpt-4o';
    await updateAssistants({
      variables: {
        assistant_id: testIdx?.test_assistant_idx,
        model: tempgptModel,
      },
    });

    setBtnSaveTest(true);
    setFormSubmitClick(true);
    formControl.submit();

    setGptModel(tempgptModel);
  };
  return (
    <>
      <div className="add-header">
        <h2>Add/Edit Content</h2>
        <Switch checkedChildren="gpt-4o" unCheckedChildren="gpt-4o-mini" checked={gptModel === 'gpt-4o'} onChange={handleGptModel} />
        <div className="right-section">
          <div className="upload-btn">
            <img src="/images/talking-tutor/upload.png" alt="upload-btn" />
            <ExcelUpload onFileSelect={handleFileSelect} setLoading={setLoading} />
          </div>

          <Button type="primary">
            <img src="/images/talking-tutor/close-icon.png" alt="close-arrow" onClick={() => history.goBack()} />
          </Button>
        </div>
      </div>
      {createLoading ? (
        <Loading />
      ) : (
        <>
          <Form
            labelCol={{
              span: 0,
            }}
            wrapperCol={{
              span: 24,
            }}
            layout="vertical"
            form={formControl}
            onFinish={handleFormSubmit}
          >
            <div className="add-header-field">
              <div className="main-row cust-row cust-one">
                <Form.Item
                  name="group_name"
                  label="Assignment Group Name"
                  style={{ marginBottom: 5 }}
                  rules={[{ required: false, message: 'Please Enter Group Name' }]}
                >
                  <Input name="group_name" disabled={groupDisable} ref={inputRef} />
                </Form.Item>
                <Form.Item name="level" label="Level" style={{ marginBottom: 5 }} className="select-form-control">
                  <Select
                    className="select-form-control"
                    onChange={(e) => setLevel(e)}
                    placeholder="Select Level"
                    defaultValue={level}
                    disabled={groupDisable}
                  >
                    {levelList.map((e) => {
                      return <Option value={`${e}`}>{e === 0 ? 'PH' : `${isNaN(e) ? '' : 'mx'} ${e}`}</Option>;
                    })}
                  </Select>
                </Form.Item>
              </div>
              {/* <div className='main-row cust-row cust-two'>
                                {userInfoVar()?.type === 5 ?
                                    <Form.Item name="content_list" label="Content List" style={{ marginBottom: 5 }} className='select-form-control'>
                                        <Select
                                            style={{ width: 164, color: '#065aa9' }}
                                            defaultValue={contentIdx}
                                            placeholder="폴더 유형 선택"
                                            optionFilterProp="children"
                                            onChange={(val) => setContentIdx(val)}
                                        >
                                            {getContentList?.getContentList?.map((item) => {
                                                return (
                                                    <>
                                                        <Select.Option value={item.idx}>{item?.content_name}</Select.Option>
                                                    </>
                                                )
                                            })}

                                        </Select>
                                    </Form.Item>
                                    :
                                    <Form.Item name="acess_level" label="Content Folder" style={{ marginBottom: 5 }} className='select-form-control'>
                                        <Select
                                            style={{ width: 150, color: '#065aa9' }}
                                            defaultValue={folderType}
                                            placeholder="폴더 유형 선택"
                                            optionFilterProp="children"
                                            onChange={(val) => setFolderType(val)}
                                        >
                                            <Select.Option value="publisher">Company</Select.Option>
                                            <Select.Option value="campus">Campus</Select.Option>
                                            <Select.Option value="me">me</Select.Option>
                                        </Select>
                                    </Form.Item>
                                }
                            </div> */}
            </div>
            {/* <div className='main-row cust-row'>
                            <Form.Item name="group_name" label="Assignment Group Name" style={{ marginBottom: 5 }} rules={[{ required: false, message: 'Please Enter Group Name' }]}>
                                <Input name="group_name" disabled={groupDisable} />
                            </Form.Item>
                            <Form.Item name="level" label="Level" style={{ marginBottom: 5 }} className='select-form-control'>
                                <Select className='select-form-control' onChange={(e) => setLevel(e)} placeholder="Select Level" defaultValue={level} disabled={groupDisable}>
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((e) => {
                                        return (
                                            <Option name='' value={`mx ${String(e).padStart(2, '0')}`}>
                                                {`mx ${String(e).padStart(2, '0')}`}
                                            </Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className='main-row cust-row cust-two'>
                            <Form.Item name="acess_level" label="Content Folder" style={{ marginBottom: 5 }} className='select-form-control'>
                                <Select
                                    style={{ width: 150, color: '#065aa9' }}
                                    defaultValue={folderType}
                                    placeholder="폴더 유형 선택"
                                    optionFilterProp="children"
                                    onChange={(val) => setFolderType(val)}
                                >
                                    <Select.Option value="publisher">Company</Select.Option>
                                    <Select.Option value="campus">Campus</Select.Option>
                                    <Select.Option value="me">me</Select.Option>
                                </Select>
                            </Form.Item>
                        </div> */}
            <div className="middle-row blue-middle-row coman-row">
              <div className="left-part">
                <div className="content-list">
                  <h2>content list</h2>
                  <div className="list-items">
                    {/* {contentList?.map((ele, index) => {
                                        return (
                                            <div>
                                                <div>
                                                    <span className={index == indx ? 'active-span' : ''} onClick={() => handleListClick(ele, index)}>                                                                  <p style={{ marginBottom: 0, lineHeight: 2 }}>{ele?.title}</p>
                                                        <Button className='remove-btn' onClick={(e) => { e.stopPropagation(); handleRemoveTopic(index); }}>X</Button>
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    })} */}
                    {/* dragable Droppable functionality code */}
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided) => (
                          <div {...provided.droppableProps} ref={provided.innerRef}>
                            {contentList?.map((item, index) => (
                              <Draggable key={item.id} draggableId={item?.id?.toString()} index={index}>
                                {(provided) => (
                                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                    {/* Render your row content here */}
                                    <div>
                                      <div>
                                        <span className={index === indx ? 'active-span' : ''} onClick={() => handleListClick(item, index)}>
                                          <p style={{ marginBottom: 0, lineHeight: 1.3 }}>{item?.title}</p>
                                          <Button
                                            className="remove-btn"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              handleRemoveTopic(index);
                                            }}
                                          >
                                            X
                                          </Button>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    {/* end dragable Droppable */}

                    <button className="add-more-btn" type="button" onClick={handleContentList}>
                      add more +
                    </button>
                  </div>
                </div>
                <div className="middle-input">
                  <Form.Item
                    name="title"
                    label="Title"
                    style={{ marginBottom: 5 }}
                    rules={[{ required: false, message: 'Please Enter Title' }]}
                  >
                    <Input
                      name="title"
                      onChange={(e) => {
                        if (indx <= contentList.length - 1) {
                          setContentList((prevState) => {
                            const updatedList = [...prevState];
                            updatedList[indx].title = e.target.value;
                            return updatedList;
                          });
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="topic"
                    label="Topic"
                    style={{ marginBottom: 5 }}
                    rules={[{ required: false, message: 'Please Enter Topic' }]}
                  >
                    <Input
                      name="topic"
                      onChange={(e) => {
                        if (indx <= contentList.length - 1) {
                          setContentList((prevState) => {
                            const updatedList = [...prevState];
                            updatedList[indx].topic = e.target.value;
                            return updatedList;
                          });
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item name="prompt" label="Prompt" style={{ marginBottom: 5 }}>
                    <Tabs activeKey={tabIndex} type="card" style={{ height: '100%' }} className="promot-tabs" onChange={handleTabChange}>
                      <Tabs.TabPane tab="Question" key="1" style={{ height: '100%' }} tabBarStyle={{ backgroundColor: '#ffda00' }}>
                        <TextArea name="question" value={tabVal?.question} rows={7} onChange={(e) => handlePromptChange(e)} />
                      </Tabs.TabPane>
                      <Tabs.TabPane tab="Chat" key="2" style={{ height: '100%' }}>
                        <TextArea name="chat" value={tabVal?.chat} rows={7} onChange={(e) => handlePromptChange(e)} />
                      </Tabs.TabPane>
                      <Tabs.TabPane tab="Debate" key="3" style={{ height: '100%' }}>
                        <TextArea name="debate" value={tabVal?.debate} rows={7} onChange={(e) => handlePromptChange(e)} />
                      </Tabs.TabPane>
                    </Tabs>
                  </Form.Item>
                  {promptValidate ? (
                    <p style={{ margin: '-15px 0 10px 10px', color: '#ff4d4f', fontSize: '15px' }}> Please Enter Prompt Value</p>
                  ) : (
                    ''
                  )}
                  <div className="action-img-btn-wrap">
                    <div>
                      <img
                        src="/images/talking-tutor/y_1.png"
                        alt="y_1-icon"
                        // className="enabled-btn" onClick={() => handleRecording(TO_LESSON)}
                        className={disableBtn ? 'disabled-btn' : 'enabled-btn'}
                        onClick={!disableBtn ? () => handleRecording(TO_LESSON) : undefined}
                      />
                      {/* <p>Repeat</p> */}
                    </div>
                    <div>
                      <img
                        src="/images/talking-tutor/y_2.png"
                        alt="y_2-icon"
                        // className="enabled-btn" onClick={() => handleRecording(PAUSE)}
                        className={disableBtn ? 'disabled-btn' : 'enabled-btn'}
                        onClick={!disableBtn ? () => handleRecording(PAUSE) : undefined}
                      />
                      {/* <p>Pause</p> */}
                    </div>
                    {/* <img src="/images/talking-tutor/y_1.png" alt="y_1-icon" className={!disableBtn ? "disabled-btn" : "enabled-btn"} />
                                        <img src="/images/talking-tutor/y_2.png" alt="y_2-icon" className={!disableBtn ? "disabled-btn" : "enabled-btn"} /> */}

                    {currentText !== '' || recordText !== '' ? (
                      <AButton
                        type="primary"
                        shape="circle"
                        icon={<SendOutlined />}
                        onClick={() => onSubmit(recordText)}
                        style={{ height: 60, width: 60, transform: 'rotate(270deg)' }}
                        className="blink_me"
                      />
                    ) : (
                      <>
                        <img
                          src={`/images/talking-tutor/${micValue}`}
                          className={recordAudio.current !== null && !micBtnChange && micValue == 'stop2x.png' ? 'blink_me' : ''}
                          alt="mice_1-icon"
                          onClick={
                            !disableControl
                              ? undefined
                              : !disableBtn || pauseStop?.pause || pauseStop?.stop
                              ? micValue == 'mice_3.png' || micValue == 'robot.png'
                                ? undefined
                                : handleMicBtnClick
                              : undefined
                          }
                          style={
                            !disableControl
                              ? {
                                  cursor: 'not-allowed',
                                  width: '60px',
                                  height: '64px',
                                  marginTop: '-4px',
                                  opacity: '0.5',
                                }
                              : {
                                  cursor: 'pointer',
                                  width: '60px',
                                  height: '64px',
                                  marginTop: '-4px',
                                }
                          }
                        />
                        {/* <p style={{ marginTop: '4px' }}>Start</p> */}
                      </>
                    )}
                    <div>
                      <img
                        src="/images/talking-tutor/y_5.png"
                        alt="y_5-icon"
                        // className="enabled-btn" onClick={() => handleRecording(STOP)}
                        className={disableBtn || micValue === 'stop2x.png' ? 'disabled-btn' : 'enabled-btn'}
                        onClick={disableBtn || micValue === 'stop2x.png' ? undefined : () => handleReRecord()}
                      />
                      {/* <p>Stop</p> */}
                    </div>
                    <div>
                      <img
                        src="/images/talking-tutor/y_4.png"
                        alt="y_4-icon"
                        // className={"enabled-btn"} onClick={() => handleRecording(REPEAT)}
                        className={disableBtn ? 'disabled-btn' : 'enabled-btn'}
                        onClick={!disableBtn ? () => handleRecording(REPEAT) : undefined}
                      />
                      {/* <p>Restart</p> */}
                    </div>
                  </div>
                  <div className="setting-btns">
                    <div className="left-btn">
                      <img
                        src="/images/talking-tutor/setting_b.png"
                        style={{ cursor: 'pointer' }}
                        alt="setting-icon"
                        onClick={handleSetting}
                      />
                      <img
                        src="/images/talking-tutor/guide_o.png"
                        alt="guide-icon"
                        style={{ cursor: 'pointer' }}
                        onClick={() => window.open('https://platform.openai.com/docs/assistants/overview', '_blank')}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="right-part upload-right-part">
                <div className="upload-wrap">
                  {fileLoading || deleteLoding ? (
                    <LoadingScreen>
                      <div style={{ flex: 1, resize: 'none', padding: '0' }}>
                        <Spin fontSize="24px" height="44px" />
                      </div>
                    </LoadingScreen>
                  ) : null}
                  <UploadViewModal
                    buttonText={`Upload Book: ${filedata?.length}`}
                    title="Upload Book"
                    content={
                      <Form.Item>
                        {/* {uploadDisable ? ( */}
                        <Upload
                          accept=".pdf"
                          name="file"
                          multiple={true}
                          action={handleUploadBook}
                          showUploadList={true}
                          fileList={filedata}
                          onRemove={(e) => handleRemove(e)}
                          onChange={handleUploadComplete}
                        >
                          <Button icon={<UploadOutlined />}>Upload Book</Button>
                        </Upload>
                      </Form.Item>
                    }
                  />
                  <hr />
                  <UploadViewModal
                    buttonText={`Upload Image: ${imagePaths?.length}`}
                    title="Upload Image"
                    content={
                      <Form.Item name="image_upload">
                        <Upload
                          accept="image/*"
                          name="file"
                          multiple={true}
                          fileList={imagePaths}
                          action={handleUplaod}
                          listType="text"
                          onRemove={(e) => handleRemoveImage(e)}
                          showUploadList={true}
                          // onChange={({ fileList }) => setUploadFileList(fileList)}
                        >
                          <Button icon={<UploadOutlined />}>Upload Image</Button>
                        </Upload>
                      </Form.Item>
                    }
                  />
                  &nbsp;
                  <UploadViewModal
                    buttonText={`Upload Audio: ${audioPaths?.length}`}
                    title="Upload Audio"
                    content={
                      <Form.Item name="audio_upload">
                        <Upload
                          accept="audio/*"
                          name="file"
                          style={{ display: 'inline-block', flex: 1 }}
                          multiple={true}
                          fileList={audioPaths}
                          action={handleUplaod}
                          listType="text"
                          onRemove={(e) => handleRemoveAudio(e)}
                          showUploadList={true}
                        >
                          <Button icon={<UploadOutlined />}>Upload Audio</Button>
                        </Upload>
                      </Form.Item>
                    }
                  />
                </div>
                <Form.Item
                  labelCol={{
                    span: 0,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  name="dialog"
                  label="Dialogue"
                  style={{ marginBottom: 5 }}
                >
                  {!activeAnswer ? (
                    <MessageBox
                      id="MessageBox"
                      style={{
                        height: '25vh',
                        overflow: 'auto',
                        margin: '10px 0',
                        backdround: '#fff',
                      }}
                      className="message-box"
                      ref={messageBoxRef}
                    >
                      <InfiniteScroll
                        scrollableTarget="MessageBox"
                        style={{ display: 'flex', flexDirection: 'column' }}
                        //next={aiUserRequestResponseList}
                        inverse={false}
                        hasMore={true}
                        dataLength={chatList?.length}
                        loader={
                          openAiMsgLoading ? (
                            <p style={{ textAlign: 'center' }}>
                              <b>Loading...</b>
                            </p>
                          ) : null
                        }
                        endMessage={
                          msgLoading || runLoading || stepLoading ? (
                            <p style={{ textAlign: 'center' }}>
                              <b>생각중...</b>
                            </p>
                          ) : null
                        }
                      >
                        {chatList?.map((thread, key) => {
                          const isMe = String(userInfoVar()?.id) === thread.chat_user_id;
                          return (
                            <React.Fragment key={`message-item-key${key}`}>
                              <DateDivider
                                isShow={
                                  key < chatList?.length &&
                                  moment(new Date(thread?.created)).format('YYYY-MM-DD') !==
                                    moment(new Date(chatList[key - 1]?.created)).format('YYYY-MM-DD')
                                }
                                date={`${moment(new Date(thread?.created)).format('YYYY-MM-DD')}`}
                              />
                              <MessageItem
                                type={'ai-talking-tutor'}
                                isMe={isMe}
                                name={thread.chat_user_name}
                                message={thread.content}
                                urls={thread.urls}
                                currentFileList={[...imagePaths.map((ele) => ele.path), ...audioPaths.map((ele) => ele.path)]}
                                date={thread.created}
                                readYN={true}
                                unreadCount={0}
                              />
                            </React.Fragment>
                          );
                        })}
                        {msgLoading && (
                          <p style={{ textAlign: 'center' }}>
                            <b>loading...</b>
                          </p>
                        )}
                      </InfiniteScroll>
                    </MessageBox>
                  ) : (
                    <Input.TextArea
                      style={{
                        height: '30vh',
                        overflow: 'auto',
                        border: '1px solid #00b598',
                        backdround: '#fff',
                        margin: '10px 0',
                        color: '#00b598',
                      }}
                      placeholder="답변하기"
                      name="answer"
                    />
                  )}
                </Form.Item>
                <Form.Item label="Message" style={{ marginBottom: 5 }}>
                  <TextArea
                    className="msg-input"
                    rows={2}
                    aria-expanded="false"
                    style={{ cursor: 'pointer', minHeight: '70px' }}
                    onFocus={(e) => {
                      if (autoSendRef.current.length) {
                        clearTimeout(autoSendRef.current[0]);
                        autoSendRef.current = [];
                      }
                    }}
                    onChange={(e) => {
                      if (autoSendRef.current.length) {
                        clearTimeout(autoSendRef.current[0]);
                        autoSendRef.current = [];
                      }
                      setRecordText(e.target.value);
                    }}
                    value={recordText}
                    onBlur={(e) => {
                      setRecordText(e.target.value);
                      stopRecording('message');
                      setStartRecording(false);
                    }}
                  />
                </Form.Item>

                <p className="clear-wrap">
                  clear
                  <img src="/images/talking-tutor/redo.png" alt="close-arrow" onClick={handleClear} />
                </p>
                <div className="form-btn">
                  <div className="right-btn">
                    <Button
                      style={fileLoading || deleteLoding ? { cursor: 'not-allowed', opacity: 0.5 } : {}}
                      onClick={fileLoading || deleteLoding ? undefined : disableControl ? handleCancel : undefined}
                    >
                      <img src="/images/talking-tutor/cancel.png" alt="cancel-btn" />
                    </Button>
                    {/* <Button htmlType="button" onClick={{ deleteLoding ? undefined : () => (setFormSubmitClick(true), formControl.submit()) }}>
                                            <img src="/images/talking-tutor/save.png"
                                                alt="save-btn" />
                                        </Button> */}
                    <Button
                      htmlType="button"
                      style={fileLoading || deleteLoding ? { cursor: 'not-allowed', opacity: 0.5 } : {}}
                      onClick={
                        fileLoading || deleteLoding
                          ? undefined
                          : () => {
                              setFormSubmitClick(true);
                              formControl.submit();
                            }
                      }
                    >
                      <img src="/images/talking-tutor/save.png" alt="save-btn" />
                    </Button>
                    <Button
                      htmlType="button"
                      style={fileLoading || deleteLoding ? { cursor: 'not-allowed', opacity: 0.5 } : {}}
                      onClick={
                        fileLoading || deleteLoding
                          ? undefined
                          : () => {
                              setBtnSaveTest(true);
                              setFormSubmitClick(true);
                              formControl.submit();
                            }
                      }
                    >
                      Save & Test
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
          <ReactPlayer
            url={currentUrl}
            playing={currentUrl !== ''}
            width={0}
            height={0}
            loop={false}
            onEnded={handlePlayEnd}
            onError={handlePlayEnd}
          />
          {soundPlay ? <SoundEffect src={sound} onEnd={handleSoundEnd} /> : ''}
        </>
      )}
      {showModal ? (
        <Modal
          className="instruction-modal"
          visible={showModal}
          style={{ top: '40%' }}
          closable={true}
          footer={null}
          onCancel={() => setShowModal(false)}
          title="Pre-Instruction Setting"
        >
          <Form form={modalForm} onFinish={handleModalFormSubmit}>
            <Form.Item name="model_prompt" style={{ marginBottom: 5 }}>
              <Tabs
                activeKey={modalTabIndex}
                type="card"
                style={{ height: '100%' }}
                className="promot-tabs"
                onChange={handleModalTabChange}
              >
                <Tabs.TabPane tab="Instruction" key="1" style={{ height: '100%' }} tabBarStyle={{ backgroundColor: '#ffda00' }}>
                  <TextArea
                    name="modal_main_instruction"
                    value={modalTabVal?.modal_main_instruction}
                    rows={10}
                    onChange={(e) => handleModalPromptChange(e)}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Description" key="2" style={{ height: '100%' }} tabBarStyle={{ backgroundColor: '#ffda00' }}>
                  <TextArea
                    name="modal_main_description"
                    value={modalTabVal?.modal_main_description}
                    rows={10}
                    onChange={(e) => handleModalPromptChange(e)}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Question" key="3" style={{ height: '100%' }} tabBarStyle={{ backgroundColor: '#ffda00' }}>
                  <TextArea
                    name="modal_question"
                    value={modalTabVal?.modal_question}
                    rows={10}
                    onChange={(e) => handleModalPromptChange(e)}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Chat" key="4" style={{ height: '100%' }}>
                  <TextArea name="modal_chat" value={modalTabVal?.modal_chat} rows={10} onChange={(e) => handleModalPromptChange(e)} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Debate" key="5" style={{ height: '100%' }}>
                  <TextArea name="modal_debate" value={modalTabVal?.modal_debate} rows={10} onChange={(e) => handleModalPromptChange(e)} />
                </Tabs.TabPane>
              </Tabs>
            </Form.Item>
            <div className="instruction-btn-wrap">
              <Button onClick={handleModalClose}>
                {' '}
                {/* Modify onClick here */}
                <img src="/images/talking-tutor/cancel.png" alt="cancel-btn" />
              </Button>
              <Button htmlType="submit">
                <img src="/images/talking-tutor/save.png" alt="save-btn" />
              </Button>
            </div>
          </Form>
        </Modal>
      ) : (
        ''
      )}
      <Modal visible={clearBtn} width={400} onCancel={handleClosClear} className="restart-task-modal">
        <h3>Are you sure you want to clear?</h3>

        <div className="modal-footer">
          <Button onClick={handleYes} className="ant-btn ant-btn-default">
            <span>Yes</span>
          </Button>
          <Button onClick={handleNo} className="ant-btn btn-primary">
            <span>No</span>
          </Button>
        </div>
      </Modal>
    </>
  );
};
export default withRouter(EditGroupList);

export const DateDivider = ({ date, isShow }) => {
  if (!isShow) return false;
  return (
    <Divider plain>
      <span
        style={{
          padding: '3px',
          backgroundColor: '#666',
          color: '#fff',
          borderRadius: '5px',
        }}
      >
        {date}
      </span>
    </Divider>
  );
};

export const UploadViewModal = ({ content, buttonText, title }) => {
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Button onClick={showModal}>{buttonText}</Button>
      <Modal title={title} visible={visible} onOk={handleOk} onCancel={handleCancel}>
        {content}
      </Modal>
    </>
  );
};
