import React, { useState, useEffect } from 'react';
import { Col, Typography, Space, Row, Button, Modal, Checkbox, Radio } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import CustomTable from 'src/components/common/CustomTable';
import { ASSIGNED_TEST_LIST } from 'src/operations/queries/getTests';
import { GET_TYPE_CLASS } from 'src/operations/queries/getClass';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import UseHistoryState from 'src/components/common/UseHistoryState';
import TestCenterMenuGroup from 'src/components/common/TestCenterMenuGroup';
import Print from 'src/components/Test/print';

const { Title } = Typography;
const classOptions = [
  { label: '진행반', value: 0 },
  { label: '종료반', value: 1 },
];

const onHandleRowClick = (row, index, user) => {
  // if (window.Android && row.isMeeting) {
  //   if (user.type == 1) {
  //     window.Android.joinZoomClass(JSON.stringify(user));
  //   }else{
  //     window.Android.startZoomClass(JSON.stringify(user));
  //   }
  // }
};

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
`;

const ResultsPage = (props) => {
  const query = new URLSearchParams(props.location.search);
  const isRefresh = query.get('refresh');

  const [openPrint, setOpenPrint] = useState(false);
  const [printData, setPrintData] = useState({});
  const [classes, setClasses] = useState([]);
  const [assigned_tests, setAssignedTests] = useState([]);
  const [class_idx, setClassIdx] = useState(0);
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(10);
  const [total, setTotal] = useState(0);
  const [checkedList, setCheckedList] = useState(new Array(assigned_tests.length).fill(false));
  const [searchValue, setSearchValue] = UseHistoryState(
    {
      class_idx: 0,
      page: 0,
      class_type: 0, //class_type - 0: 진행, 1: 종료
    },
    'test_result',
  );

  const { data, loading, refetch } = useQuery(ASSIGNED_TEST_LIST, {
    // variables: { class_idx, page, take },
    variables: { class_idx: searchValue.class_idx, class_type: searchValue.class_type },
    fetchPolicy: 'no-cache',
  });

  const classQuery = useQuery(GET_TYPE_CLASS, {
    variables: {},
  });

  useEffect(() => {
    if (data?.assignedTestsList) {
      setAssignedTests(data?.assignedTestsList.assigned_tests);
      setTotal(data?.assignedTestsList.total);
    }

    if (classQuery?.data?.classList) {
      setClasses(classQuery?.data?.classList);
    }

    if (isRefresh) {
      refetch();
      props.history.replace({ search: '' });
    }
  }, [data, classQuery, isRefresh]);

  function onChange(e, idx) {
    checkedList[idx] = e.target.checked;
    setCheckedList(JSON.parse(JSON.stringify(checkedList)));
  }

  function onCheckAllChange(e) {
    if (e.target.checked) {
      setCheckedList(new Array(assigned_tests.length).fill(true));
    } else {
      setCheckedList([]);
    }
  }

  const handleTestAssignDelete = () => {
    const astDelete = checkedList.map((f, i) => (f ? `${i}` : f)).filter((f) => f);
    const idxs = assigned_tests.filter((s, i) => astDelete.includes(`${i}`)).map((f) => f.idx);
    props.onTestAssignDelete({ idxs });
    setTimeout(() => {
      setCheckedList([]);
      refetch();
      classQuery.refetch();
    }, 1000);
  };

  function setCurrentState() {
    setSearchValue((prev) => {
      return {
        ...prev,
        class_type: searchValue.class_type,
      };
    });
  }

  const columns = [
    {
      title: 'No',
      dataIndex: 'idx',
      key: 'No',
      align: 'center',
      width: 80,
    },
    {
      title: 'Date',
      dataIndex: 'start_time',
      align: 'center',
      key: 'date',
      width: 100,
      sorter: (a, b) => a.start_time && a.start_time.localeCompare(b.start_time),
      render: (text, record) => {
        return <Space size="middle">{text.substr(0, 10)}</Space>;
      },
    },
    {
      title: 'Class',
      key: 'class',
      dataIndex: 'idx',
      align: 'center',
      width: 200,
      sorter: (a, b) => a.class?.name && a.class?.name.localeCompare(b.class?.name),
      render: (text, record) => {
        return <Space size="middle">{record?.class?.name}</Space>;
      },
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      sorter: (a, b) => a.title && a.title.localeCompare(b.title),
    },
    {
      title: 'Test Number',
      dataIndex: 'number',
      key: 'number',
      width: 200,
      align: 'center',
      render: (text, record) => {
        return <Space size="middle">{record.tests.code}</Space>;
      },
    },
    {
      title: 'Completed',
      dataIndex: 'done',
      width: 100,
      key: 'total',
      align: 'center',
      render: (text, record) => {
        return <Space size="middle">{`${record.done}/${record.assigned_test_users.length}`}</Space>;
      },
    },
    {
      title: 'Action',
      key: '',
      width: 100,
      render: (text, record) => {
        const startT = moment(new Date(`${record.start_time.replace('Z', '')}+09:00`));
        if (moment().isBefore(startT)) {
          return (
            <Space size="middle">
              <Link to={`/test/manage/action/assign-edit/${record.idx}`}>
                <Button>Edit</Button>
              </Link>
            </Space>
          );
        } else {
          return (
            <Space size="middle">
              <Link to={`/test/${record.idx}`}>
                <Button onClick={setCurrentState}>Open</Button>
              </Link>
            </Space>
          );
        }
      },
    },
    {
      title: 'Print',
      key: '',
      width: 100,
      render: (text, record) => {
        return (
          <Space size="middle">
            <Button
              onClick={() => {
                setOpenPrint(true);
                setPrintData(record);
              }}
            >
              Print
            </Button>
          </Space>
        );
      },
    },
    {
      title: () => {
        return <Checkbox onChange={onCheckAllChange}></Checkbox>;
      },
      key: '',
      width: 40,
      render: (text, record, idx) => {
        return <Checkbox checked={checkedList[idx]} onChange={(e) => onChange(e, idx)}></Checkbox>;
      },
    },
  ];

  function handleTableChange(pagination, filters) {
    setSearchValue((prev) => {
      return {
        ...prev,
        page: pagination.current - 1,
      };
    });
  }

  console.log('searchValue => ', JSON.stringify(searchValue));

  return (
    <>
      <TestCenterMenuGroup currentMenu="test-result" />
      <Col className="contenttest-wrapper" span={24} style={{ textAlign: 'right', margin: 0 }}>
        <MainBlock className="testresult-mainblock">
          <Row gutter={[24, 16]} style={{ margin: 0 }}>
            <Col className="contenttest-title-result" span={12} xs={4} md={6} lg={12} style={{ textAlign: 'left' }}>
              <Title level={4}>Test Results</Title>
            </Col>
            <Col
              className="contenttest-headitems-result"
              span={12}
              xs={20}
              md={18}
              lg={12}
              style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end' }}
            >
              <Radio.Group
                options={classOptions}
                optionType="button"
                value={searchValue.class_type}
                buttonStyle="solid"
                onChange={(e) => {
                  setSearchValue((prev) => {
                    return {
                      ...prev,
                      class_type: e.target.value,
                    };
                  });
                }}
              />
              &nbsp; &nbsp;
              {/* <SearchClassComp
                hasTeacherDropBox={false}
                hideFinishedClass={true}
                defaultClassType={searchValue.class_type}
                onSearchClassChange={onClassChange}
                onSearchClassTypeChange={onClassTypeChange}
              /> */}
              {/* <Select
                style={{ width: 150, color: '#065aa9', marginRight: 10 }}
                placeholder="Select a Class"
                //onChange={(idx) => setClassIdx(idx)}
                //defaultValue={searchValue.class_idx}
                value={searchValue.class_idx}
                onChange={(val) =>
                  setSearchValue((prev) => {
                    return {
                      ...prev,
                      class_idx: parseInt(val),
                    };
                  })
                }
              >
                <Select.Option value={0}>전체</Select.Option>
                {classes.map((item) => (
                  <Select.Option key={item.idx} value={item.idx} label={item.name}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select> */}
              <Button danger onClick={handleTestAssignDelete}>
                Delete
              </Button>
            </Col>
          </Row>
          <CustomTable
            className="contenttest-table"
            bordered
            loading={loading}
            dataSource={assigned_tests}
            sorter={(a, b) => a.No - b.No}
            columns={columns}
            //scroll={{ y: 'calc(100vh - 254px) !important' }}
            //pagination={{ pageSize: take, total }}
            pagination={{ pageSize: take, current: searchValue.page + 1, position: ['bottomCenter'] }}
            size="small"
            color="#edf3fb"
            onChange={handleTableChange}
            //onChange={(a) => setPage(a.current - 1)}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => onHandleRowClick(record, rowIndex),
              };
            }}
          />
        </MainBlock>
      </Col>
      <Modal
        title="Print"
        open={openPrint}
        onCancel={() => setOpenPrint(false)}
        footer={null}
        width={'80%'}
        style={{ top: 20, padding: 0 }}
        bodyStyle={{ padding: 0 }}
      >
        <Print id={printData.idx} />
      </Modal>
    </>
  );
};

export default withRouter(ResultsPage);
