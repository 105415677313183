import React, { useEffect, useState, useMemo } from 'react';
import { Button, Col, DatePicker, Input, Row, AutoComplete, Select } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';

import FormField from 'src/components/common/FormField';
import { phoneNumberCheck } from 'src/utils';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { userInfoVar } from 'src/apollo/cache';

const UpdateStudentForm = ({ userInfo, onDateChange, showPostModal, form, setValues, gradeOptions, isTeacher = false }) => {
  const [phone, setPhone] = useState(userInfo?.phone ? userInfo?.phone : undefined);
  const [parentPhone, setParentPhone] = useState(userInfo?.parent_phone ? userInfo?.parent_phone : undefined);
  const company = useSelector(classStoreData);

  const StudentAccess = useMemo(() => {
    if (userInfoVar()?.campus?.company?.study_access_check) {
      return JSON.parse(userInfoVar()?.campus?.company?.study_access_check);
    }
    return false;
  }, [userInfoVar()?.campus?.company]);

  const handleChange = (e) => {
    const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(e.target.value)) {
      if (e.target.name === 'phone') {
        setPhone(e.target.value);
      } else {
        setParentPhone(e.target.value);
      }
    }

    setValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    if (phone) {
      setPhone(phoneNumberCheck(phone));
    }
    if (parentPhone) {
      setParentPhone(phoneNumberCheck(parentPhone));
    }

    form.setFieldsValue({
      name: userInfo?.name ? userInfo?.name : undefined,
      birthday: userInfo?.birthday ? moment(userInfo?.birthday) : undefined,
      id: userInfo?.id ? userInfo?.id : undefined,
      phone: phone,
      email: userInfo?.email ? userInfo?.email : undefined,
      parent_phone: parentPhone,
      parent_name: userInfo?.parent_name ? userInfo?.parent_name : undefined,
      school_name: userInfo?.school_name ? userInfo?.school_name : undefined,
      school_grade: userInfo?.school_grade ? userInfo?.school_grade : undefined,
      address: userInfo?.address ? userInfo?.address : undefined,
      memo: userInfo?.memo ? userInfo?.memo : undefined,
      english_name: userInfo?.english_name ? userInfo?.english_name : undefined,
      lexile_level: userInfo?.lexile_level ? userInfo?.lexile_level : undefined,
      study_access_available:
        userInfo?.study_access_available && typeof userInfo?.study_access_available === 'string'
          ? JSON.parse(userInfo?.study_access_available)
          : undefined,
    });
  }, [
    userInfo?.name,
    userInfo?.birthday,
    userInfo?.id,
    userInfo?.email,
    userInfo?.parent_name,
    userInfo?.school_name,
    userInfo?.school_grade,
    userInfo?.address,
    userInfo?.memo,
    userInfo?.english_name,
    userInfo?.lexile_level,
    userInfo?.study_access_available,
    phone,
    parentPhone,
    setPhone,
    setParentPhone,
    form,
  ]);

  return (
    <>
      <FormField hasFeedback={true} title="학생 이름">
        <FormItem name="name" rules={[{ required: true, message: '학생이름을 입력해 주세요.' }]} hasFeedback style={{ width: '90%' }}>
          <Input name="name" />
        </FormItem>
      </FormField>

      <FormField hasFeedback={false} title="생년월일">
        <FormItem name="birthday" rules={[{ required: false }]} hasFeedback style={{ width: '90%' }}>
          <DatePicker name="birthday" onChange={(_, d) => onDateChange('birthday', d)} placeholder="생년월일" style={{ width: '100%' }} />
        </FormItem>
      </FormField>

      <FormField hasFeedback={true} title="학생 ID">
        <FormItem name="id" rules={[{ required: true, message: '학생ID를 입력해 주세요.' }]} hasFeedback style={{ width: '90%' }}>
          <Input name="id" disabled />
        </FormItem>
      </FormField>

      <FormField hasFeedback={false} title="학생 연락처">
        <FormItem name="phone" rules={[{ required: false }]} hasFeedback style={{ width: '90%' }}>
          <Input name="phone" onChange={(e) => handleChange(e)} maxLength={13} />
        </FormItem>
      </FormField>

      {!userInfo?.external_id && (
        <>
          <FormField hasFeedback={false} title="비밀번호">
            <FormItem
              name="pw"
              rules={[
                {
                  required: false,
                  message: '비밀번호를 입력해 주세요.',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    console.log('value', value);
                    console.log("Value('password')", typeof getFieldValue('pw'));
                    if (!value || (getFieldValue('pw') === value && value.length >= 4)) {
                      return Promise.resolve();
                    } else if (value.length < 4) {
                      return Promise.reject('최소 4개이상 입력하셔야 합니다.');
                    }
                  },
                }),
              ]}
              hasFeedback
              style={{ width: '90%' }}
            >
              <Input.Password name="pw" autoComplete="new-password" />
            </FormItem>
          </FormField>

          <FormField hasFeedback={false} title="비밀번호 확인">
            <FormItem
              name="confirm"
              dependencies={['pw']}
              hasFeedback
              rules={[
                {
                  required: false,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    console.log('value', value);
                    console.log("Value('password')", typeof getFieldValue('pw'));
                    if (!value || (getFieldValue('pw') === value && value.length >= 4)) {
                      return Promise.resolve();
                    } else if (value.length < 4) {
                      return Promise.reject('최소 4개이상 입력하셔야 합니다.');
                    }
                    return Promise.reject('입력하신 비밀번호가 일치하지 않습니다.');
                  },
                }),
              ]}
              style={{ width: '90%' }}
            >
              <Input.Password />
            </FormItem>
          </FormField>
        </>
      )}

      <FormField title="이메일">
        <FormItem name="email" hasFeedback style={{ width: '90%' }}>
          <Input name="email" disabled />
        </FormItem>
      </FormField>

      <FormField hasFeedback={false} title="학부모 성함">
        <FormItem name="parent_name" rules={[{ required: false }]} hasFeedback style={{ width: '90%' }}>
          <Input name="parent_name" />
        </FormItem>
      </FormField>

      <FormField hasFeedback={false} title="학부모 연락처">
        <FormItem name="parent_phone" rules={[{ required: false }]} hasFeedback style={{ width: '90%' }}>
          <Input name="parent_phone" onChange={(e) => handleChange(e)} maxLength={13} />
        </FormItem>
      </FormField>

      <FormField hasFeedback={false} title="학교">
        <FormItem name="school_name" rules={[{ required: false }]} hasFeedback style={{ width: '90%' }}>
          <Input name="school_name" />
        </FormItem>
      </FormField>

      <FormField hasFeedback={false} title="학년">
        <FormItem name="school_grade" rules={[{ required: false }]} hasFeedback style={{ width: '90%' }}>
          {/* <Input name="school_grade" /> */}
          <AutoComplete options={gradeOptions} name="school_grade" style={{ width: '100%' }} />
        </FormItem>
      </FormField>

      <FormField hasFeedback={false} title="주소">
        <Row>
          <Col span={20}>
            <FormItem name="address" rules={[{ required: false }]} hasFeedback>
              <Input name="address" />
            </FormItem>
          </Col>

          <Col span={4}>
            <Button onClick={showPostModal}>
              <SearchOutlined />
            </Button>
          </Col>
        </Row>
      </FormField>

      <FormField hasFeedback={false} title="영어이름">
        <FormItem name="english_name" rules={[{ required: false }]} hasFeedback style={{ width: '90%' }}>
          <Input name="english_name" />
        </FormItem>
      </FormField>
      {isTeacher && (
        <FormField hasFeedback={false} title="lexile level">
          <FormItem name="lexile_level" rules={[{ required: false }]} hasFeedback style={{ width: '90%' }}>
            <Input name="lexile_level" />
          </FormItem>
        </FormField>
      )}

      {StudentAccess.length > 0 && userInfoVar()?.type > 0 ? (
        <FormField title="Study Access">
          <FormItem name="study_access_available" style={{ width: '90%' }}>
            <Select mode="multiple" placeholder="Select Study Access" style={{ width: '100%' }}>
              {StudentAccess.map((company) => (
                <Select.Option key={company.id} value={company.id}>
                  {company.name}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
        </FormField>
      ) : null}

      {company === 'lucid' ? (
        <>
          <FormField hasFeedback={false} title="메모">
            <FormItem name="memo" rules={[{ required: false }]} hasFeedback style={{ width: '90%' }}>
              <Input name="memo" />
            </FormItem>
          </FormField>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default UpdateStudentForm;
