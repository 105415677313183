import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Input, Row, Typography, Modal, Radio, Form, Tooltip } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import { PlusOutlined, RedoOutlined } from '@ant-design/icons';
import moment from 'moment';

import StudentModal from 'src/components/Modal/StudentModal';
import CustomTable from 'src/components/common/CustomTable';
import CustomButton from 'src/components/common/CustomButton';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { openNotification } from 'src/components/common/Notification';
import ClassAndMemberMenuGroup from 'src/components/common/ClassAndMemberMenuGroup';

import { userInfoVar, userTypVar } from 'src/apollo/cache';
import { useLoginTo, useModal } from 'src/utils/hooks';
import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';
import UseHistoryState from 'src/components/common/UseHistoryState';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import FormItem from 'antd/lib/form/FormItem';
import FormField from 'src/components/common/FormField';
import { ExportExcel } from 'src/utils';
import { useHistory } from 'react-router-dom';
const short = require('short-uuid');
const { Title, Text } = Typography;
const { confirm } = Modal;

const stateOptions = [
  { label: '재원', value: '1' },
  { label: '휴원', value: '3' },
];
const today = new Date();
const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

const fileName = date;
const columnsExcel = [
  {
    title: 'No',
    align: 'center',
    dataIndex: 'no',
    width: 40,
  },
  {
    title: 'ID',
    align: 'center',
    dataIndex: 'id',
    key: 'id',
  },
];

function StudentsCommon() {
  const loginTo = useLoginTo(false);

  const [students, setStudents] = useState([]);
  const [filterTable, setFilterTable] = useState(null);
  const [searchValue, setSeachValue] = useState('');
  const [checkRowList, setcheckRowList] = useState([]);
  const { visible, showModal, handleCancel, popupMode } = useModal();
  const [visiblepopup, showUniquepopup] = useState(false);
  const company = useSelector(classStoreData);
  const [uniquenumber, setUniqueNumber] = useState('');
  const [displayerror, setDisplayError] = useState(false);
  const [uniqueNumberArray, setUniqueNumberArray] = useState([]);
  const [codeArray, SetCodeArray] = useState([]);
  // const [apiResponse, setApiResponse] = useState();
  //const [userStat, setUserStat] = useState('1');
  const [localState, setLocalState] = UseHistoryState({
    userStat: '1',
    currentPage: '1',
  });
  //
  const pageSize = 12;
  // const [pageSize, setPageSize] = useState(12);
  const history = useHistory();
  // console.log(history.location.state)
  const handlePageChange = (pageNumber) => {
    setLocalState((prev) => ({
      ...prev,
      currentPage: pageNumber,
    }));
  };

  const StudentAccess = useMemo(() => {
    if (userInfoVar()?.campus?.company?.study_access_check) {
      return JSON.parse(userInfoVar()?.campus?.company?.study_access_check);
    }
    return false;
  }, [userInfoVar()?.campus?.company]);

  const {
    data,
    loading: getStudentsLoading,
    refetch,
  } = useQuery(queries.getUser.GET_USERS, {
    fetchPolicy: 'no-cache',
    variables: { type: userTypVar().student, stat: localState.userStat },
  });

  const [deleteUser] = useMutation(mutations.deleteUser.DELETE_USER, {
    onCompleted(data) {
      if (data) {
        refetch();
        openNotification('삭제 완료!');
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  const handleUniqueCode = (e) => {
    setUniqueNumber(e.target.value);
    if (e.target.value == ' ') {
      setDisplayError(true);
    } else {
      setDisplayError(false);
    }
  };
  const [usercreate, { loading }] = useMutation(mutations.createIDUser.CREATE_ID_USER, {
    refetchQueries: [
      {
        query: queries.getUser.GET_USERS,
        variables: { type: userTypVar().student },
      },
      {
        query: queries.getUser.GET_USER,
      },
    ],
    onCompleted(data) {
      if (data) {
        if (refetch) {
          refetch();
        }
        if (data.createIDUser) {
          // setApiResponse(true);
          ExportExcel(columnsExcel, codeArray, fileName);
        }
        Modal.success({
          // title: `${data.createUser.unique_id}`,
          title: 'Id generated successfully',
        });
      }
    },
    onError(error) {
      const message = String(error).split(':')[1];

      Modal.error({
        title: `${message}`,
      });
    },
  });
  const getRecentClassInfo = (classStudents) => {
    if (classStudents && classStudents instanceof Array) {
      const sortedClassStudents = classStudents.sort((a, b) => {
        if (moment(a.class.idate).isBefore(b.class.idate)) {
          return 1;
        } else {
          return -1;
        }
      });
      // console.log(sortedClassStudents[0]?.class);
      return sortedClassStudents[0]?.class;
    }
    return null;
  };

  useEffect(() => {
    setSeachValue('');
    setFilterTable(null);
    if (data?.getUsers) {
      setStudents(
        data.getUsers.reduce((acc, cur, index) => {
          const classInfo = getRecentClassInfo(cur?.class_student);
          const class_name = classInfo?.name;
          const class_book = classInfo?.book?.book_sub[0]?.title || classInfo?.book_code;
          const class_idx = classInfo?.idx;
          const obj = {
            key: cur.idx,
            No: index + 1,
            name: cur.name,
            id: cur.id,
            view_id: cur.view_id,
            phone: cur.phone,
            email: cur.email,
            class_name,
            class_book,
            class_idx,
            stat: localState.userStat,
            grade: cur.school_grade,
            study_access_available:
              cur?.study_access_available && typeof cur?.study_access_available === 'string'
                ? JSON.parse(cur?.study_access_available)
                : null,
            loginTo,
          };

          return [...acc, obj];
        }, []),
      );
      //dependency에 loginTo 넣으면 안 됨.
    }
  }, [data, localState.userStat]);

  const handleSearch = (value) => {
    setSeachValue(value);
    const filterTable = students.filter((student) => {
      return Object.keys(student).some((field) => String(student[field]).toLowerCase().includes(value.toLowerCase()));
    });

    setFilterTable(filterTable);
  };

  const onSeachChange = (event) => {
    setSeachValue(event.target.value);
  };

  const rowSelection = {
    onChange: (_, selectedRows) => {
      setcheckRowList(selectedRows.map((data) => data.key));
    },
  };

  const deleteConfirm = () => {
    if (checkRowList.length) {
      confirm({
        title: '삭제된 회원정보는 복구가 어렵습니다. 정말 삭제하시겠습니까?',
        okText: '예',
        okType: 'primary',
        cancelText: '아니오',
        onOk() {
          deleteUser({
            variables: {
              user_idx_list: checkRowList,
            },
          });
        },
        onCancel() {},
      });
    } else {
      infoConfirm();
    }
  };

  function infoConfirm() {
    Modal.warning({
      title: '삭제할 항목을 선택해 주세요.',
      okText: '확인',
    });
  }
  const handleUniqueCancel = () => {
    showUniquepopup(false);
    setDisplayError(false);
  };
  const handleFormSubmit = () => {
    if (uniquenumber) {
      setDisplayError(false);
      const uniqueRes = [];
      const codeArr = [{}];
      for (let i = 0; i < uniquenumber; i++) {
        let id = short.generate();
        uniqueRes.push(id);
        if (uniqueRes.length) {
          setUniqueNumberArray(uniqueRes);
        }
        showUniquepopup(false);
        codeArr.push({ no: i + 1, id: id });
        if (codeArr.length) {
          SetCodeArray(codeArr);
        }
      }
    } else {
      setDisplayError(true);
    }
  };
  useEffect(() => {
    if (uniqueNumberArray.length) {
      let id = JSON.stringify(uniqueNumberArray);
      usercreate({
        variables: {
          id: id,
          unique_id: id,
          pw: id,
          name: '',
          birthday: '',
          phone: '',
          parent_name: '',
          parent_phone: '',
          english_name: '',
          email: '',
          school_name: '',
          school_grade: '',
          address: '',
          memo: '',
          type: userTypVar().student,
          campus_idx: userInfoVar()?.campus_idx,
        },
      });
    }
  }, [uniqueNumberArray]);

  const generateUniqueID = () => {
    showUniquepopup(true);
    let id = short.generate();
    // if (id) {
    // usercreate({
    // variables: {
    // idx: "",
    // name: "",
    // birthday: "",
    // id: id,
    // pw: id,
    // phone: "",
    // parent_name: "",
    // parent_phone: "",
    // english_name: "",
    // email: "",
    // school_name: "",
    // school_grade: "",
    // address: "",
    // memo: "",
    // type: userTypVar().student,
    // unique_id: id,
    // },
    // });
    // }
  };
  let tableData = filterTable === null ? students : filterTable;

  const columns = [
    {
      title: 'No',
      dataIndex: 'No',
      key: 'No',
      align: 'center',
      width: 90,
    },
    {
      title: '학생 이름',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => {
        return <Link to={{ pathname: `/students/${record.key}` }}>{text} </Link>;
      },
      width: 190,
    },
    {
      title: 'ID',
      dataIndex: 'view_id',
      key: 'id',
      align: 'center',
      sorter: (a, b) => a.id.localeCompare(b.id),
      render: (text, record) => {
        return <Link to={{ pathname: `/students/${record.key}` }}>{text} </Link>;
      },
    },
    {
      title: '반',
      dataIndex: 'class_name',
      key: 'class_name',
      align: 'center',
    },
    {
      title: '교재',
      dataIndex: 'class_book',
      key: 'class_book',
      align: 'center',
    },
    {
      title: '학생 연락처',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
    },
    {
      title: '학년',
      dataIndex: 'grade',
      key: 'grade',
      align: 'center',
    },
    ...(StudentAccess.length > 0
      ? [
          {
            title: 'Study Access',
            dataIndex: 'study_access_available',
            key: 'study_access_available',
            align: 'center',
            render: (text) => {
              let available = StudentAccess.filter((content) => text?.includes(content.id));
              return <span>{available.map((content) => content.name).join(', ')}</span>;
            },
          },
        ]
      : []),

    {
      title: '상세보기',
      key: 'action',
      align: 'center',
      width: '100px',
      render: (_, record) => {
        return (
          <Button
            onClick={() => {
              history.push('/students/' + record.key, { currentPage: localState.currentPage });
            }}
          >
            <span>View </span>
          </Button>
        );
      },
    },
    {
      title: '로그인',
      dataIndex: 'login',
      key: 'login',
      align: 'center',
      width: '100px',
      render: (_, record) => {
        return (
          <CustomButton
            type="primary"
            size="small"
            onClick={() => {
              //loginTo(record.id, record.client, record.loginUser);
              record.loginTo(record.id);
            }}
          >
            로그인
          </CustomButton>
        );
      },
    },
  ];

  return (
    <>
      <ClassAndMemberMenuGroup currentMenu="student" />
      <HeaderTitle level={4}>학생 관리</HeaderTitle>
      <Row className="tblclass-list" gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper style={{ height: 'calc(100vh - 18vh)', overflowY: 'auto' }}>
            <div
              className="header-classroom-teacher"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 10,
              }}
            >
              <Title className="header-classroom-title" level={5}>
                학생 List
              </Title>
              <div className="header-classroom-search">
                <span style={{ paddingRight: 10 }}>
                  <Radio.Group
                    options={stateOptions}
                    optionType="button"
                    value={localState.userStat}
                    buttonStyle="solid"
                    onChange={(e) => {
                      //setUserStat(e.target.value);
                      setLocalState((prev) => ({
                        ...prev,
                        userStat: e.target.value,
                      }));
                    }}
                  />
                </span>

                <Input.Search
                  className="inputsearch-classroom"
                  placeholder="검색어를 입력해 주세요"
                  allowClear
                  style={{ width: 250 }}
                  enterButton
                  onSearch={handleSearch}
                  onChange={onSeachChange}
                  value={searchValue}
                />
                <CustomButton style={{ marginRight: 10 }} onClick={() => handleSearch('')}>
                  <RedoOutlined />
                </CustomButton>

                {!userInfoVar()?.campus?.external_id && (
                  <CustomButton style={{ marginRight: 10 }} onClick={() => showModal('create')}>
                    <PlusOutlined style={{ color: '#52c419' }} />
                    <Text type={'success'}>등록</Text>
                  </CustomButton>
                )}
                <CustomButton
                  danger
                  style={{
                    marginRight: 10,
                  }}
                  onClick={deleteConfirm}
                >
                  삭제
                </CustomButton>

                {company === 'default' && (
                  <CustomButton
                    success
                    style={{
                      marginRight: 10,
                    }}
                    onClick={generateUniqueID}
                  >
                    <Text type={'success'}>Unique ID</Text>
                  </CustomButton>
                )}
              </div>
            </div>

            <CustomTable
              className="classroom-table-table"
              loading={getStudentsLoading}
              dataSource={tableData?.reverse()}
              columns={columns}
              scroll={{ y: 'calc(100vh - 254px)' }}
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              pagination={{ pageSize: pageSize, current: localState.currentPage, total: tableData.length, onChange: handlePageChange }}
              size="small"
              color="#edf3fb"
              // onRow={(record, rowIndex) => {
              //   return {
              //     onClick: (event) => {}, // click row
              //   };
              // }}
            />
          </HalfWrapper>
        </Col>
      </Row>
      {visible && <StudentModal popupMode={popupMode} handleCancel={handleCancel} visible={visible} refetch={refetch} />}
      {visiblepopup ? (
        <Modal
          className="fullwdmodal uniquecode"
          visible={visiblepopup}
          //title={myInfo ? '내 정보' : `학생 정보`}
          onCancel={handleUniqueCancel}
          width={'25%'}
          footer={[
            <Button key="submit" type="primary" onClick={handleFormSubmit}>
              Generate
            </Button>,
          ]}
        >
          <Form
            className="classmodal"
            //form={form}
            name="control-ref"
            // layout="inline"
            //labelCol={{ span: 24 }}
            //wrapperCol={{ span: 14 }}
            autoComplete="off"
          >
            <FormField title="">
              <FormItem name="uniquenum" rules={[{ required: true }]} hasFeedback style={{ width: '100%', marginBottom: '10px' }}>
                <Input placeholder="Enter Number" name="uniquenum" type="number" onChange={handleUniqueCode} />
                {displayerror ? <label>Please Enter Number</label> : ''}
              </FormItem>
            </FormField>
          </Form>
        </Modal>
      ) : (
        ''
      )}
    </>
  );
}

export default StudentsCommon;
