import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation createUser(
    $id: String!
    $pw: String!
    $name: String!
    $birthday: String
    $phone: String
    $parent_name: String
    $parent_phone: String
    $english_name: String
    $email: String
    $school_name: String
    $school_grade: String
    $address: String
    $deleted_working_days: String
    $working_hour: String
    $current_salary: String
    $date_of_entry: String
    $resident_reg_no: String
    $account_number: String
    $academic_background: String
    $marital_status: String
    $children: String
    $blood_type: String
    $report_income: String
    $special_category: String
    $memo: String
    $department: String
    $campus_idx: Int
    $type: String!
    $unique_id: String
    $lexile_level: String
    $study_access_available: String
  ) {
    createUser(
      id: $id
      pw: $pw
      name: $name
      birthday: $birthday
      phone: $phone
      parent_name: $parent_name
      parent_phone: $parent_phone
      english_name: $english_name
      email: $email
      school_name: $school_name
      school_grade: $school_grade
      address: $address
      deleted_working_days: $deleted_working_days
      working_hour: $working_hour
      current_salary: $current_salary
      date_of_entry: $date_of_entry
      resident_reg_no: $resident_reg_no
      account_number: $account_number
      academic_background: $academic_background
      marital_status: $marital_status
      children: $children
      blood_type: $blood_type
      report_income: $report_income
      special_category: $special_category
      memo: $memo
      department: $department
      campus_idx: $campus_idx
      type: $type
      unique_id: $unique_id
      lexile_level: $lexile_level
      study_access_available: $study_access_available
    ) {
      idx
      id
      name
      english_name
      email
      phone
      memo
      birthday
      parent_name
      parent_phone
      school_name
      school_grade
      address
      deleted_working_days
      working_hour
      current_salary
      date_of_entry
      resident_reg_no
      account_number
      academic_background
      marital_status
      children
      blood_type
      report_income
      special_category
      type
      campus_idx
      department
      unique_id
      idate
      lexile_level
      study_access_available
      class_student {
        idx
        class {
          idx
          name
        }
      }
    }
  }
`;

export const CREATE_STUDENTS = gql`
  mutation createStudentList($student_list: [StudentUserBatchInput!]!) {
    createStudentList(student_list: $student_list) {
      idx
    }
  }
`;
