import React, { useState, useMemo } from 'react';
import { Button, Row } from 'antd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { HeaderTitle } from 'src/components/common/Styles';
import Student from 'src/components/DashBoard/MainPage/Student';
import SubPage from 'src/components/DashBoard/SubPage';
import { classStoreData } from 'src/operations/store';
import MyEbookLinkBtn from './MyEbookLinkBtn';
// import SimplePopup from 'src/popups/SimplePopup';
import { isAfterSchool, getDashboardTitle } from 'src/utils';

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StudentPage = () => {
  const [tab, setTab] = useState('main');
  const company = useSelector(classStoreData);
  // console.log('defaultCompany', defaultCompany);
  const isEbookView = useMemo(() => {
    return ['default', 'cleveredu', 'cleveredu2'].includes(company) ? true : false;
  }, [company]);
  return (
    <>
      {/* {isAfterSchool(company) && <SimplePopup />} */}
      <Row justify="space-between" className="buttonlisting-dashboard">
        <HeaderTitle level={4}>{getDashboardTitle(company)}</HeaderTitle>
        {/* <ButtonWrapper className="buttonlisting-wrap">
          <Button
            className="btn-schedule"
            type={tab === 'main' && 'primary'}
            size="large"
            style={{ margin: 5 }}
            onClick={() => setTab('main')}
          >
            교재주문
          </Button>
          <Button className="btn-messageicon" type={tab === 'sub' && 'primary'} size="large" onClick={() => setTab('sub')}>
            수업자료
          </Button>
          <Button className="btn-ebook" type={tab === 'sub' && 'primary'} size="large" onClick={() => setTab('sub')}>
            e-Book
          </Button>
        </ButtonWrapper> */}
        <ButtonWrapper className="buttonlisting-wrap">
          {isEbookView && <MyEbookLinkBtn />}
          {company !== 'englishtap' && (
            <>
              <Button
                className="btn-schedule"
                type={tab === 'main' && 'primary'}
                size="large"
                style={{ margin: 5 }}
                onClick={() => setTab('main')}
              >
                Schedule
              </Button>
              <Button className="btn-messageicon" type={tab === 'sub' && 'primary'} size="large" onClick={() => setTab('sub')}>
                쪽지함
              </Button>
            </>
          )}
        </ButtonWrapper>
      </Row>

      <Row gutter={[24, 16]}>{tab === 'main' ? <Student /> : <SubPage />}</Row>
    </>
  );
};

export default StudentPage;
