import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { Form } from 'antd';
import DefaultModal from './DefaultModal';
import CreatePlacementTestForm from 'src/pages/Consulting/CreatePlacementTestForm';
import { CREATE_CONSULT_LEVELTEST_FOR_TALKINGSAM } from 'src/operations/mutations/consult';
import { GET_CONSULT_LIST } from 'src/operations/queries/consult';
import { userInfoVar } from 'src/apollo/cache';

const CreatePlacementTestModal = ({ visible, onCancel, refetch, campus_idx, ym, ...rest }) => {
  const [form] = Form.useForm();
  const cmp_name = userInfoVar()?.company_name;

  const handleCancel = useCallback(() => {
    form.resetFields();
    onCancel();
  }, [form, onCancel]);

  const [create, { loading }] = useMutation(CREATE_CONSULT_LEVELTEST_FOR_TALKINGSAM, {
    onCompleted(data) {
      if (data) {
        alert('등록했습니다.');
        handleCancel();
        refetch();
        form.resetFields();
      } else {
        alert('오류가 발생했습니다.');
      }
    },
    onError(error) {
      console.log('error', error);
      alert('오류가 발생했습니다(2).');
      form.resetFields();
    },
    refetchQueries: [
      {
        query: GET_CONSULT_LIST,
        variables: { campus_idx, ym },
      },
    ],
  });

  return (
    visible ? <DefaultModal
      title="등록"
      form={form}
      ActionCancel={handleCancel}
      popupMode="create"
      loading={loading}
      style={{ width: '80%' }}
      company_name={cmp_name}
      visible={visible}
      {...rest}
    >
      <CreatePlacementTestForm form={form} onCreate={create} />
    </DefaultModal> : null
  );
};

export default CreatePlacementTestModal;
