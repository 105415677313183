import React, { useRef, useState, useMemo } from 'react';
import { Form, Input, Button, DatePicker, Typography, Radio, Checkbox, AutoComplete } from 'antd';
import { useMutation } from '@apollo/client';
import moment from 'moment';

import FormField from 'src/components/common/FormField';
import SearchCampusModal from 'src/components/Modal/SearchCampusModal';
import { ApplicationForm, Top, Bottom, SurveyWrapper } from 'src/popups/LevelTestAppForm/LevelTestStyledComp';
import { CREATE_CONSULT_LEVELTEST_FOR_TALKINGSAM } from 'src/operations/mutations/consult';
import queryString from 'query-string';
const { Title } = Typography;

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

const checkboxLearningOptions = [
  { label: '혼자 학습하는 것을 선호한다.', value: '1' },
  { label: '그룹으로 학습하는 것을 선호한다.(경쟁심 동기부여)', value: '2' },
  { label: '학습을 자기 주도적으로 한다.', value: '3' },
  { label: '차분하고 꼼꼼한 성격이다.', value: '4' },
  { label: '의욕은 있지만 꼼꼼하지 못하다.', value: '5' },
  { label: '외향적인 성격으로 발표하는 것을 좋아한다.', value: '6' },
  { label: '내성적인 성격으로 친구들 앞에서 이야기 하는 것을 어려워한다.', value: '7' },
];

const gradeList = ['초1', '초2', '초3', '초4', '초5', '초6', '중1', '중2', '중3', '고1', '고2', '고3'];

const LevelTestAppForm = () => {
  const params = queryString.parse(window.location.search);
  const company_name = params.company ? params.company : '';
  const subdomain = useMemo(() => {
    if (window.location.hostname) {
      return window.location.hostname.split('.')[0];
    }
    return '';
  }, []);
  let currentCompany = company_name !== '' ? company_name : subdomain;
  const survey1_1_memo = useRef();
  const survey3_1_memo = useRef();
  const survey3_2_memo = useRef();
  const survey3_3_memo = useRef();
  const survey3_4_memo = useRef();
  const survey3_5_memo = useRef();
  const survey4_1_memo = useRef();
  const survey5_8_memo = useRef();

  const [visible, setVisible] = useState(false);

  const [form] = Form.useForm();

  const [create, { loading }] = useMutation(CREATE_CONSULT_LEVELTEST_FOR_TALKINGSAM, {
    onCompleted(data) {
      if (data) {
        alert('제출했습니다.');
        window.self.close();
      } else {
        alert('오류가 발생했습니다.');
      }
    },
    onError(error) {
      console.log('error', error);
      alert('오류가 발생했습니다(2).');
    },
  });

  const renderItem = (title) => ({
    value: title,
    label: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {title}
      </div>
    ),
  });

  const handleCancelModal = () => {
    setVisible(false);
  };

  const handleChooseCampus = (values) => {
    console.log('handleChooseCampus', values);
    const { campus_name, campus_idx } = values;
    form.setFieldsValue({ campus_name, campus_idx });
    setVisible(false);
  };

  const handleSubmit = (values) => {
    const variables = {};
    const keys = Object.keys(values);
    for (const key of keys) {
      if (key === 'reserve_date' || key === 'birth_date') {
        variables[key] = moment(values[key]).format('YYYY-MM-DD');
      } else if (key === 'campus_name') {
        //pass
      } else if (key.includes('survey') && values[key] instanceof Array) {
        variables[key] = values[key].join('|');
      } else {
        variables[key] = values[key];
      }
    }
    const surveyRefs = {
      survey1_1_memo,
      survey3_1_memo,
      survey3_2_memo,
      survey3_3_memo,
      survey3_4_memo,
      survey3_5_memo,
      survey4_1_memo,
      survey5_8_memo,
    };
    const refKeys = Object.keys(surveyRefs);

    for (const refKey of refKeys) {
      if (surveyRefs[refKey]?.current?.state?.value) {
        variables[refKey] = surveyRefs[refKey].current.state.value;
      }
    }
    console.log('variables', variables);
    if (currentCompany === 'educo') {
      variables['ticket_code'] = 'EDUCO-PT';
      variables['survey1'] = '2';
      variables['survey2'] = '1';
      variables['survey3'] = '2';
      variables['survey4'] = '1';
      variables['survey5'] = '1';
      variables['survey6'] = '1';
    }
    create({ variables });
    return false;
  };
  const checkboxExpOptions = [
    {
      label: (
        <>
          영어유치원 (유치원명:{' '}
          <Input
            ref={survey3_1_memo}
            placeholder="예: 토킹샘유치원 1년"
            style={{ display: 'inline-block', width: '50%', marginLeft: '10px' }}
          />
          )
        </>
      ),
      value: '1',
    },
    {
      label: (
        <>
          어학원 (학원명:{' '}
          <Input
            ref={survey3_2_memo}
            placeholder="예: 토킹샘아카데미 6개월"
            style={{ display: 'inline-block', width: '50%', marginLeft: '10px' }}
          />
          )
        </>
      ),
      value: '2',
    },
    {
      label: (
        <>
          학습지 (학습지명{' '}
          <Input
            ref={survey3_3_memo}
            placeholder="예: 토킹샘 학습 1년"
            style={{ display: 'inline-block', width: '50%', marginLeft: '10px' }}
          />
          )
        </>
      ),
      value: '3',
    },
    {
      label: (
        <>
          1:1(개인)교습{' '}
          <Input
            ref={survey3_4_memo}
            placeholder="예: 파닉스 3개월"
            style={{ display: 'inline-block', width: '50%', marginLeft: '10px' }}
          />
        </>
      ),
      value: '4',
    },
    {
      label: (
        <>
          기타 <Input ref={survey3_5_memo} placeholder="" style={{ display: 'inline-block', width: '50%', marginLeft: '10px' }} />
        </>
      ),
      value: '5',
    },
  ];

  const checkboxHardOptions = [
    { label: 'Phonics', value: '1' },
    { label: 'Grammar', value: '2' },
    { label: 'Vocabulary', value: '3' },
    { label: 'Listening', value: '4' },
    { label: 'Speaking', value: '5' },
    { label: 'Reading', value: '6' },
    { label: 'Writing', value: '7' },
    {
      label: (
        <>
          기타 <Input ref={survey5_8_memo} style={{ display: 'inline-block', width: '50%', marginLeft: '10px' }} />
        </>
      ),
      value: '8',
    },
  ];
  return (
    <>
      <Top>
        <h1 style={{ fontWeight: 'bold', fontSize: 36, color: '#fff' }}>
          {`${currentCompany === 'educo' ? 'Level' : 'Placement'}`} Test 신청
        </h1>
      </Top>
      <ApplicationForm>
        <Form layout="inline" style={{ width: '100%' }} form={form} onFinish={handleSubmit}>
          <Form.Item
            style={{ display: 'none' }}
            name="campus_idx"
            rules={[
              {
                required: true,
                message: '학원을 선택해 주세요.',
              },
            ]}
          >
            <Input type="hidden" name="campus_idx" />
          </Form.Item>
          <FormField hasFeedback={true} title="이름">
            <Form.Item
              name="student_name"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: '학생이름을 입력해 주세요.',
                },
              ]}
            >
              <Input placeholder="학생이름" size="large" name="student_name"></Input>
            </Form.Item>
          </FormField>
          <FormField hasFeedback={true} title="생년월일">
            <Form.Item
              name="birth_date"
              rules={[
                {
                  required: true,
                  message: '입력해주세요.',
                },
              ]}
            >
              <DatePicker size="large" name="birth_date" defaultValue={moment('2010-01-01')} inputReadOnly />
            </Form.Item>
          </FormField>
          <FormField hasFeedback={true} title="학교/학년">
            <Form.Item
              name="school_info"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: '입력해 주세요.',
                },
              ]}
            >
              {currentCompany === 'educo' ? (
                <AutoComplete
                  name="school_info"
                  popupClassName="certain-category-search-dropdown"
                  options={gradeList.map((grade) => renderItem(grade))}
                ></AutoComplete>
              ) : (
                <Input placeholder="학교/학년" size="large" name="school_info" />
              )}
            </Form.Item>
          </FormField>
          <FormField hasFeedback={true} title="학부모전화">
            <Form.Item
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: '숫자만 입력해 주세요.',
                },
              ]}
              name="phone"
            >
              <Input type="text" placeholder="학부모전화" size="large" name="phone" maxLength={13} />
            </Form.Item>
          </FormField>
          <FormField hasFeedback={true} title="캠퍼스">
            <Form.Item
              name="campus_name"
              rules={[
                {
                  required: true,
                  message: '학원을 선택해 주세요.',
                },
              ]}
            >
              <Input.Search
                readOnly
                type="text"
                placeholder=""
                size="large"
                name="campus_name"
                onSearch={() => {
                  setVisible(true);
                }}
              />
            </Form.Item>
          </FormField>
          <FormField hasFeedback={true} title="상담희망일">
            <Form.Item
              name="reserve_date"
              rules={[
                {
                  required: true,
                  message: '예약날짜를 선택해 주세요.',
                },
              ]}
            >
              <DatePicker size="large" name="reserve_date" inputReadOnly />
            </Form.Item>
          </FormField>

          {currentCompany !== 'educo' && (
            <SurveyWrapper>
              <Title level={5}>1. 방문하시게 된 계기를 입력해 주세요.</Title>
              <Form.Item
                name="survey1"
                style={{ width: '90%' }}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: '선택해 주세요.',
                  },
                ]}
                shouldUpdate={(prevValues, curValues) => prevValues.survey1 === curValues.survey1}
              >
                <Radio.Group name="survey1">
                  <Radio style={radioStyle} value={'1'}>
                    지인의 소개
                    <Input
                      ref={survey1_1_memo}
                      placeholder="(예: 학생이름)"
                      name="survey1_1"
                      style={{ display: 'inline-block', width: '50%', marginLeft: '10px' }}
                    />
                  </Radio>
                  <Radio style={radioStyle} value={'2'}>
                    광고지
                  </Radio>
                  <Radio style={radioStyle} value={'3'}>
                    간판
                  </Radio>
                  <Radio style={radioStyle} value={'4'}>
                    인터넷
                  </Radio>
                  <Radio style={radioStyle} value={'5'}>
                    배너광고
                  </Radio>
                </Radio.Group>
              </Form.Item>

              <Title level={5}>2. 영어공부(학원, 개인교습 등) 경험이 있나요? 있다면 이름과 기간을 알려주세요.</Title>
              <Form.Item
                name="survey2"
                style={{ width: '90%' }}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: '선택해 주세요.',
                  },
                ]}
              >
                <Radio.Group name="survey2">
                  <Radio style={radioStyle} value={'1'}>
                    있다.
                  </Radio>
                  <Radio style={radioStyle} value={'2'}>
                    없다.
                  </Radio>
                </Radio.Group>
              </Form.Item>

              <Title level={4}>2-a. '네'를 선택하신 경우 입력해 주세요.(중복선택가능)</Title>
              <Form.Item name="survey3" style={{ width: '90%' }}>
                <Checkbox.Group name="survey3" options={checkboxExpOptions} />
              </Form.Item>

              <Title level={5}>3. 해외 체류 경험(어학연수, 유학 등)이 있나요? 있다면 장소와 기간을 알려주세요.</Title>
              <Form.Item
                name="survey4"
                style={{ width: '90%' }}
                rules={[
                  {
                    required: true,
                    message: '선택해 주세요.',
                  },
                ]}
              >
                <Radio.Group name="survey4">
                  <Radio style={radioStyle} value={'1'}>
                    있다.
                    <Input
                      ref={survey4_1_memo}
                      placeholder="예: 캐나다 6개월 2018.03~2018.08"
                      style={{ display: 'inline-block', width: '70%', marginLeft: '10px' }}
                    />
                  </Radio>
                  <Radio style={radioStyle} value={'2'}>
                    없다.
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <Title level={5}>4. 자녀가 가장 어려워하거나 중점적으로 학습했으면 하는 영역은 무엇인가요?(중복 선택 가능)</Title>
              <Form.Item
                name="survey5"
                style={{ width: '90%' }}
                rules={[
                  {
                    required: true,
                    message: '선택해 주세요.',
                  },
                ]}
              >
                <Checkbox.Group name="survey5" options={checkboxHardOptions} />
              </Form.Item>
              <Title level={5}>5. 학부모님께서 생각하시는 자녀의 학습 성향을 선택해주세요. (중복 선택 가능)</Title>
              <Form.Item
                name="survey6"
                style={{ width: '90%' }}
                rules={[
                  {
                    required: true,
                    message: '선택해 주세요.',
                  },
                ]}
              >
                <Checkbox.Group name="survey6" options={checkboxLearningOptions} style={{ display: 'block' }} />
              </Form.Item>
            </SurveyWrapper>
          )}
        </Form>
      </ApplicationForm>
      <Bottom>
        <Button
          type="primary"
          loading={loading}
          onClick={() => {
            form.submit();
          }}
        >
          제출
        </Button>
      </Bottom>
      <SearchCampusModal
        company={currentCompany ? currentCompany : 'educo'}
        visible={visible}
        onCancel={handleCancelModal}
        onChoose={handleChooseCampus}
      />
    </>
  );
};

export default LevelTestAppForm;
