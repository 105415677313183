import { gql } from '@apollo/client';

export const TESTS_LIST = gql`
  query testsList($type: String, $q: String, $page: Int, $take: Int, $category_ids: [Int!]) {
    testsList(type: $type, q: $q, page: $page, take: $take, category_ids: $category_ids) {
      total
      page
      take
      tests {
        idx
        title
        idate
        code
        meta
        user {
          idx
          name
        }
        campus {
          idx
          name
        }
        idate
      }
    }
  }
`;

export const TEST_CATEGORY_LIST = gql`
  query testCategoryList($parent_id: Int, $company_idx: Int) {
    testCategoryList(parent_id: $parent_id, company_idx: $company_idx) {
      idx
      name
      parent_id
      other_test_category {
        idx
        name
        parent_id
        other_test_category {
          idx
          name
          parent_id
        }
      }
    }
  }
`;

export const TESTS_SINGLE = gql`
  query testsSingle($idx: Int!) {
    testsSingle(idx: $idx) {
      idx
      title
      idate
      meta
      code
      test_document_idx
      test_category_idx
      main_pdf {
        idx
        name
        path
        mime_type
        encoding
        is_public
        storage_type
        folders {
          idx
          name
          idate
          user {
            idx
            name
          }
        }
        user {
          idx
          name
        }
        videos {
          idx
          title
          video_id
          data
        }
        idate
      }
      extra_pdf {
        idx
        name
        path
        mime_type
        encoding
        is_public
        storage_type
        folders {
          idx
          name
          idate
          user {
            idx
            name
          }
        }
        user {
          idx
          name
        }
        videos {
          idx
          title
          video_id
          data
        }
        idate
      }
      extra_audio {
        idx
        name
        path
        mime_type
        encoding
        is_public
        storage_type
        folders {
          idx
          name
          idate
          user {
            idx
            name
          }
        }
        user {
          idx
          name
        }
        videos {
          idx
          title
          video_id
          data
        }
        idate
      }
      extra_video {
        idx
        name
        path
        mime_type
        encoding
        is_public
        storage_type
        folders {
          idx
          name
          idate
          user {
            idx
            name
          }
        }
        user {
          idx
          name
        }
        videos {
          idx
          title
          video_id
          data
        }
        idate
      }
      test_answers {
        idx
        answer
        option_count
        code
        condition
        pdf {
          idx
          name
          path
          mime_type
          encoding
          is_public
          storage_type
          folders {
            idx
            name
            idate
            user {
              idx
              name
            }
          }
          user {
            idx
            name
          }
          videos {
            idx
            title
            video_id
            data
          }
          idate
        }
        audio {
          idx
          name
          path
          mime_type
          encoding
          is_public
          storage_type
          folders {
            idx
            name
            idate
            user {
              idx
              name
            }
          }
          user {
            idx
            name
          }
          videos {
            idx
            title
            video_id
            data
          }
          idate
        }
        video {
          idx
          name
          path
          mime_type
          encoding
          is_public
          storage_type
          folders {
            idx
            name
            idate
            user {
              idx
              name
            }
          }
          user {
            idx
            name
          }
          videos {
            idx
            title
            video_id
            data
          }
          idate
        }
      }
      user {
        idx
        name
      }
      campus {
        idx
        name
      }
      idate
    }
  }
`;

export const ASSIGNED_TEST_LIST = gql`
  query assignedTestsList($class_idx: Int, $class_type: Int, $q: String, $page: Int, $take: Int) {
    assignedTestsList(class_idx: $class_idx, class_type: $class_type, q: $q, page: $page, take: $take) {
      total
      page
      take
      assigned_tests {
        idx
        title
        idate
        start_time
        done
        class {
          idx
          name
        }
        tests {
          idx
          title
          code
        }
        user {
          idx
          name
        }
        assigned_test_users {
          idx
          user {
            idx
          }
        }
        idate
      }
    }
  }
`;

export const ASSIGNED_TEST_USER_LIST = gql`
  query assignedTestUserList($class_idx: Int, $q: String, $page: Int, $take: Int) {
    assignedTestUserList(class_idx: $class_idx, q: $q, page: $page, take: $take) {
      total
      page
      take
      assigned_test_users {
        idx
        status
        score
        idate
        start_time
        end_time
        assigned_tests {
          idx
          title
          start_time
          tests {
            idx
            title
            code
          }
        }
        assigned_test_answers {
          code
        }
        user {
          idx
          name
        }
        idate
      }
    }
  }
`;

export const ASSIGNED_TEST_USER_SINGLE = gql`
  query assignedTestUserSingle($idx: Int!, $user_idx: Int) {
    assignedTestUserSingle(idx: $idx, user_idx: $user_idx) {
      idx
      status
      score
      idate
      start_time
      end_time
      assigned_tests {
        idx
        title
        start_time
        limit_time
        retries_count
        tests {
          idx
          title
          code
          test_document_idx
          main_pdf {
            path
            name
          }
          extra_pdf {
            idx
            name
            path
            mime_type
            encoding
            is_public
            storage_type
            folders {
              idx
              name
              idate
              user {
                idx
                name
              }
            }
            user {
              idx
              name
            }
            videos {
              idx
              title
              video_id
              data
            }
            idate
          }
          extra_audio {
            idx
            name
            path
            mime_type
            encoding
            is_public
            storage_type
            folders {
              idx
              name
              idate
              user {
                idx
                name
              }
            }
            user {
              idx
              name
            }
            videos {
              idx
              title
              video_id
              data
            }
            idate
          }
          extra_video {
            idx
            name
            path
            mime_type
            encoding
            is_public
            storage_type
            folders {
              idx
              name
              idate
              user {
                idx
                name
              }
            }
            user {
              idx
              name
            }
            videos {
              idx
              title
              video_id
              data
            }
            idate
          }
          test_answers {
            idx
            code
            answer
            option_count
            condition
            pdf {
              idx
              name
              path
              mime_type
              encoding
              is_public
              storage_type
              folders {
                idx
                name
                idate
                user {
                  idx
                  name
                }
              }
              user {
                idx
                name
              }
              videos {
                idx
                title
                video_id
                data
              }
              idate
            }
            audio {
              idx
              name
              path
              mime_type
              encoding
              is_public
              storage_type
              folders {
                idx
                name
                idate
                user {
                  idx
                  name
                }
              }
              user {
                idx
                name
              }
              videos {
                idx
                title
                video_id
                data
              }
              idate
            }
            video {
              idx
              name
              path
              mime_type
              encoding
              is_public
              storage_type
              folders {
                idx
                name
                idate
                user {
                  idx
                  name
                }
              }
              user {
                idx
                name
              }
              videos {
                idx
                title
                video_id
                data
              }
              idate
            }
            question_idx
            questions {
              parent_id
              number
              question
              question_type
              information_text
              question_context
              question_direction
              answer
              answer_description
              options
              option_count
              condition
              questions {
                question
                question_context
              }
            }
          }
        }
      }
      assigned_test_answers {
        idx
        answer
        is_correct
        code
        sure
      }
      assigned_test_answer_retakes {
        idx
        answer
        is_correct
        code
        sure
        retries
      }
      user {
        idx
        name
      }
      idate
    }
  }
`;

export const ASSIGNED_TEST_USER_SINGLE_META = gql`
  query assignedTestUserSingle($idx: Int!, $user_idx: Int) {
    assignedTestUserSingle(idx: $idx, user_idx: $user_idx) {
      idx
      status
      score
      idate
      start_time
      end_time
      assigned_tests {
        idx
        title
        start_time
        limit_time
        retries_count
        tests {
          idx
          title
          code
          test_document_idx
          batch_type
          test_type
          form_id
          total_questions
          main_pdf {
            path
            name
          }
          extra_pdf {
            idx
            name
            path
            mime_type
            encoding
            is_public
            storage_type
            folders {
              idx
              name
              idate
              user {
                idx
                name
              }
            }
            user {
              idx
              name
            }
            videos {
              idx
              title
              video_id
              data
            }
            idate
          }
          extra_audio {
            idx
            name
            path
            mime_type
            encoding
            is_public
            storage_type
            folders {
              idx
              name
              idate
              user {
                idx
                name
              }
            }
            user {
              idx
              name
            }
            videos {
              idx
              title
              video_id
              data
            }
            idate
          }
          extra_video {
            idx
            name
            path
            mime_type
            encoding
            is_public
            storage_type
            folders {
              idx
              name
              idate
              user {
                idx
                name
              }
            }
            user {
              idx
              name
            }
            videos {
              idx
              title
              video_id
              data
            }
            idate
          }
          test_answers {
            idx
            code
            answer
            option_count
            condition
            pdf {
              idx
              name
              path
              mime_type
              encoding
              is_public
              storage_type
              folders {
                idx
                name
                idate
                user {
                  idx
                  name
                }
              }
              user {
                idx
                name
              }
              videos {
                idx
                title
                video_id
                data
              }
              idate
            }
            audio {
              idx
              name
              path
              mime_type
              encoding
              is_public
              storage_type
              folders {
                idx
                name
                idate
                user {
                  idx
                  name
                }
              }
              user {
                idx
                name
              }
              videos {
                idx
                title
                video_id
                data
              }
              idate
            }
            video {
              idx
              name
              path
              mime_type
              encoding
              is_public
              storage_type
              folders {
                idx
                name
                idate
                user {
                  idx
                  name
                }
              }
              user {
                idx
                name
              }
              videos {
                idx
                title
                video_id
                data
              }
              idate
            }
            question_idx
            questions {
              parent_id
              number
              question
              question_type
              information_text
              question_context
              question_direction
              answer
              answer_description
              options
              option_count
              condition
              image
              mp3
              questions {
                question
                question_context
              }
            }
          }
        }
      }
      assigned_test_answers {
        idx
        answer
        is_correct
        code
        sure
      }
      assigned_test_answer_retakes {
        idx
        answer
        is_correct
        code
        sure
        retries
      }
      user {
        idx
        name
      }
      idate
    }
  }
`;

export const ASSIGNED_TEST_SINGLE = gql`
  query assignedTestsSingle($idx: Int!) {
    assignedTestsSingle(idx: $idx) {
      idx
      title
      idate
      start_time
      done
      retries_count
      class {
        idx
      }
      tests {
        idx
        title
        code
        test_document_idx
        main_pdf {
          path
          name
        }
        test_answers {
          idx
          code
          answer
          condition
        }
      }
      user {
        idx
        name
      }
      assigned_test_users {
        idx
        score
        start_time
        end_time
        user {
          idx
          name
        }
        assigned_test_answers {
          idx
          answer
          is_correct
          code
          sure
        }
        assigned_test_answer_retakes {
          idx
          answer
          is_correct
          code
          sure
          retries
        }
      }
      idate
    }
  }
`;

export const ASSIGNED_TEST_USER_SINGLE_FOR_MANGER = gql`
  query assignedTestUserSingleForManager($idx: Int!) {
    assignedTestUserSingleForManager(idx: $idx) {
      idx
      status
      score
      start_time
      end_time
      user {
        idx
        id
        name
        parent_phone
        stat
        class_student {
          class {
            name
          }
        }
      }
      assigned_tests {
        title
        tests {
          idx
          title
          test_answers {
            meta
            code
            answer
            condition
          }
        }
      }
      assigned_test_answers {
        code
        answer
        is_correct
      }
    }
  }
`;

export const ASSIGNED_TEST_USER_SINGLE_FOR_MANGER_QUESTIONS = gql`
  query assignedTestUserSingleForManager($idx: Int!) {
    assignedTestUserSingleForManager(idx: $idx) {
      idx
      status
      score
      start_time
      end_time
      user {
        idx
        id
        name
        parent_phone
        stat
        class_student {
          class {
            name
          }
        }
      }
      assigned_tests {
        title
        tests {
          idx
          title
          type
          test_type
          test_answers {
            meta
            code
            answer
            condition
            question_idx
            questions {
              parent_id
              number
              question
              question_type
              information_text
              question_context
              question_direction
              answer
              answer_description
              options
              option_count
              condition
              questions {
                question
                question_context
              }
            }
          }
        }
      }
      assigned_test_answers {
        code
        answer
        is_correct
      }
    }
  }
`;

export const TEST_GROUP_LIST = gql`
  query testGroupList($type: String, $searchText: String, $category_ids: [Int!]) {
    testGroupList(type: $type, searchText: $searchText, category_ids: $category_ids) {
      idx
      name
      idate
      tests {
        idx
        title
      }
    }
  }
`;
export const DAILY_TEST_GROUP_LIST = gql`
  query dailyTestGroupList($type: String, $searchText: String) {
    dailyTestGroupList(type: $type, searchText: $searchText)
  }
`;
export const DAILY_TEST_GROUP_DETAIL = gql`
  query dailyTestGroupDetail($groupIdx: Int!) {
    dailyTestGroupDetail(groupIdx: $groupIdx)
  }
`;

export const DAILY_TEST_GROUP_RESULT_LIST = gql`
  query dailyTestGroupResultList($type: String, $searchText: String, $campus_idx: Int, $class_idx: Int, $parent_idx: Int) {
    dailyTestGroupResultList(
      type: $type
      searchText: $searchText
      campus_idx: $campus_idx
      class_idx: $class_idx
      parent_idx: $parent_idx
    ) {
      idx
      daily_test_group_idx
      class_idx
      create_user_idx
      assigned_date
      test_time
      cut_line
      no_of_retake
      idate
      class_idx_details
      count
      test_group_detail
    }
  }
`;

export const DAILY_TEST_GROUP_PARENT_RESULT_LIST = gql`
  query dailyTestGroupResultParentList($type: String, $searchText: String, $campus_idx: Int, $class_idx: Int) {
    dailyTestGroupResultParentList(type: $type, searchText: $searchText, campus_idx: $campus_idx, class_idx: $class_idx) {
      idx
      begin_date
      end_date
      count
    }
  }
`;

export const TEST_GROUP_INFO = gql`
  query testGroupInfo($idx: Int!) {
    testGroupInfo(idx: $idx) {
      idx
      base_code
      test_category_idx
      user {
        idx
        name
      }

      campus {
        idx
      }

      company {
        idx
      }
      name
      idate
      tests {
        idx
        code
        title
        meta
        type
        main_pdf {
          idx
        }
        extra_pdf {
          idx
        }
        extra_audio {
          idx
        }
        extra_video {
          idx
        }
        total_questions
        test_group {
          name
        }
        user {
          idx
          name
        }
      }
    }
  }
`;

export const DAILY_TEST_GROUP_INFO = gql`
  query dailytestGroupInfo($idx: [Int!]!) {
    dailytestGroupInfo(idx: $idx)
  }
`;
export const GROUP_WISE_TEST_LIST = gql`
  query groupWiseTestList($idx: Int!) {
    groupWiseTestList(idx: $idx)
  }
`;

export const ASSIGN_TESTS_USERS = gql`
  query assignTestsUsers($user_idx: Int!) {
    assignTestsUsers(user_idx: $user_idx) {
      idx
      user {
        name
      }
      assigned_tests {
        title
      }
      start_time
      end_time
      score
      status
      idate
    }
  }
`;

export const TEST_GROUP_ASSIGNED_LIST = gql`
  query testGroupAssignedList($class_idx: Int) {
    testGroupAssignedList(class_idx: $class_idx) {
      idx
      user {
        idx
        name
      }

      class {
        idx
        name
      }

      assigned_tests {
        idx
        title
        start_time
        assigned_test_users {
          user_idx
          status
          score
          user {
            name
          }
          assigned_tests {
            idx
          }
        }
      }

      test_group {
        idx
        name
        tests {
          idx
          assigned_tests {
            idx
            title
            start_time
          }
        }
      }

      begin_date
      end_date
      test_time
      idate
    }
  }
`;

export const TEST_GROUP_ASSIGNED_DETAIL = gql`
  query testGroupAssignedDetail($test_group_assigned_idx: Int!) {
    testGroupAssignedDetail(test_group_assigned_idx: $test_group_assigned_idx) {
      idx
      user {
        idx
        name
      }

      class {
        idx
        name
      }

      assigned_tests {
        idx
        title
        start_time
        assigned_test_users {
          user_idx
          status
          score
          user {
            name
          }
          assigned_tests {
            idx
            title
            start_time
          }
        }
      }

      test_group {
        idx
        name
        tests {
          idx
          assigned_tests {
            idx
            title
            start_time
            assigned_test_users {
              user_idx
              status
              score
              user {
                name
              }
              assigned_tests {
                idx
              }
            }
          }
        }
      }

      begin_date
      end_date
      test_time
      idate
    }
  }
`;

export const ASSIGNED_TEST_USERS_SINGLE_FOR_MANGER = gql`
  query assignedTestUsersSingleForManager($idx: Int!) {
    assignedTestUsersSingleForManager(idx: $idx) {
      idx
      status
      score
      start_time
      end_time
      user {
        idx
        id
        name
        school_name
        school_grade
        parent_phone
        stat
        class_student {
          class {
            name
          }
        }
      }
      assigned_tests {
        title
        tests {
          idx
          title
          test_answers {
            code
            answer
            meta
            condition
            option_count
          }
        }
      }
      assigned_test_answers {
        code
        answer
        is_correct
        sure
      }
      assigned_test_answer_retakes {
        idx
        answer
        is_correct
        code
        sure
      }
    }
  }
`;

export const TEST_GROUP_RESULT_LIST = gql`
  query testGroupResultList($daily_test_group_assigned_idx: Int!) {
    testGroupResultList(daily_test_group_assigned_idx: $daily_test_group_assigned_idx)
  }
`;
export const TEST_DETAIL = gql`
  query getTest($daily_test_group_assigned_idx: Int!) {
    getTest(daily_test_group_assigned_idx: $daily_test_group_assigned_idx)
  }
`;
export const DAILY_TEST_GROUP_RESULT_ClASS_WISE = gql`
  query getTestResultClassWise($assigned_group_idx: Int!, $class_idx: Int!, $test_idx: Int!) {
    getTestResultClassWise(assigned_group_idx: $assigned_group_idx, class_idx: $class_idx, test_idx: $test_idx)
  }
`;
export const STUDENT_RESULT_TEST_WISE = gql`
  query getStudentResultTestWise($assigned_group_idx: Int!, $class_idx: Int!, $test_idx: Int!, $user_idx: Int!) {
    getStudentResultTestWise(assigned_group_idx: $assigned_group_idx, class_idx: $class_idx, test_idx: $test_idx, user_idx: $user_idx)
  }
`;

export const TEST_RESULT = gql`
  query getTestResult($assigned_group_idx: Int!, $class_idx: Int!, $test_idx: Int!, $user_idx: Int!) {
    getTestResult(assigned_group_idx: $assigned_group_idx, class_idx: $class_idx, test_idx: $test_idx, user_idx: $user_idx)
  }
`;
export const DAILY_ASSIGNED_TEST_LIST = gql`
  query assignedTestsResultList($class_idx: Int, $class_type: Int, $q: String, $page: Int, $take: Int) {
    assignedTestsResultList(class_idx: $class_idx, class_type: $class_type, q: $q, page: $page, take: $take) {
      idx
      daily_test_group_idx
      class_idx
      create_user_idx
      assigned_date
      test_time
      cut_line
      no_of_retake
      idate
      class_idx_details
      count
      test_group_detail
    }
  }
`;

// export const DAILY_GROUP_ASSIGNED_TEST_LIST = gql`
//   query getDailyGroupAssignedTestList($assigned_idx: Int!, $test_idx: Int!) {
//     getDailyGroupAssignedTestList(assigned_idx: $assigned_idx, test_idx: $test_idx)
//   }
// `;
