import React, { useState, useEffect, useMemo } from 'react';
import { Link, withRouter } from 'react-router-dom';
import fileDownload from 'js-file-download';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Checkbox, Button, Space, Input, Modal, Select, Form, DatePicker, List, Tooltip } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
import XLSX from 'xlsx';
import { useHistory } from 'react-router-dom';
import * as _ from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import { userInfoVar } from 'src/apollo/cache';
import { openNotification } from 'src/components/common/Notification';
import WritingReportCoachMenuGroup from 'src/components/common/WritingReportCoachMenuGroup';
import { CLASS_STUDENT_LIST, GET_TYPE_CLASS } from 'src/operations/queries/getClass';
import { ASSIGN_AI_TOPIC_TO_STUDENTS, AI_TOPIC_DELETE, DELETE_AI_GROUP, EDIT_AI_TOPIC } from 'src/operations/mutations/aiTopic';
import { AI_Talking_TOPICS_LIST } from 'src/operations/queries/aiGroupTopic';
import { CREATE_AI_GROUP_TOPIC } from 'src/operations/mutations/aiGroupTopic';
import { AI_TOPICS_LIST } from 'src/operations/queries/aiTopic';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { DeleteOutlined, PlusCircleOutlined, DownCircleOutlined } from '@ant-design/icons';
const { Option } = Select;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;
const ColumnFlex = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const AssignModal = styled(Modal)`
  .ant-modal-header {
    background-color: #deeaf6;
    border-radius: 20px;
  }
  .ant-modal-content {
    border: 3px solid #fff2cc;
    border-radius: 20px;
    background-color: #deeaf6;
  }
  width: 860px;
  min-width: 860px;
  @media (max-width: 900px) {
    min-width: 750px;
    width: 750px;
  }
`;
const ModalLabel = styled.div`
  color: #374a5d;
  font-weight: bold;
  padding-right: 10px;
`;
const EditFormItem = styled(Form.Item)`
  .ant-form-item-label > label {
    width: 100%;
  }
`;

const WritingCoachAssign = () => {
  const companyName = useSelector(classStoreData);
  const history = useHistory();
  const [books, setBooks] = useState([]);
  const [topicGroups, setTopicGroups] = useState([]);
  const [editData, setEditData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [topicType, setTopicType] = useState('book-report-group');
  const [classes, setClasses] = useState([]);
  const [editVisible, setEditVisible] = useState(false);
  const [level, setLevel] = useState(-1);
  const [searchValue, setSeachValue] = useState('');
  const [folderType, setFolderType] = useState(userInfoVar()?.type === 2 ? 'campus' : userInfoVar()?.type === 4 ? 'publisher' : 'me');
  const [assignBook, setAssignBook] = useState([]);
  const [assignModal, setAssignModal] = useState(false);
  const [pg, setPg] = useState({ current: 0, pageSize: 20 });
  const [ch, setCh] = useState(
    books.map((e) => {
      const obj = {
        idx: e.idx,
        value: false,
      };
      return obj;
    }),
  );
  useEffect(() => {
    if (level !== -1) {
      setPg({ current: 0, pageSize: 20 });
    }
    setPg({ ...pg, current: 0 });
  }, [folderType, topicType, level]);
  const [createAIGroupTopic, { loading: createLoading }] = useMutation(CREATE_AI_GROUP_TOPIC);
  const [deleteAITopicGroup] = useMutation(DELETE_AI_GROUP);
  // const [createAITopic, { loading: createLoading }] = useMutation(CREATE_AI_TOPIC);

  const [aiTopicDelete] = useMutation(AI_TOPIC_DELETE);

  const { data, refetch } = useQuery(AI_TOPICS_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      folderType: topicType === 'levelup' ? 'NA' : folderType,
      type: topicType,
      take: pg.pageSize,
      page: pg.current,
      q: searchValue,
      level: level === -1 ? null : topicType === 'levelup' ? `"level": ${level},` : `"level":"${level}"`,
    },
    skip: topicType === 'book-report-group',
  });

  const { data: groupData, refetch: refetch1 } = useQuery(AI_Talking_TOPICS_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      folderType: folderType,
      type: 'book-report',
      take: pg.pageSize,
      page: pg.current,
      q: searchValue,
      level: level !== -1 ? `${level}` : null,
    },
    skip: topicType === 'book-report' || topicType === 'levelup',
  });

  useEffect(() => {
    if (groupData?.aiTalkingGroupList) {
      setTopicGroups(
        groupData?.aiTalkingGroupList.aiTopicsGroup.map((item, key) => ({ ...item, total: groupData?.aiTalkingGroupList.total })),
      );
    }
  }, [groupData?.aiTalkingGroupList]);

  const classQuery = useQuery(GET_TYPE_CLASS, {
    variables: {},
  });

  useEffect(() => {
    if (classQuery?.data?.classList) {
      setClasses(classQuery?.data?.classList);
    }
  }, [classQuery?.data?.classList]);

  useEffect(() => {
    if (data?.aiTopicsList) {
      setBooks(
        data?.aiTopicsList.aiTopics.map((item, key) => {
          let topicData = JSON.parse(item.data || '{}');
          let groupDetail = JSON.parse(item.group_detail || '{}');
          return {
            ...item,
            item_no: key + 1,
            level: topicData?.book?.level,
            topic: topicData?.book?.topic,
            prompt: topicData?.book?.prompt,
            total: data?.aiTopicsList.total,
            group_name: groupDetail?.group_name || '',
          };
        }),
      );
    }
  }, [data]);

  const handleExcelFileDownload = () => {
    (async () => {
      const blob = await fetch('https://cdn.cloubot.com/common/topics_group.xlsx').then((r) => r.blob());
      fileDownload(blob, 'topics.xlsx');
    })();
  };

  useEffect(() => {
    setCh(
      books.map((e) => {
        const obj = {
          idx: e.idx,
          value: false,
        };
        return obj;
      }),
    );
  }, [books]);
  // console.log(books, ch);
  const onSeachChange = (event) => {
    setSeachValue(event.target.value);
  };

  function onChange(e, idx) {
    ch[ch.findIndex((element) => element.idx === idx)] = { idx: idx, value: e.target.checked };
    setCh(JSON.parse(JSON.stringify(ch)));
  }
  const currentPage = (i) => {
    return Math.floor(i / pg.pageSize) === pg.current - 1;
  };
  function onCheckAllChange(e) {
    const arr2 = _.cloneDeep(ch);
    if (e.target.checked) {
      const checked = arr2.map((e) => {
        const obj = {
          idx: e.idx,
          value: true,
        };
        return obj;
      });
      setCh(checked);
    } else {
      setCh(
        arr2.map((e) => {
          const obj = {
            idx: e.idx,
            value: false,
          };
          return obj;
        }),
      );
    }
  }
  if (loading) {
    return <div className="wait-comment">로딩중...</div>;
  }
  if (error) {
    return <div className="wait-comment">에러가 발생했습니다.</div>;
  }

  const handleDelete = async () => {
    const e = ch.filter((e) => e.value);
    try {
      await aiTopicDelete({
        variables: {
          idxs: e.map((e) => e.idx),
        },
      });
    } catch (e) {
      console.log(e);
    }
    refetch();
  };

  const handleAITopicDelete = () => {
    const e = ch.filter((e) => e.value);
    if (e.length > 0) {
      Modal.confirm({
        title: 'Delete',
        content: 'Do you want to delete the selected items?',
        onOk: () => {
          handleDelete();
        },
        onCancel() {},
      });
    } else {
      alert('Select the topic/prompts!');
    }
  };

  const handleDeleteGroup = async (idx) => {
    try {
      await deleteAITopicGroup({
        variables: { idx: idx },
      }).then((result) => {
        if (result?.error) {
          openNotification('삭제 중 오류가 발생했습니다.');
        } else {
          refetch1();
          openNotification('귀하의 기록이 성공적으로 삭제되었습니다!');
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleAiGroupDelete = (idx) => {
    Modal.confirm({
      title: 'Delete',
      content: 'Do you want to delete the selected items?',
      onOk: () => {
        handleDeleteGroup(idx);
      },
      onCancel() {},
    });
  };

  const columns = [
    {
      title: () => {
        const tmp = ch.map((e) => {
          return e.value;
        });
        return <Checkbox checked={tmp.filter((s) => s).length === tmp.length} onChange={onCheckAllChange}></Checkbox>;
      },
      align: 'center',
      key: 'action',
      render: (text, record, idx) => {
        return (
          <Space size="middle">
            <Checkbox
              idx={record.idx}
              checked={ch[ch.findIndex((element) => element.idx === record.idx)]?.value}
              onChange={(e) => onChange(e, record.idx)}
            ></Checkbox>
          </Space>
        );
      },
      width: 50,
    },
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: 50,
      render: (text, record, idx) => {
        return <div>{record.total - (pg.current * pg.pageSize + idx)}</div>;
      },
    },
    {
      title: 'Group Name',
      dataIndex: 'group_name',
      key: 'group_name',
      // align: 'center',
      sorter: (a, b) => a.group_name && a.group_name.localeCompare(b.group_name),
    },
    {
      title: 'Title',
      dataIndex: 'topic',
      key: 'topic',
      // align: 'center',
      sorter: (a, b) => a.topic && a.topic.localeCompare(b.topic),
    },
    {
      title: 'Prompt',
      dataIndex: 'prompt',
      key: 'prompt',
      // align: 'center',
      // width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="topLeft" color="#fff" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: 'Level',
      dataIndex: 'level',
      key: 'level',
      align: 'center',
      width: 100,
    },

    {
      title: 'Created',
      dataIndex: 'idate',
      key: 'idate',
      align: 'center',
      width: 100,
      render: (text, record) => {
        return <div>{moment(text).format('YYYY-MM-DD')}</div>;
      },
    },

    {
      title: 'Action',
      key: 'action',
      align: 'center',
      width: 100,
      render: (text, record, idx) => {
        let canEditOrDelete;
        if (userInfoVar()?.type === 2 && (folderType === 'campus' || folderType === 'me')) {
          canEditOrDelete = true;
        } else if (userInfoVar()?.type === 1 && folderType === 'me') {
          canEditOrDelete = true;
        } else if (userInfoVar()?.type === 4 && folderType === 'publisher') {
          canEditOrDelete = true;
        } else {
          canEditOrDelete = false;
        }

        const buttonStyle = canEditOrDelete ? { cursor: 'pointer' } : { opacity: '0.5', cursor: 'not-allowed' };
        return (
          <div className="table-btns">
            <>
              {topicType === 'book-report' && (
                <Button style={buttonStyle} size="small" onClick={canEditOrDelete ? () => handleEdit(record) : undefined}>
                  Edit
                </Button>
              )}
            </>
          </div>
        );
      },
    },
  ];

  const ColumnsGroup = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: 50,
      render: (text, record, idx) => {
        return <div>{record.total - (pg.current * pg.pageSize + idx)}</div>;
      },
    },
    {
      title: 'Group Name',
      dataIndex: 'group_name',
      key: 'group_name',
      align: 'center',
      width: 250,
      sorter: (a, b) => a.group_name && a.group_name.localeCompare(b.group_name),
    },
    {
      title: 'Level',
      dataIndex: 'level',
      key: 'level',
      align: 'center',
      width: 50,
    },
    {
      title: 'Lessons',
      dataIndex: 'lessons',
      key: 'lessons',
      align: 'center',
      width: 50,
      render: (text, record) => {
        return <div>{record?.ai_topic?.length}</div>;
      },
    },
    {
      title: 'Created',
      dataIndex: 'idate',
      key: 'idate',
      align: 'center',
      width: 100,
      render: (text, record) => {
        return <div>{moment(text).format('YYYY-MM-DD')}</div>;
      },
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      width: 100,
      render: (text, record, idx) => {
        let canEditOrDelete;
        if (userInfoVar()?.type === 2 && (folderType === 'campus' || folderType === 'me')) {
          canEditOrDelete = true;
        } else if (userInfoVar()?.type === 1 && folderType === 'me') {
          canEditOrDelete = true;
        } else if (userInfoVar()?.type === 4 && folderType === 'publisher') {
          canEditOrDelete = true;
        } else {
          canEditOrDelete = false;
        }

        const buttonStyle = canEditOrDelete ? { cursor: 'pointer' } : { opacity: '0.5', cursor: 'not-allowed' };
        return (
          <div className="table-btns">
            <>
              {userInfoVar()?.type <= 3 && (
                <Button size="small" className="ant-btn-primary" onClick={() => handleAssignGroupData(record.ai_topic)}>
                  Assign
                </Button>
              )}
              <Button style={buttonStyle} size="small" onClick={canEditOrDelete ? () => handleEdit(record) : undefined}>
                Edit
              </Button>
              <Button
                size="small"
                style={buttonStyle}
                onClick={canEditOrDelete ? () => handleAiGroupDelete(record.idx) : undefined}
                className="delet-btn"
              >
                Delete
              </Button>
            </>
          </div>
        );
      },
    },
  ];

  const handleFileSelect = async (data) => {
    let getGroupList = data.reduce((acc, cur) => {
      if (acc.indexOf(cur.group_name) === -1) {
        acc.push(cur.group_name);
      }
      return acc;
    }, []);

    for (let i = 0; i < getGroupList.length; i++) {
      let updatedArray = [];
      let groupData = data.filter((e) => e.group_name === getGroupList[i]);

      for (let i = 0; i < groupData.length; i++) {
        updatedArray.push({
          level: groupData[i].level.trim(),
          topic: groupData[i].title.trim(),
          prompt: groupData[i].prompt.trim(),
          group_name: groupData[i].group_name,
          title: groupData[i].title,
        });
      }
      try {
        await createAIGroupTopic({
          variables: {
            folder_type: folderType,
            type: 'book-report',
            data: JSON.stringify(updatedArray),
          },
        }).then((result) => {
          refetch1();
        });
        // for (let i = 0; i < data.length; i++) {
        //   await createAITopic({
        //     variables: {
        //       topic: data[i].prompt,
        //       type: 'book-report',
        //       folder_type: folderType,
        //       data: JSON.stringify({
        //         book: {
        //           level: data[i].level.trim(),
        //           topic: data[i].title.trim(),
        //           prompt: data[i].prompt.trim(),
        //         },
        //       }),
        //     },
        //   });
        // }
      } catch (e) {
        console.log(e);
      }
    }
  };
  const handleAssignData = () => {
    let data = [];
    books.forEach((item) => {
      ch?.forEach((checked) => {
        if (item.idx === checked.idx && checked.value) {
          data.push(item);
        }
      });
    });

    if (data?.length > 0) {
      localStorage.setItem('selectedPrompts', JSON.stringify(data));

      history.push(`/aicoach/topiclist/assign/`);
    } else {
      alert('Select the topic/prompts!');
    }
  };

  const handleAssignGroupData = (ai_topic) => {
    let data = [];

    for (let i = 0; i < ai_topic.length; i++) {
      const topicData = JSON.parse(ai_topic[i].data || '{}');
      data.push({
        idx: ai_topic[i].idx,
        level: topicData?.book?.level,
        topic: topicData?.book?.topic,
        prompt: topicData?.book?.prompt,
      });
    }

    if (data?.length > 0) {
      localStorage.setItem('selectedPrompts', JSON.stringify(data));
      history.push(`/aicoach/topiclist/assign/`);
    } else {
      alert('Select the topic/prompts!');
    }
  };

  const handleEdit = (record) => {
    setEditData(record);
    setEditVisible(true);
  };

  const LevelList =
    companyName === 'aone' && topicType !== 'levelup' ? [-1, 'CS', 'AS', 'TS', 'GS', 'SS'] : [-1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  return (
    <>
      {userInfoVar()?.type <= 3 ? (
        <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 10, paddingBottom: 10, alignItems: 'center' }}>
          <WritingReportCoachMenuGroup currentMenu="assign" />
          <Button type="primary" onClick={handleExcelFileDownload}>
            Title / Prompt 등록 양식 다운로드
          </Button>
        </div>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 10, paddingBottom: 0, marginLeft: 15 }}>
          <Button type="primary" onClick={handleExcelFileDownload}>
            Title / Prompt 등록 양식 다운로드
          </Button>
        </div>
      )}
      <div className="level-up-library-con">
        <div className="main-inner-wrap float__box">
          <div className="section__inner">
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className=" float__box">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h2>AI Writing Coach : Assign</h2>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* <div className="float__right lexile-select">
                  <Dropdown overlay={lv} trigger={['click']}>
                    <Link onClick={(e) => e.preventDefault()}>
                      <Space>
                        {level === 'All' ? 'Level' : level}
                        <DownOutlined />
                      </Space>
                    </Link>
                  </Dropdown>
                </div> */}
                {/* <Link to="/bookr/assign" className="headitems-add"> */}

                <Select style={{ width: 150, color: '#065aa9' }} value={level} onChange={(val) => setLevel(val)}>
                  {LevelList.map((e) => (
                    <Select.Option value={e}>{e === 0 ? 'PH' : e === -1 ? 'All level' : `${isNaN(e) ? '' : 'mx'} ${e}`}</Select.Option>
                  ))}
                </Select>

                {userInfoVar()?.type <= 4 && (
                  <Select style={{ width: 150, color: '#065aa9' }} value={topicType} onChange={(val) => setTopicType(val)}>
                    <Select.Option value="book-report-group">Ai Coach Group</Select.Option>
                    <Select.Option value="book-report">Ai Coach All</Select.Option>
                    <Select.Option value="levelup">Levelup</Select.Option>
                  </Select>
                )}
                {topicType !== 'levelup' && (
                  <>
                    <Select
                      style={{ width: 150, color: '#065aa9' }}
                      defaultValue={folderType}
                      placeholder="폴더 유형 선택"
                      optionFilterProp="children"
                      onChange={(val) => setFolderType(val)}
                    >
                      {/* <Select.Option value="publisher">Company</Select.Option>
                      <Select.Option value="campus">Campus</Select.Option>
                      <Select.Option value="me">me</Select.Option> */}
                      {userInfoVar()?.type === 4 ? (
                        <>
                          <Select.Option value="publisher">Company</Select.Option>
                        </>
                      ) : userInfoVar()?.type === 2 || userInfoVar()?.type === 1 ? (
                        <>
                          <Select.Option value="publisher">Company</Select.Option>
                          <Select.Option value="campus">Campus</Select.Option>
                          <Select.Option value="me">Personal</Select.Option>
                        </>
                      ) : (
                        <></>
                      )}
                    </Select>{' '}
                    {topicType === 'book-report-group' && <ExcelUpload onFileSelect={handleFileSelect} setLoading={setLoading} />}
                  </>
                )}

                <Input.Search
                  className="inputsearch-classroom"
                  placeholder="search"
                  allowClear
                  style={{ width: 250 }}
                  enterButton
                  onChange={onSeachChange}
                  value={searchValue}
                />
                {userInfoVar()?.type <= 3 && topicType !== 'book-report-group' && (
                  <Button style={{ marginLeft: 10 }} onClick={handleAssignData}>
                    Assign
                  </Button>
                )}
                {topicType === 'book-report' && (
                  <Button style={{ marginLeft: 10 }} onClick={handleAITopicDelete} type="primary" danger>
                    Delete
                  </Button>
                )}
              </div>
            </div>

            <CustomTable
              style={{ width: '100%' }}
              pagination={{
                showSizeChanger: true,
                pageSize: pg.pageSize,
                current: pg.current + 1,
                total: data?.aiTopicsList?.total || 0,
                position: ['bottomCenter'],
              }}
              dataSource={topicType === 'book-report-group' ? topicGroups : books}
              columns={topicType === 'book-report-group' ? ColumnsGroup : columns}
              loading={loading || createLoading}
              bordered
              scroll={{ y: 'calc(100vh - 274px)' }}
              onChange={(pagination) => {
                setPg({
                  current: pagination.current - 1,
                  pageSize: pagination.pageSize,
                });
              }}
              size="small"
              color="#edf3fb"
            />
          </div>
        </div>
      </div>
      <AssignModal
        title={<div style={{ fontSize: '20px', color: '#23394b' }}>Writing Coach Assign Page</div>}
        visible={assignModal}
        onCancel={() => {
          setAssignModal(false);
        }}
        footer={null}
        // width={900}
      >
        <AssignReport book={assignBook} classes={classes} close={() => setAssignModal(false)} />
      </AssignModal>
      <EditModal
        type={topicType}
        data={editData}
        refetch={() => {
          refetch();
          refetch1();
        }}
        folderType={folderType}
        visible={editVisible}
        onClose={() => setEditVisible(false)}
      />
    </>
  );
};

export default withRouter(WritingCoachAssign);

const AssignReport = ({ book, classes, close }) => {
  const [students, setStudents] = useState([]);
  const [selectedClass, setSelectedClass] = useState(-1);
  const [prompt, setPrompt] = useState(null);
  const [checkedList, setCheckedList] = useState([]);
  const [level, setLevel] = useState('Grade 1');
  const [assignDate, setAssignDate] = useState(null);
  const [dueDate, setDueDate] = useState(6);
  const [version, setVersion] = useState('short');
  const [sentences, setSentences] = useState('sentences');
  const [feedback, setFeedback] = useState(false);
  const [revise, setRevise] = useState(false);
  const [getSelectClassStudents, { data: fetchStudents }] = useLazyQuery(CLASS_STUDENT_LIST);

  const [assignAITopicsToStudents, { loading }] = useMutation(ASSIGN_AI_TOPIC_TO_STUDENTS);

  const levelList = [
    { value: 'Grade 1', label: 'Basic A' },
    { value: 'Grade 4', label: 'Basic B' },
    {
      value: 'Grade 7',
      label: 'Intermediate',
    },
    {
      value: 'Grade 11',
      label: 'Advanced',
    },
    { value: 'College Level', label: 'College' },
  ];

  useEffect(() => {
    if (book.prompt) {
      setPrompt(book.prompt);
    }
  }, [book]);
  const handleSaveAndAssign = async () => {
    const selectedStudents = students.filter((student, idx) => checkedList[idx]);
    const studentIdxs = selectedStudents.map((student) => student.user?.idx);

    await assignAITopicsToStudents({
      variables: {
        class_idx: selectedClass.idx,
        start_time: [moment(assignDate).format('YYYY-MM-DD')],
        limit_time: 1440 * dueDate,
        user_idx_list: studentIdxs,
        type: 'book-report',
        external_data: [
          {
            idx: book.idx,
            ai_topic_idx: book.idx,
            writing_coach: true,
            dueDate: dueDate,
            version: version,
            sentences: sentences,
            feedback: feedback,
            revise: revise,
            revisedPrompt: prompt,
          },
        ],
        start_date: moment(assignDate).format('YYYY-MM-DD'),
        end_date: moment(assignDate).add(dueDate, 'days').format('YYYY-MM-DD'),
        study_time: moment(assignDate).format('HH:mm'),
        data: JSON.stringify({
          book: book,
          level: level,
          assign_date: assignDate,
          writing_coach: true,
          dueDate: dueDate,
          version: version,
          sentences: sentences,
          feedback: feedback,
          revise: revise,
          revisedPrompt: prompt,
        }),
      },
    });

    close();
  };

  useEffect(() => {
    if (fetchStudents?.classStudentList) {
      setStudents(fetchStudents?.classStudentList);
    }
  }, [fetchStudents]);

  function handleChange(value) {
    setCheckedList([]);
    setSelectedClass(classes?.filter((classInfo) => classInfo?.idx === value)[0]);
    getSelectClassStudents({
      variables: { class_idx: value },
    });
  }

  function onCheckAllChange(e) {
    if (e.target.checked) {
      setCheckedList(new Array(students.length).fill(true));
    } else {
      setCheckedList([]);
    }
  }

  function onChange(e, idx) {
    checkedList[idx] = e.target.checked;
    setCheckedList(JSON.parse(JSON.stringify(checkedList)));
  }

  return (
    <ModalWrapper>
      <RowFlex style={{ height: '100%', justifyContent: 'space-between', alignItems: 'stretch', width: '100%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'space-between',
            flex: 1,
            paddingRight: '20px',
            // height:'100%',
            minHeight: '200px',
            width: '70%',
            borderRight: '1px solid #5a789a',
            marginRight: '20px',
          }}
        >
          <RowFlex style={{ padding: '0 0 12px', width: '100%' }}>
            <ModalLabel style={{ width: '100px', minWidth: '100px' }}>Assignment :</ModalLabel>
            <Form.Item style={{ marginBottom: 0, width: '100%' }}>
              <Input placeholder="Topic" value={book?.topic} disabled />
            </Form.Item>
          </RowFlex>
          <ColumnFlex>
            <ModalLabel style={{ paddingBottom: '9px' }}>Topic / Prompt :</ModalLabel>
            <Form.Item style={{ marginBottom: 0 }} name="prompt">
              <Input.TextArea placeholder={book?.prompt || 'Prompt'} value={prompt} onChange={(e) => setPrompt(e.target.value)} />
            </Form.Item>
          </ColumnFlex>
          <ModalLabel style={{ color: '#2E75B6', padding: '10px 0 5px 0' }}>Student</ModalLabel>
          <RowFlex style={{ justifyContent: 'space-between', width: '100%' }}>
            <RowFlex>
              <ModalLabel style={{ width: '65px' }}>Level :</ModalLabel>
              <Form.Item style={{ marginBottom: 0 }}>
                <Select
                  style={{ alignItems: 'center', width: '135px', maxWidth: '100%', border: '1px solid #d9d9d9', backgroundColor: '#fff' }}
                  value={level}
                  onChange={(e) => {
                    setLevel(e);
                  }}
                  bordered={false}
                >
                  {levelList.map((e) => (
                    <Option key={e.value} value={e.value}>
                      {e.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </RowFlex>
            <RowFlex style={{ justifyContent: 'space-between' }}>
              <ModalLabel style={{ width: '130px', textAlign: 'right' }}>Min. Words :</ModalLabel>
              <Form.Item style={{ marginBottom: 0 }}>
                <Select
                  style={{ alignItems: 'center', width: '130px', maxWidth: '100%', border: '1px solid #d9d9d9', backgroundColor: '#fff' }}
                  value={sentences}
                  onChange={(e) => {
                    setSentences(e);
                  }}
                  bordered={false}
                >
                  {[
                    {
                      value: 'sentences',
                      label: `Sentences`,
                    },
                    { value: 60, label: 'Min 60 words' },
                    { value: 100, label: 'Min 100 words' },
                    {
                      value: 150,
                      label: 'Min 150 words',
                    },
                    {
                      value: 250,
                      label: 'Min 250 words',
                    },
                  ].map((e) => (
                    <Option key={e.value} value={e.value}>
                      {e.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </RowFlex>
          </RowFlex>
          <RowFlex style={{ justifyContent: 'space-between', width: '100%' }}>
            <RowFlex>
              <ModalLabel style={{ width: '65px' }}>Report :</ModalLabel>
              <Form.Item style={{ marginBottom: 0 }}>
                <Select
                  style={{ alignItems: 'center', width: '135px', maxWidth: '100%', border: '1px solid #d9d9d9', backgroundColor: '#fff' }}
                  value={version}
                  onChange={(e) => {
                    setVersion(e);
                  }}
                  bordered={false}
                >
                  {[
                    { value: 'long', label: 'Long Ver.' },
                    { value: 'short', label: 'Short Ver.' },
                  ].map((e) => (
                    <Option key={e.value} value={e.value}>
                      {e.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </RowFlex>
            <RowFlex style={{ justifyContent: 'space-between', padding: '12px 0 0px' }}>
              <ModalLabel style={{ width: '130px', textAlign: 'right' }}>Feedback Button :</ModalLabel>
              <Form.Item style={{ marginBottom: 0 }}>
                <Select
                  style={{ alignItems: 'center', width: '130px', maxWidth: '100%', border: '1px solid #d9d9d9', backgroundColor: '#fff' }}
                  value={feedback}
                  onChange={(e) => {
                    setFeedback(e);
                  }}
                  bordered={false}
                >
                  {[
                    { value: true, label: 'On' },
                    { value: false, label: 'Off' },
                  ].map((e) => (
                    <Option key={e.value} value={e.value}>
                      {e.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </RowFlex>
          </RowFlex>

          <RowFlex style={{ width: '100%', justifyContent: 'end' }}>
            <RowFlex style={{ minWidth: '250px', width: '50%', justifyContent: 'space-between', padding: '12px 0 6px' }}>
              <ModalLabel style={{ width: '130px', textAlign: 'right' }}>Revise Button :</ModalLabel>
              <Form.Item style={{ marginBottom: 0 }}>
                <Select
                  style={{ alignItems: 'center', width: '130px', maxWidth: '100%', border: '1px solid #d9d9d9', backgroundColor: '#fff' }}
                  value={revise}
                  onChange={(e) => {
                    setRevise(e);
                  }}
                  bordered={false}
                >
                  {[
                    { value: true, label: 'On' },
                    { value: false, label: 'Off' },
                  ].map((e) => (
                    <Option key={e.value} value={e.value}>
                      {e.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </RowFlex>
          </RowFlex>
          <ColumnFlex>
            <ModalLabel style={{ paddingBottom: '6px 0 4px' }}>Assign Date :</ModalLabel>
            <RowFlex>
              <Form.Item style={{ marginBottom: 0, width: '50%' }}>
                <DatePicker
                  placeholder="Calendar pull down
                "
                  style={{ width: '100%' }}
                  onChange={(e) => setAssignDate(e)}
                />
              </Form.Item>
              &nbsp; &nbsp; &nbsp;
              <Form.Item style={{ marginBottom: 0, width: '50%' }}>
                <Select
                  style={{ alignItems: 'center', width: '200px', maxWidth: '100%', border: '1px solid #d9d9d9', backgroundColor: '#fff' }}
                  placeholder={` Due Date `}
                  onChange={(e) => {
                    setDueDate(e);
                  }}
                  bordered={false}
                >
                  {[1, 2, 3, 4, 5, 6].map((e) => (
                    <Option key={e} value={e}>
                      {e}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </RowFlex>
          </ColumnFlex>
        </div>
        <Form
          // labelCol={{
          //   span: 0,
          // }}
          // wrapperCol={{
          //   span: 24,
          // }}
          style={{ width: '30%' }}
          layout="vertical"
        >
          <Form.Item name="class" label="" style={{ marginBottom: 5 }}>
            <Select
              showSearch
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              style={{ width: '100%', minWidth: 100 }}
              placeholder="Class"
              onChange={handleChange}
            >
              {classes.map((classInfo) => (
                <Option key={classInfo.idx} value={classInfo.idx}>
                  {classInfo.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <StudentList
            header={
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <label>Name</label>
                <div>
                  Select All &nbsp; <Checkbox onChange={onCheckAllChange}></Checkbox>
                </div>
              </div>
            }
            footer={<div></div>}
            size="small"
            bordered
            dataSource={students}
            style={{ backgroundColor: '#fff' }}
            renderItem={(item, idx) => (
              <List.Item style={{ display: 'flex' }}>
                <span>{item.user.name}</span> <Checkbox checked={checkedList[idx]} onChange={(e) => onChange(e, idx)}></Checkbox>
              </List.Item>
            )}
          />
        </Form>
      </RowFlex>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
        <Button style={{ marginRight: 10 }} onClick={close}>
          Cancel
        </Button>
        <Button type="primary" onClick={handleSaveAndAssign} loading={loading}>
          {' '}
          Assign{' '}
        </Button>
      </div>
    </ModalWrapper>
  );
};

const ExcelUpload = ({ onFileSelect, setLoading }) => {
  const [topics, setTopics] = useState([]);

  useEffect(() => {
    if (topics.length > 0) {
      onFileSelect(
        topics.map((item) => {
          return { ...item };
        }),
      );
    }
    // eslint-disable-next-line
  }, [topics]);

  const props = {
    multiple: false,
    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    onChange: (info) => {
      const file = info.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          /* Parse data */
          //https://stackoverflow.com/questions/53163552/format-date-with-sheetjs
          const ab = e.target.result;
          const wb = XLSX.read(ab, { type: 'binary', cellText: false, cellDates: true });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          console.log('ws', wsname);
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, {
            header: ['title', 'prompt', 'level', 'group_name'],
            raw: false,
            dateNF: 'yyyy-mm-dd',
          });
          if (data && data instanceof Array) {
            const filtered_data = data.slice(1);
            setTopics(filtered_data);
          } else {
            alert('오류가 발생했습니다.');
          }
        };
        reader.readAsArrayBuffer(file);
      } else {
        setTopics([]);
      }
    },
  };
  return (
    <Input
      key={`finle-input-0`}
      type="file"
      {...props}
      name="files"
      style={{
        width: '200px',
        margin: '0 10px',
      }}
    />
  );
};

const StudentList = styled(List)`
  border: 1px solid #5194b6;
  background-color: '#fff';
  height: 250px;
  .ant-list-header {
    border-bottom: 1px solid #5194b6;
  }
  .ant-list-items {
    overflow-y: auto;
    height: 200px;
  }
  .ant-spin-nested-loading {
    .ant-spin-container {
      .ant-list-empty-text {
        .ant-empty {
          /* .ant-empty-description{ */
          display: none;
          /* } */
        }
      }
    }
  }
`;

const ModalWrapper = styled(ColumnFlex)`
  min-height: 350px;
  justify-content: space-between;
  /* width: 860px;
@media (max-width: 900px) {
    width: 750px;
  } */
`;

const EditModal = ({ visible, onClose, type, data, refetch, folderType }) => {
  const [aiTopics, setAiTopics] = useState([]);
  const [createAIGroupTopic, { loading: createLoading }] = useMutation(CREATE_AI_GROUP_TOPIC);
  const [editAITopic, { loading: editLoading }] = useMutation(EDIT_AI_TOPIC);
  const [activeIdx, setActiveIdx] = useState(null);
  const handleClickLesson = (idx) => {
    if (activeIdx === idx) {
      setActiveIdx(null);
    } else {
      setActiveIdx(idx);
    }
  };
  useEffect(() => {
    if (type === 'book-report-group') {
      setAiTopics(data?.ai_topic || []);
    } else {
      setAiTopics(data ? [data] : []);
    }
  }, [data, type]);

  const handleSaveClick = async (values) => {
    if (type === 'book-report-group') {
      let updatedArray = [];
      for (let i = 0; i < values.aiTopics.length; i++) {
        updatedArray.push({
          id: values.aiTopics[i].idx,
          ai_talking_group_idx: data.idx,
          level: values.aiTopics[i].level,
          topic: values.aiTopics[i].topic,
          prompt: values.aiTopics[i].prompt,
          group_name: values.group_name,
        });
      }
      await createAIGroupTopic({
        variables: {
          folder_type: folderType,
          type: 'book-report',
          data: JSON.stringify(updatedArray),
        },
      });
    } else {
      console.log(values);
      for (let i = 0; i < values.aiTopics.length; i++) {
        await editAITopic({
          variables: {
            idx: values.aiTopics[i].idx,
            topic: values.aiTopics[i].prompt,
            type: 'book-report',
            data: JSON.stringify({
              book: {
                level: values.aiTopics[i].level,
                topic: values.aiTopics[i].topic,
                prompt: values.aiTopics[i].prompt,
              },
            }),
          },
        });
      }
    }
    refetch();
    onClose();
  };

  const handleAddTopic = () => {
    setAiTopics([
      ...aiTopics,
      {
        level: '',
        topic: '',
        prompt: '',
      },
    ]);
  };

  const handleDeleteTopic = (indx) => {
    if (window.confirm(`Lesson ${indx + 1} 항목을 삭제하시겠습니까?`)) {
      setAiTopics(aiTopics.filter((item, index) => index !== indx));
    }
  };

  return (
    <Modal title="Edit" visible={visible} onCancel={onClose} footer={null} width={900} destroyOnClose onClosed={() => refetch()}>
      <div>
        <Form layout="vertical" onFinish={handleSaveClick} initialValues={data ? { group_name: data.group_name, level: data.level } : {}}>
          {type === 'book-report-group' && (
            <Form.Item label="Group Name" name="group_name" rules={[{ required: true, message: 'Please input group name!' }]}>
              <Input />
            </Form.Item>
          )}

          {type === 'book-report-group' && (
            <Form.Item label="Level" name="level" rules={[{ required: true, message: 'Please input level!' }]}>
              <Input />
            </Form.Item>
          )}

          {aiTopics &&
            aiTopics.map((item, indx) => {
              let topicData = JSON.parse(item.data || '{}');
              return (
                <div key={indx} style={{ border: '1px solid #d9d9d9', padding: '10px', marginBottom: '10px', paddingTop: '10px' }}>
                  {activeIdx === indx ? (
                    <>
                      <EditFormItem
                        label={
                          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                            <span>Title</span>
                            <div>
                              <DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => handleDeleteTopic(indx)} /> &nbsp;
                              <DownCircleOutlined
                                style={{ cursor: 'pointer' }}
                                rotate={activeIdx === indx && 180}
                                onClick={() => handleClickLesson(indx)}
                              />
                            </div>
                          </div>
                        }
                        name={['aiTopics', indx, 'topic']}
                        initialValue={topicData?.book?.topic}
                        rules={[{ required: true, message: 'Please input title!' }]}
                      >
                        <Input />
                      </EditFormItem>
                      <Form.Item
                        label="Prompt"
                        name={['aiTopics', indx, 'prompt']}
                        initialValue={topicData?.book?.prompt}
                        rules={[{ required: true, message: 'Please input prompt!' }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Level"
                        name={['aiTopics', indx, 'level']}
                        initialValue={topicData?.book?.level}
                        rules={[{ required: true, message: 'Please input level!' }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item name={['aiTopics', indx, 'idx']} initialValue={item.idx} style={{ display: 'none' }}>
                        <Input />
                      </Form.Item>
                    </>
                  ) : (
                    <div
                      style={{ width: '100%', display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }}
                      onClick={() => {
                        handleClickLesson(indx);
                      }}
                    >
                      <span>Lesson {indx + 1}</span>
                      <div>
                        <DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => handleDeleteTopic(indx)} /> &nbsp;
                        <DownCircleOutlined rotate={activeIdx === indx && 180} />
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
            <Button type="secondary" onClick={handleAddTopic}>
              Add Topic <PlusCircleOutlined />
            </Button>
          </div>
          <Form.Item>
            <Button type="secondary" htmlType="button" onClick={onClose} style={{ marginRight: 10 }}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" loading={createLoading || editLoading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};
