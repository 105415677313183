import React, { useCallback, useEffect, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Form } from 'antd';
import DefaultModal from './DefaultModal';
import { openNotification } from 'src/components/common/Notification';
import CreateConsultForm from 'src/pages/Consulting/CreateConsultForm';
import { MOD_CONSULT } from 'src/operations/mutations/consult';
import { GET_CONSULT_LIST, GET_ORDER_BY_ID } from 'src/operations/queries/consult';
import moment from 'moment';

const ConsultFormModal = ({ onCancel, order_idx, campus_idx, visible, searchMonth, onComplete = false, ...rest }) => {
  const [form] = Form.useForm();
  const { data: dataOrder, refetch } = useQuery(GET_ORDER_BY_ID, { skip: !order_idx, variables: { idx: order_idx } });
  console.log('dataOrder', dataOrder, order_idx);
  const [modConsult, { loading: loadingCreate }] = useMutation(MOD_CONSULT, {
    onCompleted: () => {
      openNotification('수정 완료!');
      handleCancel();
      if (onComplete) {
        onComplete();
        refetch();
      }
    },
    onError(error) {
      alert('error');
      console.log('error', error);
    },
    refetchQueries: [
      {
        query: GET_CONSULT_LIST,
        variables: { campus_idx, ym: searchMonth },
      },
    ],
  });

  useEffect(() => {
    if (dataOrder && visible) {
      form.setFieldsValue({
        student_name: dataOrder.orderItemByIdx.name,
        phone: dataOrder.orderItemByIdx.parent_phone,
        birthday: dataOrder.orderItemByIdx.user_data.birthday ? moment(dataOrder.orderItemByIdx.user_data.birthday) : null,
        reserve_date: dataOrder.orderItemByIdx?.order_leveltest_data?.reserve_date
          ? moment(dataOrder.orderItemByIdx?.order_leveltest_data?.reserve_date)
          : null,
        school_info: dataOrder.orderItemByIdx?.school_info,
      });
    }
  }, [dataOrder, form, visible]);
  const handleSubmit = useCallback(
    (values) => {
      const { phone, student_name, reserve_date, birthday, school_info } = values;
      modConsult({ variables: { order_idx, phone, student_name, reserve_date, birth_date: birthday, school_info, campus_idx } });
    },
    [order_idx, modConsult, campus_idx],
  );
  const handleCancel = useCallback(() => {
    form.resetFields();
    onCancel();
  }, [form, onCancel]);
  return (
    <DefaultModal
      title="예약 정보 수정"
      form={form}
      loading={loadingCreate}
      ActionCancel={handleCancel}
      popupMode="mod"
      width={60}
      visible={visible}
      {...rest}
    >
      {/* create 가 아니라 수정임.. 헐..*/}
      <CreateConsultForm form={form} onFinish={handleSubmit} />
    </DefaultModal>
  );
};

export default ConsultFormModal;
